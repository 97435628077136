import { API_BASE_URL, ACCESS_TOKEN } from '../constants/index.js';
import { request, textRequest, jjrequest, jsonSuccessFailureRequest, downloadFileRequest } from './APIUtils.js';

export function createPagedAnnotationValidation(aegId, name, mode, systemVocabularies, userVocabularies) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  var body = { name, mode };
  if (systemVocabularies) {
    body = {...body, systemVocabularies}
  }
  if (userVocabularies) {
    body = {...body, userVocabularies}
  }

  return jsonSuccessFailureRequest({
    url: API_BASE_URL + "/paged-annotation-validation/new?aegId=" + aegId,
    body: JSON.stringify(body),
    method: 'POST'
  })
}

export function updatePagedAnnotationValidation(id, name, mode, systemVocabularies, userVocabularies) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  var body = { name, mode };
  if (systemVocabularies) {
    body = {...body, systemVocabularies}
  }
  if (userVocabularies) {
    body = {...body, userVocabularies}
  }

  return jsonSuccessFailureRequest({
    url: API_BASE_URL + "/paged-annotation-validation/update/" + id,
    body: JSON.stringify(body),
    method: 'POST'
  })
}

export function deletePagedAnnotationValidation(id) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  return jsonSuccessFailureRequest({
    url: API_BASE_URL + "/paged-annotation-validation/delete/" + id,
    method: 'DELETE'
  });
}

export function editAnnotationValidation(validationId) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }
  return request({
    url: API_BASE_URL + "/paged-annotation-validation/editValidation/" + validationId,
    method: 'POST'
  })
}

export function stopPagedAnnotationValidation(validationId) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }
  return jsonSuccessFailureRequest({
    url: API_BASE_URL + "/paged-annotation-validation/stop/" + validationId,
    method: 'POST'
  })
}

export function resumePagedAnnotationValidation(validationId) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }
  return jsonSuccessFailureRequest({
    url: API_BASE_URL + "/paged-annotation-validation/resume/" + validationId,
    method: 'POST'
  })
}

export function commitAnnotationValidationPage(id, edits, lockId) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  return jjrequest({
    url: API_BASE_URL + "/paged-annotation-validation/commit-page/" + id + "?lockId=" + lockId,
    body: JSON.stringify(edits),
    method: 'POST',
  })
}

export function getValidationProgress(id) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }
  return jsonSuccessFailureRequest({
    url: API_BASE_URL + "/paged-annotation-validation/get/" + id + "?details=base,vocabularies,progress",
    method: 'GET',
  })
}

export function viewAnnotationValidation(id, currentPage, mode, serial, navigation, requestedPage) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }
  let url = API_BASE_URL + "/paged-annotation-validation/view/" + id + "?currentPage=" + currentPage + "&mode=" + mode;
  if (serial) {
    url = url + "&navigation=" + navigation;
  }
  else {
    url = url + "&requestedPage=" + requestedPage;
  }
  return request({
    url: url,
    method: 'GET'
  })
}

export function executePagedAnnotationValidation(id) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  return jsonSuccessFailureRequest({
    url: API_BASE_URL + "/paged-annotation-validation/execute/" + id,
    method: 'POST',
  })
}

export function clearPagedAnnotationValidationExecution(id) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  return jsonSuccessFailureRequest({
    url: API_BASE_URL + "/paged-annotation-validation/clear-execution/" + id,
    method: 'POST'
  })
}

export function publishPagedAnnotationValidation(id) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  return jsonSuccessFailureRequest({
    url: API_BASE_URL + "/paged-annotation-validation/publish/" + id,
    method: 'POST',
  })
}

export function unpublishPagedAnnotationValidation(id) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  return jsonSuccessFailureRequest({
    url: API_BASE_URL + "/paged-annotation-validation/unpublish/" + id,
    method: 'POST',
  })
}

export function previewLastPagedAnnotationValidationExecution(id, shard, nextLine) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  var x = "";
  if (shard != null && nextLine != null) {
    x = "?shard=" + shard + "&offset=" + nextLine;
  }

  return textRequest({
    url: API_BASE_URL + "/paged-annotation-validation/preview-last-execution/" + id + x,
    method: 'GET'
  });
}

export function previewPublishedPagedAnnotationValidationExecution(id, shard, nextLine) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  var x = "";
  if (shard != null && nextLine != null) {
    x = "?shard=" + shard + "&offset=" + nextLine;
  }

  return textRequest({
    url: API_BASE_URL + "/paged-annotation-validation/preview-published-execution/" + id + x,
    method: 'GET'
  });
}

export function downloadPagedAnnotationValidationExecution(id) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  downloadFileRequest({
    url: API_BASE_URL + "/paged-annotation-validation/download-last-execution/" + id,
    method: 'GET'
  });
}

export function downloadPublishedPagedAnnotationValidationExecution(id) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  downloadFileRequest({
    url: API_BASE_URL + "/paged-annotation-validation/download-published-execution/" + id,
    method: 'GET'
  });
}

export function removeValidationPageLock(pavId, mode, page) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }
  return request({
    url: API_BASE_URL + "/paged-annotation-validation/remove-lock/" + pavId + "?mode=" + mode + "&page=" + page,
    method: 'POST',
  });
}

export function resolveResourceForPagedAnnotationValidation(pavId, resource) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }
  return jsonSuccessFailureRequest({
    url: API_BASE_URL + "/paged-annotation-validation/vocabularies/" + pavId +"/resolve?resource=" + resource,
    method: 'GET'
  })
}
