import React, { Component } from "react";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Collapse from "react-bootstrap/Collapse";
import Dropdown from "react-bootstrap/Dropdown";
import Button from "react-bootstrap/Button";
import AddRDFVocabularyModal from "./modals/AddRDFVocabularyModal.js"
import DeleteModal from "./modals/DeleteModal.js"

import { getRDFVocabularies } from '../../utils/VocabulariesAPI.js';
import { addRDFVocabulary, removeRDFVocabulary } from '../../utils/SystemAPI.js';
import { actionsMenu, toggleBoxColumn, throwToast, sortByField } from '../../utils/UIUtils';

export class RDFVocabulariesTab extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rdfVocabularies: null,
      selectedRdfVocabulary: null,

      rdfVocabulariesOpen: true,

      addRdfVocabularyModalShow: false
    };
  }

  componentDidMount() {
    getRDFVocabularies()
      .then(response => {
        this.setState({ rdfVocabularies: response.data.sort((a,b) => sortByField('name', a, b)) });
      })
      .catch(error => {
         throwToast('error', error.message);
      });
  }

  executeAction(action, params) {
    if (action === 'select-rdf-vocabulary') {
      this.setState({ selectedRdfVocabulary: params.rdfVocabulary})
    } else if (action === 'add-rdf-vocabulary') {
      addRDFVocabulary(params.id)
        .then(response => {
          this.setState({ rdfVocabularies: this.state.rdfVocabularies.concat(response.data).sort((a,b) => sortByField('name', a, b)) });
        })
        .catch(error => {
           throwToast('error', error.message);
        });
    } else  if (action === 'remove-rdf-vocabulary') {
      this.setState({ deleteModalShow:true, deleteModalCommand: 'remove-rdf-vocabulary', deleteModalParams:params})
    } else  if (action === 'remove-rdf-vocabulary-ok') {
      console.log(params)
      removeRDFVocabulary(params.id)
        .then(response => {
          this.setState({ rdfVocabularies: this.state.rdfVocabularies.filter(e => e.id != params.id), selectedRdfVocabulary: undefined });
        })
        .catch(error => {
           throwToast('error', error.message);
        });
    }
  }


  render() {

    return (
      <Container className="pl-2">
        <Row>

          <Col md={3}>
            <Container className={this.state.rdfVocabularies && this.state.rdfVocabularies.length > 0 ? "groupborder" : "groupborder-empty"}>
              <Row className={this.state.rdfVocabularies && this.state.rdfVocabularies.length > 0 ? "header" : "header-empty"}>
                <Col>
                  RDF Vocabularies
                </Col>

                {toggleBoxColumn('rdfVocabulariesOpen', this, 'rdfVocabularies')}

                <Col className="mybutton" md="auto">
                  {actionsMenu(
                  <div>
                    <Dropdown.Item className="py-2"
                                   onClick={() => this.setState({ addRdfVocabularyModalShow: true })}
                                   >
                      <span className="menu-icon fa fa-plus fa-lg mr-3" />Add RDF Vocabulary...
                    </Dropdown.Item>
                  </div>)}
                </Col>

                {this.state.addRdfVocabularyModalShow &&
                <AddRDFVocabularyModal show={this.state.addRdfVocabularyModalShow}
                                   onOK={(id) => { this.executeAction('add-rdf-vocabulary', { id }); this.setState({ addRdfVocabularyModalShow: false})}}
                                   onClose={() => this.setState({ addRdfVocabularyModalShow: false})}/>}
              </Row>

              <Collapse in={this.state.rdfVocabulariesOpen}>
                <div>
                  {this.state.rdfVocabularies && this.state.rdfVocabularies.map((rdfVocabulary, index) => (
                  <Row key={"rdf-vocabulary-" + index}>
                    <Col md={12} className={this.state.selectedRdfVocabulary && this.state.selectedRdfVocabulary.name === rdfVocabulary.name? " selected-item" : ""}>
                      <span className="lbutton alink" onClick={() => this.executeAction('select-rdf-vocabulary', { rdfVocabulary: rdfVocabulary })}>
                      {rdfVocabulary.name}
                      </span>
                    </Col>
                  </Row>))}
                </div>
              </Collapse>

            </Container>
          </Col>

          <Col md={9}>
            <Container className="userspace-right">
              <Row className="userspace-right-inner">
                <Col>

                  {this.state.selectedRdfVocabulary &&
                  <Container className="groupborder">
                    <Row className="header">
                      <Col>
                        <span className="crimson-std">RDF Vocabulary:</span> {this.state.selectedRdfVocabulary.name}
                      </Col>
                      <Col className="mybutton" md={1}>
                        {actionsMenu(
                        <div>
                          <Dropdown.Item className="py-2"
                                         onClick={() => this.executeAction('remove-rdf-vocabulary', { id: this.state.selectedRdfVocabulary.id } )}>
                            <span className="menu-icon fa fa-edit fa-lg mr-3" />Remove
                          </Dropdown.Item>
                        </div>)}
                      </Col>

                      {this.state.deleteModalShow && <DeleteModal show={this.state.deleteModalShow}
                                              command={this.state.deleteModalCommand}
                                              params={this.state.deleteModalParams}
                                              actions={(choice, command, params) => { this.setState( {deleteModalShow:false }); return (choice === 'ok' ? this.executeAction(command + '-ok', params): null)} }
                                              />}
                    </Row>

                    <Row className="object-field-row">
                      <Col md={3}>
                        Name
                      </Col>
                      <Col md={9}>
                        {this.state.selectedRdfVocabulary.name}
                      </Col>
                    </Row>

                    {this.state.selectedRdfVocabulary.entityDescriptors && this.state.selectedRdfVocabulary.entityDescriptors.map((e,index) => (
                    <div key={index}>
                      <Row className="object-field-row">
                        <Col md={3}>
                          Namespace
                        </Col>
                        <Col md={9}>
                          {e.namespace}
                        </Col>
                      </Row>

                      <Row className="object-field-row">
                        <Col md={3}>
                          Prefix
                        </Col>
                        <Col md={9}>
                          {e.prefix}
                        </Col>
                      </Row>
                    </div>))}
                  </Container>}

                </Col>

              </Row>
            </Container>
          </Col>

        </Row>
    </Container>
    );
  }
}

export default RDFVocabulariesTab;
