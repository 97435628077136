import React, { Component } from "react";
import Container from "react-bootstrap/Container";
import Collapse from "react-bootstrap/Collapse";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import InputGroup from "react-bootstrap/InputGroup";
import FormControl from "react-bootstrap/FormControl";
import { toast } from 'react-toastify';
import { getAllTemplates, createTemplate, deleteTemplate, getTemplate } from "../../../utils/TemplateAPI"
import { getOtherPublicDatasets, getMyDatasets } from "../../../utils/DatasetAPI"
import { checkComparatorIdentifier } from "../../../utils/ComparatorAPI"
//import { getAllTemplates, createAnnotatorTemplate, deleteTemplate, getTemplate, getEditAnnotator } from "../../../utils/APIUtils"
import { renderClasses, applySelection, readClasses, getSchema, getNames, getCheckboxes } from '../../../utils/SchemaUtils';
import { create, all } from 'mathjs'

import { copyReplaceAt } from '../../../utils/functions.js'
import { toggleBoxColumn } from '../../../utils/UIUtils.js'

export class ComparatorModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      comparator: props.comparator,
      schemaDatasetId: props.comparator ? props.comparator.schemaDatasetId : undefined,

      datasets: [],

      checkboxes: {},

      metrics: [],
      logics: [],

      dimensions: [],

      schemaOpen: true,
      metricsOpen: true,
      logicsOpen: true
    }

    this.checkboxes = {}
    this.names = {}
    this.datatypes = {}

    this.metricsDimension = [];
    this.metricsFunction = [];
    this.metricsPreprocess = [];
    this.metricsName = [];

    this.logicsExpression = [];
    this.logicsCondition = [];
    this.logicsConditionType = [];

    this.config = { }
    this.math = create(all, this.config)

    this.metricFunctions = [
      {label: 'Equality distance', value:'strdist:equal', datatype: 'xsd:integer'},
      {label: 'Hamming distance', value:'strdist:hamming', datatype: 'xsd:decimal' },
      {label: 'Jaro distance', value:'strdist:jaro', datatype: 'xsd:decimal'},
      {label: 'Jaro-Winkler distance', value:'strdist:jaro-winkler', datatype: 'xsd:decimal'},
      {label: 'Levenshtein distance', value:'strdist:levenshtein', datatype: 'xsd:decimal'},
      {label: 'Substring distance', value:'strdist:substring', datatype: 'xsd:integer'},
    ]

    this.conditionTypes = [
      {label: 'Success condition', value:'successCondition'},
      {label: 'Failure condition', value:'failCondition' }
    ]

    this.getDatasets()

    if (this.props.comparator) {
      this.schemaDatasetIdChanged(this.props.comparator.schemaDatasetId)
    }

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  existsComparatorIdentifier() {
    if (this.identifier.value) {
      checkComparatorIdentifier(this.identifier.value, this.props.dataset.id)
      .then(text => {
        if (!text.data.valid || text.data.exists) {
           this.setState( {identifierExists : true })
        } else
            this.setState( {identifierExists : false })
        })
      }
    return false
   }

  componentDidMount() {
    this.complete(this.props)
  }

  complete(props) {
    var metrics = props.comparator ? props.comparator.computation.metrics : [];
    var logics = props.comparator ? props.comparator.computation.logic : [];
    var dimensions = props.comparator ? props.comparator.computation.dimensions.map(e => e.name) : [];

    this.setState({ dimensions, metrics, logics })
  }

  componentDidUpdate() {
    if (this.state.comparator) {
       if (this.schemaDatasetId.value == '') {
         this.schemaDatasetId.value = this.state.comparator.schemaDatasetId
       }

       for (var i in this.state.metrics)  {
         this.metricsDimension[i].value = this.state.metrics[i].dimension
         this.metricsFunction[i].value = this.state.metrics[i].function
         this.metricsPreprocess[i].value = this.state.metrics[i].preprocess ? this.state.metrics[i].preprocess.join(', ') : ''
         this.metricsName[i].value = this.state.metrics[i].name ? this.state.metrics[i].name : ''
       }

       for (var i in this.state.logics)  {
         this.fillInLogic(i, this.state.logics[i])
       }

    }
  }

  fillInLogic(i, logic) {
    this.logicsExpression[i].value = logic.expression ? logic.expression : ''
    if (logic.failCondition != null) {
      this.logicsConditionType[i].value = 'failCondition'
      this.logicsCondition[i].value = logic.failCondition.replace("\{expression\}","").trim()
    } else if (logic.successCondition  != null) {
      this.logicsConditionType[i].value = 'successCondition'
      this.logicsCondition[i].value = logic.successCondition.replace("\{expression\}","").trim()
    }
  }

  getFunctionType(func) {
    for (var f of this.metricFunctions) {
      if (f.value == func) {
        return f.datatype
      }
    }
  }

  getDatatypeValues() {
    return [ '', "xsd:string", "xsd:integer" ]
  }

  getDatasets() {
    var datasetArrayA = []
    var datasetArrayB = []

    var _this = this;
    var promises = [] ;
    promises.push(
      new Promise(function(resolve, reject) {
        getMyDatasets(['COLLECTION'],'DATASET', _this.props.project ? _this.props.project.id : null)
          .then(response => {

            for (var d of response.data) {
              if (d.publishState.state.startsWith("PUBLISHED")) {
                datasetArrayA.push(d)
              }
            }

            resolve()
          }).catch(error => { resolve() })
        })
    )

      if (this.props.project) {
        promises.push(
          new Promise(function(resolve, reject) {
          getOtherPublicDatasets(['COLLECTION'],'DATASET', _this.props.project.id)
            .then(response => {

              for (var d of response.data) {
                if (d.publishState.state.startsWith("PUBLISHED")) {
                  datasetArrayB.push(d)
                }
              }

              resolve()
            }).catch(error => { resolve() })
          })
        )
      }

      Promise.all(promises).then(() => {
        _this.setState({ datasets: datasetArrayA.concat(datasetArrayB) })
      })
  }

  schemaDatasetIdChanged(datasetId) {
    getSchema(this.onLoad, this, datasetId)
    this.setState( { schemaDatasetId: datasetId })

  }

  onLoad(_this) {
      if (_this.props.comparator) {
          _this.indexChanged();
     }
  }

  indexChanged() {

    var checkboxes = {};
    for (var ch in this.state.checkboxes) {
      if (ch.match('^check-c[0-9]+$')) {
        if (this.checkboxes[ch]) {
          checkboxes[ch] = this.state.checkboxes[ch]
          this.checkboxes[ch].checked = false
        }
      // } else {
      //   delete this.checkboxes[ch]
      }
    }

    var _this = this;
    this.setState({ checkboxes }, () => {
       applySelection(this, this.props.comparator)

     } )
  }

  // do not delete. ir is used by the schema UI
  enableNewIndex() {
  }




  handleSubmit(event) {
    event.preventDefault();

    var res = readClasses('', [], { value: 0 }, this )

    var dimensions = [];
    for (var ikm of res.metadata) {
      dimensions.push( { name: ikm.name, column: ikm.name, datatype: ikm.datatype})
    }

    // console.log(dimensions)

    var _this = this;

    var metrics = [];
    console.log('A')
    console.log(this.state.metrics)
    this.state.metrics.map((el, index) => {
      var obj = {name: el.name, dimension: el.dimension, function: el.function, datatype: this.getFunctionType(el.function) }
      if (el.preprocess && el.preprocess.length > 0) {
        obj = {...obj, preprocess: el.preprocess }
      }
      metrics.push(obj)
    })

    // console.log(metrics)

    var logic = [];
    this.state.logics.map((el, index) => {
      logic.push(el)
    })

    // console.log(logic)

    if (this.props.comparator && this.props.comparator.id) {
      this.props.onUpdate(this.props.comparator.id,
                          this.name.value,
                          this.identifier.value,
                          this.description.value,
                          this.schemaDatasetId.value,
                          res && res.elements ? res.elements[0] : null,
                          res ? res.metadata : null,
                          { dimensions, metrics, logic}
                        )
    } else {
      this.props.onOK(this.name.value,
                      this.identifier.value,
                      this.description.value,
                      this.schemaDatasetId.value,
                      res && res.elements ? res.elements[0] : null,
                      res ? res.metadata : null,
                      { dimensions, metrics, logic}
                    )
    }

  }

  addMetric() {
    this.setState({ metrics: this.state.metrics.slice().concat({ })});
  }

  deleteMetric(index) {
    this.setState({ metrics: this.state.metrics.slice(0, index).concat(this.state.metrics.slice(index + 1)) },
      () => {
      for (var i in this.state.metrics)  {
        this.metricsDimension[i].value = this.state.metrics[i].dimension
        this.metricsFunction[i].value = this.state.metrics[i].function
        this.metricsPreprocess[i].value = this.state.metrics[i].preprocess ? this.state.metrics[i].preprocess.join(', ') : ''
        this.metricsName[i].value = this.state.metrics[i].name ? this.state.metrics[i].name : ''
      }})
  }

  moveUpMetric(index) {
    this.setState({ metrics: this.state.metrics.slice(0, index - 1).concat([this.state.metrics[index]]).concat([this.state.metrics[index-1]]).concat(this.state.metrics.slice(index + 1)) },
      () => {
        for (var i in this.state.metrics)  {
          this.metricsDimension[i].value = this.state.metrics[i].dimension
          this.metricsFunction[i].value = this.state.metrics[i].function
          this.metricsPreprocess[i].value = this.state.metrics[i].preprocess ? this.state.metrics[i].preprocess.join(', ') : ''
          this.metricsName[i].value = this.state.metrics[i].name ? this.state.metrics[i].name : ''
        }})
  }

  moveDownMetric(index) {
    this.setState({ metrics: this.state.metrics.slice(0, index).concat([this.state.metrics[index + 1 ]]).concat([this.state.metrics[index]]).concat(this.state.metrics.slice(index + 2)) },
      () => {
        for (var i in this.state.metrics)  {
          this.metricsDimension[i].value = this.state.metrics[i].dimension
          this.metricsFunction[i].value = this.state.metrics[i].function
          this.metricsPreprocess[i].value = this.state.metrics[i].preprocess ? this.state.metrics[i].preprocess.join(', ') : ''
          this.metricsName[i].value = this.state.metrics[i].name ? this.state.metrics[i].name : ''
        }})
  }

  metricChanged(index, event, field) {
    var v = event.target.value;
    if (field == 'preprocess') {
      if (v && v.length > 0) {
        var arr = v.split(",");
        for (var i in arr) {
          arr[i] = arr[i].trim();
        }
        v = arr;
      } else {
        v = []
      }
    }
    this.setState(copyReplaceAt('metrics', this.state.metrics, index, { ...this.state.metrics[index], [field]: v}))
  }

  addLogic() {
    this.setState({ logics: this.state.logics.slice().concat({})});
  }

  deleteLogic(index) {
    this.setState({ logics: this.state.logics.slice(0, index).concat(this.state.logics.slice(index + 1)) },
      () => {
      for (var i in this.state.logics)  {
        this.fillInLogic(i, this.state.logics[i]);
      }})
  }

  moveUpLogic(index) {
    this.setState({ logics: this.state.logics.slice(0, index - 1).concat([this.state.logics[index]]).concat([this.state.logics[index-1]]).concat(this.state.logics.slice(index + 1)) },
      () => {
      for (var i in this.state.logics)  {
        this.fillInLogic(i, this.state.logics[i]);
      }})
  }

  moveDownLogic(index) {
    this.setState({ logics: this.state.logics.slice(0, index).concat([this.state.logics[index + 1 ]]).concat([this.state.logics[index]]).concat(this.state.logics.slice(index + 2)) },
      () => {
      for (var i in this.state.logics)  {
        this.fillInLogic(i, this.state.logics[i]);
      }})
  }

  logicChanged(index, event, field) {
    var obj = this.state.logics[index];
    if (field == 'expression') {
      obj = { ...obj, 'expression': event.target.value}
    } else if (field == 'condition') {
      var ct = this.logicsConditionType[index].value
      if (ct) {
        obj = { 'expression': obj.expression, [ct]: '{expression} ' + event.target.value}
      }
    } else if (field == 'conditionType') {
      var ct = this.logicsCondition[index].value
      if (ct) {
        obj = { 'expression': obj.expression, [event.target.value]: '{expression} ' + ct}
      }
    }
    this.setState(copyReplaceAt('logics', this.state.logics, index, obj))
  }

  nameChanged() {
    this.setState( {dimensions: getNames(this)})
  }

  isValidExpression(expr) {
    var regex = ""
    for (var v of this.state.metrics) {
      if (regex.length > 0) {
        regex += "|";
      }
      regex += v.name
    }

    regex = "\\{(?:" + regex + ")\\}"

    var re = new RegExp(regex, "g")
    var variables = expr.match(re)

    var sexpr = expr
    if (variables) {
      for (var v of variables) {
        sexpr = sexpr.replaceAll(v, "1")
      }
    }

    try {
      this.math.evaluate(sexpr)
      return true;
    } catch (e) {
      // console.log(e)
      return false;
    }
  }

  render() {

    return (
      <Modal show={this.props.show} onHide={this.props.onClose} animation={false} backdrop="static" size="xl" >
        <Form onSubmit={this.handleSubmit} id="annotator-modal-form">
          <Modal.Header>
            <Modal.Title>{this.props.comparator && this.props.comparator.id ? 'Edit Comparator' : 'New Comparator'}</Modal.Title>
          </Modal.Header>

          <Modal.Body>
          <Form.Group>
            <Form.Label className="required">Name</Form.Label>
            <Form.Control ref={node => (this.name = node)}
                 onChange={() => this.setState({ error: false })}
                 pattern="\S.*"
                 defaultValue={this.props.comparator ? this.props.comparator.name : ""}
                 required/>
          </Form.Group>

          <Form.Group>
            <Form.Label className="required">Identifier</Form.Label>
            <Form.Control ref={node => (this.identifier = node)}
                 onChange={() => this.existsComparatorIdentifier()}
                 isInvalid={this.identifier && this.identifier.value && ((!this.props.mapping && this.state.identifierExists) || (this.props.mapping && this.state.identifierExists && this.props.mapping.identifier !== this.identifier.value))}
                 defaultValue={this.props.comparator ? this.props.comparator.identifier : ""}
                 required/>
            <Form.Control.Feedback type="invalid">
                 Identifier already exists or is invalid.
           </Form.Control.Feedback>
          </Form.Group>

          <Form.Group>
            <Form.Label>Description</Form.Label>
            <Form.Control as="textarea" ref={node => (this.description = node)}
                 defaultValue={this.props.comparator ? this.props.comparator.description : ""}/>
          </Form.Group>

          <Form.Group>
            <Form.Label className="required">Schema dataset</Form.Label>
            <Form.Control as="select" ref={node => (this.schemaDatasetId = node)}
              // defaultValue={this.props.comparator ? this.props.comparator.schemaDatasetId : '' }
              defaultValue=''
              onChange={(event) => this.schemaDatasetIdChanged(event.target.value)}
              required>
              <option hidden disabled value=''> -- Select a base dataset -- </option>
              {this.state.datasets.map((el, index) =>
                <option key={index} value={el.id}>{el.name}</option>
              )}
            </Form.Control>
          </Form.Group>

            <Container className={this.state.checkboxes.length > 0 ? "groupborder" : "groupborder-empty"}>
              <Row className={this.state.checkboxes.length > 0 ? "header" : "header-empty"}>

                <Col className="bold">
                  <span className="crimson-std">Properties</span>
                </Col>

                {/*toggleBoxColumn('schemaOpen', this, 'checkboxes')*/}
                <Col className="mybutton" md="auto">
                  <Button type="button" className="menubutton"  aria-label="Toggle" onClick={() => this.setState({ schemaOpen: !this.state.schemaOpen})}><span className={this.state.checkboxes ? 'fa fa-angle-double-up' : 'fa fa-angle-double-down'}></span></Button>
                </Col>

                <Col className="mybutton" md={1}>
                </Col>
              </Row>

              <Collapse in={this.state.schemaOpen}>
                <div>
                  <Row className="index-field-row">
                    <Col md="5" >
                      Path
                    </Col>
                    <Col md="6">
                    <Row>
                      <Col md="3" className="index-column">
                        Name
                      </Col>
                      <Col md="2" className="index-column">
                        Type
                      </Col>
                      </Row>
                    </Col>
                  </Row>
                  {renderClasses(Object.keys(this.state.checkboxes).filter(k => k.match('^check-c[0-9]+$')), 0, this)}
                </div>
              </Collapse>
            </Container>

            <Container className={this.state.metrics.length > 0 ? "groupborder" : "groupborder-empty"}>
              <Row className={this.state.metrics.length > 0 ? "header" : "header-empty"}>

                <Col className="bold">
                  <span className="crimson-std">Metrics</span>
                </Col>

                {toggleBoxColumn('metricsOpen', this, 'metrics')}

                <Col className="mybutton" md={1}>
                  <Button type="button" className="deleteaddbutton" aria-label="New" onClick={(event) => this.addMetric()}><span className="fa fa-plus"></span></Button>
                </Col>
              </Row>

              <Collapse in={this.state.metricsOpen}>
                <div>
                  {this.state.metrics.map((el, index) =>
                  <Row key={index}>
                    <Col>
                    <Form.Group>
                      <Form.Label className="required">Name</Form.Label>
                      <Form.Control ref={node => (this.metricsName[index] = node)}
                        onChange={(event) => this.metricChanged(index, event, 'name')}
                        defaultValue={el.name ? el.name : ''} required>
                      </Form.Control>
                    </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group>
                        <Form.Label className="required">Property</Form.Label>
                        <Form.Control as="select"
                          ref={node => (this.metricsDimension[index] = node)}
                          onChange={(event) => this.metricChanged(index, event, 'dimension')}
                          defaultValue={el.dimension ? el.dimension : ''} required>
                          <option disabled value=''> -- Select a property -- </option>
                           {this.state.dimensions.map((el2, index2) =>
                            <option key={index2} value={el2}>{el2}</option>
                          )}
                        </Form.Control>
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group>
                        <Form.Label className="required">Metric</Form.Label>
                        <Form.Control as="select"
                          ref={node => (this.metricsFunction[index] = node)}
                          onChange={(event) => this.metricChanged(index, event, 'function')}
                          defaultValue={el.function ? el.function : ''} required>
                          <option disabled value=''> -- Select a metric -- </option>
                          {this.metricFunctions.map((el2, index2) =>
                            <option key={index2} value={el2.value}>{el2.label}</option>
                          )}
                        </Form.Control>
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group>
                        <Form.Label>Preprocess</Form.Label>
                        <Form.Control //as="select"
                          ref={node => (this.metricsPreprocess[index] = node)}
                          onChange={(event) => this.metricChanged(index, event, 'preprocess')}
                          defaultValue={el.preprocess ? el.preprocess : ''}>
                        </Form.Control>
                      </Form.Group>
                    </Col>
                    <Col md="auto" className="deleteeditbuttoncol">
                      <Button type="button" className="deleteeditbutton mr-3" aria-label="Delete" onClick={(event) => this.deleteMetric(index)}><span className="fa fa-times"></span></Button>
                      <Button type="button" className="deleteeditbutton mr-3" aria-label="Down" disabled={index==this.state.metrics.length-1} onClick={(event) => this.moveDownMetric(index)}><span className="fa fa-chevron-down"></span></Button>
                      <Button type="button" className="deleteeditbutton mr-3" aria-label="Up" disabled={index==0} onClick={(event) => this.moveUpMetric(index)}><span className="fa fa-chevron-up"></span></Button>
                    </Col>
                  </Row>)}
                </div>
              </Collapse>
            </Container>

            <Container className={this.state.logics.length > 0 ? "groupborder" : "groupborder-empty"}>
              <Row className={this.state.logics.length > 0 ? "header" : "header-empty"}>

                <Col className="bold">
                  {/*{this.props.dataset.name}: */}
                  <span className="crimson-std">Logic</span>
                </Col>

                {toggleBoxColumn('logicsOpen', this, 'logics')}

                <Col className="mybutton" md={1}>
                  <Button type="button" className="deleteaddbutton" aria-label="New" onClick={(event) => this.addLogic()}><span className="fa fa-plus"></span></Button>
                </Col>
              </Row>

              <Collapse in={this.state.logicsOpen}>
                <div>
                  {this.state.logics.map((el, index) =>
                  <Row key={index}>
                    <Col md="auto">
                      <span className="bold">{index + 1}</span>
                    </Col>
                    <Col>
                      <Form.Group>
                        <Form.Label className="required">Condition Type</Form.Label>
                        <Form.Control as="select"
                          ref={node => (this.logicsConditionType[index] = node)}
                          onChange={(event) => this.logicChanged(index, event, 'conditionType')}
                          defaultValue={el.conditionType ? el.conditionType : ''} required>
                          <option disabled value=''> -- Select a type -- </option>
                           {this.conditionTypes.map((el2, index2) =>
                            <option key={index2} value={el2.value}>{el2.label}</option>
                          )}
                        </Form.Control>
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group>
                        <Form.Label className="required">Expression</Form.Label>
                        <Form.Control ref={node => (this.logicsExpression[index] = node)}
                          onChange={(event) => this.logicChanged(index, event, 'expression')}
                          isInvalid={!this.logicsExpression[index] || !this.isValidExpression(this.logicsExpression[index].value)}
                          defaultValue={el.expression ? el.expression : ''} required>
                        </Form.Control>
                        <Form.Control.Feedback type="invalid">
                          Invalid expression
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col md={2}>
                      <Form.Group>
                        <Form.Label className="required">Condition</Form.Label>
                        <Form.Control ref={node => (this.logicsCondition[index] = node)}
                          onChange={(event) => this.logicChanged(index, event, 'condition')}
                          isInvalid={!this.logicsCondition[index] || !(new RegExp("^(?:<|>|<=|>=|==|!=)\\s?(?:[0-9]?\\.[0-9]*|[0-9]+)$").test(this.logicsCondition[index].value))}
                          defaultValue={el.condition ? el.condition : ''} required>
                        </Form.Control>
                        <Form.Control.Feedback type="invalid">
                          Invalid condition
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col md="auto" className="deleteeditbuttoncol">
                      <Button type="button" className="deleteeditbutton mr-3" aria-label="Delete" onClick={(event) => this.deleteLogic(index)}><span className="fa fa-times"></span></Button>
                      <Button type="button" className="deleteeditbutton mr-3" aria-label="Down" disabled={index==this.state.logics.length-1} onClick={(event) => this.moveDownLogic(index)}><span className="fa fa-chevron-down"></span></Button>
                      <Button type="button" className="deleteeditbutton mr-3" aria-label="Up" disabled={index==0} onClick={(event) => this.moveUpLogic(index)}><span className="fa fa-chevron-up"></span></Button>
                    </Col>

                </Row>)}
                </div>
              </Collapse>
            </Container>

          </Modal.Body>

          <Modal.Footer>
            <Button type="submit" variant="primary" id="submit-btn" className="mr-2">
              {this.props.comparator && this.props.comparator.id ? 'Update' : 'Create'}
            </Button>
            <Button variant="secondary" onClick={this.props.onClose}>
              Cancel
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    )
  }
}

export default ComparatorModal;
