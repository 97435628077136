import { API_BASE_URL, ACCESS_TOKEN } from '../constants/index.js';
import { noTokenRequest, jsonSuccessFailureRequest } from './APIUtils.js';

export function getCurrentDatabase() {
  return noTokenRequest({
    url: API_BASE_URL + "/database/current",
    method: 'GET'
  });
}

export function getDataServices() {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  return jsonSuccessFailureRequest({
    url: API_BASE_URL + "/database/services",
    method: 'GET',
  })
}

export function getImportTemplates() {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  return jsonSuccessFailureRequest({
    url: API_BASE_URL + "/database/templates",
    method: 'GET',
  })
}

export function getValidationModes() {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  return jsonSuccessFailureRequest({
    url: API_BASE_URL + "/database/validation-modes",
    method: 'GET',
  })
}

// export function getIndexStructures() {
//   if (!localStorage.getItem(ACCESS_TOKEN)) {
//     return Promise.reject("No access token set.");
//   }
//
//   return jsonSuccessFailureRequest({
//     url: API_BASE_URL + "/database/index-structures",
//     method: 'GET',
//   })
// }

export function getPreprocessFunctions() {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  return jsonSuccessFailureRequest({
    url: API_BASE_URL + "/database/functions",
    method: 'GET',
  })
}

export function getSystemTime() {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  return jsonSuccessFailureRequest({
    url: API_BASE_URL + "/database/system-time",
    method: 'GET',
  })
}
