import React, { Component } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import ReactTooltip from "react-tooltip";
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import { DropdownButton, Dropdown } from "react-bootstrap";
import { getApiKeys, getTemplate } from '../../../utils/TemplateAPI';
import { checkMappingIdentifier } from "../../../utils/MappingsAPI.js";

export class NewBindingModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      api_keys: [],

    }

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(event) {
    event.preventDefault();

    this.props.onOK(this.props.mappingId, this.props.instance ? this.props.instance.id : null, this.identifier.value, this.props.parameters.map(param => { return { name: param.endsWith('*') ? param.substring(0, param.length - 1) : param, value: this[param].value } }).filter(e => e.value.length > 0 ), this.active.checked);
  }

  getApiKeys() {
    getApiKeys().then(response => {
      this.setState({ api_keys: response })
    })
  }

  api_keySelected(api_key, param){
    this[param].value = api_key.templateString

  }

  api_keyHandler(param) {
    if (param === "API_KEY") {
      return (
        <React.Fragment>
          <OverlayTrigger
            key="overlay-info"
            placement="right"
            delay={150}
            overlay={<Tooltip id="tooltip-info"><span>Click here to get your own Europeana API key.<br/>Then store it in your profile for easy access.</span></Tooltip>}
          >
            <a className="green fa fa-info-circle ml-2" href="https://pro.europeana.eu/page/get-api" target="_blank" />
          </OverlayTrigger>
          <DropdownButton title="Load key" variant="outline-primary" className="float-right mb-2" size="sm" onClick={() => this.getApiKeys()}>
            {this.state.api_keys.map((el, index) => (
              <React.Fragment key={"api-key-" + index}>
                <Dropdown.Item data-tip={el.templateString} data-effect="solid" data-place="right" onClick={() => this.api_keySelected(el, param)}>
                  {el.name}
                </Dropdown.Item>
                <ReactTooltip delay={150} />
              </React.Fragment>
            ))
            }
          </DropdownButton>
        </React.Fragment>
      )
    }
    else return null

    return (
      <Form.Label>{param}</Form.Label>
    )
  }

  findValue(instance, parameter) {
    if (instance) {
      var r = instance.binding.filter(e => e.name === parameter)
      if (r.length > 0) {
        return r[0].value;
      }
    }
  }

  existsMappingIdentifier() {
    if (this.identifier.value) {
      checkMappingIdentifier(this.identifier.value, this.props.dataset.id, this.props.mappingId)
      .then(response => {
        if (!response.data.valid || response.data.exists) {
           this.setState( {identifierExists : true })
        } else
            this.setState( {identifierExists : false })
        })
      }
   }

  render() {
    return (
      <Modal show={this.props.show} onHide={this.props.onClose} animation={false} backdrop="static">
        <Form onSubmit={this.handleSubmit}>
          <Modal.Header>
            <Modal.Title>{this.props.instance ? 'Edit' : 'New'} mapping instance</Modal.Title>
          </Modal.Header>
          <Modal.Body>

          <Form.Group>
            <Form.Label>Identifier</Form.Label>
            <Form.Control ref={node => (this.identifier = node)}
                  onChange={() => this.existsMappingIdentifier()}  // TODO CHECK
                 isInvalid={this.identifier && this.identifier.value && ((!this.props.instance && this.state.identifierExists) || (this.props.instance && this.state.identifierExists && this.props.instance.identifier !== this.identifier.value))}
                 defaultValue={this.props.instance ? this.props.instance.identifier : ""}/>
            <Form.Control.Feedback type="invalid">
                 Identifier already exists or is invalid.
           </Form.Control.Feedback>
          </Form.Group>

            {this.props.parameters.map((param, index) => {
              var required = true;
              var name = param;
              if (param.endsWith("*")) {
                name = param.substring(0, param.length - 1);
                required = false;
              }
              return (
                <React.Fragment>
                {required &&
                <Form.Group key={index}>
                  <Form.Label className="required">Parameter: {name}</Form.Label>
                  {this.api_keyHandler(param)}
                  <Form.Control ref={node => (this[param] = node)}
                    defaultValue={this.findValue(this.props.instance, name)}
                    required/>
                </Form.Group>}
                {!required &&
                <Form.Group key={index}>
                  <Form.Label>Parameter: {name}</Form.Label>
                  {this.api_keyHandler(param)}
                  <Form.Control ref={node => (this[param] = node)}
                    defaultValue={this.findValue(this.props.instance, name)}
                    />
                </Form.Group>}
                </React.Fragment>)
            })}

              <Form.Group>
                <Form.Check label="Active" ref={node => (this.active = node)}
                  defaultChecked={this.props.instance ? this.props.instance.active : true}/>
              </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button type="submit" variant="primary">
              OK
            </Button>
            <Button variant="secondary" onClick={this.props.onClose}>
              Cancel
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    )
  }
}

export default NewBindingModal;
