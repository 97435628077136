import {useState} from 'react';
import React, { Component } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Multiselect from 'multiselect-react-dropdown';

import { throwToast } from '../../../utils/UIUtils';
import { renderClasses, applySelection, readClasses, getSchema } from '../../../utils/SchemaUtils';
import { getIndexStructures } from "../../../utils/IndexStructureAPI"

export class IndexModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      show: this.props.show,

      indexStructures: []
    }

    this.handleSubmit = this.handleSubmit.bind(this);
  }


  handleSubmit(event) {
    event.preventDefault();

    if (this.props.index && this.props.index.id) {
      this.props.onUpdate(this.props.index.id,
                          this.name.value,
                          this.indexStructure.value,
                          this.indexEngine.value
                        )
    } else {
      this.props.onOK(this.name.value,
                      this.indexStructure.value,
                      this.indexEngine.value
                    )
    }
  }

  componentDidMount() {
    if (this.props.project) {
      getIndexStructures(null, this.props.project.id)
        .then(response => {
          this.setState({ indexStructures: response.data })
        })
    } else {
      getIndexStructures(null)
        .then(response => {
          this.setState({ indexStructures: response.data })
        })
    }
  }

  componentDidUpdate() {
    if (this.props.index && this.indexStructure.value == '') {
       this.indexStructure.value = this.props.index.indexStructureId
    }
  }

  render() {

    return (
      <Modal show={this.props.show} onHide={this.props.onClose} animation={false} backdrop="static" >
        <Form onSubmit={this.handleSubmit}>
          <Modal.Header>
            <Modal.Title>{this.props.index ? 'Edit' : 'New'} Index</Modal.Title>
          </Modal.Header>
          <Modal.Body>

          <Form.Group>
            <Form.Label className="required">Name</Form.Label>
            <Form.Control ref={node => (this.name = node)}
                 onChange={() => this.setState({ error: false })}
                 pattern="\S.*"
                 defaultValue={this.props.index ? this.props.index.name : ""}
                 required/>
          </Form.Group>

            <Form.Group>
              <Form.Label className="required">Index Definition</Form.Label>
              <Form.Control as="select" ref={node => (this.indexStructure = node)}
                            defaultValue=''
                            disabled={this.props.index && this.props.index.createState && this.props.index.createState.state != 'NOT_CREATED'}
                            required>
                <option key={0} value='' disabled>--- Select ---</option>
                {this.state.indexStructures.map((entry, index) =>
                   <option key={index + 1} value={entry.id}>{entry.identifier}</option>)}
              </Form.Control>
            </Form.Group>

            {//this.props.indexEngines.length > 1 &&
              <Form.Group>
              <Form.Label className="required">Index engine</Form.Label>
              <Form.Control as="select" ref={node => (this.indexEngine = node)}
                            defaultValue={this.props.index ? this.props.index.indexEngine : (this.props.indexEngines.length == 1 ? this.props.indexEngines[0] : '')}
                            disabled={this.props.index && this.props.index.createState && this.props.index.createState.state != 'NOT_CREATED'}
                            required>
                <option key={0} value='' disabled>--- Select ---</option>
                {this.props.indexEngines.map((entry, index) =>
                  <option key={index} value={entry}>{entry}</option>)}
              </Form.Control>
            </Form.Group>}

          </Modal.Body>
          <Modal.Footer>
            <Button type="submit" variant="primary">
              {this.props.index && this.props.index.id ? 'Update' : 'Create'}
            </Button>
            <Button variant="secondary" onClick={this.props.onClose}>
              Cancel
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
  )}
}


export default IndexModal;
