import { API_BASE_URL, ACCESS_TOKEN } from '../constants/index.js';
import { request, textRequest, sRequest, jsonSuccessFailureRequest, downloadFileRequest } from './APIUtils.js';

export function checkProjectIdentifier(identifier) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  return jsonSuccessFailureRequest({
    url: API_BASE_URL + "/project/exists-identifier?identifier=" + encodeURIComponent(identifier),
    method: 'GET'
  })
}

export function getProject(id, details) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  return jsonSuccessFailureRequest({
    url: API_BASE_URL + "/project/get/" + id + (details ? "?details=true" : ""),
    method: 'GET',
  })
}

export function createProject(name, identifier, publik) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  var body = { name,  public: publik };

  if (identifier && identifier !== "") {
    body = {...body, identifier }
  }

  return jsonSuccessFailureRequest({
    url: API_BASE_URL + "/project/new",
    body: JSON.stringify(body),
    method: 'POST',
  })
}

export function updateProject(id, name, identifier, publik) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  var body = { name, public: publik };

  if (identifier && identifier !== "") {
    body = {...body, identifier }
  }

  return jsonSuccessFailureRequest({
    url: API_BASE_URL + "/project/update/" + id,
    body: JSON.stringify(body),
    method: 'POST',
  })
}

export function deleteProject(id) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  return jsonSuccessFailureRequest({
    url: API_BASE_URL + "/project/delete/" + id,
    method: 'DELETE'
  });

}

export function getMyProjects() {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  return jsonSuccessFailureRequest({
    url: API_BASE_URL + "/project/get-all-my",
    method: 'GET',
  })
}

export function getJoinedProjects() {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  return jsonSuccessFailureRequest({
    url: API_BASE_URL + "/project/get-all-joined",
    method: 'GET',
  })
}

export function getOtherPublicProjects() {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  return jsonSuccessFailureRequest({
    url: API_BASE_URL + "/project/get-all-other-public",
    method: 'GET',
  })
}

export function joinProject(id) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }
  return jsonSuccessFailureRequest({
    url: API_BASE_URL + "/project/join/" + id,
    method: 'POST'
  });
}

export function unjoinProject(projectId, userId) {
   if (!localStorage.getItem(ACCESS_TOKEN)) {
     return Promise.reject("No access token set.");
   }

   return jsonSuccessFailureRequest({
     url: API_BASE_URL + "/project/unjoin/" + projectId + "?userId=" + userId,
     method: 'POST',
   });
 }
