import { API_BASE_URL, ACCESS_TOKEN } from '../constants/index.js';
import { jsonSuccessFailureRequest } from './APIUtils.js';

export function getRDFVocabularies() {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  return jsonSuccessFailureRequest({
    url: API_BASE_URL + "/system/get-vocabularies",
    method: 'GET',
  })
}

export function addRDFVocabulary(id) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  return jsonSuccessFailureRequest({
    url: API_BASE_URL + "/system/add-vocabulary/" + id,
    method: 'POST',
  })
}

export function removeRDFVocabulary(id) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  return jsonSuccessFailureRequest({
    url: API_BASE_URL + "/system/remove-vocabulary/" + id,
    method: 'POST',
  })
}
