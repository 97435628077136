import { API_BASE_URL, ACCESS_TOKEN } from '../constants/index.js';
import { request, textRequest, sRequest, jsonSuccessFailureRequest, formSuccessFailureRequest } from './APIUtils.js';

export function updateFile(id, name, description, data, url, active) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  var body = { active }
  if (name && name != null) {
     body = { ...body, name }
   }
   if (url) {
     body = {...body, url }
   }
   if (description) {
     body = {...body, description }
   }

  var formData = new FormData();
  if (data) {
    formData.append('file', data);
  }

  formData.append('json', JSON.stringify(body));

  return formSuccessFailureRequest({
    url: API_BASE_URL + "/files/update/" + id,
    method: 'POST',
    body: formData,
  })
}

export function createFile(name, datasetId, description, group, data, url, active) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  var body = { name, active, group: group ? group : 0  }

  if (url) {
    body = {...body, url }
  }
  if (description) {
    body = {...body, description }
  }

  var formData = new FormData();
  if (data != null) {
    formData.append('file', data);
  }

  formData.append('json', JSON.stringify(body));

  return formSuccessFailureRequest({
    url: API_BASE_URL + "/files/create?datasetId=" + datasetId,
    method: 'POST',
    body: formData,
  })
}


export function getFiles(datasetId) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  return jsonSuccessFailureRequest({
    url: API_BASE_URL + "/files/get-all-my?datasetId=" + datasetId,
    method: 'GET'
  })
}

export function deleteFile(id) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  return jsonSuccessFailureRequest({
    url: API_BASE_URL + "/files/delete/" + id,
    method: 'DELETE'
  });

}

export function previewLastFile(id) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  return textRequest({
    url: API_BASE_URL + "/files/preview-last/" + id,
    method: 'GET'
  });

}

export function previewPublishedFile(id) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  return textRequest({
    url: API_BASE_URL + "/files/preview-published/" + id,
    method: 'GET'
  });

}

export function downloadLastFile(id) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  return sRequest({
    url: API_BASE_URL + "/files/download-last/" + id,
    method: 'GET'
  });
}

export function downloadPublishedFile(id) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  return sRequest({
    url: API_BASE_URL + "/files/download-published/" + id,
    method: 'GET'
  });
}

export function changeFileOrder(id, step) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  return jsonSuccessFailureRequest({
    url: API_BASE_URL + "/files/change-order/" + id + "?step=" + step,
    method: 'POST'
  })
}

export function changeFileGroup(id, group) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  return jsonSuccessFailureRequest({
    url: API_BASE_URL + "/files/change-group/" + id + "?group=" + group,
    method: 'POST'
  })
}
