import { API_BASE_URL, ACCESS_TOKEN } from '../constants/index.js';
import { jsonSuccessFailureRequest } from './APIUtils.js';

export function getCampaign(id, details) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  return jsonSuccessFailureRequest({
    url: API_BASE_URL + "/campaign/get/" + id + (details ? "?details=true" : ""),
    method: 'GET',
  })
}

export function getOwnedCampaigns(type, projectId) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  return jsonSuccessFailureRequest({
    url: API_BASE_URL + "/campaign/get-all-my?type=" + type + (projectId ? "&projectId=" + projectId : ""),
    method: 'GET',
  });
}

export function getJoinedCampaigns(type) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  return jsonSuccessFailureRequest({
    url: API_BASE_URL + "/campaign/get-all-joined?type=" + type + "&page=1&size=10",
    method: 'GET',
  });
}


export function getActiveCampaigns(type) {
  return jsonSuccessFailureRequest({
    url: API_BASE_URL + "/campaign/get-all-active?type=" + type,
    method: 'GET',
  });
}


export function joinCampaign(id) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }
  return jsonSuccessFailureRequest({
    url: API_BASE_URL + "/campaign/join/" + id,
    method: 'POST'
  });
}

export function unjoinCampaign(campaignId, userId) {
   if (!localStorage.getItem(ACCESS_TOKEN)) {
     return Promise.reject("No access token set.");
   }

   return jsonSuccessFailureRequest({
     url: API_BASE_URL + "/campaign/unjoin/" + campaignId + "?userId=" + userId,
     method: 'POST',
   });
 }

export function createCampaign(name, state, type, projectId) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  var body = { name, state, type };

  return jsonSuccessFailureRequest({
    url: API_BASE_URL + '/campaign/create' + (projectId != null ? "?projectId=" + projectId : ""),
    method: 'POST',
    body: JSON.stringify(body)
  })
}

export function updateCampaign(id, name, state) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  var body = { name, state };

  return jsonSuccessFailureRequest({
    url: API_BASE_URL + "/campaign/update/" + id,
    method: 'POST',
    body: JSON.stringify(body)
  })

}

export function deleteCampaign(id) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  return jsonSuccessFailureRequest({
    url: API_BASE_URL + "/campaign/delete/" + id,
    method: 'DELETE',
  })
}

export function addCampaignToProject(campaignId, projectId) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  return jsonSuccessFailureRequest({
    url: API_BASE_URL + "/campaign/add-to-project/" + campaignId + "?projectId=" + projectId,
    method: 'POST',
  })
}

export function removeCampaignFromProject(campaignId, projectId) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  return jsonSuccessFailureRequest({
    url: API_BASE_URL + "/campaign/remove-from-project/" + campaignId + "?projectId=" + projectId,
    method: 'POST',
  })
}

export function getAssignedDatasetsToUser(campaignId, userId) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  return jsonSuccessFailureRequest({
    url:  API_BASE_URL + "/campaign/get-assigned-datasets-to-user/" + campaignId + "?userId=" + userId,
    method: 'GET',
  })
}

export function getMyAssignedDatasets(campaignId) {
  if(!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  return jsonSuccessFailureRequest({
    url:  API_BASE_URL + "/campaign/get-my-assigned-datasets/" + campaignId,
    method: 'GET',
  })
}

export function addDatasetToCampaign(campaignId, datasetId) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

    return jsonSuccessFailureRequest({
      url: API_BASE_URL + "/campaign/add-dataset/"+ campaignId + "?datasetId=" + datasetId,
      method: 'POST',
    });
}

export function removeDatasetFromCampaign(campaignId, datasetId) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

    return jsonSuccessFailureRequest({
      url: API_BASE_URL + "/campaign/remove-dataset/"+ campaignId + "?datasetId=" + datasetId,
      method: 'POST',
    });
}

export function assignDatasetToUser(campaignId, userId, datasetId) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

    return jsonSuccessFailureRequest({
      url: API_BASE_URL + "/campaign/assign-dataset-to-user/"+ campaignId + "?userId=" + userId + "&datasetId=" + datasetId,
      method: 'POST',
    });

}

export function assignAllDatasetsToUser(campaignId, userId) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  return jsonSuccessFailureRequest({
    url: API_BASE_URL + "/campaign/assign-all-datasets-to-user/" + campaignId + "?userId=" + userId,
    method: 'POST',
  })
}

export function unassignDatasetFromUser(campaignId, userId, datasetId) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }
  return jsonSuccessFailureRequest({
    url: API_BASE_URL + "/campaign/unassign-dataset-from-user/"+ campaignId + "?userId=" + userId + "&datasetId=" + datasetId,
    method: 'POST',
  });
}

export function unassignAllDatasetsFromUser(campaignId, userId) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  return jsonSuccessFailureRequest({
    url: API_BASE_URL + "/campaign/unassign-all-datasets-from-user/" + campaignId + "?userId=" + userId,
    method: 'POST',
  })
}

export function getDatasetProgress(id, datasetId) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }
  return jsonSuccessFailureRequest({
    url: API_BASE_URL + "/campaign/get-paged-annotation-validations/" + id + "?datasetId=" + datasetId,
    method: 'GET',
  })
}
