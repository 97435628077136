import { API_BASE_URL, ACCESS_TOKEN } from '../constants/index.js';
import { request, textrequest, textRequest, jsonSuccessFailureRequest, genericSuccessFailureRequest, sjRequest } from './APIUtils.js';

export function checkDatasetIdentifier(identifier, datasetId, projectId) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  return jsonSuccessFailureRequest({
    url: API_BASE_URL + "/dataset/exists-identifier?identifier=" + encodeURIComponent(identifier) + (datasetId ? "&datasetId=" + datasetId : "") + (projectId ? "&projectId=" + projectId : ""),
    method: 'GET'
  })
}

export function createDataset(template, name, identifier, privat, scope, type, category, typeUri, asProperty, links, multiGroup, sparqlEndpoint, namedGraph, lastUpdatedSparqlQuery, entityDescriptors, projectId) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  var body = { name, scope:scope.toUpperCase(), type: type.toUpperCase(), category: category.toUpperCase(), public: (privat ? false : true) };

  if (identifier && identifier !== "") {
    body = {...body, identifier }
  }
  if (template) {
    body = {...body, template }
  }
  if (asProperty) {
    body = {...body, asProperty }
  }
  if (links && links.length > 0) {
    body = {...body, links }
  }
  if (multiGroup != undefined) {
    body = {...body, multiGroup }
  }

  if (typeUri) {
    body = {...body, typeUri: [ typeUri ]  }
  }

  if (sparqlEndpoint) {
    var remoteTripleStore = { sparqlEndpoint }
    if (namedGraph) {
      remoteTripleStore = {...remoteTripleStore, namedGraph: namedGraph.split(",").map(e => e.trim()) }
    }
    if (lastUpdatedSparqlQuery) {
      remoteTripleStore = {...remoteTripleStore, lastUpdatedSparqlQuery }
    }

    body = {...body, remoteTripleStore}
  }

  if (entityDescriptors) {
     body = {...body, entityDescriptors}
  }

  return jsonSuccessFailureRequest({
    url: API_BASE_URL + '/dataset/create' + (projectId ? "?projectId=" + projectId : ""),
    method: 'POST',
    body: JSON.stringify(body)
  })
}

 export function updateDataset(id, name, identifier, privat, scope, type, category, typeUri, asProperty, links, multiGroup, sparqlEndpoint, namedGraph, lastUpdatedSparqlQuery, entityDescriptors) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  var body = { name, scope:scope.toUpperCase(), type: type.toUpperCase(), category: category.toUpperCase(), public: (privat ? false : true) };

  if (identifier && identifier !== "") {
    body = {...body, identifier }
  }

  if (asProperty) {
    body = {...body, asProperty }
  }

  if (links && links.length > 0) {
    body = {...body, links }
  }
  if (multiGroup != undefined) {
    body = {...body, multiGroup }
  }

  if (typeUri) {
    body = {...body, typeUri: [ typeUri ]  }
  }

  if (sparqlEndpoint) {
    var remoteTripleStore = { sparqlEndpoint }
    if (namedGraph) {
      remoteTripleStore = {...remoteTripleStore, namedGraph: namedGraph.split(",").map(e => e.trim()) }
    }
    if (lastUpdatedSparqlQuery) {
      remoteTripleStore = {...remoteTripleStore, lastUpdatedSparqlQuery }
    }

    body = {...body, remoteTripleStore}
  }

  if (entityDescriptors) {
     body = {...body, entityDescriptors}
  }

  return jsonSuccessFailureRequest({
    url: API_BASE_URL + "/dataset/update/" + id,
    method: 'POST',
    body: JSON.stringify(body)
  })

}

export function deleteDataset(id) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  return jsonSuccessFailureRequest({
    url: API_BASE_URL + "/dataset/delete/" + id,
    method: 'DELETE',
  })
}

export function getDataset(id, details) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  return jsonSuccessFailureRequest({
    url: API_BASE_URL + "/dataset/get/" + id + (details ? "?details=true" : ""),
    method: 'GET',
  })
}

export function getMyDatasets(scope, type, projectId, onlyPublished) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  return jsonSuccessFailureRequest({
    url: API_BASE_URL + "/dataset/get-all-my?type=" + type + (scope ? "&scope=" + scope.join(',') : "") + (projectId ? "&projectId=" + projectId : "") + (onlyPublished ? "&onlyPublished=" + onlyPublished : ""),
    method: 'GET',
  })
}

export function getOtherPublicDatasets(scope, type, projectId) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  return jsonSuccessFailureRequest({
    url: API_BASE_URL + "/dataset/get-all-other-public?type=" + type + (scope ? "&scope=" + scope.join(',') : "") + (projectId ? "&projectId=" + projectId : ""),
    method: 'GET',
  })
}

export function getDatasetDescription(id, format) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  var contentType;
  if (format == 'JSONLD') {
    contentType = 'application/jsonld'
  } else if (format == 'TTL' || format == 'TURTLE') {
     contentType = 'text/turtle'
  } else if (format == 'NT' || format == 'NTRIPLES') {
    contentType = 'application/n-triples'
  } else if (format == 'RDF/XML') {
     contentType = 'application/rdf+xml'
   } else if (format == 'TRIG') {
    contentType = 'application/trig'
  }

  return genericSuccessFailureRequest({
    url: API_BASE_URL + "/dataset/rdf-schema/" + id,
    method: 'GET',
    contentType: contentType
  });
}

export function getDatasetSchema(id) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  return jsonSuccessFailureRequest({
    url: API_BASE_URL + "/dataset/schema/" + id,
    method: 'GET',
  });
}

export function getDatasetMetadata(id) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  return jsonSuccessFailureRequest({
    url: API_BASE_URL + "/dataset/schema/" + id + "/metadata",
    method: 'GET',
  });
}

export function getDatasetPropertyValues(id, path, mode, page, size) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  return jsonSuccessFailureRequest({
    url: API_BASE_URL + "/dataset/schema/" + id + "/list-property-values?mode=" + mode + (page ? "&page=" + page : "") + (size ? "&size=" + size : ""),
    body: JSON.stringify(path),
    method: 'POST',
  })
}

export function getDatasetPropertyValueItems(id, path, value, page, size) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  return jsonSuccessFailureRequest({
     url: API_BASE_URL + "/dataset/schema/" + id + "/list-items-for-property-value" + (page ? "?page=" + page : "") + (size ? (page ? "&" : "?") + "size=" + size : ""),
     body: JSON.stringify({ path: path, value: value }),
     method: 'POST',
  })
}

export function getDatasetItemPropertiesPropertyValueAndTarget(id, path, value, target) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  return request({
     url: API_BASE_URL + "/dataset/schema/" + id + "/list-properties-for-target",
     body: JSON.stringify({ path: path, value: value, target: target }),
     method: 'POST',
  })
}

export function downloadDatasetPropertyValues(id, path, mode) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  return sjRequest({
    url: API_BASE_URL + "/dataset/schema/" + id + "/download-property-values?mode=" + mode,
    body: JSON.stringify(path),
    method: 'POST',
  })
}

export function getDatasetSchemaClasses(id, embedders) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  return jsonSuccessFailureRequest({
    url: API_BASE_URL + "/dataset/schema/" + id + "/classes" + (embedders ? "?embedders=true" : ""),
    method: 'GET'
  });

}

export function addDatasetToProject(datasetId, projectId) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  return jsonSuccessFailureRequest({
    url: API_BASE_URL + "/dataset/add-to-project/" + datasetId + "?projectId=" + projectId,
    method: 'POST',
  })
}

export function addDataset(id, toId) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  return jsonSuccessFailureRequest({
    url: API_BASE_URL + "/dataset/add-dataset/" + toId + "?datasetId=" + id,
    method: 'POST',
  })
}

export function removeDataset(id, fromId) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  return jsonSuccessFailureRequest({
    url: API_BASE_URL + "/dataset/remove-dataset/" + fromId + "?datasetId=" + id,
    method: 'POST',
  })
}

// export function publishDataset(id, privat, tripleStore) {
export function publishDataset(id, group, tripleStore) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  return jsonSuccessFailureRequest({
    // url: API_BASE_URL + "/dataset/publish/" + id + "?visibility=" + (privat ? "private" : "public") + (tripleStore ? "&triple-store=" + tripleStore : ""),
    url: API_BASE_URL + "/dataset/publish/" + id + (tripleStore ? "?triple-store=" + tripleStore : "") + (group != undefined ? (tripleStore ? "&" : "?") + "group=" + group : ""),
    method: 'POST',
  })
}

// export function publishUnpublishedContent(id) {
//   if (!localStorage.getItem(ACCESS_TOKEN)) {
//     return Promise.reject("No access token set.");
//   }
//
//   return jsonSuccessFailureRequest({
//     url: API_BASE_URL + "/dataset/republish/" + id + "?content=ONLY_NEW",
//     method: 'POST',
//   })
// }

export function unpublishDataset(id, group) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  return jsonSuccessFailureRequest({
    url: API_BASE_URL + "/dataset/unpublish/" + id + (group != undefined ? "?group=" + group : ""),
    method: 'POST',
  })
}

export function republishDataset(id, group, content) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  return jsonSuccessFailureRequest({
    url: API_BASE_URL + "/dataset/republish/" + id + "?content=" + content + (group != undefined ? "&group=" + group : ""),
    method: 'POST',
  })
}

// export function republishDatasetMetadata(id) {
//   if (!localStorage.getItem(ACCESS_TOKEN)) {
//     return Promise.reject("No access token set.");
//   }
//
//   return textrequest({
//     url: API_BASE_URL + "/dataset/republish/" + id + "?content=NONE",
//     method: 'POST',
//   })
// }

export function executeAllMappings(id, group) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  return jsonSuccessFailureRequest({
    url: API_BASE_URL + "/dataset/execute-mappings/" + id + "?group=" + group,
    method: 'POST'
  })
}


// export function executeAllMappingsAndRepublish(id) {
//   if (!localStorage.getItem(ACCESS_TOKEN)) {
//     return Promise.reject("No access token set.");
//   }
//
//   return jsonSuccessFailureRequest({
//     url: API_BASE_URL + "/dataset/execute-mappings-and-republish/" + id,
//     method: 'POST'
//   })
// }


// export function flipDatasetVisibility(id) {
//   if (!localStorage.getItem(ACCESS_TOKEN)) {
//     return Promise.reject("No access token set.");
//   }
//
//   return textrequest({
//     url: API_BASE_URL + "/dataset/flipVisibility/" + id,
//     method: 'POST',
//   })
// }
