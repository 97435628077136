import React, { Component } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import ReactTooltip from "react-tooltip";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { filterByLanguage } from "../../../utils/functions.js";
import BootstrapSwitchButton from 'bootstrap-switch-button-react'
import { getRDFVocabularies } from "../../../utils/SystemAPI"

export class AddRDFVocabularyModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      show: this.props.show,
    }

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(event) {
     event.preventDefault();

    this.props.onOK(this.rdfVocabulary.value)
 }

 componentDidMount() {
   getRDFVocabularies()
     .then(response => {
       this.setState({ rdfVocabularies: response.data })
     })
 }

  render() {
    return (
      <Modal show={this.props.show} onHide={this.props.onClose} animation={false} backdrop="static">
        <Form onSubmit={this.handleSubmit}>
          <Modal.Header>
            <Modal.Title>Add RDF Vocabulary</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group>
              <Form.Label className="required">RDF Vocabulary</Form.Label>
              <Form.Control as="select" ref={node => (this.rdfVocabulary = node)}
                    defaultValue=''
                    required>
                  <option disabled value=''> --- Select RDF Vocabulary --- </option>
                  {this.state.rdfVocabularies && this.state.rdfVocabularies.map((voc, index) =>
                    <option key={index} value={voc.id}>{voc.name}</option>
                  )}
              </Form.Control>

            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button type="submit" variant="primary">
              OK
            </Button>
            <Button variant="secondary" onClick={this.props.onClose}>
              Cancel
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
  )}
}

export default AddRDFVocabularyModal;
