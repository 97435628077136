import { API_BASE_URL, ACCESS_TOKEN } from '../constants/index.js';
import { jsonSuccessFailureRequest, formSuccessFailureRequest } from './APIUtils.js';


export function getIndexStructuresByUser(datasetId) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  var rq = (datasetId ? '?datasetId=' + datasetId : '');

  return jsonSuccessFailureRequest({
    url: API_BASE_URL + "/index-structure/get-all-my" + rq,
    method: 'GET',
  })
}

export function getIndexStructures(datasetId, projectId) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  var rq = (datasetId ? '?datasetId=' + datasetId : '');
  rq += (projectId ? '?projectId=' + projectId : ''); // cannot have both project and dataset !!!

  return jsonSuccessFailureRequest({
    url: API_BASE_URL + "/index-structure/get-all" + rq,
    method: 'GET',
  })
}

export function getIndexStructure(id) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  return jsonSuccessFailureRequest({
    url: API_BASE_URL + "/index-structure/get/" + id,
    method: 'GET'
  })
}

export function updateIndexStructure(id, name, identifier, description, schemaDatasetId, schema, elements, keysMetadata) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  var body = { name, identifier, schemaDatasetId, schema, elements, keysMetadata }
   if (description) {
      body = { ...body, description }
    }

  return jsonSuccessFailureRequest({
    url: API_BASE_URL + "/index-structure/update/" + id,
    method: 'POST',
    body: JSON.stringify(body),
  })
}

export function createIndexStructure(name, datasetId, identifier, description, schemaDatasetId, schema, elements, keysMetadata) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  var body = { name, identifier, schemaDatasetId, schema, elements, keysMetadata }
  if (description) {
     body = { ...body, description }
   }

  return jsonSuccessFailureRequest({
    url: API_BASE_URL + "/index-structure/new?datasetId=" + datasetId,
    method: 'POST',
    body:  JSON.stringify(body),
  })
}

export function deleteIndexStructure(id) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  return jsonSuccessFailureRequest({
    url: API_BASE_URL + "/index-structure/delete/" + id,
    method: 'DELETE'
  });

}

export function checkIndexStructureIdentifier(identifier) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  return jsonSuccessFailureRequest({
    url: API_BASE_URL + "/index-structure/exists-identifier?identifier=" + encodeURIComponent(identifier),
    method: 'GET'
  })
}
