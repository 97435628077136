import { API_BASE_URL, WS_BASE_URL } from '../../constants/index.js';

import React, { Component } from "react";
import Container from "react-bootstrap/Container";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import Form from "react-bootstrap/Form";
import FormControl from "react-bootstrap/FormControl";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";
import Collapse from "react-bootstrap/Collapse";
import SockJsClient from 'react-stomp';

import { createDataset, updateDataset, deleteDataset, getMyDatasets, getOtherPublicDatasets, getDataset, publishDataset, unpublishDataset, executeAllMappings, republishDataset, addDatasetToProject } from '../../utils/DatasetAPI';
import { getRDFVocabularies } from '../../utils/VocabulariesAPI';
import { getValidationModes, getImportTemplates } from '../../utils/DatabaseAPI';
// import { getPrototypes } from '../../utils/PrototypeAPI';
import { throwToast, sortByField } from '../../utils/UIUtils';
import { getMyProjects, getJoinedProjects, getProject, createProject, updateProject, joinProject } from '../../utils/ProjectAPI.js';
import { getPrototypes } from '../../utils/PrototypeAPI';
import { inviteUser } from '../../utils/UsersAPI';

import DatasetModal from "./modals/DatasetModal.js";
import Datasets from "../Datasets.js";
import DatasetEditor from "./DatasetEditor.js";
//import CatalogEditor from "./api/CatalogEditor.js";
import PublishedSchema from "./PublishedSchema.js";

import CampaignsTab from "./CampaignsTab.js";
import TaskMonitorTab from "./TaskMonitorTab.js"
import ProjectsTab from "./ProjectsTab.js"
import TripleStoresTab from "./TripleStoresTab.js"
import ElasticsTab from "./ElasticsTab.js"
import UsersTab from "./UsersTab.js"
import RDFVocabulariesTab from "./RDFVocabulariesTab.js"

import NewAlignmentModal from "./modals/NewAlignmentModal.js";
import DeleteModal from "./modals/DeleteModal.js";

// import { getDataServices, getPreprocessFunctions, getIndexStructures } from '../../utils/DatabaseAPI.js'
import { getDataServices, getPreprocessFunctions} from '../../utils/DatabaseAPI.js'
import { getOntologyQueryProperties } from '../../utils/APIUtils.js'
import { Localizer } from '../../config/localizer.js'

import { actionsMenu, toggleBoxColumn, MessageQueue } from '../../utils/UIUtils';

export class UserSpace extends Component {
  constructor(props) {
    super(props);

    this.state = {
      collectionCatalogModalShow: false,
      collectionCatalogModalDataset: null,
      collectionCatalogsOpen: true,
      collectionCatalogs: [],
      collectionCurrentCatalog: null,

      collectionDatasetModalShow: false,
      collectionDatasetModalDataset: null,
      collectionDatasetsOpen: true,
      collectionDatasets: [],
      collectionCurrentDataset: null,

      // vocabularyCatalogModalShow: false,
      // vocabularyCatalogModalDataset: null,
      // vocabularyCatalogsOpen: true,
      // vocabularyCatalogs: [],
      // vocabularyCurrentCatalog: null,

      vocabularyDatasetModalShow: false,
      vocabularyDatasetModalDataset: null,
      vocabularyDatasetsOpen: true,
      vocabularyDatasets: [],
      vocabularyCurrentDataset: null,

      annotationDatasetModalShow: false,
      annotationDatasetModalDataset: null,
      annotationDatasetsOpen: true,
      annotationDatasets: [],
      annotationCurrentDataset: null,

      alignmentDatasetModalShow: false,
      alignmentDatasetModalDataset: null,
      alignmentDatasetsOpen: true,
      alignmentDatasets: [],
      alignmentCurrentDataset: null,

      ownedPublishedDatasets: [],
      projectPublishedDatasets: [],

      selectedPublishedDataset: null,

      campaignDatasets: [],
      // validatorDatasets: [],
      // selectedAssignedPublishedDataset: null,

      projectCollectionDatasets: [],
      projectCollectionDatasetsOpen: true,

      projectVocabularyDatasets: [],
      projectVocabularyDatasetsOpen: true,

      projectShaclDatasets: [],
      projectShaclDatasetsOpen: true,
      projectD2rmlDatasets: [],
      projectD2rmlDatasetsOpen: true,
      projectAnnotatorDatasets: [],
      projectAnnotatorDatasetsOpen: true,
      projectComparatorDatasets: [],
      projectComparatorDatasetsOpen: true,
      projectIndexDatasets: [],
      projectIndexDatasetsOpen: true,

      queryOntologyProperties: [],
      dataAnnotators: [],
      // tripleStores: [],
      indices: [],
      catalogImportTemplates: [],
      datasetImportTemplates: [],
      mappingSampleTemplates: [],

      dataImportCategory: "data",
      dataImportType: "datasets",
      dataImportScope: "collections",

      publishedDataType: "datasets",
      publishedDataCategory: "collections",

      publishedDataOpen: true,
      projectPublishedDataOpen: true,

      // shaclShapes: null,

      shaclDatasetModalShow: false,
      shaclDatasetModalDataset: null,
      shaclDatasetsOpen: true,
      shaclDatasets: [],
      shaclCurrentDataset: null,

      d2rmlDatasetModalShow: false,
      d2rmlDatasetModalDataset: null,
      d2rmlDatasetsOpen: true,
      d2rmlDatasets: [],
      d2rmlCurrentDataset: null,

      annotatorDatasetModalShow: false,
      annotatorDatasetModalDataset: null,
      annotatorDatasetsOpen: true,
      annotatorDatasets: [],
      annotatorCurrentDataset: null,

      comparatorDatasetModalShow: false,
      comparatorDatasetModalDataset: null,
      comparatorDatasetsOpen: true,
      comparatorDatasets: [],
      comparatorCurrentDataset: null,

      indexDatasetModalShow: false,
      indexDatasetModalDataset: null,
      indexDatasetsOpen: true,
      indexDatasets: [],
      indexCurrentDataset: null,

      myProjects: [],
      joinedProjects: [],
      currentProject: null,

      // selectedProject: props.user.defaultProjectId,
    };

     this.messageQueueMap = new Map();

     // this.vocabularyTypes = [ { label: 'Temporal', uri: 'http://sw.islab.ntua.gr/semaspace/model/TemporalCollection' },
     //                          { label: 'Spatial', uri: 'http://sw.islab.ntua.gr/semaspace/model/SpatialCollection' },
     //                          { label: 'Thesaurus/Ontology', uri: 'http://sw.islab.ntua.gr/semaspace/model/ThesaurusCollection' },
     //                          { label: 'Assertion Box', uri: 'http://sw.islab.ntua.gr/semaspace/model/AssertionCollection' },]
  }

  get isValidator() { return this.props.user.role === 'VALIDATOR' }
  get isAdministrator() { return this.props.user.role === 'ADMINISTRATOR' }
  get isEditor() { return this.props.user.role === 'EDITOR'; }

  componentDidMount() {

    if (this.isEditor) {

      this.getProjects()
      // this.getDatasets()

      getDataServices()
          .then(response => {
            if (response.data && response.data.annotators) {
              this.setState({ dataAnnotators: response.data.annotators})
            }
            if (response.data && response.data.embedders) {
              this.setState({ dataEmbedders: response.data.embedders })
            }
          })

      getPreprocessFunctions()
              .then(response => {
                if (response.data && response.data.functions) {
                  this.setState({ preprocessFunctions: response.data.functions.sort((a,b) => sortByField('uri', a, b)) })
                }
                if (response.data && response.data.operations) {
                  this.setState({ preprocessOperations: response.data.operations.sort((a,b) => sortByField('name', a, b)) })
                }
              })

      getImportTemplates()
        .then(json => this.setState({ catalogImportTemplates: json.data.catalogTemplates ? json.data.catalogTemplates : [],
                                      datasetImportTemplates: json.data.datasetTemplates ? json.data.datasetTemplates : [],
                                      mappingSampleTemplates: json.data.mappingSampleTemplates ? json.data.mappingSampleTemplates : []
         }))
    }

    getRDFVocabularies()
      .then(response => this.setState({ rdfVocabularies: response.data}))

    getValidationModes()
      .then(response => this.setState({ validationModes: response.data}))

    getOntologyQueryProperties()
      .then(json => this.setState({ queryOntologyProperties: json}))

    // getIndexStructures()
    //   .then(response => this.setState({ indices: response.data }))
}

getDatasets() {
  var campaignDatasets = [];

  var myProject = this.state.selectedProject && this.isMyProject(this.state.selectedProject) != null;

  getMyDatasets(null,'CATALOG', this.state.selectedProject ? this.state.selectedProject.id : null)
    .then(response => {
      this.setState({ collectionCatalogs: response.data });
      for (var d of response.data) {
        if (d.publishState.state.startsWith("PUBLISHED")) {
          campaignDatasets.push(d)
        }
      }
  })

  getMyDatasets(['COLLECTION'],'DATASET', this.state.selectedProject ? this.state.selectedProject.id : null)
    .then(response => {
      this.setState({ collectionDatasets: response.data });

      var datasetArray = []
      for (var d of response.data) {
        if (d.publishState.state.startsWith("PUBLISHED")) {
          datasetArray.push({ dataset: d, selected: false })
          campaignDatasets.push(d)
        }
      }

      this.setState({ ownedPublishedDatasets: datasetArray })
    })

    // if (myProject) {
    if (this.state.selectedProject) {
      getOtherPublicDatasets(['COLLECTION'],'DATASET', this.state.selectedProject.id)
        .then(response => {
          this.setState({ projectCollectionDatasets: response.data})

          var datasetArray = []
          for (var d of response.data) {
            if (d.publishState.state.startsWith("PUBLISHED")) {
              datasetArray.push({ dataset: d, selected: false })
            }
          }

          this.setState({ projectPublishedDatasets: datasetArray })
        })
      } else {
        this.setState({ projectCollectionDatasets: [] })
        this.setState({ projectPublishedDatasets: [] })
      }

  getMyDatasets(['VOCABULARY'],'DATASET', this.state.selectedProject ? this.state.selectedProject.id : null)
    .then(response => {
      this.setState({ vocabularyDatasets: response.data });
      // for (var d of response.data) {
      //   if (d.publishState.state.startsWith("PUBLISHED")) {
      //     campaignDatasets.push(d)
      //   }
      // }
    })

  // if (myProject) {
  if (this.state.selectedProject) {
    getOtherPublicDatasets(['VOCABULARY'],'DATASET', this.state.selectedProject.id)
      .then(response => {
        this.setState({ projectVocabularyDatasets: response.data})
      })
    } else {
      this.setState({ projectVocabularyDatasets: []})
    }


  getMyDatasets(['ALIGNMENT'],'DATASET', this.state.selectedProject ? this.state.selectedProject.id : null)
    .then(response => {
      this.setState({ alignmentDatasets: response.data });
      // for (var d of response.data) {
      //   if (d.publishState.state.startsWith("PUBLISHED")) {
      //     campaignDatasets.push(d)
      //   }
      // }
    })

// getMyDatasets('ANNOTATION','DATASET')
//   .then(annotationDatasets => {
//     this.setState({ annotationDatasets });
//   })

// getMyDatasets('COLLECTION','CATALOG')
//   .then(collectionCatalogs => {
//     this.setState({ collectionCatalogs });
//   })
//
// getMyDatasets('VOCABULARY','CATALOG')
//   .then(vocabularyCatalogs => {
//     this.setState({ vocabularyCatalogs });
//   })

  getMyDatasets(['SHACL'],'DATASET', this.state.selectedProject ? this.state.selectedProject.id : null)
    .then(response => {
      this.setState({ shaclDatasets: response.data });
  })

  if (this.state.selectedProject) {
    getOtherPublicDatasets(['SHACL'],'DATASET', this.state.selectedProject.id)
      .then(response => {
        this.setState({ projectShaclDatasets: response.data})
      })
    } else {
      this.setState({ projectShaclDatasets: []})
    }

  getMyDatasets(['D2RML'],'DATASET', this.state.selectedProject ? this.state.selectedProject.id : null)
    .then(response => {
      this.setState({ d2rmlDatasets: response.data });
  })

  if (this.state.selectedProject) {
    getOtherPublicDatasets(['D2RML'],'DATASET', this.state.selectedProject.id)
      .then(response => {
        this.setState({ projectD2rmlDatasets: response.data})
      })
    } else {
      this.setState({ projectD2rmlDatasets: []})
    }

  getMyDatasets(['ANNOTATOR'],'DATASET', this.state.selectedProject ? this.state.selectedProject.id : null)
    .then(response => {
      this.setState({ annotatorDatasets: response.data });
  })

  if (this.state.selectedProject) {
    getOtherPublicDatasets(['ANNOTATOR'],'DATASET', this.state.selectedProject.id)
      .then(response => {
        this.setState({ projectAnnotatorDatasets: response.data})
      })
  } else {
    this.setState({ projectAnnotatorDatasets: []})
  }

  getPrototypes(null,'ANNOTATOR', this.state.selectedProject ? this.state.selectedProject.id : null)
    .then(response => {
      if (response) {
        this.setState({ userAnnotators: response.data})
      }
    })

  getMyDatasets(['COMPARATOR'],'DATASET', this.state.selectedProject ? this.state.selectedProject.id : null)
    .then(response => {
      this.setState({ comparatorDatasets: response.data });
  })

  if (this.state.selectedProject) {
    getOtherPublicDatasets(['COMPARATOR'],'DATASET', this.state.selectedProject.id)
      .then(response => {
        this.setState({ projectComparatorDatasets: response.data})
      })
  } else {
    this.setState({ projectComparatorDatasets: []})
  }

  getMyDatasets(['INDEX'],'DATASET', this.state.selectedProject ? this.state.selectedProject.id : null)
    .then(response => {
      this.setState({ indexDatasets: response.data });
  })

  if (this.state.selectedProject) {
    getOtherPublicDatasets(['INDEX'],'DATASET', this.state.selectedProject.id)
      .then(response => {
        this.setState({ projectIndexDatasets: response.data})
      })
  } else {
    this.setState({ projectIndexDatasets: []})
  }

  this.setState({campaignDatasets})

}

isMyProject(project) {
  return this.state.myProjects.find(e => e.id == project.id);
}

componentDidUpdate() {
  this.handleSelect();
}

handleSelect() {
  if (this.state.selectedProjectType) {
    if (this.projectA != null) {
      this.projectA.value = this.state.selectedProjectType + this.state.selectedProject.id
    }
    if (this.projectB != null) {
      this.projectB.value = this.state.selectedProjectType + this.state.selectedProject.id
    }
  }
}

getProjects() {

  var promises = []

  var myProjects = [];
  var joinedProjects = [];
  var selectedProject
  var selectedProjectType
  var _this = this;

  promises.push(
    new Promise(function(resolve, reject) {
      getMyProjects()
        .then(response => {
          myProjects = response.data;
          if (_this.props.user.defaultProjectId) {
            var p = myProjects.find(e => e.id == _this.props.user.defaultProjectId);
            if (p != null) {
              selectedProject = p;
              selectedProjectType = 'm'
            }
          }
            resolve()
          })
        .catch(error => {
          resolve()
        });
  }))

  promises.push(
    new Promise(function(resolve, reject) {
      getJoinedProjects()
        .then(response => {
          joinedProjects = response.data;
          if (_this.props.user.defaultProjectId) {
            var p =  joinedProjects.find(e => e.id == _this.props.user.defaultProjectId);
            if (p != null) {
              selectedProject = p;
              selectedProjectType = 'p'
            }
          }
            resolve()
        })
        .catch(error => {
          resolve()
        });
    }))

    Promise.all(promises)
     .then(() => {
       this.setState( {selectedProject, selectedProjectType, myProjects, joinedProjects}, () => { this.getDatasets()})
     })
}


getEditorDatasets() {
  var type;
  var scope;
  if (this.state.publishedDataType === 'datasets') {
    type = "DATASET"
    if (this.state.publishedDataCategory === 'collections') {
      scope = ["COLLECTION"]
    } else if (this.state.publishedDataCategory === 'vocabularies') {
      scope = ["VOCABULARY"]
    } else if (this.state.publishedDataCategory === 'alignments') {
      scope = ["ALIGNMENT"]
    }
  } else {
    type = "CATALOG"
  }

  getMyDatasets(scope, type, this.state.selectedProject ? this.state.selectedProject.id : null, true )
    .then(response => {
        this.setState({ ownedPublishedDatasets: response.data.map(el => {return { dataset: el, selected: false } } ) })
      })
}

projectChanged(event) {
  event.preventDefault();

  var p = event.target.value;

  if (this.projectA) {
    this.projectA.value = p
  }
  if (this.projectB) {
    this.projectB.value = p
  }

  if (p === '') {
    this.setState({ selectedProjectType: undefined, selectedProject: undefined}, () => this.getDatasets());
  } else {

    var projectId = p.substring(1);
    if (p.startsWith('m')) {
      this.setState({ selectedProjectType: 'm', selectedProject : this.state.myProjects.find(e => e.id === projectId)}, () => this.getDatasets())
    } else if (p.startsWith('p')) {
      this.setState({ selectedProjectType: 'p', selectedProject : this.state.joinedProjects.find(e => e.id === projectId)}, () => this.getDatasets())
    }
  }
}

handleMessage(message) {
    var field
    if (this.state.collectionCurrentDataset && this.state.collectionCurrentDataset.id === message.id) {
      field = 'collectionCurrentDataset';
    } else if (this.state.vocabularyCurrentDataset && this.state.vocabularyCurrentDataset.id === message.id) {
      field = 'vocabularyCurrentDataset';
    } else if (this.state.alignmentCurrentDataset && this.state.alignmentCurrentDataset.id === message.id) {
      field = 'alignmentCurrentDataset';
    }

    var obj = { ...this.state[field], [message.type +'State']: message.content }

    if (message.type === 'publish' && field != 'alignmentCurrentDataset') {
      obj = { ...obj, 'loadState': 'NOT_LOADED' }
    }

// console.log({ [field]: obj });
    return { [field]: obj }
  }


  handleServerEvent(message) {
    // console.log(message)
    if (message.content.state.startsWith("PUBLISHED") || message.content.state == "UNPUBLISHED" || message.content.state == "UNPUBLISHING") {
      this.getEditorDatasets()
    }

    var id = message.id + ":" + message.type
    var queue = this.messageQueueMap.get(id)
    if (!queue) {
      queue = new MessageQueue(this, (message) => this.handleMessage(message))
      this.messageQueueMap.set(id, queue)
    }

    queue.push(message)

}

executeAction(action, params) {
  // console.log(action);
  // console.log(params);
  if (action === 'create-project') {
     if (params.id && params.id != null) {
       updateProject(params.id, params.name, params.identifier, params.public)
        .then(response => {
            var projects = this.state.myProjects.map(el => el.id === params.id ? response.data : el);
            this.setState( { myProjects: projects, currentProject: response.data } )
        })
        .catch(error => {
          //   throwToast('error', error.message);
        })
    } else {
      createProject(params.name, params.identifier, params.public)
        .then(response => {
            this.setState( { myProjects: this.state.myProjects.slice().concat(response.data)} )
        })
        .catch(error => {
          //   throwToast('error', error.message);
        })
    }
  } else if (action === 'select-project') {
      getProject(params.project.id, true)
        .then(response => {
          this.setState({ currentProject: response.data })
        })
        .catch(error => {
        });
  } else if (action === 'join-project') {
      joinProject(params.id)
        .then(response => {
          this.setState( { joinedProjects: this.state.joinedProjects.slice().concat(response.data)} )
        })
        .catch(error => {
        })
  } else if (action === 'invite-user') {
     inviteUser(params.email, params.projectId, params.role)
       .then(response => {
          throwToast('success', 'The user has been notified.');
       })
       .catch(error => {
              //   throwToast('error', error.message);
       })
  } else if (action === 'create-dataset') {
    var links = [];
    if (params.ontology) {
      links.push({ type: 'ABOX_FOR', value: params.ontology });
    }
    if (params.source) {
      links.push({ type: 'SOURCE', value: params.source });
    }
    if (params.target) {
      links.push({ type: 'TARGET', value: params.target });
    }
    if (params.id && params.id != null) {
      // updateDataset(params.id, params.name, params.identifier, params.visibility, params.triplestore, params.scope, params.type, params.uri, params.asProperty, links)
      updateDataset(params.id, params.name, params.identifier, params.visibility, params.scope, params.type, params.category, params.uri, params.asProperty, links, params.multiGroup, params.sparqlEndpoint, params.namedGraph, params.lastUpdatedSparqlQuery, params.entityDescriptors)
        .then(response => {
          if (params.type === 'DATASET') {
            var datasets = this.state[params.scope.toLowerCase() + 'Datasets'].map(el => el.id === params.id ? response.data : el);
            this.setState( {[params.scope.toLowerCase() + 'Datasets']: datasets, [params.scope.toLowerCase() + 'CurrentDataset']: response.data } )
          } else if (params.type === 'CATALOG') {
            var catalogs = this.state[params.scope.toLowerCase() + 'Catalogs'].map(el => el.id === params.id ? response.data : el);
            this.setState( {[params.scope.toLowerCase() + 'Catalogs']: catalogs, [params.scope.toLowerCase() + 'CurrentCatalog']: response.data } )
          }
        });
    } else {
      createDataset(params.template, params.name, params.identifier, params.visibility,  params.scope, params.type, params.category, params.uri, params.asProperty, links, params.multiGroup, params.sparqlEndpoint, params.namedGraph, params.lastUpdatedSparqlQuery, params.entityDescriptors, this.state.selectedProject ? this.state.selectedProject.id : null)
        .then(response => {
          if (params.type === 'DATASET') {
            this.setState( {[params.scope.toLowerCase() + 'Datasets']: this.state[params.scope.toLowerCase() + 'Datasets'].slice().concat(response.data)} )
          } else if (params.type === 'CATALOG') {
            this.setState( {[params.scope.toLowerCase() + 'Catalogs']: this.state[params.scope.toLowerCase() + 'Catalogs'].slice().concat({...response.data, datasets:[]} )} )
          }

          // this.datasetUuidMap.set(response.data.uuid, response.data.id)
        })
        .catch(error => {
        //   throwToast('error', error.message);
        })
      }
  } else if (action === 'select-dataset') {
      getDataset(params.id)
        .then((response) => {
          this.setState({ [params.scope.toLowerCase() + 'CurrentDataset']: response.data, [params.scope.toLowerCase() + 'CurrentDatasetMy']: params.my })
          window.scroll({ top: 0, left: 0, behavior: 'smooth' });
        })
        .catch(error => {
        //   throwToast('error', error.message);
        })

    } else if (action === 'select-catalog') {
         getDataset(params.id)
         .then((response) => {
           this.setState({ collectionCurrentCatalog: response.data, collectionCurrentCatalogMy: params.my, collectionCurrentDataset: null, collectionCatalogDatasets: response.data.datasets, collectionCatalogDatasetsOpen: true })
           window.scroll({ top: 0, left: 0, behavior: 'smooth' });
         })
         .catch(error => {
         //   throwToast('error', error.message);
         })
           // .then(json => {
           //     this.setState({ collectionCurrentCatalog: json, collectionCurrentDataset: null, collectionCatalogDatasets: json.datasets, collectionCatalogDatasetsOpen: true })
           // })
           // window.scroll({ top: 0, left: 0, behavior: 'smooth' });
    } else  if (action === 'delete-dataset') {
      this.setState({ deleteModalShow:true, deleteModalCommand: 'delete-dataset', deleteModalParams:params})
    } else  if (action === 'delete-dataset-ok') {
      deleteDataset(params.id)
        .then(response => {
            // if (params.type === 'catalog') {
            if (params.type === 'CATALOG') {
              var catalogs = this.state[params.scope.toLowerCase() + 'Catalogs'].filter(el => el.id !== params.id)
              this.setState( { [params.scope.toLowerCase() + 'Catalogs']: catalogs, [params.scope.toLowerCase() + 'CurrentDataset']: null, [params.scope.toLowerCase() + 'CurrentCatalog']: null} )
            // } else if (params.type === 'dataset') {
            } else if (params.type === 'DATASET')  {
              var datasets = this.state[params.scope.toLowerCase() + 'Datasets'].filter(el => el.id !== params.id)
              this.setState( {[params.scope.toLowerCase() + 'Datasets']: datasets, [params.scope.toLowerCase() + 'CurrentDataset']: null, [params.scope.toLowerCase() + 'CurrentCatalog']: null} )
            }
          })
        .catch(error => {
        //   throwToast('error', error.message);
        })
    } else if (action === 'add-dataset-to-catalog') {
      var datasets = this.state[params.scope.toLowerCase() + 'CurrentCatalog'].datasets.slice().concat(this.state[params.scope.toLowerCase() + 'Datasets'].filter(el => el.id === params.id))
      this.setState({ [params.scope.toLowerCase() + 'CurrentCatalog']: {...this.state[params.scope.toLowerCase() + 'CurrentCatalog'], datasets: datasets }});
    } else if (action === 'remove-dataset-from-catalog') {
      var datasets = this.state[params.scope.toLowerCase() + 'CurrentCatalog'].datasets.filter(el => el.id !== params.id)
      this.setState({ [params.scope.toLowerCase() + 'CurrentCatalog']: {...this.state[params.scope.toLowerCase() + 'CurrentCatalog'], datasets: datasets }});

    } else if (action === 'add-dataset-to-project') {
      addDatasetToProject(params.datasetId, params.projectId)
       .then(response => {
         throwToast('success', response.message)
       })
       .catch(error => {
       //   throwToast('error', error.message);
       })

    } else if (action === 'publish-dataset') {
      // publishDataset(params.id, params.visibility, params.tripleStore)
       publishDataset(params.id, params.group, params.tripleStore)
        .then(response => {
          throwToast('success', response.message)
        })
        .catch(error => {
        //   throwToast('error', error.message);
        })
    } else if (action === 'publish-dataset-unpublished-content') {
      republishDataset(params.id, params.group, 'ONLY_NEW')
        .then(response => {
          throwToast('success', response.message)
        })
        .catch(error => {
        //   throwToast('error', error.message);
        })
    } else if (action === 'unpublish-dataset') {
      unpublishDataset(params.id, params.group)
        .then(response => {
          throwToast('success', response.message)
        })
        .catch(error => {
        //   throwToast('error', error.message);
        })
    } else if (action === 'republish-dataset') {
      republishDataset(params.id, params.group, 'ALL')
        .then(response => {
          throwToast('success', response.message)
        })
        .catch(error => {
        //   throwToast('error', error.message);
        })
    } else if (action === 'republish-dataset-metadata') {
      republishDataset(params.id, params.group, 'NONE')
        .then(response => {
          throwToast('success', response.message)
        })
        .catch(error => {
        //   throwToast('error', error.message);
        })

    } else if (action === 'execute-all-mappings') {
      executeAllMappings(params.id, params.group)
        .then(response => {
          throwToast('success', response.message)
        })
        .catch(error => {
      //     throwToast('error', error.message)
      })

    } else if (action === 'update-dataset') {
      // if (params.type === 'dataset') {
      if (params.type === 'DATASET') {
        this.setState({ [params.scope.toLowerCase() + 'DatasetModalShow']: true, [params.scope.toLowerCase() + 'DatasetModalDataset']: this.state[params.scope.toLowerCase() + 'CurrentDataset'] ? this.state[params.scope.toLowerCase() + 'CurrentDataset'] : this.state[params.scope.toLowerCase() + 'CurrentCatalog']})
      } else {
        this.setState({ [params.scope.toLowerCase() + 'CatalogModalShow']: true, [params.scope.toLowerCase() + 'CatalogModalDataset']: this.state[params.scope.toLowerCase() + 'CurrentCatalog'] ? this.state[params.scope.toLowerCase() + 'CurrentCatalog'] : this.state[params.scope.toLowerCase() + 'CurrentCatalog']})
      }

    } else if (action === 'select-import') {
      if (params.dataImportCategory || params.dataImportType) {
        if (params.dataImportCategory) {
          this.setState({ dataImportCategory: params.dataImportCategory})
        }

        if (params.dataImportType) {
          if (params.dataImportType === 'catalogs') {
            this.setState({ dataImportType: params.dataImportType, dataImportScope: null, collectionCurrentCatalog: null, collectionCurrentDataset: null, collectionCatalogDatasets: null });
          } else if (params.dataImportType === 'datasets') {
            this.setState({ dataImportType: params.dataImportType, dataImportScope: this.state.dataImportScope ? this.state.dataImportScope : 'collections', collectionCurrentCatalog: null, collectionCatalogDatasets: null, collectionCurrentDataset: null, vocabularyCurrentDataset: null, alignmentCurrentDataset: null });
          } else if (params.dataImportType === 'd2rmls') {
            this.setState({ dataImportType: params.dataImportType, dataImportScope: null, d2rmlCurrentDataset: null });
          } else if (params.dataImportType === 'shacls') {
            this.setState({ dataImportType: params.dataImportType, dataImportScope: null, shaclCurrentDataset: null });
          } else if (params.dataImportType === 'annotators') {
            this.setState({ dataImportType: params.dataImportType, dataImportScope: null, annotatorCurrentDataset: null });
          } else if (params.dataImportType === 'comparators') {
            this.setState({ dataImportType: params.dataImportType, dataImportScope: null, comparatorCurrentDataset: null });
          } else if (params.dataImportType === 'indexes') {
            this.setState({ dataImportType: params.dataImportType, dataImportScope: null, indexCurrentDataset: null });
          }
      // } else if (params.dataImportType) {
      //   if (params.dataImportType === 'catalogs') {
      //     this.setState({ dataImportType: 'catalogs', dataImportScope: null, collectionCurrentCatalog: null, collectionCurrentDataset: null, collectionCatalogDatasets: null });
      //   } else if (params.dataImportType === 'datasets') {
      //     this.setState({ dataImportType: 'datasets', dataImportScope: this.state.dataImportScope ? this.state.dataImportScope : 'collections', collectionCurrentCatalog: null, collectionCatalogDatasets: null, collectionCurrentDataset: null, vocabularyCurrentDataset: null, alignmentCurrentDataset: null });
      //   } else if (params.dataImportType === 'd2rmls') {
      //     this.setState({ dataImportType: 'd2rmls', dataImportScope: null, d2rmlCurrentDataset: null });
      //   } else if (params.dataImportType === 'shacls') {
      //     this.setState({ dataImportType: 'shacls', dataImportScope: null, shaclCurrentDataset: null });
      //   } else if (params.dataImportType === 'annotators') {
      //     this.setState({ dataImportType: 'annotators', dataImportScope: null, annotatorCurrentDataset: null });
      //   } else if (params.dataImportType === 'comparators') {
      //     this.setState({ dataImportType: 'comparators', dataImportScope: null, comparatorCurrentDataset: null });
        }
      } else if (params.dataImportScope) {
        this.setState({ dataImportScope: params.dataImportScope })
      }

    } else if (action === 'select-published') {
      var obj = {};
      if (params.publishedDataType) {
        if (params.publishedDataType === 'catalogs') {
           obj = { publishedDataType: 'catalogs', publishedDataCategory: null };
        } else if (params.publishedDataType === 'datasets') {
          obj = { publishedDataType: 'datasets', publishedDataCategory: this.state.publishedDataCategory ? this.state.publishedDataCategory : 'collections' };
        }
      } else if (params.publishedDataCategory) {
        obj = { publishedDataCategory: params.publishedDataCategory }
      }

      obj = {...obj, selectedPublishedDataset: null};

      this.setState(obj, () => this.getEditorDatasets());
    } else if (action === 'select-published-dataset') {
       // this.setState({ ['selected' + params.ptype + 'PublishedDataset']: null}, () => this.setState({ ['selected' + params.ptype + 'PublishedDataset']: params.dataset}))
       this.setState({ ['selectedPublishedDataset']: null}, () => this.setState({ ['selectedPublishedDataset']: params.dataset, selectedPublishedDatasetUserType: params.ptype == 'Owned' ? 'OWNER' : 'NOT_OWNER' }))
    }
}

  setCurrentState(params, object) {
    if (object) {
      this.setState({ [this.buildCurrentField(params)]: object })
    }
  }

  resetCurrentState(params, level, state, startedAt, completedAt) {
    var currentField = this.buildCurrentField(params)

    var obj = {...this.state[currentField], [level + 'State']:state }

    if (startedAt) {
      obj = {...obj, [level + 'StartedAt']:startedAt }
    } else {
      delete obj.startedAt
    }

    if (completedAt) {
      obj = {...obj, [level + 'CompletedAt']:completedAt }
    } else {
      delete obj.startedAt
    }

    this.setState({ [currentField]: obj })
  }

  buildCurrentField(params) {
    return params.scope.toLowerCase() + 'Current' + params.type.charAt(0) + params.type.substring(1).toLowerCase()
  }

updateState(params, state, level) {
  if (level == null) {
    level = "publish"
  }

  if (params.type === 'CATALOG') {
    this.setState({ [params.scope.toLowerCase() + 'CurrentCatalog']: {...this.state[params.scope.toLowerCase() + 'CurrentCatalog'], [level + 'State']:state, [level + 'StartedAt']: new Date(), [level + 'CompletedAt']: null }})
  } else if (params.type === 'DATASET') {
    this.setState({ [params.scope.toLowerCase() + 'CurrentDataset']: {...this.state[params.scope.toLowerCase() + 'CurrentDataset'], [level + 'State']:state, [level + 'StartedAt']: new Date(), [level + 'CompletedAt']: null }})
  }
}


  // isTemporal(dataset) {
  //   for (var i in dataset.typeUri) {
  //     if (dataset.typeUri[i]=== 'http://sw.islab.ntua.gr/semaspace/model/TemporalCollection') {
  //       return true;
  //     }
  //   }
  //
  //   return false;
  // }

  // isSpatial(dataset) {
  //   for (var i in dataset.typeUri) {
  //     if (dataset.typeUri[i]=== 'http://sw.islab.ntua.gr/semaspace/model/SpatialCollection') {
  //       return true;
  //     }
  //   }
  //
  //   return false;
  // }

  // isThesaurus(dataset) {
  //   for (var i in dataset.typeUri) {
  //     if (dataset.typeUri[i]=== 'http://sw.islab.ntua.gr/semaspace/model/ThesaurusCollection') {
  //       return true;
  //     }
  //   }
  //
  //   return false;
  // }

goToTopOfThePage(){
  window.scroll({
    top: 0,
    left: 0,
    behavior: 'smooth'
   });
}

renderLeftPanel(name, key, keyi, label, scope) { // e.g. name = comparators, key = comparator, key= Comparator, label = Comparator, scope = COMPARATOR
  return (
    <React.Fragment>
      {this.state.dataImportType === name  &&
      <Container className={this.state[key + 'Datasets'] && this.state[key + 'Datasets'].length > 0 ? "groupborder" : "groupborder-empty"}>
        <Row className={this.state[key + 'Datasets'] && this.state[key + 'Datasets'].length > 0 ? "header" : "header-empty"}>
          <Col>
            My {label} Collections
          </Col>

          {toggleBoxColumn(key + 'DatasetsOpen', this, key + 'Datasets')}

          <Col className="mybutton" md="auto">
            {actionsMenu(
            <div>
              <Dropdown.Item className="py-2"
                             onClick={() => this.setState({ [key + 'DatasetModalShow']: true, [key + 'DatasetModalDataset']: null})}>
                <span className="menu-icon fa fa-plus fa-lg mr-3" />New {label} collection...
              </Dropdown.Item>
            </div>)}

            {this.state[key + 'DatasetModalShow'] &&
            <DatasetModal show={this.state[key + 'DatasetModalShow']}
                             type='DATASET'
                             scope={scope}
                             visibility={true}
                             dataset={this.state[key + 'DatasetModalDataset']}
                             myProjects={this.state.myProjects}
                             joinedProjects={this.state.joinedProjects}
                             selectedProject={this.state.selectedProject}
                             onOK={(id, template, name, identifier, visibility) => {this.executeAction('create-dataset', {id: id, template: template, name: name, identifier: identifier, visibility: visibility, scope: scope, type:'DATASET', category:'PROTOTYPE'}); this.setState({ [key + 'DatasetModalShow']: false})}}
                             onClose={() => this.setState({ [key + 'DatasetModalShow']: false})}/>}
          </Col>
        </Row>

        <Collapse in={this.state[key + 'DatasetsOpen']}>
          <div>
            {this.state[key + 'Datasets'].map((el,index) => (
            <Row key={index}>
              <Col md={12} className={"text-truncate" + (this.state[key + 'CurrentDataset'] && this.state[key + 'CurrentDataset'].id === el.id? " selected-item" : "")}>
                <span className="lbutton alink" onClick={() => this.executeAction('select-dataset', {id: el.id, scope: scope, type: 'DATASET', my:true})}>
                {el.name}
                </span>
              </Col>
            </Row>))}
          </div>
        </Collapse>
      </Container>}

      {this.state.dataImportType === name && this.state.selectedProject &&
      <Container className={this.state['project' + keyi + 'Datasets'] && this.state['project' + keyi + 'Datasets'].length > 0 ? "groupborder" : "groupborder-empty"}>
        <Row className={this.state['project' + keyi + 'Datasets'] && this.state['project' + keyi + 'Datasets'].length > 0 ? "header" : "header-empty"}>
          <Col>
            Project {label} Collections
          </Col>

          {toggleBoxColumn('project' + keyi + 'DatasetsOpen', this, 'project' + keyi + 'Datasets')}

          <Col className="mybutton" md="auto">
          </Col>
        </Row>

        <Collapse in={this.state['project' + keyi + 'DatasetsOpen']}>
          <div>
            {this.state['project' + keyi + 'Datasets'].map((el,index) => (
            <Row key={index}>
              <Col md={12} className={"text-truncate" + (this.state[key + 'CurrentDataset'] && this.state[key + 'CurrentDataset'].id === el.id? " selected-item" : "")}>
                <span className="lbutton alink" onClick={() => this.executeAction('select-dataset', {id: el.id, scope: scope, type: 'DATASET', my:false})}>
                {el.name}
                </span>
              </Col>
            </Row>))}
          </div>
        </Collapse>
      </Container>}
    </React.Fragment>
    )
}

renderDatasetEditor(name, key, scope) {
  return (
    <React.Fragment>
      {(this.state.dataImportCategory === "schemas" && this.state.dataImportType === name && this.state[key + 'CurrentDataset']) &&
          <DatasetEditor
            ref={node => (this.datasetEditor = node)}
            user={this.props.user}
            database={this.props.database}
            visibility={true}
            datasets={this.state.collectionDatasets}
            vocabularies={this.state.vocabularyDatasets}
            templates={this.state.mappingSampleTemplates}
            alignments={this.state.alignmentDatasets}
            type = 'DATASET'
            scope = {scope}
            dataset={this.state[key + 'CurrentDataset']}
            project={this.state.selectedProject}
            my={this.state[key + 'CurrentDatasetMy']}
            myProjects={this.state.myProjects}
            joinedProjects={this.state.joinedProjects}
            metadata={true}
            indices={this.state.indices}
            // shaclShapes={this.state.shaclShapes}
            actions={(action, params) => this.executeAction(action, { ...params, scope: scope})}/>}
      </React.Fragment>
    )
  }

  render() {

    var defaultTab;
    if (this.isEditor) {
      defaultTab = "import";
    } else if (this.isValidator) {
      defaultTab = "campaigns"
    } else if (this.isAdministrator) {
      defaultTab = "users"
    }

    return (
      <Container className="p-0">
        <Row>
          <Col>
            {this.props.user &&
            <SockJsClient url={WS_BASE_URL} topics={[
                '/monitor/' + this.props.user.id + '/mapping',
                '/monitor/' + this.props.user.id + '/file',
                '/monitor/' + this.props.user.id + '/dataset',
                '/monitor/' + this.props.user.id + '/annotator',
                '/monitor/' + this.props.user.id + '/embedder',
                '/monitor/' + this.props.user.id + '/index',
                '/monitor/' + this.props.user.id + '/filter_annotation_validation',
                '/monitor/' + this.props.user.id + '/paged_annotation_validation',
                '/monitor/' + this.props.user.id + '/user_task',
                '/monitor/' + this.props.user.id + '/distribution',
                '/monitor/' + this.props.user.id + '/login' ]}
                onMessage={(msg, topic) => {
                  // console.log(topic)
                  // console.log(msg)

                    if (topic === '/monitor/' + this.props.user.id + '/mapping') {
                      if (this.datasetEditor) {
                        this.datasetEditor.handleServerEvent('mapping', msg)
                      }
                      if (this.vocabularyDatasetEditor) {
                        this.vocabularyDatasetEditor.handleServerEvent('mapping', msg)
                      }
                      if (this.alignmentDatasetEditor) {
                        this.alignmentDatasetEditor.handleServerEvent('mapping', msg)
                      }
                    } else if (topic === '/monitor/' + this.props.user.id + '/file') {
                      if (this.datasetEditor) {
                        this.datasetEditor.handleServerEvent('file', msg)
                      }
                      if (this.vocabularyDatasetEditor) {
                        this.vocabularyDatasetEditor.handleServerEvent('file', msg)
                      }
                      if (this.alignmentDatasetEditor) {
                        this.alignmentDatasetEditor.handleServerEvent('file', msg)
                      }
                    }
                    else if (topic === '/monitor/' + this.props.user.id + '/dataset') {
                      if (this.datasetEditor) {
                        this.datasetEditor.handleServerEvent('dataset', msg)
                      }
                      if (this.vocabularyDatasetEditor) {
                        this.vocabularyDatasetEditor.handleServerEvent('dataset', msg)
                      }
                      this.handleServerEvent(msg)
                    }
                    else if (topic === '/monitor/' + this.props.user.id + '/index') {
                      if (this.datasetEditor) {
                        this.datasetEditor.handleServerEvent('index', msg)
                      }
                      if (this.vocabularyDatasetEditor) {
                        this.vocabularyDatasetEditor.handleServerEvent('index', msg)
                      }
                    }
                    else if (topic === '/monitor/' + this.props.user.id + '/user_task') {
                      if (this.datasetEditor) {
                        this.datasetEditor.handleServerEvent('user_task', msg)
                      }
                      if (this.vocabularyDatasetEditor) {
                        this.vocabularyDatasetEditor.handleServerEvent('user_task', msg)
                      }
                    }
                    else if (topic === '/monitor/' + this.props.user.id + '/distribution') {
                      if (this.datasetEditor) {
                        this.datasetEditor.handleServerEvent('distribution', msg)
                      }
                      if (this.vocabularyDatasetEditor) {
                        this.vocabularyDatasetEditor.handleServerEvent('distribution', msg)
                      }
                    }
                    else if (topic === '/monitor/' + this.props.user.id + '/annotator') {
                      if (this.ownedPublishedSchema) {
                        this.ownedPublishedSchema.handleServerEvent('annotator', msg)
                      }
                    }
                    else if (topic === '/monitor/' + this.props.user.id + '/embedder') {
                      if (this.ownedPublishedSchema) {
                        this.ownedPublishedSchema.handleServerEvent('embedder', msg)
                      }
                    }

                    else if (topic === '/monitor/' + this.props.user.id + '/filter_annotation_validation') {
                      if (this.ownedPublishedSchema) {
                        this.ownedPublishedSchema.handleServerEvent('filter_annotation_validation', msg)
                      }
                    }
                    else if (topic === '/monitor/' + this.props.user.id + '/paged_annotation_validation') {
                      if (this.ownedPublishedSchema) {
                        this.ownedPublishedSchema.handleServerEvent('paged_annotation_validation', msg)
                      }
                    }
                    else if (topic === '/monitor/' + this.props.user.id + '/login') {
                      this.props.actions('log-out')
                      alert("The same user has loged in from another location.")
                    }
                  }
                }
                onConnect={() => {console.log("ws connected")}}
                onConnectFailure={() => {console.log("failed to connect")}}
             />}

            <Tabs defaultActiveKey={defaultTab} mountOnEnter className={this.props.user.role.toLowerCase() + "-tabs"} onSelect={e => this.handleSelect(e)}>
              {this.isEditor &&
              <Tab eventKey="project" title="Projects">
                <ProjectsTab
                   myProjects={this.state.myProjects}
                   joinedProjects={this.state.joinedProjects}
                   currentProject={this.state.currentProject}
                   actions={(action, params) => this.executeAction(action, params)}/>
              </Tab>}

              {this.isEditor &&
              <Tab eventKey="import" title="Data Import">
                <Container className="pl-2 pr-2">
                  <Row>
                    <Col md={3}>

                      <Row>
                        <Col md={3}>Project</Col>
                        <Col className="align-self-center mb-2">
                          <Form.Control as="select" ref={node => (this.projectA = node)}
                            // defaultValue={this.state.selectedProject ? this.state.selectedProject.id : ''}
                            defaultValue={this.state.selectedProjectType ? this.state.selectedProjectType + this.state.selectedProject.id :''}
                            onChange={(event) => this.projectChanged(event)}
                            required>
                            <option disabled value='@'> --- My projects --- </option>
                            <option value=''> &lt;All my projects&gt; </option>
                            {this.state.myProjects.map((project, index) =>
                              <option key={index} value={'m' + project.id}>{project.name}</option>
                            )}
                            <option disabled value='@'> --- Joined projects --- </option>
                            {this.state.joinedProjects.map((project, index) =>
                              <option key={index} value={'p' + project.id}>{project.name}</option>
                            )}
                          </Form.Control>
                        </Col>
                      </Row>

                    <Row>
                      <Col className="align-self-center mb-2">
                        <ButtonGroup className="choice-buttons">
                          <Button className="data-choice-button" variant={this.state.dataImportCategory === "data"? "danger" : "secondary"} onClick={() => this.executeAction('select-import', { dataImportCategory: "data"})}>Data</Button>
                          <Button className="data-choice-button" variant={this.state.dataImportCategory === "schemas"? "danger" : "secondary"} onClick={() => this.executeAction('select-import', { dataImportCategory: "schemas", dataImportType: "d2rmls"})}>Schemas</Button>
                        </ButtonGroup>
                      </Col>
                    </Row>

                    {this.state.dataImportCategory === "schemas" &&
                    <div>
                      <Row>
                        <Col className="align-self-center mb-2">
                          {/*<ButtonGroup className="choice-buttons">
                            <Button className="data-choice-button" variant={this.state.dataImportType === "d2rmls"? "danger" : "secondary"} onClick={() => this.executeAction('select-import', { dataImportType: "d2rmls"})}>D2RML</Button>
                            <Button className="data-choice-button" variant={this.state.dataImportType === "shacls"? "danger" : "secondary"} onClick={() => this.executeAction('select-import', { dataImportType: "shacls"})}>SHACL</Button>
                            <Button className="data-choice-button" variant={this.state.dataImportType === "annotators"? "danger" : "secondary"} onClick={() => this.executeAction('select-import', { dataImportType: "annotators"})}>Annotators</Button>
                            <Button className="data-choice-button" variant={this.state.dataImportType === "comparators"? "danger" : "secondary"} onClick={() => this.executeAction('select-import', { dataImportType: "comparators"})}>Comparators</Button>
                          </ButtonGroup>*/}
                          <Row>
                            <Col md="auto" className="m-choice">
                              <Form.Label>Schema type</Form.Label>
                            </Col>
                            <Col className="m-option">
                              <Form.Control as="select"
                                            onClick={(event) => this.executeAction('select-import', { dataImportType: event.target.value })}
                                            defaultValue={this.state.dataImportType}>
                                <option value="d2rmls">D2RML Documents</option>
                                <option value="shacls">SHACL Documents</option>
                                <option value="annotators">Annotators</option>
                                <option value="comparators">Comparators</option>
                                <option value="indexes">Index Definitions</option>
                              </Form.Control>
                            </Col>
                          </Row>
                        </Col>
                      </Row>

                      {this.renderLeftPanel("d2rmls", "d2rml", "D2rml", "D2RML Document", "D2RML")}
                      {this.renderLeftPanel("shacls", "shacl", "Shacl", "SHACL Document", "SHACL")}
                      {this.renderLeftPanel("annotators", "annotator", "Annotator", "Annotator", "ANNOTATOR")}
                      {this.renderLeftPanel("comparators", "comparator", "Comparator", "Comparator", "COMPARATOR")}
                      {this.renderLeftPanel("indexes", "index", "Index", "Index Structure", "INDEX")}

                    </div>}

                    {this.state.dataImportCategory === "data" &&
                    <div>
                    <Row>
                      <Col className="align-self-center mb-2">
                        <ButtonGroup className="choice-buttons">
                          <Button className="data-choice-button" variant={this.state.dataImportType === "catalogs"? "danger" : "secondary"} onClick={() => this.executeAction('select-import', { dataImportType: "catalogs"})}>Catalogs</Button>
                          <Button className="data-choice-button" variant={this.state.dataImportType === "datasets"? "danger" : "secondary"} onClick={() => this.executeAction('select-import', { dataImportType: "datasets"})}>Datasets</Button>
                        </ButtonGroup>
                        {/*<Row>
                          <Col md="auto" className="m-choice">
                            <Form.Label>Data type</Form.Label>
                          </Col>
                          <Col className="m-option">
                            <Form.Control as="select"
                                          onClick={(event) => this.executeAction('select-import', { dataImportType: event.target.value })}
                                          defaultValue={this.state.dataImportType}>
                              <option value="catalogs">Catalogs</option>
                              <option value="datasets">Datasets</option>
                            </Form.Control>
                          </Col>
                        </Row>*/}
                      </Col>
                    </Row>

                    {this.state.dataImportType === "datasets" &&
                    <Row>
                      <Col className="align-self-center data-import-buttons">
                        <ButtonGroup className="choice-buttons">
                          <Button className="data-choice-button" variant={this.state.dataImportScope === "collections"? "danger" : "secondary"} onClick={() => this.executeAction('select-import', { dataImportScope: "collections"})}>Collections</Button>
                          <Button className="data-choice-button" variant={this.state.dataImportScope === "vocabularies"? "danger" : "secondary"} onClick={() => this.executeAction('select-import', { dataImportScope: "vocabularies"})}>Vocabularies</Button>
                          <Button className="data-choice-button" variant={this.state.dataImportScope === "alignments"? "danger" : "secondary"} onClick={() => this.executeAction('select-import', { dataImportScope: "alignments"})}>Alignments</Button>
                        </ButtonGroup>
                        {/*<Row>
                          <Col md="auto" className="m-choice">
                            <Form.Label>Dataset type</Form.Label>
                          </Col>
                          <Col className="m-option">
                            <Form.Control as="select"
                                          onClick={(event) => this.executeAction('select-import', { dataImportScope: event.target.value })}
                                          defaultValue={this.state.dataImportScope}>
                              <option value="collections">Collection</option>
                              <option value="vocabularies">Vocabulary</option>
                              <option value="alignments">Alignment</option>
                            </Form.Control>
                          </Col>
                        </Row>*/}
                      </Col>
                    </Row>}

                    {this.state.dataImportType === "catalogs" &&
                    <Container className={this.state.collectionCatalogs && this.state.collectionCatalogs.length > 0 ? "groupborder" : "groupborder-empty"}>
                      <Row className={this.state.collectionCatalogs && this.state.collectionCatalogs.length > 0 ? "header" : "header-empty"}>
                        <Col>
                          Catalogs
                        </Col>

                        {toggleBoxColumn('collectionCatalogsOpen', this, 'collectionCatalogs')}

                        <Col className="mybutton" md="auto">
                          {actionsMenu(
                          <div>
                            <Dropdown.Item className="py-2"
                                           onClick={() => this.setState({ collectionCatalogModalShow: true, collectionCatalogModalDataset: null})}>
                              <span className="menu-icon fa fa-plus fa-lg mr-3" />New catalog...
                            </Dropdown.Item>
                          </div>)}

                          {this.state.collectionCatalogModalShow &&
                          <DatasetModal show={this.state.collectionCatalogModalShow}
                                           type='CATALOG'
                                           tripleStores={this.props.database.tripleStores}
                                           templates={this.state.catalogImportTemplates}
                                           visibility={true}
                                           dataset={this.state.collectionCatalogModalDataset}
                                           myProjects={this.state.myProjects}
                                           joinedProjects={this.state.joinedProjects}
                                           selectedProject={this.state.selectedProject}
                                           onOK={(id, template, name, identifier, visibility) => {this.executeAction('create-dataset', {id: id, template: template, name: name, identifier: identifier, visibility: visibility, scope: 'COLLECTION', type:'CATALOG', category:'DATA', uri: 'http://sw.islab.ntua.gr/semaspace/model/DataCatalog'}); this.setState({ collectionCatalogModalShow: false})}}
                                           onClose={() => this.setState({ collectionCatalogModalShow: false})}/>}
                        </Col>
                      </Row>

                      <Collapse in={this.state.collectionCatalogsOpen}>
                        <div>
                          {this.state.collectionCatalogs.map((el,index) => (
                          <Row key={index}>
                            <Col md={12} className={"text-truncate" + (this.state.collectionCurrentCatalog && this.state.collectionCurrentCatalog.id === el.id? " selected-item" : "")}>
                              <span className="lbutton alink" onClick={() => this.executeAction('select-catalog', {id: el.id, my:true})}>
                              {el.name}
                              </span>
                            </Col>
                          </Row>))}
                        </div>
                      </Collapse>
                    </Container>}


                    {this.state.dataImportType === "catalogs" && this.state.collectionCurrentCatalog &&
                    <Row>
                      <Col className="in-down">
                        <span className='crimson-std fa fa-angle-down fa-lg'></span>
                      </Col>
                    </Row>}

                    {this.state.dataImportType === "catalogs" && this.state.collectionCurrentCatalog &&
                    <Container className={this.state.collectionCatalogDatasets && this.state.collectionCatalogDatasets.length > 0 ? "groupborder" : "groupborder-empty"}>
                      <Row className={this.state.collectionCatalogDatasets && this.state.collectionCatalogDatasets.length > 0 ? "header" : "header-empty"}>
                        <Col>
                          Collections
                        </Col>

                        {toggleBoxColumn('collectionCatalogDatasetsOpen', this, 'collectionCatalogDatasets')}

                        <Col className="mybutton" md="auto">
                        </Col>
                      </Row>

                      <Collapse in={this.state.collectionCatalogDatasetsOpen}>
                        <div>
                          {this.state.collectionCatalogDatasets && this.state.collectionCatalogDatasets.map((el,index) => (
                          <Row key={index}>
                            <Col md={12} className={"text-truncate" + (this.state.collectionCurrentDataset && this.state.collectionCurrentDataset.id === el.id? " selected-item" : "")}>
                              <span className="lbutton alink" onClick={() => this.executeAction('select-dataset', {id: el.id, scope: 'COLLECTION', type: 'DATASET', my:true})}>
                              {el.name}
                              </span>
                            </Col>
                          </Row>))}
                        </div>
                      </Collapse>
                    </Container>}


                    {this.state.dataImportType === "datasets" && this.state.dataImportScope === "collections" &&
                    <Container className={this.state.collectionDatasets && this.state.collectionDatasets.length > 0 ? "groupborder" : "groupborder-empty"}>
                      <Row className={this.state.collectionDatasets && this.state.collectionDatasets.length > 0 ? "header" : "header-empty"}>
                        <Col>
                          My Collections
                        </Col>

                        {toggleBoxColumn('collectionDatasetsOpen', this, 'collectionDatasets')}

                        <Col className="mybutton" md="auto">
                          {actionsMenu(
                          <div>
                            <Dropdown.Item className="py-2"
                                           onClick={() => this.setState({ collectionDatasetModalShow: true, collectionDatasetModalDataset: null})}>
                              <span className="menu-icon fa fa-plus fa-lg mr-3" />New collection...
                            </Dropdown.Item>
                          </div>)}

                          {this.state.collectionDatasetModalShow &&
                          <DatasetModal show={this.state.collectionDatasetModalShow}
                                           type='DATASET'
                                           scope='COLLECTION'
                                           tripleStores={this.props.database.tripleStores}
                                           templates={this.state.datasetImportTemplates}
                                           visibility={true}
                                           dataset={this.state.collectionDatasetModalDataset}
                                           myProjects={this.state.myProjects}
                                           joinedProjects={this.state.joinedProjects}
                                           selectedProject={this.state.selectedProject}
                                           onOK={(id, template, name, identifier, visibility, uri, property, clazz, ontology, multiGroup, sparqlEndpoint, namedGraph, lastUpdatedSparqlQuery) => {this.executeAction('create-dataset', {id: id, template: template, name: name, identifier: identifier, visibility: visibility, scope: 'COLLECTION', type:'DATASET', category:'DATA', uri: 'http://sw.islab.ntua.gr/semaspace/model/DataCollection', sparqlEndpoint, namedGraph, lastUpdatedSparqlQuery, multiGroup}); this.setState({ collectionDatasetModalShow: false})}}
                                           onClose={() => this.setState({ collectionDatasetModalShow: false})}/>}
                        </Col>
                      </Row>

                      <Collapse in={this.state.collectionDatasetsOpen}>
                        <div>
                          {this.state.collectionDatasets && this.state.collectionDatasets.map((el,index) => (
                          <Row key={index}>
                            <Col md={12} className={"text-truncate" + (this.state.collectionCurrentDataset && this.state.collectionCurrentDataset.id === el.id? " selected-item" : "")}>
                              <span className="lbutton alink" onClick={() => this.executeAction('select-dataset', {id: el.id, scope: 'COLLECTION', type: 'DATASET', my:true})}>
                              {el.name}
                              </span>
                            </Col>
                          </Row>))}
                        </div>
                      </Collapse>
                    </Container>}

                    {this.state.dataImportType === "datasets" && this.state.dataImportScope === "collections" && this.state.selectedProject &&
                    <Container className={this.state.projectCollectionDatasets && this.state.projectCollectionDatasets.length > 0 ? "groupborder" : "groupborder-empty"}>
                      <Row className={this.state.projectCollectionDatasets && this.state.projectCollectionDatasets.length > 0 ? "header" : "header-empty"}>
                        <Col>
                          Project Collections
                        </Col>

                        {toggleBoxColumn('projectCollectionDatasetsOpen', this, 'projectCollectionDatasets')}

                        <Col className="mybutton" md="auto">
                        </Col>
                      </Row>

                      <Collapse in={this.state.projectCollectionDatasetsOpen}>
                        <div>
                          {this.state.projectCollectionDatasets && this.state.projectCollectionDatasets.map((el,index) => (
                          <Row key={index}>
                            <Col md={12} className={"text-truncate" + (this.state.collectionCurrentDataset && this.state.collectionCurrentDataset.id === el.id? " selected-item" : "")}>
                              <span className="lbutton alink" onClick={() => this.executeAction('select-dataset', {id: el.id, scope: 'COLLECTION', type: 'DATASET', my:false})}>
                              {el.name}
                              </span>
                            </Col>
                          </Row>))}
                        </div>
                      </Collapse>
                    </Container>}


                    {this.state.dataImportType === "datasets" && this.state.dataImportScope === "vocabularies" &&
                    <Container className={this.state.vocabularyDatasets && this.state.vocabularyDatasets.length > 0 ? "groupborder" : "groupborder-empty"}>
                      <Row className={this.state.vocabularyDatasets && this.state.vocabularyDatasets.length > 0 ? "header" : "header-empty"}>
                        <Col>
                          My Vocabularies
                        </Col>

                        {toggleBoxColumn('vocabularyDatasetsOpen', this, 'vocabularyDatasets')}

                        <Col className="mybutton" md="auto">
                          {actionsMenu(
                          <div>
                            <Dropdown.Item className="py-2"
                                           onClick={() => this.setState({ vocabularyDatasetModalShow: true, vocabularyDatasetModalDataset: null})}>
                              <span className="menu-icon fa fa-plus fa-lg mr-3" />New vocabulary...
                            </Dropdown.Item>
                          </div>)}
                          {this.state.vocabularyDatasetModalShow &&
                          <DatasetModal show={this.state.vocabularyDatasetModalShow}
                                            type='DATASET'
                                            scope='VOCABULARY'
                                            database={this.props.database}
                                            tripleStores={this.props.database.tripleStores}
                                            visibility={true}
                                            dataset={this.state.vocabularyDatasetModalDataset}
                                            myProjects={this.state.myProjects}
                                            joinedProjects={this.state.joinedProjects}
                                            selectedProject={this.state.selectedProject}
                                            // types={this.vocabularyTypes}
                                            vocabularies={this.state.projectVocabularyDatasets}
                                            onOK={(id, template, name, identifier, visibility, uri, property, clazz, ontology, mulitGroup, sparqlEndpoint, namedGraph, lastUpdatedSparqlQuery, entityDescriptors) => {this.executeAction('create-dataset', {id: id, template: template, name: name, identifier: identifier, visibility: visibility, scope: 'VOCABULARY', type:'DATASET', category:'DATA', uri: uri, ontology:ontology, sparqlEndpoint, namedGraph, lastUpdatedSparqlQuery, entityDescriptors, mulitGroup}); this.setState({ vocabularyDatasetModalShow: false})}}
                                            onClose={() => this.setState({ vocabularyDatasetModalShow: false})}/>}
                        </Col>
                      </Row>

                      <Collapse in={this.state.vocabularyDatasetsOpen}>
                        <div>
                          {this.state.vocabularyDatasets && this.state.vocabularyDatasets.map((el,index) => (
                          <Row key={index} className={"text-truncate" + (this.state.vocabularyCurrentDataset && this.state.vocabularyCurrentDataset.id === el.id? " selected-item" : "")}>
                            {/*<Col md={2} className="align-self-center">
                              {this.isTemporal(el) && <i className="fa fa-calendar"></i>}
                              {this.isSpatial(el) && <i className="fa fa-globe"></i>}
                              {this.isThesaurus(el) && <i className="fa fa-list"></i>}
                            </Col>*/}
                            <Col md={12} >
                              <span className="lbutton alink" onClick={() => this.executeAction('select-dataset', {id: el.id, scope: 'VOCABULARY',type: 'DATASET', my:true})}>
                              {el.name}
                              </span>
                            </Col>
                          </Row>))}
                        </div>
                      </Collapse>

                    </Container>}

                    {this.state.dataImportType === "datasets" && this.state.dataImportScope === "vocabularies" && this.state.selectedProject &&
                    <Container className={this.state.projectVocabularyDatasets && this.state.projectVocabularyDatasets.length > 0 ? "groupborder" : "groupborder-empty"}>
                      <Row className={this.state.projectVocabularyDatasets && this.state.projectVocabularyDatasets.length > 0 ? "header" : "header-empty"}>
                        <Col>
                          Project Vocabularies
                        </Col>

                        {toggleBoxColumn('projectVocabularyDatasetsOpen', this, 'projectVocabularyDatasets')}

                        <Col className="mybutton" md="auto">

                        </Col>
                      </Row>

                      <Collapse in={this.state.projectVocabularyDatasetsOpen}>
                        <div>
                          {this.state.projectVocabularyDatasets && this.state.projectVocabularyDatasets.map((el,index) => (
                          <Row key={index} className={"text-truncate" + (this.state.vocabularyCurrentDataset && this.state.vocabularyCurrentDataset.id === el.id? " selected-item" : "")}>
                            {/*<Col md={2} className="align-self-center">
                              {this.isTemporal(el) && <i className="fa fa-calendar"></i>}
                              {this.isSpatial(el) && <i className="fa fa-globe"></i>}
                              {this.isThesaurus(el) && <i className="fa fa-list"></i>}
                            </Col>*/}
                            <Col md={12} >
                              <span className="lbutton alink" onClick={() => this.executeAction('select-dataset', {id: el.id, scope: 'VOCABULARY',type: 'DATASET', my:false})}>
                              {el.name}
                              </span>
                            </Col>
                          </Row>))}
                        </div>
                      </Collapse>

                    </Container>}

                    {this.state.dataImportType === "datasets" && this.state.dataImportScope === "alignments" &&
                    <div>
{/*                      <Container  className="groupborder">
                        <Row className="header">
                          <Col>
                            Groups
                          </Col>
                          <Col className="mybutton" md="auto">
                            <Button type="button" className="menubutton"  aria-label="Add"  onClick={() => this.setState({ vocabularyCatalogModalShow: true})}><span className='fa fa-plus'></span></Button>
                            <DatasetModal type='Vocabulary Group' show={this.state.vocabularyCatalogModalShow}
                                         onOK={(name) => {this.executeAction('create-dataset', {name: name, scope: 'vocabulary', type:'catalog'}); this.setState({ vocabularyCatalogModalShow: false})}}
                                         onClose={() => this.setState({ vocabularyCatalogModalShow: false})}/>
                          </Col>
                          <Col className="mybutton" md="auto">
                            <Button type="button" className="menubutton"  aria-label="Toggle" onClick={() => this.setState({ vocabularyCatalogsOpen: !this.state.vocabularyCatalogsOpen})}><span className={this.state.vocabularyCatalogsOpen ? 'fa fa-angle-double-up' : 'fa fa-angle-double-down'}></span></Button>
                          </Col>
                        </Row>
                        {this.state.vocabularyCatalogs.map((el,index) => (
                        <Row key={index}>
                          <Col>
                            <span className="abutton" onClick={() => this.executeAction('select-dataset', {id: el.id, scope: 'vocabulary', type: 'catalog'})}>
                            {el.name}
                            </span>
                          </Col>
                        </Row>))}
                      </Container> */}

                      <Container className={this.state.alignmentDatasets && this.state.alignmentDatasets.length > 0 ? "groupborder" : "groupborder-empty"}>
                        <Row className={this.state.alignmentDatasets && this.state.alignmentDatasets.length > 0 ? "header" : "header-empty"}>
                          <Col>
                            Alignments
                          </Col>

                          {toggleBoxColumn('alignmentDatasetsOpen', this, 'alignmentDatasets')}

                          <Col className="mybutton" md="auto">
                            {actionsMenu(
                            <div>
                              <Dropdown.Item className="py-2"
                                             onClick={() => this.setState({ alignmentDatasetModalShow: true, alignmentDatasetModalDataset: null})}>
                                <span className="menu-icon fa fa-plus fa-lg mr-3" />New alignment...
                              </Dropdown.Item>
                            </div>)}

                            {this.state.alignmentDatasetModalShow &&
                            <NewAlignmentModal show={this.state.alignmentDatasetModalShow}
                                               type='ALIGNMENT'
                                               database={this.props.database}
                                               dataset={this.state.alignmentDatasetModalDataset}
                                               // types={this.vocabularyTypes}
                                               vocabularies={this.state.vocabularyDatasets}
                                               onOK={(id, name, uri, source, target) => {this.executeAction('create-dataset', {id: id, name: name, scope:'ALIGNMENT', type:'DATASET', category:'DATA', uri: uri, source:source, target:target}); this.setState({ alignmentDatasetModalShow: false})}}
                                               onClose={() => this.setState({ alignmentDatasetModalShow: false})}/>}
                          </Col>
                        </Row>

                        <Collapse in={this.state.alignmentDatasetsOpen}>
                          <div>
                            {this.state.alignmentDatasets && this.state.alignmentDatasets.map((el,index) => (
                            <Row key={index}>
                              <Col md={12} className={"text-truncate" + (this.state.alignmentCurrentDataset && this.state.alignmentCurrentDataset.id === el.id? " selected-item" : "")}>
                                <span className="lbutton alink" onClick={() => this.executeAction('select-dataset', {id: el.id, scope: 'ALIGNMENT', type: 'DATASET', my:true})}>
                                {el.name}
                                </span>
                              </Col>
                            </Row>))}
                          </div>
                        </Collapse>
                      </Container>
                    </div>}
                    </div>}

                    </Col>
                    {(this.state.dataImportType === "catalogs" || this.state.dataImportScope === "collections" || this.state.dataImportType === "shacls" || this.state.dataImportType === "d2rmls" || this.state.dataImportType === "annotators" || this.state.dataImportType === "comparators" || this.state.dataImportType === "indexes") &&
                    <Col className="import-right">

                    {this.state.dataImportType === "catalogs" && !this.state.collectionCurrentCatalog &&
                    <Container className="groupborder">
                      <Row className="mt-4 text-center">
                        <Col>Select a catalog from the left to view, or click the <span className="fa fa-bars fa-md m-2"/> button to create a new one.</Col>
                      </Row>
                    </Container>}

                    {this.state.dataImportType === "collections" && !this.state.collectionCurrentDataset &&
                    <Container className="groupborder">
                      <Row className="mt-4 text-center">
                        <Col>Select a collection from the left to view, or click the <span className="fa fa-bars fa-md m-2"/> button to create a new one.</Col>
                      </Row>
                    </Container>}

                    {this.renderDatasetEditor("d2rmls", "d2rml", "D2RML")}
                    {this.renderDatasetEditor("shacls", "shacl", "SHACL")}
                    {this.renderDatasetEditor("annotators", "annotator", "ANNOTATOR")}
                    {this.renderDatasetEditor("comparators", "comparator", "COMPARATOR")}
                    {this.renderDatasetEditor("indexes", "index", "INDEX")}

                    {this.state.dataImportCategory === "data" && ((this.state.dataImportType === "catalogs" && this.state.collectionCurrentCatalog) || this.state.dataImportScope === "collections")

                        && (this.state.collectionCurrentDataset || this.state.collectionCurrentCatalog) &&
                        <DatasetEditor
                          ref={node => (this.datasetEditor = node)}
                          user={this.props.user}
                          database={this.props.database}
                          visibility={true}
                          datasets={this.state.collectionDatasets}
                          vocabularies={this.state.vocabularyDatasets}
                          templates={this.state.mappingSampleTemplates}
                          alignments={this.state.alignmentDatasets}
                          type = {this.state.collectionCurrentDataset ? 'DATASET' : 'CATALOG'}
                          scope = 'COLLECTION'
                          dataset={this.state.collectionCurrentDataset ? this.state.collectionCurrentDataset : this.state.collectionCurrentCatalog}
                          project={this.state.selectedProject}
                          my={this.state.collectionCurrentDataset ? this.state.collectionCurrentDatasetMy : this.state.collectionCurrentCatalogMy}
                          myProjects={this.state.myProjects}
                          joinedProjects={this.state.joinedProjects}
                          metadata={true}
                          indices={this.state.indices}
                          // shaclShapes={this.state.shaclShapes}
                          actions={(action, params) => this.executeAction(action, { ...params, scope:'COLLECTION'})}/>}
                    </Col>}

                    {this.state.dataImportScope === "vocabularies" &&
                    <Col>

                      {!this.state.vocabularyCurrentDataset &&
                      <Container className="groupborder">
                        <Row className="mt-4 text-center">
                          <Col>Select a vocabulary from the left to view, or click the <span className="fa fa-bars fa-md m-2"/> button to create a new one.</Col>
                        </Row>
                      </Container>}

                       {this.state.dataImportCategory === "data" && (this.state.vocabularyCurrentCatalog || this.state.vocabularyCurrentDataset) &&
                       <DatasetEditor ref={node => (this.vocabularyDatasetEditor = node)}
                                      user={this.props.user}
                                      database={this.props.database}
                                      visibility={true}
                                      datasets={this.state.vocabularyDatasets}
                                      templates={this.state.mappingSampleTemplates}
                                      type = {this.state.vocabularyCurrentDataset ? 'DATASET' : 'CATALOG'}
                                      scope = 'VOCABULARY'
                                      dataset={this.state.vocabularyCurrentDataset ? this.state.vocabularyCurrentDataset : this.state.vocabularyCurrentCatalog}
                                      project={this.state.selectedProject}
                                      my={this.state.vocabularyCurrentDataset ? this.state.vocabularyCurrentDatasetMy : this.state.vocabularyCurrentCatalogMy}
                                      myProjects={this.state.myProjects}
                                      joinedProjects={this.state.joinedProjects}
                                      metadata={true}
                                      indices={this.state.indices}
                                      // shaclShapes={this.state.shaclShapes}
                                      actions={(action, params) => this.executeAction(action, { ...params, scope:'VOCABULARY'})}/>}
                    </Col>}

                    {this.state.dataImportScope === "alignments" &&
                    <Col>

                      {!this.state.alignmentCurrentDataset &&
                      <Container className="groupborder">
                        <Row className="mt-4 text-center">
                          <Col>Select an alignment from the left to view, or click the <span className="fa fa-bars fa-md m-2"/> button to create a new one.</Col>
                        </Row>
                      </Container>}
{/*                      {this.state.alignmentCurrentCatalog &&
                      <CatalogEditor user={this.props.user}
                                     datasets={this.state.alignmentDatasets}
                                     catalog={this.state.alignmentCurrentCatalog}
                                     actions={(action, params) => this.executeAction(action, { ...params, scope:'alignment'})}/>} */}
                      {this.state.alignmentCurrentDataset &&
                      <DatasetEditor ref={node => (this.alignmentDatasetEditor = node)}
                                     user={this.props.user}
                                     database={this.props.database}
                                     templates={this.state.mappingSampleTemplates}
                                     visibility={false}
                                     type = 'DATASET'
                                     scope = 'ALIGNMENT'
                                     dataset={this.state.alignmentCurrentDataset}
                                     project={this.state.selectedProject}
                                     my={this.state.alignmentCurrentDatasetMy}
                                     myProjects={this.state.myProjects}
                                     joinedProjects={this.state.joinedProjects}
                                     indices={this.state.indices}
                                     // shaclShapes={this.state.shaclShapes}
                                     actions={(action, params) => this.executeAction(action, { ...params, scope:'ALIGNMENT'})}/>}
                    </Col>}
                  </Row>
                </Container>
              </Tab>}

{/*
              <Tab eventKey="annotations" title={Localizer.annotations_import[this.props.language]}>
                <Container>
                  <Row>
                    <Col md={3}>
                      <Container  className="groupborder">
                        <Row className="header">
                          <Col>
                            {Localizer.annotations[this.props.language]}
                          </Col>
                          <Col className="mybutton" md="auto">
                            <Button type="button" className="menubutton"  aria-label="Add"  onClick={() => this.setState({ annotationDatasetModalShow: true})}><span className='fa fa-plus'></span></Button>
                            <DatasetModal type='Annotation' show={this.state.annotationDatasetModalShow}
                                         queryProperties={this.state.queryOntologyProperties}
                                         onOK={(name, type, property) => {this.executeAction('create-dataset', {name: name, scope: 'annotation', type:'dataset', uri: 'http://sw.islab.ntua.gr/semaspace/model/AnnotationSet', asProperty: property}); this.setState({ newAnnotationDatasetModalShow: false})}}
                                         onClose={() => this.setState({ annotationDatasetModalShow: false})}/>
                          </Col>
                          <Col className="mybutton" md="auto">
                            <Button type="button" className="menubutton"  aria-label="Toggle" onClick={() => this.setState({ annotationDatasetsOpen: !this.state.annotationDatasetsOpen})}><span className={this.state.annotationDatasetsOpen ? 'fa fa-angle-double-up' : 'fa fa-angle-double-down'}></span></Button>
                          </Col>
                        </Row>
                        {this.state.annotationDatasets.map((el,index) => (
                        <Row key={index}>
                          <Col>
                            <span className="lbutton alink" onClick={() => this.executeAction('select-dataset', {id: el.id, scope: 'annotation',type: 'dataset'})}>
                            {el.name}
                            </span>
                          </Col>
                        </Row>))}
                      </Container>
                    </Col>
                    <Col>
                      {this.state.annotationCurrentDataset &&
                      <DatasetEditor ref={node => (this.annotationDatasetEditor = node)}
                                     user={this.props.user}
                                     visibility={false}
                                     type = 'dataset'
                                     dataset={this.state.annotationCurrentDataset}
                                     actions={(action, params) => this.executeAction(action, { ...params, scope:'annotation'})}/>}
                    </Col>
                  </Row>
                </Container>
              </Tab> */}

              {this.isEditor &&
              <Tab eventKey="published_data" title={Localizer.published_datasets[this.props.language]}>
                <Container className="pl-2">
                  <Row>
                    <Col md={3}>

                    <Row>
                      <Col md={3}>Project</Col>
                      <Col className="align-self-center mb-2">
                        <Form.Control as="select" ref={node => (this.projectB = node)}
                          defaultValue={this.state.selectedProjectType ? this.state.selectedProjectType + this.state.selectedProject.id :''}
                          onChange={(event) => this.projectChanged(event)}
                          required>
                          <option disabled value='@'> --- My projects --- </option>
                          <option value=''> &lt;All my projects&gt; </option>
                          {this.state.myProjects.map((project, index) =>
                            <option key={index} value={'m' + project.id}>{project.name}</option>
                          )}
                          <option disabled value='@'> --- Joined projects --- </option>
                          {this.state.joinedProjects.map((project, index) =>
                            <option key={index} value={'p' + project.id}>{project.name}</option>
                          )}
                        </Form.Control>
                      </Col>
                    </Row>

                      <Row>
                        <Col className="align-self-center mb-2">
                          <ButtonGroup className="choice-buttons">
                            <Button className="data-choice-button" variant={this.state.publishedDataType === "catalogs"? "danger" : "secondary"} onClick={() => this.executeAction('select-published', { publishedDataType: "catalogs"})}>Catalogs</Button>
                            <Button className="data-choice-button" variant={this.state.publishedDataType === "datasets"? "danger" : "secondary"} onClick={() => this.executeAction('select-published', { publishedDataType: "datasets"})}>Datasets</Button>
                          </ButtonGroup>
                        </Col>
                      </Row>

                      {this.state.publishedDataType === "datasets" &&
                      <Row>
                        <Col className="align-self-center data-import-buttons">
                          <ButtonGroup className="choice-buttons">
                            <Button className="data-choice-button" variant={this.state.publishedDataCategory === "collections"? "danger" : "secondary"} onClick={() => this.executeAction('select-published', { publishedDataCategory: "collections"})}>Collections</Button>
                            <Button className="data-choice-button" variant={this.state.publishedDataCategory === "vocabularies"? "danger" : "secondary"} onClick={() => this.executeAction('select-published', { publishedDataCategory: "vocabularies"})}>Vocabularies</Button>
                            <Button className="data-choice-button" variant={this.state.publishedDataCategory === "alignments"? "danger" : "secondary"} onClick={() => this.executeAction('select-published', { publishedDataCategory: "alignments"})}>Alignments</Button>
                          </ButtonGroup>
                        </Col>
                      </Row>}

                      <Container className={this.state.ownedPublishedDatasets && this.state.ownedPublishedDatasets.length > 0 ? "groupborder" : "groupborder-empty"}>
                        <Row className={this.state.ownedPublishedDatasets && this.state.ownedPublishedDatasets.length > 0 ? "header" : "header-empty"}>
                          {this.state.publishedDataType == "catalogs" && <Col>Catalogs</Col>}
                          {this.state.publishedDataType == "datasets" && this.state.publishedDataCategory === "collections" && <Col>My Collections</Col>}
                          {this.state.publishedDataType == "datasets" && this.state.publishedDataCategory === "vocabularies" && <Col>My Vocabularies</Col>}
                          {this.state.publishedDataType == "datasets" && this.state.publishedDataCategory === "alignments" && <Col>My Alignments</Col>}

                          {toggleBoxColumn('publishedDataOpen', this, 'ownedPublishedDatasets')}
                        </Row>

                            <Collapse in={this.state.publishedDataOpen}>
                              <div>
                                {this.state.ownedPublishedDatasets && this.state.ownedPublishedDatasets.map((el,index) => (
                                <Row key={index}>
                                  <Col md={12} className={"text-truncate" + (this.state.selectedPublishedDataset && this.state.selectedPublishedDataset.id == el.dataset.id? " selected-item" : "")}>
                                    <span className="lbutton alink" onClick={() => this.executeAction('select-published-dataset', {dataset: el.dataset, ptype: 'Owned'})}>
                                    {el.dataset.name}
                                    </span>
                                  </Col>
                                </Row>))}
                              </div>
                            </Collapse>

                          </Container>

                          {this.state.selectedProject &&
                            <Container className={this.state.projectPublishedDatasets && this.state.projectPublishedDatasets.length > 0 ? "groupborder" : "groupborder-empty"}>
                              <Row className={this.state.projectPublishedDatasets && this.state.projectPublishedDatasets.length > 0 ? "header" : "header-empty"}>
                                {this.state.publishedDataType == "catalogs" && <Col>Catalogs</Col>}
                                {this.state.publishedDataType == "datasets" && this.state.publishedDataCategory === "collections" && <Col>Project Collections</Col>}
                                {this.state.publishedDataType == "datasets" && this.state.publishedDataCategory === "vocabularies" && <Col>Project Vocabularies</Col>}
                                {this.state.publishedDataType == "datasets" && this.state.publishedDataCategory === "alignments" && <Col>Project Alignments</Col>}

                                {toggleBoxColumn('projectPublishedDataOpen', this, 'projectPublishedDatasets')}
                              </Row>

                            <Collapse in={this.state.projectPublishedDataOpen}>
                              <div>
                                {this.state.projectPublishedDatasets && this.state.projectPublishedDatasets.map((el,index) => (
                                <Row key={index}>
                                  <Col md={12} className={"text-truncate" + (this.state.selectedProjectPublishedDataset && this.state.selectedProjectPublishedDataset.id == el.dataset.id? " selected-item" : "")}>
                                    <span className="lbutton alink" onClick={() => this.executeAction('select-published-dataset', {dataset: el.dataset, ptype: 'Project'})}>
                                    {el.dataset.name}
                                    </span>
                                  </Col>
                                </Row>))}
                              </div>
                            </Collapse>

                      </Container>}
                    </Col>
                    <Col>
                    <Container className="userspace-right">
                      <Row className=" userspace-right-inner">
                        <Container>

                          {!this.state.selectedPublishedDataset &&
                          <Container className="groupborder">
                            <Row className="mt-4 text-center">
                              <Col>Select a catalog or dataset (collection, vocabulary, alignment) from  the left to view.</Col>
                            </Row>
                          </Container>}

                          <Row>
                            <Col>
                              <PublishedSchema ref={node => (this.ownedPublishedSchema = node)}
                                               //mode='EDITOR'
                                               my={this.state.selectedPublishedDatasetUserType == 'OWNER' ? true : false}
                                               language={this.props.language}
                                               database={this.props.database}
                                               schemaDataset={this.state.selectedPublishedDataset}
                                               queryProperties={this.state.queryOntologyProperties}
                                               dataAnnotators={this.state.dataAnnotators}
                                               userAnnotators={this.state.userAnnotators}
                                               dataEmbedders={this.state.dataEmbedders}
                                               preprocessFunctions={this.state.preprocessFunctions}
                                               preprocessOperations={this.state.preprocessOperations}
                                               project={this.state.selectedProject}
                                               // vocabularies={this.state.projectVocabularyDatasets}
                                               rdfVocabularies={this.state.rdfVocabularies}
                                               validationModes={this.state.validationModes}/>
                              </Col>
                            </Row>
                          </Container>
                        </Row>
                      </Container>
                    </Col>
                  </Row>
                </Container>
              </Tab>}

              {(this.isEditor || this.isValidator) &&
              <Tab eventKey="campaigns" title="Campaigns">

                <CampaignsTab mode={this.isValidator ? 'VALIDATOR' : 'EDITOR'}
                              language={this.props.language}
                              database={this.props.database}
                              datasets={this.state.campaignDatasets}
                              selectedProject={this.state.selectedProject}
                              myProjects={this.state.myProjects}
                              joinedProjects={this.state.joinedProjects}
                              rdfVocabularies={this.state.rdfVocabularies}
                              projectChanged={(event) => this.projectChanged(event)} />
              </Tab>}

              {/*this.isValidator && this.state.assignedPublishedDatasets.length > 0 &&
              <Tab eventKey="schema_assigned" title={Localizer.assigned_datasets[this.props.language]}>
                <Container>
                  <Row>
                    <Col md={3}>
                      <Container  className="groupborder pb-0">
                        <Row className="header mb-0">
                          <Col>
                            {Localizer.assigned_datasets[this.props.language]}
                          </Col>
                        </Row>
                        <Row className="content">
                          <Accordion defaultActiveKey="group-0" bsPrefix="accordion w-100">
                            {Array.from(Object.keys(this.state.validatorDatasets)).map((key,index) => (
                              <Card key={"editor-batch-"+key}>
                                <Accordion.Toggle as={Card.Header} eventKey={"group-"+index} className="datasetGrouping-header">
                                  <span className="font-weight-bold">{key}</span>
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey={"group-"+index}>
                                  <Card.Body bsPrefix="card-body p-0">
                                    <Datasets
                                      user={this.props.currentUser}
                                      type="assigned-datasets"
                                      datasets={this.state.validatorDatasets[key]}
                                      language={this.props.language}
                                      actions={(action, params) => this.publishedDatasetActions(action, params, 'Assigned')}
                                    />
                                  </Card.Body>
                                </Accordion.Collapse>
                              </Card>
                            ))}
                          </Accordion>
                        </Row>
                      </Container>
                    </Col>
                    <Col>
                      <PublishedSchema ref={node => (this.assignedPublishedSchema = node)}
                                       mode='VALIDATOR'
                                       database={this.props.database}
                                       language={this.props.language}
                                       schemaDataset={this.state.selectedAssignedPublishedDataset}
                                       queryProperties={this.state.queryOntologyProperties}
                                       dataAnnotators={this.state.dataAnnotators}
                                       preprocessFunctions={this.state.preprocessFunctions}
                                       preprocessOperations={this.state.preprocessOperations}
                                       vocabularies={this.state.projectVocabularyDatasets}
                                       rdfVocabularies={this.state.rdfVocabularies}/>
                    </Col>
                  </Row>
                </Container>
              </Tab>*/}

              {this.isEditor &&
              <Tab eventKey="task_monitor" title={Localizer.taskMonitor[this.props.language]} >
                <TaskMonitorTab tasks={[]}/>
              </Tab>}

              {this.isAdministrator &&
              <Tab eventKey="users" title="Users">
                <UsersTab/>
              </Tab>}

              {this.isAdministrator &&
              <Tab eventKey="triple_stores" title="Triple Stores">
                <TripleStoresTab/>
              </Tab>}

              {this.isAdministrator &&
              <Tab eventKey="indices" title="Indices">
                <ElasticsTab/>
              </Tab>}

              {this.isAdministrator &&
              <Tab eventKey="rdf_vocabularies" title="RDF Vocabularies ">
                <RDFVocabulariesTab/>
              </Tab>}
            </Tabs>
          </Col>
        </Row>

        {this.state.deleteModalShow && <DeleteModal show={this.state.deleteModalShow}
                                command={this.state.deleteModalCommand}
                                params={this.state.deleteModalParams}
                                actions={(choice, command, params) => { this.setState( {deleteModalShow:false }); return (choice === 'ok' ? this.executeAction(command + '-ok', params): null)} }
                                />}
      </Container>
    );
  }
}



export default UserSpace;
