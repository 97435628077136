import React, { Component } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import ReactTooltip from "react-tooltip";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { filterByLanguage } from "../../../utils/functions.js";
import { checkProjectIdentifier } from "../../../utils/ProjectAPI.js";
import BootstrapSwitchButton from 'bootstrap-switch-button-react'

export class ProjectModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      show: this.props.show,
      error: false,
      identifierExists: false,
      class: null,
      privat: this.props.project ? !this.props.project.public : true,
    }

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  existsProjectIdentifier() {
    if (this.identifier.value) {
      checkProjectIdentifier(this.identifier.value)
      .then(response => {
        if (!response.data.valid || response.data.exists) {
           this.setState( {identifierExists : true })
        } else
            this.setState( {identifierExists : false })
        })
      }
   }

  formIsValid() {
    var ok = !(this.identifier && this.identifier.value && ((!this.props.dataset && this.state.identifierExists) || (this.props.dataset && this.state.identifierExists && this.props.dataset.identifier !== this.identifier.value)));

    return ok;
  }

  handleSubmit(event) {
     event.preventDefault();

      if (this.formIsValid()) {
        this.props.onOK(this.props.project ? this.props.project.id : null,
                        this.name.value,
                        this.identifier.value,
                        !this.state.privat,
                      );
      }
  }

  render() {
    // console.log(this.props);
    // console.log(this.state.europeanaImportMethod)
    var button = <BootstrapSwitchButton
        checked={this.props.project ? !this.props.project.public : this.state.privat}
        onlabel='Private'
        onstyle='danger'
        offlabel='Public'
        offstyle='success'
        style='w-100'
        onChange={(checked: boolean) => {
            this.setState({ privat: checked })
        }}/>

    return (
      <Modal show={this.props.show} onHide={this.props.onClose} animation={false} backdrop="static">
        <Form onSubmit={this.handleSubmit}>
          <Modal.Header>
            <Modal.Title>{!this.props.project ? 'New' : 'Edit'} Project</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group>
              <Form.Label className="required">Name</Form.Label>
              <Form.Control ref={node => (this.name = node)}
                   onChange={() => this.setState({ error: false })}
                   pattern="\S.*"
                   defaultValue={this.props.project ? this.props.project.name : ""}
                   required/>
            </Form.Group>
            <Form.Control.Feedback type="invalid">
              Provide a valid name.
            </Form.Control.Feedback>

            <Form.Group>
              <Form.Label>Identifier</Form.Label>
              <Form.Control ref={node => (this.identifier = node)}
                   onChange={() => this.existsProjectIdentifier()}
                   isInvalid={this.identifier && this.identifier.value && ((!this.props.project && this.state.identifierExists) || (this.props.project && this.state.identifierExists && this.props.project.identifier !== this.identifier.value))}
                   defaultValue={this.props.project ? this.props.project.identifier : ""}/>
              <Form.Control.Feedback type="invalid">
                   Identifier already exists or is invalid.
             </Form.Control.Feedback>
            </Form.Group>

            <Form.Group>
              <Form.Label className="required">Visibility</Form.Label>
              {button}
            </Form.Group>

          </Modal.Body>
          <Modal.Footer>
            <Button type="submit" variant="primary">
              OK
            </Button>
            <Button variant="secondary" onClick={this.props.onClose}>
              Cancel
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
  )}
}

export default ProjectModal;
