import React, { Component } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import BarLoader from "react-spinners/BarLoader";
import { previewLastMappingExecution, previewPublishedMappingExecution  } from '../../../utils/MappingsAPI';
import { previewLastAnnotatorExecution, previewPublishedAnnotatorExecution  } from '../../../utils/AnnotatorAPI';
import { previewLastEmbedderExecution, previewPublishedEmbedderExecution  } from '../../../utils/EmbedderAPI';
import { previewLastPagedAnnotationValidationExecution, previewPublishedPagedAnnotationValidationExecution  } from '../../../utils/PagedAnnotationValidationAPI';
import { previewLastFilterAnnotationValidationExecution, previewPublishedFilterAnnotationValidationExecution  } from '../../../utils/FilterAnnotationValidationAPI';
import { parseResult } from '../../../utils/UIUtils';

export class ResultsModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      shard: null,
      nextLine: null,
      extraText: "",
    }
  }

  handleScroll = (e) => {
   const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
   if (bottom &&  this.state.nextLine &&  this.state.nextLine != -1) {
     if (this.props.execution.type == 'MAPPING') {
       if (this.props.execution.mode == 'LAST_EXECUTION') {
         previewLastMappingExecution(this.props.execution.id, this.props.execution.instanceId, this.state.shard, this.state.nextLine)
           .then(response => {
             response.text().then(text =>  {
               var p = parseResult(text)
               this.setState({ extraText: this.state.extraText + p.text, lines: p.lines, shard: p.shard, nextLine: p.nextLine })})})
        } else if (this.props.execution.mode == 'PUBLISHED_EXECUTION') {
          previewPublishedMappingExecution(this.props.execution.id, this.props.execution.instanceId, this.state.shard, this.state.nextLine)
            .then(response => {
              response.text().then(text =>  {
                var p = parseResult(text)
                this.setState({ extraText: this.state.extraText + p.text, lines: p.lines, shard: p.shard, nextLine: p.nextLine })})})
         }
     } else if (this.props.execution.type == 'ANNOTATOR') {
       if (this.props.execution.mode == 'LAST_EXECUTION') {
         previewLastAnnotatorExecution(this.props.execution.id, this.state.shard, this.state.nextLine)
           .then(response => {
             response.text().then(text =>  {
               var p = parseResult(text)
               this.setState({ extraText: this.state.extraText + p.text, lines: p.lines, shard: p.shard, nextLine: p.nextLine })})})
        } else if (this.props.execution.mode == 'PUBLISHED_EXECUTION') {
          previewPublishedAnnotatorExecution(this.props.execution.id, this.state.shard, this.state.nextLine)
            .then(response => {
              response.text().then(text =>  {
                var p = parseResult(text)
                this.setState({ extraText: this.state.extraText + p.text, lines: p.lines, shard: p.shard, nextLine: p.nextLine })})})
         }
     } else if (this.props.execution.type == 'EMBEDDER') {
       if (this.props.execution.mode == 'LAST_EXECUTION') {
         previewLastEmbedderExecution(this.props.execution.id, this.state.shard, this.state.nextLine)
           .then(response => {
             response.text().then(text =>  {
               var p = parseResult(text)
               this.setState({ extraText: this.state.extraText + p.text, lines: p.lines, shard: p.shard, nextLine: p.nextLine })})})
        } else if (this.props.execution.mode == 'PUBLISHED_EXECUTION') {
          previewPublishedEmbedderExecution(this.props.execution.id, this.state.shard, this.state.nextLine)
            .then(response => {
              response.text().then(text =>  {
                var p = parseResult(text)
                this.setState({ extraText: this.state.extraText + p.text, lines: p.lines, shard: p.shard, nextLine: p.nextLine })})})
         }
       } else if (this.props.execution.type == 'FILTER_VALIDATION') {
         if (this.props.execution.mode == 'LAST_EXECUTION') {
           previewLastFilterAnnotationValidationExecution(this.props.execution.id, this.state.shard, this.state.nextLine)
             .then(response => {
               response.text().then(text =>  {
                 var p = parseResult(text)
                 this.setState({ extraText: this.state.extraText + p.text, lines: p.lines, shard: p.shard, nextLine: p.nextLine })})})
          } else if (this.props.execution.mode == 'PUBLISHED_EXECUTION') {
            previewPublishedFilterAnnotationValidationExecution(this.props.execution.id, this.state.shard, this.state.nextLine)
              .then(response => {
                response.text().then(text =>  {
                  var p = parseResult(text)
                  this.setState({ extraText: this.state.extraText + p.text, lines: p.lines, shard: p.shard, nextLine: p.nextLine })})})
           }
         } else if (this.props.execution.type == 'PAGED_VALIDATION') {
           if (this.props.execution.mode == 'LAST_EXECUTION') {
             previewLastPagedAnnotationValidationExecution(this.props.execution.id, this.state.shard, this.state.nextLine)
               .then(response => {
                 response.text().then(text =>  {
                   var p = parseResult(text)
                   this.setState({ extraText: this.state.extraText + p.text, lines: p.lines, shard: p.shard, nextLine: p.nextLine })})})
            } else if (this.props.execution.mode == 'PUBLISHED_EXECUTION') {
              previewPublishedPagedAnnotationValidationExecution(this.props.execution.id, this.state.shard, this.state.nextLine)
                .then(response => {
                  response.text().then(text =>  {
                    var p = parseResult(text)
                    this.setState({ extraText: this.state.extraText + p.text, lines: p.lines, shard: p.shard, nextLine: p.nextLine })})})
             }
         }
   }
 }

 componentWillReceiveProps(props) {
   this.setState({ shard: props.execution ? props.execution.shard : null, nextLine: props.execution ? props.execution.nextLine : null})
 }

  render() {
    return (
      <Modal size="xl" show={this.props.show} onHide={this.props.onClose} animation={false} backdrop="static">
        <Modal.Header>
          {/*<Modal.Title>{this.props.state.title}</Modal.Title>*/}
          <Modal.Title>Execution result {this.props.state.loaded && this.props.execution.file && <span className="result-filename">{this.props.execution.file}</span>}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="scrollContainer">
        {this.props.state.loading &&
          <Col className="loader">
            <BarLoader
              css='spinner'
              height={6}
              width={200}
              color='orange'
              loading={true}/>
          </Col>
        }
        {this.props.state.failed &&
          <Col>
            <span className="error">Loading values failed.</span>
          </Col>
        }

        {this.props.state.loaded && this.props.execution && this.props.execution.text &&
          <code className="scroll" onScroll={this.handleScroll}>
            <pre>{this.props.execution.text}
            {this.state.extraText}
            </pre>
          </code>}
        </Modal.Body>
        <Modal.Footer>
          <Button primary="secondary" onClick={this.props.onClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
  )}
}

export default ResultsModal;
