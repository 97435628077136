import React, { Component } from "react";
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import { labelResource, labelResource2 } from "../../../utils/APIUtils.js"
import { Localizer } from '../../../config/localizer.js'
import { filterByLanguage } from "../../../utils/functions.js";
import BarLoader from "react-spinners/BarLoader";

export class ClusterValuesModal extends Component {
  constructor(props) {
    super(props);

    this.state = this.createState(props);
  }


  componentWillReceiveProps(props) {
    var update = false;
    if (this.props.page !== props.page) {
      update = true;
    }
    if (this.props.state === null ||
        (this.props.state !== null && (this.props.state.loaded !== props.state.loaded || this.props.state.loading !== props.state.loading || this.props.state.failed !== props.state.failed))) {
      update = true
    }

    if (update) {
      //this.setState(this.createState(props), ()=>this.resolveLabels());
      this.setState(this.createState(props));
      // console.log("update");
    }
}


  createState(props) {
      var state = {};
      var keys = [];

if (props.values) {
      for (const i in props.values.values) {
        keys.push(i);

        var details = [];
        for (const j in props.values.values[i].details) {
          var el = {...props.values.values[i].details[j]}

          if (el.state === 'ADD') {
            el = {...el, originalValue: el.value, originalValue2: el.value2}
          }
          if (el.state) {
            el = {...el, originalState: el.state}
          }

          details.push(el);
        }


        state['element' + i] = { count: props.values.values[i].count, value: props.values.values[i].onValue, details,  deleted: [], accepted: [] };

        // var values = new Map();
        // var details = [];
        // for (const j in props.value[i].details) {
        //   var el = {...props.value[i].details[j]}
        //
        //   var data = values.get(props.value[i].details[j].value);
        //   if (!data) {
        //     data = de;
        //     values.put(props.value[i].details[j].value, array);
        //   }
        //
        //   if (el.state === 'ADD') {
        //     el = {...el, originalValue: el.value, originalValue2: el.value2}
        //   }
        //   if (el.state) {
        //     el = {...el, originalState: el.state}
        //   }
        //
        //   details.push(el);
        // }
        //
        //
        // state['element' + i] = { value: props.value[i].onValue, details,  deleted: [], accepted: [] };

      }
}

      state.keys = keys;

      // console.log(state);

      return state;
  }

  render() {

    return (
      <Modal size="xl" show={this.props.show} onHide={() => this.props.onClose()} animation={false} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Clusters Preview</Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-0">
        {this.props.state.loading &&
          <Col className="loader">
            <BarLoader
              css='spinner'
              height={6}
              width={200}
              color='crimson'
              loading={true}/>
          </Col>
        }
        {this.props.state.failed &&
          <Col>
            <span className="error">Loading values failed.</span>
          </Col>
        }
        {this.props.state.loaded &&
          <React.Fragment>

            <Row className="py-2 mx-0 border-bottom">
            <Col md="auto"></Col>
              <Col>Clusters: {this.props.values.totalCount}</Col><Col>Clustered items: {this.props.values.distinctSourceTotalCount}</Col>
          </Row>
            <Row className="py-2 mx-0 border-bottom">
              <Col className="d-inline-flex justify-content-center">
                <span className="pl-4 font-weight-bold font-size-3">Items count</span>
              </Col>
              <Col className="d-inline-flex justify-content-start">
                <span className="pl-4 font-weight-bold font-size-3">Items</span>
              </Col>
            </Row>


            <div className="scrollContainer">
              <div className="scroll">
                {this.state.keys.map((el,elindex) =>
                  <Row className="grouping bottomrow"  key={elindex}>
                    <Col md="auto align-items-center pt-2">
                       <span className="sticky-value">{(this.props.page-1)*20 + elindex + 1}</span>
                    </Col>
                    <Col md="auto align-items-center pt-2">
                      <Container className="annotation-uris sticky-value">
                      <Row><span title="Items count" className="annotation-uris-count">{this.state['element' + el].count && this.state['element' + el].count}</span></Row>
                      </Container>
                    </Col>

                    <Col className="pl-1 pr-2">
                      <Container>
                        {this.state['element' + el].details.map((ann,index) =>
                        <Row key={index} className="td-row align-items-center align-self-center "
                              onMouseOver={()=>  document.getElementById('element'+el + '-' + index) ? document.getElementById('element'+el + '-' + index).classList.add('highlight-selected') : null}
                              onMouseOut={()=> document.getElementById('element'+el + '-' + index) ?  document.getElementById('element'+el + '-' + index).classList.remove('highlight-selected') : null}>

                          <Col className="pl-5">
                            <Row>
                            {this.props.endpoint === null &&
                              <a href={ann.value} rel='noreferrer noopener' target="_blank"><span className="td-normal">{ann.value}</span></a>}
                            {this.props.endpoint !== null &&
                              <a href={this.props.database.lodview + 'queryResource?iri=' + encodeURIComponent(ann.value) + '&endpoint=' + this.props.endpoint} rel='noreferrer noopener' target="_blank"><span className="td-normal">{ann.value}</span></a>}
                            </Row>

                            {ann.fields && Object.entries(ann.fields).map(([key, value]) =>
                              <Row className="annotation-info">
                                 <span title="Annotation label" className="td-label annotation-label"><span className="normal-weight">{key}:</span> {value}</span>
                              </Row>
                            )}

                            {ann.score !== null && ann.score !== undefined &&
                              <Row>
                                <span title="Annotation score" className="td-label score">{ann.score.toFixed(3)}</span>
                              </Row>
                            }

                            {ann.annotatorInfo &&
                              <Row><Col md={12} className="ann-annotator">
                                {ann.annotatorInfo.name}
                              </Col></Row>
                            }
                          </Col>
                        </Row>
                      )}
                      </Container>
                    </Col>
                  </Row>
                )}
              </div>
            </div>
          </React.Fragment>
        }
        </Modal.Body>
        <Modal.Footer>
          <Row className="modalFooter">
            <Col />
            <Col className="d-inline-flex justify-content-center">
              <Button
                type="button"
                disabled={!this.props.page || this.props.page === 1}
                variant="outline-light"
                aria-label="Previous"
                className="nextbutton px-5"
                onClick={() => this.props.actions('preview-annotator-clustering', {id: this.props.id, page: this.props.page - 1})}
              >
                <span className="fa fa-angle-double-left"/>
              </Button>
              <Button
                type="button"
                disabled={this.state.keys.length < 20}
                variant="outline-light"
                aria-label="Next"
                className="nextbutton px-5"
                onClick={() => this.props.actions('preview-annotator-clustering', {id: this.props.id, page: this.props.page + 1})}
              >
                <span className="fa fa-angle-double-right"/>
              </Button>
            </Col>
            <Col className="d-inline-flex justify-content-end px-0">
              <Button variant="secondary" onClick={() =>this.props.onClose()}>
                Close
              </Button>
            </Col>
          </Row>
        </Modal.Footer>
      </Modal>
  )}
}

export default ClusterValuesModal;
