import React, { Component } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import ReactTooltip from "react-tooltip";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { filterByLanguage } from "../../../utils/functions.js";
import BootstrapSwitchButton from 'bootstrap-switch-button-react'

export class AddToProjectModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      show: this.props.show,
    }

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(event) {
     event.preventDefault();

    this.props.onOK(this.project.value.substring(1), this.project.value.substring(0,1))
 }

  render() {
    // console.log(this.props.datasets)
    return (
      <Modal show={this.props.show} onHide={this.props.onClose} animation={false} backdrop="static">
        <Form onSubmit={this.handleSubmit}>
          <Modal.Header>
            <Modal.Title>Add to project</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group>
              <Form.Label>Project</Form.Label>

              <Form.Control as="select" ref={node => (this.project = node)}
                    defaultValue=''
                    required>
                  <option disabled hidden value=''></option>
                  <option disabled value='@'> --- My projects --- </option>
                  {this.props.myProjects.map((project, index) =>
                    <option key={index} value={'m' + project.id}>{project.name}</option>
                  )}
                  <option disabled value='@'> --- Public projects --- </option>
                  {this.props.joinedProjects.map((project, index) =>
                    <option key={index} value={'p' + project.id}>{project.name}</option>
                  )}
              </Form.Control>

            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button type="submit" variant="primary">
              OK
            </Button>
            <Button variant="secondary" onClick={this.props.onClose}>
              Cancel
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
  )}
}

export default AddToProjectModal;
