import {useState} from 'react';
import React, { Component } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Multiselect from 'multiselect-react-dropdown';
import Container from "react-bootstrap/Container";
import Collapse from "react-bootstrap/Collapse";

import { throwToast } from '../../../utils/UIUtils';
import { getOtherPublicDatasets, getMyDatasets } from "../../../utils/DatasetAPI"
import { checkIndexStructureIdentifier } from "../../../utils/IndexStructureAPI"
import { renderClasses, applySelection, readClasses, getSchema } from '../../../utils/SchemaUtils';

export class IndexStructureModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      indexStructure: props.indexStructure,
      schemaDatasetId: props.indexStructure ? props.indexStructure.schemaDatasetId : undefined,

      datasets: [],

      show: this.props.show,

      schemaOpen: true,
      checkboxes: {},

      expandOptions: [{name: 'Broader', id: 'SKOS_BROADER'},{name: 'Exact Match', id: 'SKOS_EXACT_MATCH'}]
    }

    this.checkboxes = {}
    this.rdftypes = {}
    this.names = {}
    this.languages = {}
    // this.targets = {}
    this.datatypes = {}
    this.expansions = {}

    this.getDatasets()

    // getSchema(this.onLoad, this, this.props.dataset.id)
    // console.log(props.dataset)

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  existsIndexStructureIdentifier() {
    if (this.identifier.value) {
      checkIndexStructureIdentifier(this.identifier.value, this.props.dataset.id)
      .then(text => {
        if (!text.data.valid || text.data.exists) {
           this.setState( {identifierExists : true })
        } else
            this.setState( {identifierExists : false })
        })
      }
    return false
   }

   componentDidMount() {
     if (this.state.indexStructure) {
       this.schemaDatasetIdChanged(this.state.indexStructure.schemaDatasetId, this.state.indexStructure.schema)
     }
   }

   componentDidUpdate() {
     // console.log("update")
     // console.log(this.props.indexStructure)
     // console.log(this.schemaDatasetId.value)
     // console.log("-")
     if (this.props.indexStructure && this.schemaDatasetId.value == '') {
       // console.log("SET ")
       // console.log(this.props.indexStructure.schemaDatasetId)
        this.schemaDatasetId.value = this.props.indexStructure.schemaDatasetId
        // console.log("GET")
        // console.log(this.schemaDatasetId.value)
     }
   }

  getDatasets() {
    var datasetArrayA = []
    var datasetArrayB = []

    var _this = this;
    var promises = [] ;
    promises.push(
      new Promise(function(resolve, reject) {
        getMyDatasets(['COLLECTION'],'DATASET', _this.props.project ? _this.props.project.id : null)
          .then(response => {

            for (var d of response.data) {
              if (d.publishState.state.startsWith("PUBLISHED")) {
                datasetArrayA.push(d)
              }
            }

            resolve()
          }).catch(error => { resolve() })
        })
    )

      if (this.props.project) {
        promises.push(
          new Promise(function(resolve, reject) {
          getOtherPublicDatasets(['COLLECTION'],'DATASET', _this.props.project.id)
            .then(response => {

              for (var d of response.data) {
                if (d.publishState.state.startsWith("PUBLISHED")) {
                  datasetArrayB.push(d)
                }
              }

              resolve()
            }).catch(error => { resolve() })
          })
        )
      }

      Promise.all(promises).then(() => {
        _this.setState({ datasets: datasetArrayA.concat(datasetArrayB) })
      })
  }

  getDatatypeValues() {
    return [ "boolean", "date", "double", "float", "geo_point", "integer", "keyword", "long", "search_as_you_type", "text" ]
  }

  schemaDatasetIdChanged(datasetId, schema) {
    // console.log("CHANGED")
    getSchema(this.onLoad, this, datasetId, schema)
    this.setState( { schemaDatasetId: datasetId })
  }

  onLoad(_this) {
      if (_this.props.indexStructure) {
          // _this.index.value  = _this.props.index.indexStructureId;
          _this.indexChanged();
     }
  }

  handleSubmit(event) {
    event.preventDefault();

    // if (this.index.value == '') {
    //   console.log(this.readClasses('', [], 0))
    // }

    // if (this.index.value == '' && (!this.identifier.value || this.identifier.value.length == 0)) {
    //   return;
    // }

    // if (this.index.value != '') {
    //   this.props.onOK(this.index.value, null, this.indexEngine.value);
    // } else {

      // console.log(this.state)
      // var res = this.readClasses('', [], { value: 0 } )
      var res = readClasses('', [], { value: 0 }, this )

      // if (this.props.indexStructure && this.props.indexStructure.id) {
      //   this.props.onUpdate(this.props.indexStructure.id,
      //                       this.name.value,
      //                       this.identifier.value,
      //                       this.description.value,
      //                       this.schemaDatasetId.value,
      //                       this.state.classStructure
      //                       res ? res.elements : null,
      //                       res ? res.metadata : null
      //                     )
      // } else {
        this.props.onOK(this.props.indexStructure ? this.props.indexStructure.id : null,
                        this.name.value,
                        this.identifier.value,
                        this.description.value,
                        this.schemaDatasetId.value,
                        this.state.classStructure,
                        res ? res.elements : null,
                        res ? res.metadata : null
                      )
      // }
      // this.props.onOK(null, this.identifier.value, this.indexEngine.value, res.elements, res.metadata);
    // }
  }

  nameChanged() {

  }

  indexChanged() {
    // this.identifier.value = ''
    // this.identifier.disabled = true

    // var selectedIndex = this.props.indices.filter(el => el.id === this.index.value)[0]

    var checkboxes = {};
    for (var ch in this.state.checkboxes) {
      if (ch.match('^check-c[0-9]+$')) {
        checkboxes[ch] = this.state.checkboxes[ch]
        this.checkboxes[ch].checked = false
      // } else {
      //   delete this.checkboxes[ch]
      }
    }

    // this.setState({ selectedIndex, checkboxes }, () => {
    this.setState({ checkboxes }, () => {
       // this.applySelection()
       applySelection(this, this.props.indexStructure)
     } )
  }

  enableNewIndex() {
    // this.index.value = '';
    // this.identifier.disabled = false
  }


  render() {

    // console.log("RENDER")
    // console.log(this.state)
    // console.log(this.checkboxes)
    return (
      <Modal show={this.props.show} onHide={this.props.onClose} animation={false} backdrop="static" size="xl" >
        <Form onSubmit={this.handleSubmit}>
          <Modal.Header>
            <Modal.Title>Index Definition</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group>
              <Form.Label className="required">Name</Form.Label>
              <Form.Control ref={node => (this.name = node)}
                   onChange={() => this.setState({ error: false })}
                   pattern="\S.*"
                   defaultValue={this.props.indexStructure ? this.props.indexStructure.name : ""}
                   required/>
            </Form.Group>

            <Form.Group>
              <Form.Label className="required">Identifier</Form.Label>
              <Form.Control ref={node => (this.identifier = node)}
                   onChange={() => this.existsIndexStructureIdentifier()}
                   isInvalid={this.identifier && this.identifier.value && ((!this.props.mapping && this.state.identifierExists) || (this.props.mapping && this.state.identifierExists && this.props.mapping.identifier !== this.identifier.value))}
                   defaultValue={this.props.indexStructure ? this.props.indexStructure.identifier : ""}
                   required/>
              <Form.Control.Feedback type="invalid">
                   Identifier already exists or is invalid.
             </Form.Control.Feedback>
            </Form.Group>

            <Form.Group>
              <Form.Label>Description</Form.Label>
              <Form.Control as="textarea" ref={node => (this.description = node)}
                   defaultValue={this.props.indexStructure ? this.props.indexStructure.description : ""}/>
            </Form.Group>

            <Form.Group>
              <Form.Label className="required">Schema dataset</Form.Label>
              <Form.Control as="select" ref={node => (this.schemaDatasetId = node)}
                // defaultValue={this.props.indexStructure ? this.props.indexStructure.schemaDatasetId : '' }
                // defaultValue=''
                defaultValue={this.state.schemaDatasetId}
                onChange={(event) => this.schemaDatasetIdChanged(event.target.value)}
                required>
                <option hidden disabled value=''> -- Select a base dataset -- </option>
                {this.state.datasets.map((el, index) =>
                  <option key={index} value={el.id}>{el.name}</option>
                )}
              </Form.Control>
            </Form.Group>


            <Container className={this.state.checkboxes.length > 0 ? "groupborder" : "groupborder-empty"}>
              <Row className={this.state.checkboxes.length > 0 ? "header" : "header-empty"}>

                <Col className="bold">
                  <span className="crimson-std">Properties</span>
                </Col>

                {/*toggleBoxColumn('schemaOpen', this, 'checkboxes')*/}
                <Col className="mybutton" md="auto">
                  <Button type="button" className="menubutton"  aria-label="Toggle" onClick={() => this.setState({ schemaOpen: !this.state.schemaOpen})}><span className={this.state.checkboxes ? 'fa fa-angle-double-up' : 'fa fa-angle-double-down'}></span></Button>
                </Col>

                <Col className="mybutton" md={1}>
                </Col>
              </Row>

              <Collapse in={this.state.schemaOpen}>
                <div>
                <Row className="index-field-row">
                  <Col md="5" >
                    Path
                  </Col>
                  <Col md="6">
                  <Row>
                    <Col md="3" className="index-column">
                      Name
                    </Col>
                    <Col md="2" className="index-column">
                      Type
                    </Col>
                    <Col md="2" className="index-column">
                      Datatype
                    </Col>
                    <Col md="2" className="index-column">
                      Languages
                    </Col>
                    <Col md="2" className="index-column">
                      Expansion
                    </Col>
                    <Col md="1" className="index-column">

                    </Col>
                    </Row>
                  </Col>
                </Row>
                {renderClasses(Object.keys(this.state.checkboxes).filter(k => k.match('^check-c[0-9]+$')), 0, this)}
                </div>
              </Collapse>
            </Container>

          </Modal.Body>

          <Modal.Footer>
            <Button type="submit" variant="primary" id="submit-btn" className="mr-2">
              {this.props.indexStructure && this.props.indexStructure.id ? 'Update' : 'Create'}
            </Button>
            <Button variant="secondary" onClick={this.props.onClose}>
              Cancel
            </Button>
          </Modal.Footer>

        </Form>
      </Modal>
  )}
}


export default IndexStructureModal;
