import React, { Component } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ClipLoader from "react-spinners/ClipLoader";
import DropdownButton from "react-bootstrap/DropdownButton";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";


import { TIME_OPTIONS } from "../../constants/index.js"

export class ValidatableState extends Component {

  renderMapName(name) {
    return name.substring(Math.max(name.lastIndexOf("/"), name.lastIndexOf("#")) + 1);
  }

  render() {
    // console.log(this.props.value)

    var time = null
    var cz
    var ct
    var message = null;
    if (this.props.value.messages && this.props.value.messages.length > 0) {
        message = this.props.value.messages[0].message
    }

    if (this.props.value.state === 'NOT_VALIDATED') {
      cz = 'not-executed'
      ct = 'Not validated'
    } else if (this.props.value.state === 'VALIDATING') {
      cz = 'executing'
      ct = 'Validating'
      time = '(since ' + new Date(this.props.value.startedAt).toLocaleDateString('en-UK', TIME_OPTIONS) + ')';
    } else if (this.props.value.state === 'VALIDATED') {
      cz = 'executed'
      ct = 'Validated'
      time = '(at ' + new Date(this.props.value.completedAt).toLocaleDateString('en-UK', TIME_OPTIONS) + ')'
    } else if (this.props.value.state === 'VALIDATING_FAILED') {
      cz = 'failed'
      ct = 'Validation failed'
      time = '(at ' + new Date(this.props.value.completedAt).toLocaleDateString('en-UK', TIME_OPTIONS) + ')';
    } else {
      cz = 'not-executed'
      ct = 'Not validated'
    }

    return (
      <div>
        <Row className={cz}>

          <Col md="12">
            {ct} {this.props.value.state === 'VALIDATED' && <span>({this.props.value.conforms ? <span className="executed">conforms</span> : <span className="failed">does not conform</span>})</span>}
          </Col>
        </Row>
        {(time || (this.props.value.messages && this.props.value.messages.length > 0)) &&
        <div className="executeinfo">
          {this.props.value.messages && this.props.value.messages.map((msg, run) =>
          <Row key={run}>
            <Col md="12">
              {msg.type === 'ERROR' ? <span className='times crimson-std'>{msg.message}</span> : <span className='times blueviolet-std'>{msg.message}</span>}
            </Col>
          </Row>)}
          {time &&
          <Row>
            <Col md="12">
              <span className='times'>{time}</span>
              {/*this.props.value.elasticConfiguration !== null && this.props.value.elasticConfiguration !== undefined &&
              <span className='times pl-2'>@{this.props.value.elasticConfiguration}</span>*/}
            </Col>
          </Row>}
        </div>}
      </div>)
  }
}

export default ValidatableState;
