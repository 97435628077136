import { API_BASE_URL } from '../../../constants/index.js';
import React, { Component } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import { convert, adjustSchemaMapping, adjustContentMapping, d2rml, d2rmlop } from '../../../utils/D2RMLUtils';
import { getDatassets } from '../../../utils/DatasetAPI';
import { getPrototypes } from '../../../utils/PrototypeAPI';
import { validateD2RML } from '../../../utils/D2RMLAPI';
import { checkMappingIdentifier } from "../../../utils/MappingsAPI.js";

export class NewMappingUploadModal extends Component {
  constructor(props) {
    super(props);

    var input
    if (props.mapping && props.mapping.templateId) {
      input = 'D2RML_TEMPLATE'
    } else if (props.mapping && props.mapping.d2rmlId) {
      input = 'D2RML_SCHEMA'
    } else if (props.mapping && props.mapping.fileName) {
      input = 'D2RML_FILE'
    } else {
      input = ''
    }


    this.state = {
      error: false,
      invalidFile: false,
      invalidFileMessage: null,
      identifierExists: false,

      file: null,
      // templateId: null,
      // shaclId: null,
      // d2rmlId: null,
      templateId: props.mapping && props.mapping.templateId ? props.mapping.templateId : null,
      shaclId: props.mapping && props.mapping.shaclId ? props.mapping.shaclId[0] : null,
      d2rmlId: props.mapping && props.mapping.d2rmlId ? props.mapping.d2rmlId : null,

      parameters: null,
      dependecies: null,

      input: input

    }

    // this.initialize(props);

    this.handleSubmit = this.handleSubmit.bind(this);

  }

  existsMappingIdentifier() {
    if (this.identifier.value) {
      checkMappingIdentifier(this.identifier.value, this.props.dataset.id)
      .then(response => {
        if (!response.data.valid || response.data.exists) {
           this.setState( {identifierExists : true })
        } else
            this.setState( {identifierExists : false })
        })
      }
   }

   formIsValid() {
     var ok = !(this.identifier && this.identifier.value && ((!this.props.dataset && this.state.identifierExists) || (this.props.dataset && this.state.identifierExists && this.props.dataset.identifier !== this.identifier.value)));

     return ok;
   }


  componentDidMount() {
    this.initialize(this.props)
  }

  initialize(props) {
    var shaclShapes;
    var d2rmls;

    var promises = [] ;
    promises.push(
      new Promise(function(resolve, reject) {
        getPrototypes(null,'SHACL')
          .then(response => {
            if (response.data) {
              shaclShapes = response.data;
              resolve()
            }
          })
          .catch(error => { resolve() })
        })
      )

    promises.push(
      new Promise(function(resolve, reject) {
        getPrototypes(null,'D2RML')
          .then(response => {
            if (response.data) {
              // this.setState({ d2rmls: response.data }, () => resolve());
              d2rmls = response.data;
              resolve()
            }
          })
          .catch(error => { resolve() })
        })
    )

    Promise.all(promises).then(() => {
      var p = [];
      var input;
      if (props.templates && props.mapping && props.mapping.templateId) {
        var template = props.templates.find(el => el.id == props.mapping.templateId)
        if (template.parameters) {
          p = template.parameters.map(e => e.name)
        }
      }

      if (d2rmls && props.mapping && props.mapping.d2rmlId) {
        var d2rml = d2rmls.find(el => el.id == props.mapping.d2rmlId)
        if (d2rml.parameters) {
          p = d2rml.parameters.map(e => e.name)
        }
      }

      this.setState({
        templateId: props.mapping && props.mapping.templateId ? props.mapping.templateId : null,
        shaclId: props.mapping && props.mapping.shaclId ? props.mapping.shaclId[0] : null,
        d2rmlId: props.mapping && props.mapping.d2rmlId ? props.mapping.d2rmlId : null,

        parameters: p,
        d2rmls: d2rmls,
        shaclShapes: shaclShapes,
      },)
    })
  }

  handleSubmit(event) {
    event.preventDefault();

    if (!this.state.invalidFile && this.formIsValid()) {
      this.props.onOK(
        this.props.mapping ? this.props.mapping.id : null,
        this.name.value,
        this.identifier.value,
        this.description.value,
        this.state.input == 'D2RML_FILE' ? this.state.file : ( this.state.input == 'D2RML_BLANK' ? new File([""], "") : null ) ,
        this.state.input == 'D2RML_TEMPLATE' && this.template && this.template.value.length > 0 ? this.template.value : null,
        this.state.input == 'D2RML_SCHEMA' && this.d2rml && this.d2rml.value.length > 0 ? this.d2rml.value : null,
        this.state.parameters,
        this.state.dependencies,
        this.shacl && this.shacl.value.length > 0 ? this.shacl.value : null,
        this.active.checked,
        this.d2rmlIdBound ? this.d2rmlIdBound.checked : false)
    }
  }

  // addParameter() {
  //   var parameters = this.state.parameters.slice();
  //   parameters.push('')
  //   this.setState({parameters})
  // }

  // parameterChange(event, index) {
  //   var parameters = this.state.parameters.slice(0,index).concat([event.target.value]).concat(this.state.parameters.slice(index+1))
  //   this.setState({parameters})
  // }

  fileChange(event) {
      var files = event.target.files; // FileList object
      this.setState({file : event.target.files[0]});

      var _this = this;
      var reader = new FileReader();
      reader.onload = function(event) {
        var ttl = event.target.result;

        validateD2RML(ttl)
          .then(response => {
             _this.setState({ parameters: response.data.parameters ? response.data.parameters : null, dependencies: response.data.dependencies ? response.data.dependencies : null, invalidFile: false, invalidFileMessage: null, templateId: null, d2rmlId: null })
           })
          .catch(error => {
             _this.setState({ invalidFile: true, invalidFileMessage: error.message })
          })
      }

    reader.readAsText(files[0]);

    // if (this.template) {
    //   this.template.value = '';
    // }
  }

  templateChanged() {
    var p = [];

    if (this.template.value && this.props.templates) {
      var template = this.props.templates.find(el => el.id == this.template.value)
      if (template.parameters) {
        p = template.parameters.map(e => e.name)
      }
    }

    this.setState({ file: null, invalidFile: false, invalidFileMessage:null, parameters: p, templateId: this.template.value == '' ? null : this.template.value, d2rmlId: null })
    if (this.fileInput) {
      this.fileInput.value = "";
    }
  }

  d2rmlChanged() {
    var p = [];

    if (this.d2rml.value && this.state.d2rmls) {
      var d2rml = this.state.d2rmls.find(el => el.id == this.d2rml.value)
      if (d2rml.parameters) {
        p = d2rml.parameters.map(e => e.name)
      }
    }

    this.setState({ file: null, invalidFile: false, invalidFileMessage:null, parameters: p, d2rmlId: this.d2rml.value == '' ? null : this.d2rml.value, templateId: null })
    if (this.fileInput) {
      this.fileInput.value = "";
    }
  }

  inputChanged() {
    this.setState( { input: this.input.value} );
  }

  render() {

    return (
      <Modal show={this.props.show} onHide={this.props.onClose} animation={false} backdrop="static">
        <Form onSubmit={this.handleSubmit}>
          <Modal.Header>
            <Modal.Title>{this.props.mapping ? 'Edit' : 'New'} {this.props.type === 'CONTENT'? 'data mapping' : (this.props.type === 'HEADER' ? 'metadata mapping' : 'Prefix File')}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group>
              <Form.Label className="required">Name</Form.Label>
              <Form.Control placeholder="Name" ref={node => (this.name = node)}
                   onChange={() => this.setState({ error: false })}
                   pattern="\S.*"
                   defaultValue={this.props.mapping ? this.props.mapping.name : ""}
                   required/>
            </Form.Group>
            <Form.Control.Feedback type="invalid">
              Please provide a valid name.
            </Form.Control.Feedback>

            <Form.Group>
              <Form.Label>Identifier</Form.Label>
              <Form.Control ref={node => (this.identifier = node)}
                   onChange={() => this.existsMappingIdentifier()}
                   isInvalid={this.identifier && this.identifier.value && ((!this.props.mapping && this.state.identifierExists) || (this.props.mapping && this.state.identifierExists && this.props.mapping.identifier !== this.identifier.value))}
                   defaultValue={this.props.mapping ? this.props.mapping.identifier : ""}/>
              <Form.Control.Feedback type="invalid">
                   Identifier already exists or is invalid.
             </Form.Control.Feedback>
            </Form.Group>

            <Form.Group>
              <Form.Label>Description</Form.Label>
              <Form.Control as="textarea" ref={node => (this.description = node)}
                   defaultValue={this.props.mapping ? this.props.mapping.description : ""}/>
            </Form.Group>

            <Form.Group>
              <Form.Label className="required">Input type</Form.Label>
              <Form.Control as="select" ref={node => (this.input = node)}
                defaultValue={this.state.input}
                //disabled={this.props.mapping}
                onChange={() => this.inputChanged()}>
                  <option key='0' value='' disabled> -- Select D2RML input -- </option>
                  <option key='1' value='D2RML_FILE'>Upload D2RML document</option>
                  {this.props.templates && this.props.templates.length > 0 &&
                    <option key='2' value='D2RML_TEMPLATE'>D2RML template</option>
                  }
                  {this.state.d2rmls && this.state.d2rmls.length > 0 &&
                    <option key='3' value='D2RML_SCHEMA'>D2RML schema</option>
                  }
                  <option key='4' value='D2RML_BLANK'>Blank D2RML document</option>
              </Form.Control>
          </Form.Group>

            {this.state.input === 'D2RML_TEMPLATE' && this.props.templates && this.props.templates.length > 0 &&
              <Form.Group>
                <Form.Label>D2RML template</Form.Label>
                <Form.Control as="select" ref={node => (this.template = node)}
                  defaultValue={this.state.templateId ? this.state.templateId : ''}
                  //disabled={this.props.mapping}
                  onChange={() => this.templateChanged()}>
                  {/*<option key='0' value=''>(Upload D2RML document)</option>*/}
                  <option key='0' value='' disabled> -- Select D2RML template -- </option>
                  {this.props.templates.map((el, index) =>
                    <option key={index} value={el.id}>{el.name}</option>
                  )}
                </Form.Control>
            </Form.Group>}

            {this.state.input === 'D2RML_SCHEMA' &&  this.state.d2rmls && this.state.d2rmls.length > 0 &&
              <Form.Group>
                <Form.Label>D2RML schema</Form.Label>
                <Form.Control as="select" ref={node => (this.d2rml = node)}
                  defaultValue={this.state.d2rmlId ? this.state.d2rmlId : ''}
                  //disabled={this.props.mapping}
                  onChange={() => this.d2rmlChanged()}>
                  <option key='0' value='' disabled> -- Select D2RML schema -- </option>
                  {this.state.d2rmls.map((el, index) =>
                    <option key={index} value={el.id}>{el.name}</option>
                  )}
                </Form.Control>
            </Form.Group>}

            {((this.state.input === 'D2RML_SCHEMA' &&  this.state.d2rmlId) || (this.state.input === 'D2RML_TEMPLATE' && this.state.templateId)) &&
            <Form.Group>
              <Form.Check label={"Bind to " + (this.state.input === 'D2RML_SCHEMA' ? "schema" : "template")} ref={node => (this.d2rmlIdBound = node)}
                defaultChecked={this.props.mapping ? this.props.mapping.d2rmlIdBound : false}/>
            </Form.Group>
            }

            {this.state.input === 'D2RML_FILE' &&  !this.state.templateId &&
            <Form.Group>
              <Form.Label>D2RML document</Form.Label>
              <Form.Control type="file" ref={node => (this.fileInput = node)}
                            onChange={(event) => this.fileChange(event)}
                            isInvalid={this.state.invalidFile}/>
              <Form.Control.Feedback type="invalid">
                {this.state.invalidFileMessage}
              </Form.Control.Feedback>
            </Form.Group>}

            {this.props.type !== 'PREFIX' && this.state.parameters && this.state.parameters.length > 0 &&
              <Form.Group className={"mt-4 " + (this.state.parameters && this.state.parameters.length > 0 ? "groupborder" : "groupborder-empty")}>
                <Row className={"m-0 " + (this.state.parameters && this.state.parameters.length > 0 ? "header" : "header-empty")}>
                  <Col>Parameters</Col>
                  {/*<Col md="auto">
                    <Button type="button" className="menubutton" onClick={()=> this.addParameter()}><span className='fa fa-plus'></span></Button>}
                  </Col>*/}
                </Row>
                {this.state.parameters.map((el,index) =>
                  <Row className="grouping" key={index}>
                    <Col>
                      {/*<Form.Control value={el} disabled={true} onChange={(event) => this.parameterChange(event, index)}/>*/}
                      <Form.Control value={el} disabled={true}/>
                    </Col>
                  </Row>)}
              </Form.Group>}

              {this.state.shaclShapes && this.state.shaclShapes.length > 0 &&
                <Form.Group>
                  <Form.Label>SHACL Shape</Form.Label>
                  <Form.Control as="select" ref={node => (this.shacl = node)}
                    defaultValue={this.state.shaclId ? this.state.shaclId : null}
                    //disabled={this.props.mapping}
                    //onChange={() => this.templateChanged()}
                    >
                    <option key='0' value=''>(Select shape)</option>
                    {this.state.shaclShapes.map((el, index) =>
                      <option key={index + 1} value={el.id}>{el.name}</option>
                    )}
                  </Form.Control>
              </Form.Group>}

              <Form.Group>
                <Form.Check label="Active" ref={node => (this.active = node)}
                  defaultChecked={this.props.mapping ? this.props.mapping.active : true}/>
              </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button type="submit" variant="primary">
              OK
            </Button>
            <Button variant="secondary" onClick={() => {this.setState({error: false, invalidFile: false, invalidFileMessage: null, parameters: []}); this.props.onClose()}}>
              Cancel
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
  )}
}

export default NewMappingUploadModal;
