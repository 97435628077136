import React, { Component } from "react";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import Button from "react-bootstrap/Button";
import Collapse from "react-bootstrap/Collapse";
import { resolveJsonLdUri, objectToArray } from '../../utils/functions';
import SchemaProperty from "./SchemaProperty.js";
import EmbedderModal from "./modals/EmbedderModal.js";
import AnnotatorModal from "./modals/AnnotatorModal.js";
import ExecutableState from "./ExecutableState.js";
import PublishableState from "./PublishableState.js";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLock} from '@fortawesome/free-solid-svg-icons'
import Form from "react-bootstrap/Form";
import LifecycleState from "./LifecycleState.js";

import { actionsMenu, toggleBoxColumn, sortByField, pathEquals } from '../../utils/UIUtils';

import { Localizer } from '../../config/localizer.js'
import { filterByLanguage, qname } from "../../utils/functions.js";
import BarLoader from "react-spinners/BarLoader";
import { API_BASE_URL  } from '../../constants/index.js';

export class SchemaClass extends Component {
  constructor(props) {
    super(props);

    this.state = {
      embedderModalOpen: false,
      embedderToEdit: null,
      embedderToEditMode: false,

      // userAnnotators: [],
    };
  }

  // execAction(action, params) {
  //   if (action == 'show-annotator-modal') {
  //     getPrototypes(null,'ANNOTATOR')
  //        .then(response => {
  //          if (response) {
  //            this.setState({ userAnnotators: response.data, annotatorModalOpen: false, annotatorToEditClass: params.annotatorToEditClass } )
  //          }
  //     })
  //   }
  // }


  showAnnotator(el) {
    var r = this.props.dataAnnotators.filter((ann) => ann.identifier === el.annotator)
    if (r.length > 0) {
      return r[0].title
    } else {
      return el.annotatorName
      // var r = this.props.userAnnotators.filter((ann) => ann.id === el.annotatorId)
      // if (r.length > 0) {
      //   return r[0].name
      // }
    }
  }

  render() {

    // let context = this.props.schema['@context'];
    var context;

    // var fullView = this.props.mode === 'EDITOR';
    var fullView = true;
    var el = this.props.value;

    var propertyOrClass = el.hasOwnProperty('property') ? resolveJsonLdUri(el['property'],context) : resolveJsonLdUri(el['class'], context);
    var embedders = this.props.embedders.filter(el => pathEquals(el.onClass, propertyOrClass));

    var annotators = this.props.annotators.filter(el => el.onClass && pathEquals(el.onClass, propertyOrClass));

    const annotationEditGroups = this.props.annotationEditGroups.filter(el => el.onClass && pathEquals(el.onClass, propertyOrClass)).filter(el => el.published);
    // for (const i in annotationEditGroups) {
    //   if (annotationEditGroups[i].pagedAnnotationValidations && annotationEditGroups[i].pagedAnnotationValidations.length > 0 && annotationEditGroups[i].pagedAnnotationValidations[0].lifecycleState.state === 'STARTED') {
    //     pagedValidationOpen = true;
    //   }
    // }

    var propertyPartition;
    if (el.hasOwnProperty('propertyPartition')) {
      propertyPartition = objectToArray(el['propertyPartition']);
    }

    var classPartition;
    if (el.hasOwnProperty('classPartition')) {
      classPartition = objectToArray(el['classPartition']);
    }

    return (
      <Row className="schema-content">
        <Col>
          <Container className={"grouping groupborder" + (((propertyPartition && propertyPartition.length > 0) || (classPartition && classPartition.length > 0))  ? "": "-empty-dataset")}>
            <Row  className={"red header" + (((propertyPartition && propertyPartition.length > 0) || (classPartition && classPartition.length > 0))  ? "": "-empty")}>
              <Col>
                <Container>
                  <Row>
                    <span title="Class">{propertyOrClass}</span>
                  </Row>

                  {el['http://www.w3.org/2000/01/rdf-schema#label'] &&
                  <Row>
                      <span className="thin label">{filterByLanguage(el,'http://www.w3.org/2000/01/rdf-schema#label', this.props.language)}</span>
                  </Row>}
                  {el.uriSpace &&
                  <Row>
                      <span className="prefix"><span className="prefix black">Items' prefix:</span> {el.uriSpace}</span>
                  </Row>}
                </Container>
              </Col>

              {toggleBoxColumn('propertiesOpen', this, null, null, propertyPartition)}

              <Col className="thin" md="auto">
                <span title="Items count">{el['entities']}</span>
              </Col>


              {//this.props.mode === 'EDITOR' &&
                el.hasOwnProperty('class') &&
              <Col className="mybutton" md="auto">
                {actionsMenu(
                <div>
                  <Dropdown.Item className="py-2" onClick={() => this.props.actions('show-values', { path:[{type:"CLASS", uri: resolveJsonLdUri(el['class'], context)}], mode:"ALL", page: 1, lodview:true })}>
                    <span className="menu-icon fa fa-eye fa-lg mr-3"></span>Browse items...
                  </Dropdown.Item>

                  <Dropdown.Divider/>

                  <Dropdown.Item className="py-2"
                        onClick={() => this.setState({ annotatorModalOpen: true, annotatorToEditClass: propertyOrClass } )}
                        // onClick={() => this.execAction('show-annotator-modal', { annotatorToEditClass: propertyOrClass } )}
                        disabled={(!this.props.dataAnnotators && !this.props.userAnnotators)}>
                    <span className="menu-icon fa fa-plus fa-lg mr-3"></span>Add annotator...
                  </Dropdown.Item>

                  <Dropdown.Item className="py-2"
                        onClick={() => this.setState({ embedderModalOpen: true, embedderToEditClass: propertyOrClass } )}
                        disabled={ !this.props.dataEmbedders}>
                    <span className="menu-icon fa fa-plus fa-lg mr-3"></span>Add embedder...
                  </Dropdown.Item>

                </div>)}
              </Col>}
            </Row>
            {el['http://www.w3.org/2000/01/rdf-schema#label'] &&
            <Row>
              <Col>
                <span className="label">{filterByLanguage(el,'http://www.w3.org/2000/01/rdf-schema#label', this.props.language)}</span>
              </Col>
            </Row>}

            {annotators.length > 0 &&
            <Container className="groupborder-empty">
              {fullView &&
              <Row className="header-empty">
                <Col>
                  <span>Annotators</span>
                </Col>

                {toggleBoxColumn('annotatorsOpen', this, null, null, annotators)}

              </Row>}

              {fullView &&
              <Collapse in={this.state.annotatorsOpen}>
                <Row className="annotatorRowGroup">
                  <Col className="annotatorRowGroupColumn">
                    {annotators.map((el, index) =>

                      <Row key={index}>
                        <Col>
                          <Container key={index} className={"annotatorInstanceRow" + (el.ownedByUser ? "" : " notOwned")}>
                            <Row className="mappingInstanceRowData">

                              {/* <Col md="auto px-0">
                                <Form.Check value={el.selected} onClick={() => this.props.actions('check-annotator', { id: el.id })} />
                              </Col> */}
                              <Col md="5" className="text-break">
                                {el.name &&
                                <Row>
                                  <Col>
                                    <span className="mapping-name">{el.name}</span>
                                  </Col>
                                </Row>}
                                <Row>
                                  <Col><FontAwesomeIcon className="fa mr-2" icon={faLock}/><span className="crimson-std text-bold">{this.showAnnotator(el)}</span></Col>
                                </Row>
                                <Row>
                                  <Col>
                                    <span className="parameter">UUID: </span><span className="parametervalue">{el.uuid}</span>
                                  </Col>
                                </Row>
                                {el.identifier &&
                                <Row>
                                  <Col>
                                    <span className="parameter">Identifier: </span><span className="parametervalue">{el.identifier}</span>
                                  </Col>
                                </Row>}
                                {el.tags &&
                                <Row>
                                  <Col>
                                    <span className="parameter">Tags: </span><span className="parametervalue">{el.tags.join(", ")}</span>
                                  </Col>
                                </Row>}
                                {el.parameters && el.parameters.length > 0 &&
                                  <div>
                                    <Row className="paramheader">
                                      <Col><span className="paramheadertext">Parameters</span></Col>
                                    </Row>
                                  </div>}
                                {el.parameters && el.parameters.map((el2, index2) =>
                                  <Row key={index2}>
                                    <Col><span className="parameter">{el2.name}: </span><span className="parametervalue">{el2.value}</span></Col>
                                  </Row>
                                )}
                                {el.preprocess && el.preprocess.length > 0 &&
                                  <div>
                                    <Row className="paramheader">
                                      <Col><span className="paramheadertext"> Preprocess</span></Col>
                                    </Row>
                                  </div>}
                                {el.preprocess && el.preprocess.map((el1, index1) =>
                                  <Row key={"function-" + index1}>
                                    <Col md="auto">{index1 + 1}</Col>
                                    <Col>
                                      <Row key={index1}>
                                        <Col><span className="parameter">function: </span><span className="parametervalue">{qname(el1.function)}</span></Col>
                                      </Row>
                                      {el1.parameters.map((el2, index2) =>
                                        <Row key={index2}>
                                          <Col><span className="parameter">{el2.name}: </span><span className="parametervalue">{el2.value}</span></Col>
                                        </Row>
                                      )}
                                      {el1.modifier &&
                                        <Row>
                                          <Col><span className="parameter">modifier: </span><span className="parametervalue">{qname(el1.modifier)}</span></Col>
                                        </Row>
                                      }
                                    </Col>
                                  </Row>)}
                              </Col>

                              <Col md={6} className="tablecenter">
                                <Row className="stategroup">
                                  <Col>
                                    <Row>
                                      <Col>
                                        <ExecutableState value={el.executeState}/>
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col>
                                        <PublishableState value={el.publishState}/>
                                      </Col>
                                    </Row>
                                  </Col>
                                </Row>
                              </Col>

                              <Col md="1" className="">
                                <DropdownButton size="sm" title={<span title="Actions" className='fa fa-bars'></span>} className="actions">
                                    <Dropdown.Item className="py-2"
                                                   disabled={!el.ownedByUser}
                                                   onClick={() => this.setState({ annotatorModalOpen: true, annotatorToEditMode: true, annotatorToEdit: el, annotatorToEditClass: propertyOrClass })}>
                                      <span className="menu-icon fa fa-edit fa-lg mr-3"></span>Edit
                                    </Dropdown.Item>

                                  <Dropdown.Item className="py-2"
                                                 disabled={!el.ownedByUser || el.publishState.state !== "UNPUBLISHED"}
                                                 onClick={() => this.props.actions('delete-annotator', { id: el.id })}>
                                    <span className="menu-icon fa fa-trash fa-lg mr-3"></span>Delete
                                  </Dropdown.Item>

                                  <Dropdown.Divider/>

                                  <Dropdown.Item className="py-2"
                                                 disabled={!el.ownedByUser || el.executeState.state === "EXECUTING"}
                                                 onClick={() => this.props.actions('execute-annotator', { id: el.id })}>
                                    <span className="menu-icon fa fa-play-circle fa-lg mr-3"></span>Execute
                                  </Dropdown.Item>

                                  <Dropdown.Item className="py-2"
                                                 disabled={!el.ownedByUser || el.executeState.state !== "EXECUTING"}
                                                 onClick={() => this.props.actions('stop-annotator', { id: el.id })}>
                                    <span className="menu-icon fa fa-stop fa-lg mr-3"></span>Stop Execution
                                  </Dropdown.Item>

                                  <Dropdown.Divider/>

                                  <Dropdown.Item className="py-2"
                                                 disabled={el.executeState.state !== 'EXECUTED'}
                                                 onClick={() => this.props.actions('preview-annotator-execution', { id: el.id, page: 1 })}>
                                    <span className="menu-icon fa fa-list-alt fa-lg mr-3"></span>Preview annotations
                                  </Dropdown.Item>

                                  <Dropdown.Item className="py-2"
                                                 disabled={el.executeState.state !== 'EXECUTED'}
                                                 onClick={() => this.props.actions('preview-annotator-clustering', { id: el.id, page: 1, lodview: true })}>
                                    <span className="menu-icon fa fa-list-alt fa-lg mr-3"></span>Preview clustering
                                  </Dropdown.Item>

                                  <Dropdown.Divider/>

                                  <Dropdown.Item className="py-2"
                                                 disabled={!el.ownedByUser || el.executeState.state !== 'EXECUTED' || el.publishState.state == 'PUBLISHED'}
                                                 onClick={() => this.props.actions('publish-annotator', { id: el.id })}>
                                    <span className="menu-icon fa fa-calendar-check-o fa-lg mr-3"></span>Publish
                                  </Dropdown.Item>

                                  <Dropdown.Item className="py-2"
                                                 disabled={!el.ownedByUser}
                                                 onClick={() => this.props.actions('unpublish-annotator', { id: el.id })}>
                                    <span className="menu-icon fa fa-calendar-times-o fa-lg mr-3"></span>Unpublish
                                  </Dropdown.Item>

                                  <Dropdown.Item className="py-2"
                                      disabled={!el.ownedByUser}
                                      onClick={() => this.props.actions('republish-annotator', { id: el.id })}>
                                      <span className="menu-icon fa fa-calendar fa-lg mr-3"></span>Republish
                                  </Dropdown.Item>

                                  <Dropdown.Divider />

                                  <Dropdown.Item className="py-2"
                                                 disabled={el.executeState.state !== 'EXECUTED' || (el.publishedFromCurrentFileSystem && el.publishState.state === 'PUBLISHED' && !el.newExecution)}
                                                 onClick={() => this.props.actions('preview-last-annotator-execution', { id: el.id })}>
                                    <span className="menu-icon fa fa-eye fa-lg mr-3"></span>Preview last execution
                                  </Dropdown.Item>

                                  <Dropdown.Item className="py-2"
                                                 disabled={el.executeState.state !== 'EXECUTED' || (el.publishedFromCurrentFileSystem && el.publishState.state === 'PUBLISHED' && !el.newExecution)}
                                                 onClick={() => this.props.actions('download-last-annotator-execution', { id: el.id })}>
                                    <span className="menu-icon fa fa-download fa-lg mr-3"></span>Download last execution
                                  </Dropdown.Item>

                                  <Dropdown.Item className="py-2"
                                                 disabled={!el.ownedByUser || el.executeState.state !== 'EXECUTED' || (el.publishedFromCurrentFileSystem && el.publishState.state === 'PUBLISHED' && !el.newExecution)}
                                                 onClick={() => this.props.actions('clear-annotator-execution', { id: el.id })}>
                                    <span className="menu-icon fa fa-times fa-lg mr-3"></span>Clear last execution
                                  </Dropdown.Item>

                                  <Dropdown.Divider />

                                  <Dropdown.Item className="py-2"
                                                 disabled={!el.publishedFromCurrentFileSystem}
                                                 onClick={() => this.props.actions('preview-published-annotator-execution', {id: el.id})}>
                                                 <span className="menu-icon fa fa-eye fa-lg mr-3"/>Preview published execution
                                  </Dropdown.Item>

                                  <Dropdown.Item className="py-2"
                                                 disabled={!el.publishedFromCurrentFileSystem}
                                                 onClick={() => this.props.actions('download-published-annotator-execution', {id: el.id})}>
                                    <span className="menu-icon fa fa-download fa-lg mr-3"/>Download published execution
                                  </Dropdown.Item>


                                </DropdownButton>
                              </Col>
                            </Row>
                          </Container>
                        </Col>
                      </Row>)}
                  </Col>
                </Row>
              </Collapse>}
            </Container>}

            {annotators.length > 0 && fullView &&
              <Container className="groupborder-empty">
                <Row className="header-empty">
                  <Col>
                    <span>Published Annotations</span>
                  </Col>

                  {toggleBoxColumn('publishedAnnotationsOpen', this, null, null, annotationEditGroups)}
                </Row>

                <Collapse in={this.state.publishedAnnotationsOpen}>
                  <Row className="annotatorRowGroup">
                    <Col className="annotatorRowGroupColumn">
                      {annotationEditGroups.map((el, index) =>
                        <div key={"annotationEditGroup-" + index} className="bottomrow-bold">
                          <Row className="grouping bottomrow mx-0">
                            {fullView &&
                              <React.Fragment>
                                <Col className="my-2 pl-0" md={8}>
                                  {el.asProperty &&
                                  <span className="crimson-std text-bold">{el.asProperty}</span>}
                                  {!el.asProperty &&
                                  <React.Fragment>
                                    <Row>
                                      <Col><span className="parameter">Keys: </span><span className="parametervalue"> {el.keys ? el.keys.join(", ") : "--"}</span></Col>
                                    </Row>
                                    <Row>
                                      <Col><span className="parameter">Tag: </span><span className="parametervalue"> {el.tag ? el.tag : "<no tag>"}</span></Col>
                                    </Row>
                                  </React.Fragment>}
                                </Col>
                                <Col className="align-self-center text-break" md={3}>
                                  <Form.Check  label="Auto-exportable" checked={el.autoexportable} onClick={() => this.props.actions('check-annotation-edit-group', { id: el.id })}/>
                                </Col>
                                <Col className="align-self-center" md={1}>
                                  <DropdownButton size="sm" title={<span title="Actions" className='fa fa-bars'></span>} className="actions">
                                    <Dropdown.Item className="py-2" onClick={() => this.props.actions('view-annotations', { id: el.id, page: 1, mode: "ALL", annotators: annotators, filter: { fields: [] } })}>
                                      <span className="menu-icon fa fa-list-alt fa-lg mr-3"></span>View annotations...
                                    </Dropdown.Item>
                                    <Dropdown.Divider />
                                    <Dropdown.Item disabled={el.pagedAnnotationValidations && el.pagedAnnotationValidations.length > 0} className="py-2" id={'prepare-paged-validation-' + el.id} onClick={() => this.openValidationCreate(el.id, null, 'paged')}>
                                      <span className="menu-icon fa fa-file-text-o fa-lg mr-3"></span>New paged validation...
                                    </Dropdown.Item>
                                    <Dropdown.Item className="py-2" id={'prepare-filtered-validation-' + el.id} onClick={() => this.openValidationCreate(el.id, null, 'filter')}>
                                      <span className="menu-icon fa fa-filter fa-lg mr-3"></span>New filter validation...
                                    </Dropdown.Item>
                                    <Dropdown.Divider />
    {/*                                <Dropdown.Item className="py-2" onClick={() => this.props.actions('download-annotation-values', { id: el.id, mode: 'ALL' })}>
                                      <span className="menu-icon fa fa-download fa-lg mr-3"></span>Download annotations and validations
                                    </Dropdown.Item> */}
                                    <Dropdown.Item className="py-2" onClick={() => this.props.actions('export-annotations-modal', {id: el.id})}>
                                      <span className="menu-icon fa fa-download fa-lg mr-3"></span>Export annotations and validations...
                                    </Dropdown.Item>

    {/*                                <Dropdown.Item className="py-2" onClick={() => this.props.actions('download-annotation-values', { id: el.id, mode: 'NON_DELETED' })}>
                                      <span className="menu-icon fa fa-download fa-lg mr-3"></span>Download values after validation
                                    </Dropdown.Item> */}
                                    <Dropdown.Divider />
                                    <Dropdown.Item className="py-2" onClick={() => this.props.actions('score-validation-distribution', { id: el.id, accuracy: 10 })}>
                                      <span className="menu-icon fa fa-download fa-lg mr-3"></span>View score - validation distribution...
                                    </Dropdown.Item>
                                  </DropdownButton>
                                </Col>
                              </React.Fragment>
                            }
                            {/*!fullView && el.pagedAnnotationValidations && el.pagedAnnotationValidations.length > 0 &&
                              <Col className="pl-0 text-center">
                                <Row className="px-3">
                                  <Col className="text-left px-0">
                                    {el.asProperty}
                                  </Col>
                                  <Col className="text-right px-0">
                                    Validated: <strong>{this.props.validationProgress[el.pagedAnnotationValidations[0].id]}%</strong>
                                  </Col>
                                </Row>
                                <Row className="px-3">
                                  <ProgressBar
                                    animated
                                    className="mt-2 mb-1 w-100"
                                    now={this.props.validationProgress[el.pagedAnnotationValidations[0].id]}
                                  />
                                </Row>
                              </Col>
                            */}
                            {/*(!fullView && el.pagedAnnotationValidations.length > 0) &&
                              <Col className="mybutton align-self-center" md="auto">
                                <Button type="button" className="menubutton" aria-label="Validate" title="Validate" disabled={el.pagedAnnotationValidations[0].lifecycleState.state !== 'STARTED' || el.pagedAnnotationValidations[0].publishState.state === 'PUBLISHED'} onClick={() => this.openValidations(el.pagedAnnotationValidations[0], el.pagedAnnotationValidations[0].lifecycleState.state === 'STARTED' && el.pagedAnnotationValidations[0].publishState.state !== 'PUBLISHED')}>
                                  <span className="fa fa-list-alt"></span>
                                </Button>
                              </Col>
                            */}
                          </Row>

                          {fullView && el.pagedAnnotationValidations.length > 0 &&
                          <div className="bottomrow mb-2">
                            <Row className="align-self-center font-weight-bold mx-0 mb-2">
                              Paged Validations:
                            </Row>
                            <Row className="annotatorRowGroup mr-0">
                              <Col className="annotatorRowGroupColumn">
                                {el.pagedAnnotationValidations.map((pav, index) =>
                                <Row key={"pav-" + pav.id}>
                                  <Col>
                                    <Container key={index} className="annotatorInstanceRow">
                                      <Row className="mappingInstanceRowData">
                                        <Col md={5} className="">
                                          <Row>
                                            {(pav.name && pav.name !== '') ? pav.name : pav.uuid}
                                          </Row>
                                          <Row>
                                            <span title="Paged validation item sort order" className="pavMode">
                                            {(pav.mode && pav.mode !== '') ? this.printMode(pav.mode) : this.printMode('PAV-VAL:CNT:DESC')}
                                            </span>
                                          </Row>
                                        </Col>

                                        <Col md={6} className="tablecenter">
                                          <Row className="stategroup">
                                            <Col>
                                              <Row>
                                                {/*<Col id={'validation-status-' + pav.id} className="executed">
                                                  {pav.complete ? "Validation finished" : "Validation started"}
                                                  </Col>*/}
                                                  <Col>
                                                    <LifecycleState value={pav.lifecycleState} />
                                                  </Col>
                                              </Row>
                                              <Row>
                                                <Col>
                                                  <ExecutableState value={pav.executeState} />
                                                </Col>
                                              </Row>
                                              <Row>
                                                <Col>
                                                  <PublishableState value={pav.publishState} />
                                                </Col>
                                              </Row>
                                            </Col>
                                          </Row>
                                        </Col>

                                        <Col md={1} className="last-action-column">
                                          {actionsMenu(
                                          <div>
                                            <Dropdown.Item className="py-2"
                                                           onClick={() => this.openValidationCreate(el.id, pav, 'paged')}>
                                              <span className="menu-icon fa fa-pencil fa-lg mr-3"></span>Edit...
                                            </Dropdown.Item>

                                            <Dropdown.Item className="py-2"
                                                           disabled={pav.executeState.state == "EXECUTING" || pav.publishState.state == "PUBLISHED" || pav.publishState.state == "PUBLISHING" || pav.publishState.state == "UNPUBLISHING" || pav.lifecycleState.state == "STARTED" || pav.lifecycleState.state == "RESUMING" }
                                                           onClick={() => this.props.actions('delete-paged-annotation-validation', { id: pav.id, aegId: el.id })}>
                                              <span className="menu-icon fa fa-trash fa-lg mr-3"></span>Delete
                                            </Dropdown.Item>

                                            <Dropdown.Divider />

                                            <Dropdown.Item className="py-2" onClick={() => this.openValidations(pav, pav.lifecycleState.state === 'STARTED' && pav.publishState.state !== 'PUBLISHED')}>
                                              <span className="menu-icon fa fa-edit fa-lg mr-3"></span>Validations Editor...
                                            </Dropdown.Item>

                                            <Dropdown.Divider />

                                            <Dropdown.Item className="py-2" id={'start-validation-' + pav.id}
                                                           disabled={pav.lifecycleState.state === 'STARTED' || pav.publishState.state === 'PUBLISHED'}
                                                           onClick={() => this.props.actions('resume-paged-annotation-validation', { id: pav.id, aegId: el.id })}>
                                              <span className="menu-icon fa fa-unlock fa-lg mr-3"></span>Start validation
                                            </Dropdown.Item>

                                            <Dropdown.Item className="py-2" id={'stop-validation-' + pav.id}
                                                           disabled={pav.lifecycleState.state !== 'STARTED'}
                                                           onClick={() => this.props.actions('stop-paged-annotation-validation', { id: pav.id, aegId: el.id })}>
                                              <span className="menu-icon fa fa-lock fa-lg mr-3"></span>Stop validation
                                            </Dropdown.Item>

                                            <Dropdown.Divider />

                                            <Dropdown.Item className="py-2"
                                                           disabled={pav.lifecycleState.state !== 'STOPPED'}
                                                           onClick={() => this.props.actions('execute-paged-annotation-validation', { id: pav.id })}>
                                              <span className="menu-icon fa fa-play-circle fa-lg mr-3"></span>Execute
                                            </Dropdown.Item>

                                            <Dropdown.Divider />

                                            <Dropdown.Item className="py-2"
                                                           disabled={pav.executeState.state !== 'EXECUTED' || pav.lifecycleState.state !== 'STOPPED' || pav.publishState.state === 'PUBLISHED'}
                                                           onClick={() => this.props.actions('publish-paged-annotation-validation', { id: pav.id })}>
                                              <span className="menu-icon fa fa fa-calendar-check-o fa-lg mr-3"></span>Publish
                                            </Dropdown.Item>

                                            <Dropdown.Item className="py-2"
                                                           disabled={pav.publishState.state !== 'PUBLISHED'}
                                                           onClick={() => this.props.actions('unpublish-paged-annotation-validation', { id: pav.id })}>
                                              <span className="menu-icon fa fa-calendar-times-o fa-lg mr-3"></span>Unpublish
                                            </Dropdown.Item>

                                            <Dropdown.Divider />

                                            <Dropdown.Item className="py-2"
                                                           disabled={pav.executeState.state !== 'EXECUTED' || (pav.publishedFromCurrentFileSystem && pav.publishState.state === 'PUBLISHED' && !pav.newExecution)}
                                                           onClick={() => this.props.actions('preview-paged-annotation-validation-execution', { id: pav.id })}>
                                              <span className="menu-icon fa fa-eye fa-lg mr-3"></span>Preview last execution
                                            </Dropdown.Item>

                                            <Dropdown.Item className="py-2"
                                                           disabled={pav.executeState.state !== 'EXECUTED' || (pav.publishedFromCurrentFileSystem && pav.publishState.state === 'PUBLISHED' && !pav.newExecution)}
                                                           onClick={() => this.props.actions('download-paged-annotation-validation-execution', { id: pav.id })}>
                                              <span className="menu-icon fa fa-download fa-lg mr-3"></span>Download last execution
                                            </Dropdown.Item>

                                            <Dropdown.Item className="py-2"
                                                           disabled={pav.executeState.state !== 'EXECUTED' || (pav.publishedFromCurrentFileSystem && pav.publishState.state === 'PUBLISHED' && !pav.newExecution)}
                                                           onClick={() => this.props.actions('clear-paged-annotation-validation-execution', { id: pav.id, aegId: el.id })}>
                                              <span className="menu-icon fa fa-times fa-lg mr-3"></span>Clear last execution
                                            </Dropdown.Item>

                                            <Dropdown.Divider />

                                            <Dropdown.Item className="py-2"
                                                           disabled={!pav.publishedFromCurrentFileSystem}
                                                           onClick={() => this.props.actions('preview-published-paged-annotation-validation-execution', {id: pav.id})}>
                                                           <span className="menu-icon fa fa-eye fa-lg mr-3"/>Preview published execution
                                            </Dropdown.Item>

                                            <Dropdown.Item className="py-2"
                                                           disabled={!pav.publishedFromCurrentFileSystem}
                                                           onClick={() => this.props.actions('download-published-paged-annotation-validation-execution', {id: pav.id})}>
                                              <span className="menu-icon fa fa-download fa-lg mr-3"/>Download published execution
                                            </Dropdown.Item>

                                          </div>)}
                                        </Col>
                                      </Row>
                                    </Container>
                                  </Col>
                                </Row>)}
                              </Col>
                            </Row>
                          </div>}

                          {fullView && el.filterAnnotationValidations.length > 0 &&
                          <div>
                            <Row className="align-self-center font-weight-bold mx-0 mb-2">
                              Filter Validations:
                            </Row>
                            <Row className="annotatorRowGroup mr-0">
                              <Col className="annotatorRowGroupColumn">
                                {el.filterAnnotationValidations.map((fav, index) =>
                                <Row key={"fav-" + fav.id} className="grouping mx-0">
                                  <Col md={5} className="">
                                    {(fav.name && fav.name !== '') ? fav.name : fav.uuid}
                                  </Col>

                                  <Col md={6} className="tablecenter">
                                    <Row className="stategroup">
                                      <Col>
                                        <Row>
                                          <Col>
                                            <ExecutableState value={fav.executeState} />
                                          </Col>
                                        </Row>
                                        <Row>
                                          <Col>
                                            <PublishableState value={fav.publishState} />
                                          </Col>
                                        </Row>
                                      </Col>
                                    </Row>
                                  </Col>

                                  <Col md={1} className="last-action-column">
                                    {actionsMenu(
                                    <div>
                                      <Dropdown.Item className="py-2" onClick={() => this.openValidationCreate(el.id, fav, 'filter')}>
                                        <span className="menu-icon fa fa-pencil fa-lg mr-3"></span>Edit
                                      </Dropdown.Item>

                                      <Dropdown.Item className="py-2"
                                                     disabled={fav.executeState.state == "EXECUTING" || fav.publishState.state == "PUBLISHED" || fav.publishState.state == "PUBLISHING" || fav.publishState.state == "UNPUBLISHING"}
                                                     onClick={() => this.props.actions('delete-filter-annotation-validation', { id: fav.id, aegId: el.id })}>
                                        <span className="menu-icon fa fa-trash fa-lg mr-3"></span>Delete
                                      </Dropdown.Item>

                                      <Dropdown.Divider />

                                      <Dropdown.Item className="py-2"
                                                     disabled={fav.publishState.state === 'PUBLISHED'}
                                                     onClick={() => this.props.actions('execute-filter-annotation-validation', { id: fav.id })}>
                                        <span className="menu-icon fa fa-play-circle fa-lg mr-3"></span>Execute
                                      </Dropdown.Item>

                                      <Dropdown.Divider />

                                      <Dropdown.Item className="py-2"
                                                     disabled={fav.executeState.state !== 'EXECUTED' || fav.publishState.state === 'PUBLISHED'}
                                                     onClick={() => this.props.actions('publish-filter-annotation-validation', { id: fav.id })}>
                                        <span className="menu-icon fa fa fa-calendar-check-o fa-lg mr-3"></span>Publish
                                      </Dropdown.Item>

                                      <Dropdown.Item className="py-2"
                                                     disabled={fav.publishState.state !== 'PUBLISHED'}
                                                     onClick={() => this.props.actions('unpublish-filter-annotation-validation', { id: fav.id })}>
                                        <span className="menu-icon fa fa-calendar-times-o fa-lg mr-3"></span>Unpublish
                                      </Dropdown.Item>

                                      <Dropdown.Divider />

                                      <Dropdown.Item className="py-2"
                                                     disabled={fav.executeState.state !== 'EXECUTED' || (fav.publishedFromCurrentFileSystem && fav.publishState.state === 'PUBLISHED' && !fav.newExecution)}
                                                     onClick={() => this.props.actions('preview-filter-annotation-validation-execution', { id: fav.id })}>
                                        <span className="menu-icon fa fa-eye fa-lg mr-3"></span>Preview last execution
                                      </Dropdown.Item>

                                      <Dropdown.Item className="py-2"
                                                     disabled={fav.executeState.state !== 'EXECUTED' || (fav.publishedFromCurrentFileSystem && fav.publishState.state === 'PUBLISHED' && !fav.newExecution)}
                                                     onClick={() => this.props.actions('download-filter-annotation-validation-execution', { id: fav.id })}>
                                        <span className="menu-icon fa fa-download fa-lg mr-3"></span>Download last execution
                                      </Dropdown.Item>

                                      <Dropdown.Item className="py-2"
                                                     disabled={fav.executeState.state !== 'EXECUTED' || (fav.publishedFromCurrentFileSystem && fav.publishState.state === 'PUBLISHED' && !fav.newExecution)}
                                                     onClick={() => this.props.actions('clear-filter-annotation-validation-execution', { id: fav.id, aegId: el.id })}>
                                        <span className="menu-icon fa fa-times fa-lg mr-3"></span>Clear last execution
                                      </Dropdown.Item>

                                      <Dropdown.Divider />

                                      <Dropdown.Item className="py-2"
                                                     disabled={!fav.publishedFromCurrentFileSystem}
                                                     onClick={() => this.props.actions('preview-published-filter-annotation-validation-execution', {id: fav.id})}>
                                                     <span className="menu-icon fa fa-eye fa-lg mr-3"/>Preview published execution
                                      </Dropdown.Item>

                                      <Dropdown.Item className="py-2"
                                                     disabled={!fav.publishedFromCurrentFileSystem}
                                                     onClick={() => this.props.actions('download-published-filter-annotation-validation-execution', {id: fav.id})}>
                                        <span className="menu-icon fa fa-download fa-lg mr-3"/>Download published execution
                                      </Dropdown.Item>

                                    </div>)}
                                  </Col>
                                </Row>)}
                              </Col>
                            </Row>
                          </div>}
                        </div>)
                      }
                    </Col>
                  </Row>
                </Collapse>
              </Container>}

            {embedders.length > 0 &&
            <Container className="groupborder-empty">
              {fullView &&
              <Row className="header-empty">
                <Col>
                  <span>Embedders</span>
                </Col>

                {toggleBoxColumn('embeddersOpen', this, null, null, embedders)}

              </Row>}

              {fullView &&
              <Collapse in={this.state.embeddersOpen}>
                <Row className="annotatorRowGroup">
                  <Col className="annotatorRowGroupColumn">
                    {embedders.map((el, index) =>

                      <Row key={index}>
                        <Col>
                          <Container key={index} className="annotatorInstanceRow">
                            <Row className="mappingInstanceRowData">

                              {/* <Col md="auto px-0">
                                <Form.Check value={el.selected} onClick={() => this.props.actions('check-annotator', { id: el.id })} />
                              </Col> */}
                              <Col md="5" className="text-break">
                                <Row>
                                  <Col><span className="crimson-std text-bold">{this.props.dataEmbedders.filter((ann) => ann.identifier === el.embedder)[0].title}</span></Col>
                                </Row>
                                {/*el.parameters && el.parameters.length > 0 &&
                                  <div>
                                    <Row className="tableheader">
                                    </Row>
                                    <Row className="tableheader">
                                      <Col>{Localizer.parameters[this.props.language]}</Col>
                                    </Row>
                                  </div>*/}
                                {/*el.parameters && el.parameters.map((el2, index2) =>
                                  <Row key={index2}>
                                    <Col><span className="parameter">{el2.name}: </span><span className="parametervalue">{el2.value}</span></Col>
                                  </Row>
                                )*/}
                                {/*el.preprocess && el.preprocess.length > 0 &&
                                  <div>
                                    <Row className="tableheader">
                                    </Row>
                                    <Row className="tableheader">
                                      <Col>Preprocess</Col>
                                    </Row>
                                  </div>*/}
                                {/*el.preprocess && el.preprocess.map((el1, index1) =>
                                  <Row key={"function-" + index1}>
                                    <Col md="auto">{index1 + 1}</Col>
                                    <Col>
                                      <Row key={index1}>
                                        <Col><span className="parameter">function: </span><span className="parametervalue">{qname(el1.function)}</span></Col>
                                      </Row>
                                      {el1.parameters.map((el2, index2) =>
                                        <Row key={index2}>
                                          <Col><span className="parameter">{el2.name}: </span><span className="parametervalue">{el2.value}</span></Col>
                                        </Row>
                                      )}
                                      {el1.modifier &&
                                        <Row>
                                          <Col><span className="parameter">modifier: </span><span className="parametervalue">{qname(el1.modifier)}</span></Col>
                                        </Row>
                                      }
                                    </Col>
                                  </Row>)*/}
                              </Col>

                              <Col md={6} className="tablecenter">
                                <Row className="stategroup">
                                  <Col>
                                    <Row>
                                      <Col>
                                        <ExecutableState value={el.executeState}/>
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col>
                                        <PublishableState value={el.publishState}/>
                                      </Col>
                                    </Row>
                                  </Col>
                                </Row>
                              </Col>

                              <Col md="1" className="">
                                <DropdownButton size="sm" title={<span title="Actions" className='fa fa-bars'></span>} className="actions">
                                    <Dropdown.Item className="py-2"
                                                   disabled={!el.ownedByUser}
                                      onClick={() => this.setState({ embedderModalOpen: true, embedderToEditMode: true, embedderToEdit: el })}>
                                      <span className="menu-icon fa fa-edit fa-lg mr-3"></span>Edit
                                    </Dropdown.Item>

                                  <Dropdown.Item className="py-2"
                                                 disabled={!el.ownedByUser || el.publishState.state !== "UNPUBLISHED"}
                                                 onClick={() => this.props.actions('delete-embedder', { id: el.id })}>
                                    <span className="menu-icon fa fa-trash fa-lg mr-3"></span>Delete
                                  </Dropdown.Item>

                                  <Dropdown.Divider/>

                                  <Dropdown.Item className="py-2"
                                                 disabled={!el.ownedByUser || el.executeState.state === "EXECUTING"}
                                                 onClick={() => this.props.actions('execute-embedder', { id: el.id })}>
                                    <span className="menu-icon fa fa-play-circle fa-lg mr-3"></span>Execute
                                  </Dropdown.Item>

                                  <Dropdown.Item className="py-2"
                                                 disabled={!el.ownedByUser || el.executeState.state !== "EXECUTING"}
                                                 onClick={() => this.props.actions('stop-embedder', { id: el.id })}>
                                    <span className="menu-icon fa fa-stop fa-lg mr-3"></span>Stop Execution
                                  </Dropdown.Item>

                                  <Dropdown.Divider/>

                                  <Dropdown.Item className="py-2"
                                                 disabled={!el.ownedByUser || el.executeState.state !== 'EXECUTED' || el.publishState.state == 'PUBLISHED'}
                                                 onClick={() => this.props.actions('publish-embedder', { id: el.id })}>
                                    <span className="menu-icon fa fa-calendar-check-o fa-lg mr-3"></span>Publish
                                  </Dropdown.Item>

                                  <Dropdown.Item className="py-2"
                                                 disabled={!el.ownedByUser}
                                                 onClick={() => this.props.actions('unpublish-embedder', { id: el.id })}>
                                    <span className="menu-icon fa fa-calendar-times-o fa-lg mr-3"></span>Unpublish
                                  </Dropdown.Item>

                                  <Dropdown.Divider />

                                  <Dropdown.Item className="py-2"
                                                 disabled={el.executeState.state !== 'EXECUTED' || (el.publishedFromCurrentFileSystem && el.publishState.state === 'PUBLISHED' && !el.newExecution)}
                                                 onClick={() => this.props.actions('preview-last-embedder-execution', { id: el.id })}>
                                    <span className="menu-icon fa fa-eye fa-lg mr-3"></span>Preview last execution
                                  </Dropdown.Item>

                                  <Dropdown.Item className="py-2"
                                                 disabled={el.executeState.state !== 'EXECUTED' || (el.publishedFromCurrentFileSystem && el.publishState.state === 'PUBLISHED' && !el.newExecution)}
                                                 onClick={() => this.props.actions('download-last-embedder-execution', { id: el.id })}>
                                    <span className="menu-icon fa fa-download fa-lg mr-3"></span>Download last execution
                                  </Dropdown.Item>

                                  <Dropdown.Item className="py-2"
                                                 disabled={!el.ownedByUser || el.executeState.state !== 'EXECUTED' || (el.publishedFromCurrentFileSystem && el.publishState.state === 'PUBLISHED' && !el.newExecution)}
                                                 onClick={() => this.props.actions('clear-embedder-execution', { id: el.id })}>
                                    <span className="menu-icon fa fa-times fa-lg mr-3"></span>Clear last execution
                                  </Dropdown.Item>

                                  <Dropdown.Divider />

                                  <Dropdown.Item className="py-2"
                                                 disabled={!el.publishedFromCurrentFileSystem}
                                                 onClick={() => this.props.actions('preview-published-embedder-execution', {id: el.id})}>
                                                 <span className="menu-icon fa fa-eye fa-lg mr-3"/>Preview published execution
                                  </Dropdown.Item>

                                  <Dropdown.Item className="py-2"
                                                 disabled={!el.publishedFromCurrentFileSystem}
                                                 onClick={() => this.props.actions('download-published-embedder-execution', {id: el.id})}>
                                    <span className="menu-icon fa fa-download fa-lg mr-3"/>Download published execution
                                  </Dropdown.Item>


                                </DropdownButton>
                              </Col>
                            </Row>
                          </Container>
                        </Col>
                      </Row>)}
                  </Col>
                </Row>
              </Collapse>}
            </Container>}

            <Collapse in={this.state.propertiesOpen}>
            <div>
            <Row className="schema-content-list">
              {propertyPartition && propertyPartition.sort((a,b) => sortByField('property', a, b)).map((el2,index2) =>
              <SchemaProperty key={index2} value={el2}
                        path = {[{type:"CLASS", uri: resolveJsonLdUri(el['class'], context)}]}
                        // schema={this.props.schema}
                        // mode={this.props.mode}
                        my={this.props.my}
                        dataset={this.props.dataset}
                        language={this.props.language}
                        annotators={this.props.annotators}
                        annotationEditGroups={this.props.annotationEditGroups}
                        validationProgress={this.props.validationProgress}
                        vocabularizers={this.props.vocabularizers}
                        // indexes={this.props.indexes}
                        actions={(action, params) => this.props.actions(action, params)}
                        queryProperties={this.props.queryProperties}
                        dataAnnotators={this.props.dataAnnotators}
                        userAnnotators={this.props.userAnnotators}
                        preprocessFunctions={this.props.preprocessFunctions}
                        preprocessOperations={this.props.preprocessOperations}
                        project={this.props.project}
                        // vocabularies={this.props.vocabularies}
                        rdfVocabularies={this.props.rdfVocabularies}
                        validationModes={this.props.validationModes}/>)}

              {classPartition && classPartition.sort((a,b) => sortByField('class', a, b)).map((el2,index2) =>
              <SchemaProperty key={index2} value={el2}
                        path = {[{type:"PROPERTY", uri: resolveJsonLdUri(el['property'], context)}]}
                        // schema={this.props.schema}
                        // mode={this.props.mode}
                        my={this.props.my}
                        dataset={this.props.dataset}
                        language={this.props.language}
                        annotators={this.props.annotators}
                        annotationEditGroups={this.props.annotationEditGroups}
                        validationProgress={this.props.validationProgress}
                        vocabularizers={this.props.vocabularizers}
                        // indexes={this.props.indexes}
                        actions={(action, params) => this.props.actions(action, params)}
                        queryProperties={this.props.queryProperties}
                        dataAnnotators={this.props.dataAnnotators}
                        userAnnotators={this.props.userAnnotators}
                        preprocessFunctions={this.props.preprocessFunctions}
                        preprocessOperations={this.props.preprocessOperations}
                        project={this.props.project}
                        // vocabularies={this.props.vocabularies}
                        rdfVocabularies={this.props.rdfVocabularies}
                        validationModes={this.props.validationModes}/>)}
            </Row>
            </div>
            </Collapse>

          </Container>
        </Col>

        {this.state.embedderModalOpen &&
        <EmbedderModal show={this.state.embedderModalOpen}
          datasetUri={this.props.datasetUri}
          embedder={this.state.embedderToEdit}
          embedderClass={this.state.embedderToEditClass}
          // onProperty={path[path.length - 1]}
          //queryProperties={this.props.queryProperties}
          dataEmbedders={this.props.dataEmbedders}
          // preprocessFunctions={this.props.preprocessFunctions}
          // preprocessOperations={this.props.preprocessOperations}
          // vocabularies={this.props.vocabularies}
          // rdfVocabularies={this.props.rdfVocabularies}
          onOK={(embedder, variant, indexStructure, keys) => { this.props.actions('create-embedder', { embedder: embedder, variant: variant, indexStructure: indexStructure, onClass: this.state.embedderToEditClass, keys: keys }); this.setState({ embedderModalOpen: false }) }}
          // onUpdate={(id, property, annotator, thesaurus, params, preprocess, variant, defaultTarget) => { this.props.actions('edit-annotator', { id: id, asProperty: property, annotator: annotator, thesaurus: thesaurus, parameters: params, preprocess: preprocess, variant: variant, defaultTarget }); this.setState({ embedderModalOpen: false, editMode: false, editAnnotator: null }) }}
          onClose={() => this.setState({ embedderModalOpen: false, embedderToEditMode: false, embedderToEdit: null })}
          />}

          {this.state.annotatorModalOpen &&
          <AnnotatorModal show={this.state.annotatorModalOpen}
            dataset={this.props.dataset}
            annotator={this.state.annotatorToEdit}
            onClass={this.state.annotatorToEditClass}
            //queryProperties={this.props.queryProperties}
            dataAnnotators={this.props.dataAnnotators.filter(el => el.fields)}
            userAnnotators={this.props.userAnnotators.filter(el => el.fields)}
            preprocessFunctions={this.props.preprocessFunctions}
            preprocessOperations={this.props.preprocessOperations}
            project={this.props.project}
            // vocabularies={this.props.vocabularies}
            rdfVocabularies={this.props.rdfVocabularies}
            onOK={(id, name, identifier, property, tags, annotator, userAnnotator, thesaurus, params, preprocess, variant, defaultTarget, indexStructure, keysMetadata) => { this.props.actions('create-annotator', { id, name, identifier, onClass: this.state.annotatorToEditClass, asProperty: property, tags, annotator, userAnnotator, thesaurus, parameters: params, preprocess, variant, defaultTarget, indexStructure, keysMetadata }); this.setState({ annotatorModalOpen: false, annotatorToEditMode: false, annotatorToEdit: null }) }}
            onClose={() => this.setState({ annotatorModalOpen: false, annotatorToEditMode: false, annotatorToEdit: null })} />}

      </Row>
    )

  }
}



export default SchemaClass;
