export function splice(array, index) {
  return array.slice(0,index).concat(array.slice(index+1))
}

export function checkStatus (res) {
  if (res.status >= 200 && res.status < 300) {
    return res
  } else {
    let err = new Error(res.statusText)
    err.response = res
    throw err
  }
}

// export function saveDownloadedFile(response) {
//   if (response.ok) {
//     const filename = response.headers.get('content-disposition')
//       .split(';')
//       .find(n => n.includes('filename='))
//       .replace('filename=', '')
//       .trim();
//
//       response.blob().then(blob => {
//             let url = window.URL.createObjectURL(blob);
//             let a = document.createElement('a');
//             a.href = url;
//             a.download = filename;
//             a.click();
//           });
//     } else {
//        Promise.reject(response)
//     }
//   }


export function buildQueryString(params) {
  return Object.keys(params).map(key => key + '=' + params[key]).join('&');
}

export function copyReplaceAt(prop, array, index, object) {
  if (object !== null) {
    return { [prop]: array.slice(0, index)
                         .concat(object)
                         .concat(array.slice(index + 1)) }
   } else {
     return { [prop]: array.slice(0, index)
                          .concat(array.slice(index + 1)) }
   }
}

export function filterByLanguage(data, property, language, valueField, languageField) {

  if (!data[property]) {
    return null;
  }

  if (!valueField) {
    valueField = "@value"
  }

  if (!languageField) {
    languageField = "@language"
  }

  var array = objectToArray(data[property])

  for (const item of array) {
    if (item[languageField] !== undefined && (item[languageField] === language || item[languageField].startsWith(language + '-'))) {
        return item[valueField];
    }
  }

  for (const item of array) {
    if (item[languageField] !== undefined && (item[languageField] === 'en' || item[languageField].startsWith('en-'))) {
        return item[valueField];
    }
  }

  if (array[0][valueField]) {
    return array[0][valueField]
  } else {
    return array[0]
  }
}

export function resolveJsonLdUri(str, context) {
  if (!context || str.startsWith("_:") || str.startsWith('http://') || str.startsWith('https://')) {
    return str;
  }

  var pos = str.indexOf(':')
  var prefix;
  var suffix;
  if (pos !== -1) {
    prefix = str.slice(0, pos);
    suffix = str.slice(pos + 1);
  } else {
    prefix = str;
    suffix = '';
  }

  var cc = context[prefix];
  if (cc.hasOwnProperty('@id')) {
    return cc['@id'] + suffix;
  } else {
    return cc + suffix;
  }
}

export function objectToArray(obj) {
  if (Array.isArray(obj)) {
    return obj
  } else {
    return [ obj ]
  }
}

export function qname(uri) {
  if (uri.startsWith("http://www.w3.org/2005/xpath-functions/")) {
    return "xpath:" + uri.substring(39);
  } else if (uri.startsWith("http://sw.islab.ntua.gr/semaspace/ontology/")) {
    return "sema:" + uri.substring(43);
  } else if (uri.startsWith("http://islab.ntua.gr/ns/d2rml-op#")) {
    return "drop:" + uri.substring(33);
  }

  return uri;
}
