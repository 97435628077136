import { API_BASE_URL, ACCESS_TOKEN } from '../constants/index.js';
import { textRequest, jsonSuccessFailureRequest, downloadFileRequest } from './APIUtils.js';

export function checkAnnotatorIdentifier(identifier, datasetId) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  return jsonSuccessFailureRequest({
    url: API_BASE_URL + "/annotator/exists-identifier?identifier=" + encodeURIComponent(identifier) + "&datasetId=" + datasetId,
    method: 'GET'
  })
}

export function createAnnotator(datasetId, name, identifier,onProperty, onClass, asProperty, tags, annotator, annotatorId, thesaurus, parameters, preprocess, variant, defaultTarget, indexStructure, keysMetadata) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  var body = { name, asProperty, tags, annotator, annotatorId, variant };
  if (onProperty) {
    body = {...body, onPath: onProperty }
  } else if (onClass) {
    body = {...body, onClass: onClass }
  }
  if (identifier && identifier != null) {
    body = { ...body, identifier }
  }

  if (indexStructure) {
    body = {...body, indexStructure}
  }
  if (keysMetadata) {
    body = {...body, keysMetadata}
  }

  Object.assign(body, thesaurus && { thesaurus: thesaurus },
    defaultTarget && { defaultTarget },
    parameters && { parameters: parameters },
    preprocess && { preprocess: preprocess });

  return jsonSuccessFailureRequest({
    url: API_BASE_URL + "/annotator/new?datasetId=" + datasetId,
    body: JSON.stringify(body),
    method: 'POST',
  })
}

export function getAnnotator(id) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  return jsonSuccessFailureRequest({
    url: API_BASE_URL + "/annotator/get/" + id,
    method: 'GET'
  })
}

export function updateAnnotator(id, name, identifier, asProperty, tags, annotator, annotatorId, thesaurus, parameters, preprocess, variant, defaultTarget, indexStructure, keysMetadata) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  var body = { name, asProperty, tags, annotator, annotatorId, variant }

  Object.assign(body, thesaurus && { thesaurus: thesaurus },
    defaultTarget && { defaultTarget },
    parameters && { parameters: parameters },
    preprocess && { preprocess: preprocess })

  if (indexStructure) {
    body = {...body, indexStructure}
  }
  if (keysMetadata) {
    body = {...body, keysMetadata}
  }
  if (identifier && identifier != null) {
    body = { ...body, identifier }
  }

  return jsonSuccessFailureRequest({
    url: API_BASE_URL + "/annotator/update/" + id,
    body: JSON.stringify(body),
    method: 'POST'
  })
}

export function previewLastAnnotatorExecution(id, shard, nextLine) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  var x = "";
  if (shard != null && nextLine != null) {
    x = "?shard=" + shard + "&offset=" + nextLine;
  }

  return textRequest({
    url: API_BASE_URL + "/annotator/preview-last-execution/" + id + x,
    method: 'GET'
  });
}

export function previewPublishedAnnotatorExecution(id, shard, nextLine) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  var x = "";
  if (shard != null && nextLine != null) {
    x = "?shard=" + shard + "&offset=" + nextLine;
  }

  return textRequest({
    url: API_BASE_URL + "/annotator/preview-published-execution/" + id + x,
    method: 'GET'
  });
}

export function deleteAnnotator(id) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  return jsonSuccessFailureRequest({
    url: API_BASE_URL + "/annotator/delete/" + id,
    method: 'DELETE'
  });
}

export function clearAnnotatorExecution(id) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  return jsonSuccessFailureRequest({
    url: API_BASE_URL + "/annotator/clear-execution/" + id,
    method: 'POST'
  })
}

export function downloadLastAnnotatorExecution(id) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  downloadFileRequest({
    url: API_BASE_URL + "/annotator/download-last-execution/" + id,
    method: 'GET'
  });

}

export function downloadPublishedAnnotatorExecution(id) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  downloadFileRequest({
    url: API_BASE_URL + "/annotator/download-published-execution/" + id,
    method: 'GET'
  });

}

export function getAnnotatorsByUser(datasetId) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  return jsonSuccessFailureRequest({
    url: API_BASE_URL + "/annotator/get-all-my?datasetId=" + datasetId,
    method: 'GET',
  })
}

export function getAnnotators(datasetId) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  return jsonSuccessFailureRequest({
    url: API_BASE_URL + "/annotator/get-all?datasetId=" + datasetId,
    method: 'GET',
  })
}

export function previewAnnotator(id, page) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  return jsonSuccessFailureRequest({
    url: API_BASE_URL + "/annotator/preview/" + id
      + "?page=" + page,
    method: 'GET',
  })
}

export function previewAnnotatorClustering(id, page) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  return jsonSuccessFailureRequest({
    url: API_BASE_URL + "/annotator/cluster-preview/" + id
      + "?page=" + page,
    method: 'GET',
  })
}

export function prepareAnnotator(id) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  return jsonSuccessFailureRequest({
    url: API_BASE_URL + "/annotator/prepare/" + id,
    method: 'POST',
  })
}

export function executeAnnotator(id) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  return jsonSuccessFailureRequest({
    url: API_BASE_URL + "/annotator/execute/" + id,
    method: 'POST',
  })
}

export function stopAnnotator(id) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  return jsonSuccessFailureRequest({
    url: API_BASE_URL + "/annotator/stop-execution/" + id,
    method: 'POST',
  })
}

export function publishAnnotator(id) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  return jsonSuccessFailureRequest({
    url: API_BASE_URL + "/annotator/publish/" + id,
    method: 'POST',
  })
}

export function unpublishAnnotator(id) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  return jsonSuccessFailureRequest({
    url: API_BASE_URL + "/annotator/unpublish/" + id,
    method: 'POST',
  })
}

export function republishAnnotator(id) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  return jsonSuccessFailureRequest({
    url: API_BASE_URL + "/annotator/republish/" + id,
    method: 'POST',
  })
}
