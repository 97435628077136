import { API_BASE_URL, ACCESS_TOKEN } from '../constants/index.js';
import { request, jsonSuccessFailureRequest } from './APIUtils.js';

export function checkDistributionIdentifier(datasetId, identifier) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  return jsonSuccessFailureRequest({
    url: API_BASE_URL + "/distribution/exists-identifier?datasetId=" + datasetId + "&identifier=" + encodeURIComponent(identifier),
    method: 'GET'
  })
}

export function newDistribution(datasetId, name, identifier, classes, ttl, nt, serializationVocabulary, compress, license) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  var serializations = [];
  if (ttl) {
    serializations.push('TTL');
  }

  if (nt) {
    serializations.push('NT');
  }

  var body = { name, identifier, classes, serializations, serializationVocabulary, compress, license }

  return jsonSuccessFailureRequest({
    url: API_BASE_URL + "/distribution/new?datasetId=" + datasetId,
    method: 'POST',
    body: JSON.stringify(body),
  })
}

export function updateDistribution(id, name, identifier, classes, ttl, nt, serializationVocabulary, compress, license) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  var serializations = [];
  if (ttl) {
    serializations.push('TTL');
  }

  if (nt) {
    serializations.push('NT');
  }

  var body = { name, identifier, classes, serializations, serializationVocabulary, compress, license }

  return jsonSuccessFailureRequest({
    url: API_BASE_URL + "/distribution/update/" + id,
    method: 'POST',
    body: JSON.stringify(body),
  })
}

export function deleteDistribution(id) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  return jsonSuccessFailureRequest({
    url: API_BASE_URL + "/distribution/delete/" + id,
    method: 'DELETE'
  });
}

export function createDistribution(id) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  return jsonSuccessFailureRequest({
    url: API_BASE_URL + "/distribution/create/" + id,
    method: 'POST',
  })
}

export function stopCreateDistribution(id) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  return jsonSuccessFailureRequest({
    url: API_BASE_URL + "/distribution/stop-create/" + id,
    method: 'POST',
  })
}

export function destroyDistribution(id) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  return jsonSuccessFailureRequest({
    url: API_BASE_URL + "/distribution/destroy/" + id,
    method: 'POST',
  })
}

export function getDistributions(datasetId) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  return jsonSuccessFailureRequest({
    url: API_BASE_URL + "/distribution/get-all-my?datasetId=" + datasetId,
    method: 'GET'
  })
}
