import React, { Component } from "react";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import Collapse  from "react-bootstrap/Collapse";
// import { splice } from '../../utils/functions.js';
import { getD2RMLDocument, getMappings, executeMapping, stopMapping, updateMapping, getMapping, unpublishMapping, downloadMapping, createMapping, deleteMapping, uploadMappingAttachment, downloadMappingAttachment, deleteMappingAttachment, previewLastMappingExecution, downloadLastMappingExecution, previewPublishedMappingExecution, downloadPublishedMappingExecution, clearMappingExecution, createMappingInstance, updateMappingInstance, validatePublishedMappingExecution, changeMappingOrder, changeMappingGroup  } from '../../utils/MappingsAPI';
import { getFiles, deleteFile, previewLastFile, previewPublishedFile, downloadLastFile, downloadPublishedFile, updateFile, createFile, changeFileOrder, changeFileGroup } from '../../utils/FilesAPI';
import { convert } from '../../utils/D2RMLUtils';
import { getDataset, addDataset, removeDataset, getDatasetDescription } from '../../utils/DatasetAPI';
import { throwToast } from '../../utils/UIUtils';
import { getIndexes, newIndex, updateIndex, changeIndexOrder, deleteIndex, createIndex, recreateIndex, stopCreateIndex, destroyIndex } from '../../utils/IndexAPI';
import { getUserTasks, runUserTask, createUserTask, updateUserTask, deleteUserTask, scheduleUserTask, unscheduleUserTask } from '../../utils/UserTasksAPI';
import { getDistributions, newDistribution, updateDistribution, deleteDistribution, createDistribution, stopCreateDistribution, destroyDistribution } from '../../utils/DistributionAPI';
import { getPrototypes, getPrototypeContent, createPrototype, updatePrototype, deletePrototype } from '../../utils/PrototypeAPI';
import { getComparators, createComparator, updateComparator, deleteComparator } from '../../utils/ComparatorAPI';
import { getIndexStructures, createIndexStructure, updateIndexStructure, deleteIndexStructure } from '../../utils/IndexStructureAPI';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLock} from '@fortawesome/free-solid-svg-icons'

import ComparatorModal from "./modals/ComparatorModal.js";
import IndexStructureModal from "./modals/IndexStructureModal.js";
import AddToProjectModal from "./modals/AddToProjectModal.js";
import NewMappingUploadModal from "./modals/NewMappingUploadModal.js";
import NewBindingModal from "./modals/NewBindingModal.js";
import PublishModal from "./modals/PublishModal.js";
import IndexModal from "./modals/IndexModal.js";
import ExecutableState from "./ExecutableState.js";
import PublishableState from "./PublishableState.js";
import PublishableState2 from "./PublishableState2.js";
// import IndexableState from "./IndexableState.js";
import CreatableState from "./CreatableState.js";
import RunnableState from "./RunnableState.js";
import ValidatableState from "./ValidatableState.js";
import DistributableState from "./DistributableState.js";
import LoadableState from "./LoadableState.js";
import ResultsModal from "./modals/ResultsModal.js";
import DeleteModal from "./modals/DeleteModal.js";
import CreateDistributionModal from "./modals/CreateDistributionModal.js";
import DistributionModal from "./modals/DistributionModal.js";
import NewDatasetLinkModal from "./modals/NewDatasetLinkModal.js";
import RDFDataFileUploadModal from "./modals/RDFDataFileUploadModal.js";
import SourceDataFileUploadModal from "./modals/SourceDataFileUploadModal.js";
import TTLEditorModal from "./modals/TTLEditorModal.js";
import SPARQLEditorModal from "./modals/SPARQLEditorModal.js";
import UserTaskModal from "./modals/UserTaskModal.js";

import ReactTooltip from "react-tooltip";
import { API_BASE_URL } from '../../constants/index.js';

import { actionsMenu, sortByField, MessageQueue, parseResult, toggleBoxColumn } from '../../utils/UIUtils';

export class DatasetEditor extends Component {
  constructor(props) {
    super(props);

    this.state = {

      mappings: [],
      mappingsOpen: [],
      groupsOpen: [],

      files: [],
      filesOpen: true,

      indexes: [],
      indexesOpen: true,
      userTasks: [],
      distributions: [],

      newMappingModalShow: false,
      newMappingModalMapping: null,

      newBindingModalShow: false,
      parameters: [],
      mappingId: null,

      newSourceDataModalShow: false,
      newUserTaskModalShow: false,
      newDistributionModalShow: false,
      newIndexModalShow:false,

      TTLEditorModalShow:false,
      TTLEditorState: { title:null, loaded:false, loading:false, failed:false },
      TTLEditorDocument: "",

      SPARQLEditorModalShow:false,

      newDataModalShow: false,

      newTTLFileUploadModalShow: false,

      newDatasetLinkModalShow: false,
      publishModalShow: false,
      publishModalGroup: undefined,

      resultsShow: false,
      resultsState: { title:null, loaded: false, loading:false, failed:false },

      shacls: [],
      d2rmls: [],
      annotators: [],
      comparators: [],
      indexStructures: []
    };

    this.messageQueueMap = new Map();

    if (props.dataset.id) {
      this.datasetUpdated(props.dataset);
    }
  }

  componentDidUpdate() {
    ReactTooltip.rebuild();
  }

  componentWillReceiveProps(props) {
    // console.log(props.dataset.id)

    if (this.props.dataset.id !== props.dataset.id) {

      if (props.dataset.id) {
        this.datasetUpdated(props.dataset)
      }

      this.messageQueueMap = new Map();
    }
  }

  addDataset(id, toId) {
    addDataset(id, toId)
      .then(response => {
        throwToast('success', response.message)
        this.props.actions('add-dataset-to-catalog', { id: id})
      })
      .catch(error => {
      })
  }

  removeDataset(id, fromId) {
    removeDataset(id, fromId)
      .then(response => {
        throwToast('success', response.message)
        this.props.actions('remove-dataset-from-catalog', { id: id})
      })
      .catch(error => {
      })
  }

  computeCollapses(mappings, files, indices) {
    var maxGroup = 0;
    if (mappings) {
      for (var d of mappings) {
        maxGroup = Math.max(maxGroup, d.group);
      }
    }
    if (files) {
      for (var d of files) {
        maxGroup = Math.max(maxGroup, d.group);
      }
    }
    // if (indices) {
    //   for (var d of files) {
    //     maxGroup = Math.max(maxGroup, d.group);
    //   }
    // }

    var go = [];
    var mo = [];
    var fo = [];
    var io = [];

    for (var i = 0; i < Math.min(maxGroup + 1, this.state.mappingsOpen.length); i++) {
      go.push(this.state.groupsOpen[i])
      mo.push(this.state.mappingsOpen[i])
      fo.push(this.state.filesOpen[i])
      // io.push(this.state.indexes[i])
    }
    for (var i = Math.min(maxGroup + 1, this.state.mappingsOpen.length); i < maxGroup + 1; i++) {
      go.push(true)
      mo.push(true)
      fo.push(true)
      // io.push(true)
    }


    return { groupsOpen: go, mappingsOpen: mo, filesOpen: fo, indexesOpen: io }
  }

  datasetUpdated(dataset) {
      const _this = this;

      var id = dataset.id;

      if (dataset.scope !== 'SHACL' && dataset.scope !== 'D2RML' && dataset.scope !== 'ANNOTATOR' && dataset.scope !== 'COMPARATOR' && dataset.scope !== 'INDEX') {
        getDataset(id, true)
            .then(response => {

              var open = _this.computeCollapses(response.data.mappings, response.data.files, response.data.indices)

               _this.setState({ user: response.data.user,
                                groupsOpen: open.groupsOpen,
                                mappings: response.data.mappings ? response.data.mappings: [],
                                mappingsOpen: open.mappingsOpen,
                                files: response.data.rdfFiles ? response.data.rdfFiles : [],
                                filesOpen: open.filesOpen,
                                indexes: response.data.indices ? response.data.indices: [],
                                // indexesOpen: open.indexesOpen,
                                userTasks: response.data.userTasks ? response.data.userTasks.sort((a,b) => sortByField('name', a, b)) : [],
                                distributions: response.data.distributions ? response.data.distributions.sort((a,b) => sortByField('name', a, b)) : []
                              });
            })
            .catch(error => { })
      }

      if (dataset.scope === 'SHACL' ) {
        getPrototypes(id, 'SHACL')
          .then(response => {
            _this.setState({ shacls: response.data.sort((a,b) => sortByField('name', a, b)), currentShaclIndex: null });
          })
          .catch(error => { })
      } else {
        this.setState({ shacls: [], currentShaclIndex: null });
      }

      if (dataset.scope === 'D2RML' ) {
        getPrototypes(id, 'D2RML')
          .then(response => {
            _this.setState({ d2rmls: response.data, currentD2rmlIndex: null });
          })
          .catch(error => { })
      } else {
        this.setState({ d2rmls: [], currentD2rmlIndex: null });
      }

      if (dataset.scope === 'ANNOTATOR' ) {
        getPrototypes(id, 'ANNOTATOR')
          .then(response => {
            _this.setState({ annotators: response.data, currentAnnotatorIndex: null });
          })
          .catch(error => { })
      } else {
        this.setState({ annotators: [], currentAnnotatorIndex: null });
      }

      if (dataset.scope === 'COMPARATOR' ) {
        getComparators(id)
          .then(response => {
            _this.setState({ comparators: response.data, currentComparatorIndex: null });
          })
          .catch(error => { })
      } else {
        this.setState({ comparators: [], currentComparatorIndex: null });
      }

      if (dataset.scope === 'INDEX' ) {
        getIndexStructures(id)
          .then(response => {
            _this.setState({ indexStructures: response.data, currentIndexStructuresIndex: null });
          })
          .catch(error => { })
      } else {
        this.setState({ indexStructures: [], currentIndexStructuresIndex: null });
      }

  }

  // getIndexes(id) {
  //   const _this = this;
  //   getIndexes(id)
  //     .then(response => {
  //       // _this.setState({ indexes: response.data.sort((a,b) => sortByField('indexStructureIdentifier', a, b)), currentIndexIndex: null });
  //       _this.setState({ indexes: response.data, currentIndexIndex: null });
  //     })
  //     .catch(error => { })
  // }


  handleMappingMessage(message) {
    // console.log("UPDATING " + message.order)
    // console.log(message)
    if (message.instanceId) {
      return {
        mappings: this.state.mappings.map(el => {
          if (el.id !== message.id) {
            return el
          } else {
            return {
              ...el, instances: el.instances.map(inst => {
                if (inst.id !== message.instanceId) {
                  return inst
                } else {
                  var obj = {...inst, [message.type +'State']: message.content }

                  if (message.content.state == 'EXECUTED') {
                    obj = {...obj, newExecution: true}
                  }
                  return obj
                }
              })
            }
          }
        })
      }
    } else {
      return {
        mappings: this.state.mappings.map(el => {
          if (el.id !== message.id) {
             return el
          } else {
            return {
              ...el, instances: el.instances.map(inst => {
                var obj = {...inst, [message.type +'State']: message.content }

                if (message.content.state == 'EXECUTED') {
                  obj = {...obj, newExecution: true}
                }
                return obj
              })
           }
         }
       })
      }
    }
  }

  handleFileMessage(message) {
    // console.log("UPDATING " + message.order)
    // console.log(message)
      return {
        files: this.state.files.map(el => {
          if (el.id !== message.id) {
             return el
          } else {
              var obj = {...el, [message.type +'State']: message.content }

              if (message.content.state == 'EXECUTED') {
                obj = {...obj, newExecution: true}
              }
              return obj
           }
       })
      }
    }

  handleGenericMessage(message, array) {
    return {
      [array]: this.state[array].map(el => {
        if (el.id !== message.id) {
          return el
        } else {
          return {...el, [message.type +'State']: message.content }
        }
    })}
  }

  handleServerEvent(type, message) {
    // console.log(message)

    if (type == "dataset") {
      this.datasetUpdated(this.props.dataset)

    } else if (type == "mapping") {
      var id = message.id + (message.instanceId ? (":" + message.instanceId) : "") + ":" + message.type

      var queue = this.messageQueueMap.get(id)
      if (!queue) {
        queue = new MessageQueue(this, (message) => this.handleMappingMessage(message))
        this.messageQueueMap.set(id, queue)
      }

      queue.push(message)
    } else if (type == "file") {
        var id = message.id + ":" + message.type

        var queue = this.messageQueueMap.get(id)
        if (!queue) {
          queue = new MessageQueue(this, (message) => this.handleFileMessage(message))
          this.messageQueueMap.set(id, queue)
        }

        queue.push(message)
    } else if (type == "index") {
      var id = message.id + ":" + message.type

      var queue = this.messageQueueMap.get(id)
      if (!queue) {
        queue = new MessageQueue(this, (message) => this.handleGenericMessage(message, 'indexes'))
        this.messageQueueMap.set(id, queue)
      }

      queue.push(message)
    } else if (type == "user_task") {
      var id = message.id + ":" + message.type

      var queue = this.messageQueueMap.get(id)
      if (!queue) {
        queue = new MessageQueue(this, (message) => this.handleGenericMessage(message, 'userTasks'))
        this.messageQueueMap.set(id, queue)
      }

      queue.push(message)
    } else if (type == "distribution") {
      var id = message.id + ":" + message.type

      var queue = this.messageQueueMap.get(id)
      if (!queue) {
        queue = new MessageQueue(this, (message) => this.handleGenericMessage(message, 'distributions'))
        this.messageQueueMap.set(id, queue)
      }

      queue.push(message)
    }
  }

  updateMappingInfo(params,response) {
    if (response.data) {
      if (params.instanceId) {
         this.setState({ mappings: this.state.mappings.map(el =>
           el.id !== params.id ? el : {...el, instances: el.instances.map(inst => inst.id !== params.instanceId ? inst : response.data.instances.find(x => inst.id === x.id ) )}
         )})
      } else {
         this.setState({ mappings: this.state.mappings.map(el => el.id !== params.id ? el : response.data) })
      }
    }
  }

  executeAction(action, params) {
    // console.log(action)
    // console.log(params)
    if (action === 'create-mapping') {
        if (params.id && params.id != null) {
          updateMapping(params.id, params.name, params.identifier, params.description, params.data, params.templateId, params.d2rmlId, params.parameters, params.dependecies, params.shaclId, params.active, params.d2rmlIdBound)
            .then(response => {
              this.updateMappingInfo(params, response);
            })
            .catch(error => {
            //   throwToast('error', error.message);
            })
        } else {
          createMapping(params.datasetId, this.state.newMappingType, params.name, params.identifier, params.description, params.group, params.data, params.templateId, params.d2rmlId, params.parameters, params.dependecies, params.shaclId, params.active, params.d2rmlIdBound)
          .then(response => {
              this.setState({ mappings: this.state.mappings.slice().concat(response.data) })
          })
          .catch(error => {
          //   throwToast('error', error.message);
          })
        }
    } else if (action === 'create-file') {
      if (params.id && params.id != null) {
        updateFile(params.id, params.name, params.description, params.data, params.url, params.active)
          .then(response => {
            this.setState( { files: this.state.files.map(el => el.id !== params.id ? el : response.data)} );
          })
          .catch(error => {
          //   throwToast('error', error.message);
          })
      } else {
        createFile(params.name, params.datasetId, params.description, params.group, params.data, params.url, params.active)
          .then(response => {
            this.setState({ files: this.state.files.slice().concat(response.data) })
          })
          .catch(error => {
          //   throwToast('error', error.message);
          })
      }
      this.fileModal.loadingCompleted();
    } else if (action === 'create-prototype') {
      if (params.id && params.id != null) {
        updatePrototype(params.id, params.name, params.description, params.data, params.url, params.parameters, params.dependencies, params.fields, params.parameters)
          .then(response => {
            this.setState( { [params.type.toLowerCase() + 's']: this.state[params.type.toLowerCase() + 's'].map(el => el.id !== params.id ? el : response.data).sort((a,b) => sortByField('name', a, b))} );
          })
          .catch(error => {
          //   throwToast('error', error.message);
          })
      } else {
        createPrototype(params.name, params.datasetId, params.type, params.description, params.data, params.url, params.parameters, params.dependencies, params.fields)
          .then(response => {
            this.setState({ [params.type.toLowerCase() + 's']: this.state[params.type.toLowerCase() + 's'].slice().concat(response.data).sort((a,b) => sortByField('name', a, b)) })
          })
          .catch(error => {
          //   throwToast('error', error.message);
          })
      }
      this.ttlFileModal.loadingCompleted();
    } else if (action === 'update-content') {
      if (params.type === 'D2RML-MAPPING') {
        updateMapping(params.object.id, params.object.name, params.object.identifier, params.object.description, new File([params.ttl], params.object.fileName ? params.object.fileName : ""), null, null, params.parameters, params.dependencies)
          .then(response => {
            this.setState( { mappings: this.state.mappings.map(el => el.id === params.object.id ? response.data : el ),
                             TTLEditorModalShow: false, TTLEditorObject: undefined, TTLEditorType: undefined } );
          })
      } else if (params.type === 'SHACL' || params.type === 'D2RML' || params.type === 'ANNOTATOR') {
        var fullParameters;
        if (params.parameters) {
          var fullParameters = []
          for (var p of params.parameters) {
            fullParameters.push({name : p})
          }
        }

        updatePrototype(params.object.id, params.object.name, params.object.description, new File([params.ttl], "filename"), null, fullParameters,  params.dependencies, params.object.fields)
          .then(response => {
            this.setState( { [params.type.toLowerCase() + 's']: this.state[params.type.toLowerCase() + 's'].map(el => el.id === params.object.id ? response.data : el ),
                             TTLEditorModalShow: false, TTLEditorObject: undefined, TTLEditorType: undefined } );
          })
      }
    } else if (action === 'create-comparator') {
      if (params.id && params.id != null) {
        updateComparator(params.id, params.name, params.identifier, params.description, params.schemaDatasetId, params.indexStructure, params.keysMetadata, params.computation)
          .then(response => {
            this.setState( { comparators: this.state.comparators.map(el => el.id !== params.id ? el : response.data).sort((a,b) => sortByField('name', a, b))} );
          })
          .catch(error => {
          //   throwToast('error', error.message);
          })
      } else {
        createComparator(params.name, params.datasetId, params.identifier, params.description, params.schemaDatasetId, params.indexStructure, params.keysMetadata, params.computation)
          .then(response => {
            this.setState({ comparators: this.state.comparators.slice().concat(response.data).sort((a,b) => sortByField('name', a, b)) })
          })
          .catch(error => {
          //   throwToast('error', error.message);
          })
      }
    } else if (action === 'create-index-structure') {
      if (params.id && params.id != null) {
        updateIndexStructure(params.id, params.name, params.identifier, params.description, params.schemaDatasetId, params.schema, params.elements, params.keysMetadata)
          .then(response => {
            this.setState( { indexStructures: this.state.indexStructures.map(el => el.id !== params.id ? el : response.data).sort((a,b) => sortByField('name', a, b))} );
          })
          .catch(error => {
          //   throwToast('error', error.message);
          })
      } else {
        createIndexStructure(params.name, params.datasetId, params.identifier, params.description, params.schemaDatasetId, params.schema, params.elements, params.keysMetadata)
          .then(response => {
            this.setState({ indexStructures: this.state.indexStructures.slice().concat(response.data).sort((a,b) => sortByField('name', a, b)) })
          })
          .catch(error => {
          //   throwToast('error', error.message);
          })
      }
    } else if (action === 'create-index-def') {
      if (params.id && params.id != null) {
        updateIndex(params.id, params.name, params.indexStructureId, params.indexEngine)
          .then(response => {
            this.setState( { indexes: this.state.indexes.map(el => el.id !== params.id ? el : response.data).sort((a,b) => sortByField('name', a, b))} );
          })
          .catch(error => {
          //   throwToast('error', error.message);
          })
      } else {
        newIndex(params.datasetId, params.name, params.indexStructureId, params.indexEngine)
          .then(response => {
            this.setState({ indexes: this.state.indexes.slice().concat(response.data).sort((a,b) => sortByField('name', a, b)) })
          })
          .catch(error => {
          //   throwToast('error', error.message);
          })
      }
    } else if (action === 'delete-prototype') {
      this.setState({ deleteModalShow:true, deleteModalCommand: 'delete-prototype', deleteModalParams:params})
    } else if (action === 'delete-prototype-ok') {
      deletePrototype(params.id)
        .then(response => {
           throwToast('success', response.message)
          // this.setState({ [params.type.toLowerCase() + 's']: this.state[params.type.toLowerCase() + 's'].filter(el => el.id !== params.id)})
          this.setState({ [params.type.toLowerCase() + 's']: response.data})
        })
        .catch(error => {

        })
    } else if (action === 'delete-comparator') {
      this.setState({ deleteModalShow:true, deleteModalCommand: 'delete-comparator', deleteModalParams:params})
    } else if (action === 'delete-comparator-ok') {
      deleteComparator(params.id)
        .then(response => {
           throwToast('success', response.message)
          // this.setState({ [params.type.toLowerCase() + 's']: this.state[params.type.toLowerCase() + 's'].filter(el => el.id !== params.id)})
          this.setState({ comparators: response.data})
        })
        .catch(error => {

        })
      } else if (action === 'delete-index-structure') {
        this.setState({ deleteModalShow:true, deleteModalCommand: 'delete-index-structure', deleteModalParams:params})
      } else if (action === 'delete-index-structure-ok') {
        deleteIndexStructure(params.id)
          .then(response => {
             throwToast('success', response.message)
            // this.setState({ [params.type.toLowerCase() + 's']: this.state[params.type.toLowerCase() + 's'].filter(el => el.id !== params.id)})
            this.setState({ indexStructures: response.data})
          })
          .catch(error => {

          })
    } else if (action === 'delete-file') {
      this.setState({ deleteModalShow:true, deleteModalCommand: 'delete-file', deleteModalParams:params})
    } else if (action === 'delete-file-ok') {
      deleteFile(params.id)
        .then(response => {
          var open = this.computeCollapses(this.state.mappings, response.data, this.state.indices)
          this.setState({ files: response.data, groupsOpen: open.groupsOpen, mappingsOpen: open.mappingsOpen, filesOpen: open.filesOpen, //indexesOpen: openIndexesOpen
          })
        })
        .catch(error => {

        })
    } else if (action === 'change-file-group') {
        changeFileGroup(params.id, params.group)
          .then(response => {
            var open = this.computeCollapses(this.state.mappings, response.data, this.state.indices)
            this.setState({ files: response.data, groupsOpen: open.groupsOpen, mappingsOpen: open.mappingsOpen, filesOpen: open.filesOpen, //indexesOpen: openIndexesOpen
            })
          })
          .catch(error => {
             //   throwToast('error', error.message);
          })
    } else if (action === 'edit-d2rml-content') {
      this.setState({ TTLEditorDocumentIsMy: params.my, TTLEditorModalShow: true, TTLEditorType: params.type, TTLEditorObject: params.object, TTLEditorState: { loaded: false, loading:true, failed:false }},
        () => {
          getD2RMLDocument(params.object.id)
          .then(response => {
               this.setState({ TTLEditorDocument: response.data, TTLEditorState: { loaded: true, loading:false, failed:false }})
          })
          .catch(error => {

          })
      })
    } else if (action === 'edit-sparql') {
      this.setState({ SPARQLEditorModalShow: true, SPARQLEditorEndpoint: params.endpoint })
    } else if (action === 'edit-prototype-content') {
      this.setState({ TTLEditorDocumentIsMy: params.my, TTLEditorModalShow: true, TTLEditorType: params.type, TTLEditorObject: params.object, TTLEditorState: { loaded: false, loading:true, failed:false }},
        () => {
          getPrototypeContent(params.object.id)
          .then(response => {
               this.setState({ TTLEditorDocument: response.data, TTLEditorState: { loaded: true, loading:false, failed:false }})
          })
          .catch(error => {

          })
      })
    } else if (action === 'execute-mapping') {
        executeMapping(params.id, params.instanceId)
          .then(response => {
             throwToast('success', response.message)
           })
           .catch(error => {
           //   throwToast('error', error.message);
           })

    } else if (action === 'stop-mapping') {
      stopMapping(params.id, params.instanceId)
        .then(response => {
           throwToast('success', response.message);
           this.updateMappingInfo(params, response);
       })
      .catch(error => {
      //     throwToast('error', error.message);
      })
    } else if (action === 'clear-mapping-execution') {
        clearMappingExecution(params.id, params.instanceId)
        .then(response => {
           throwToast('success', response.message);
           this.updateMappingInfo(params, response);
       })
       .catch(error => {
       //    throwToast('error', error.message);
       })
    } else if (action === 'unpublish-mapping') {
       // console.log(this.state.mappings);
       unpublishMapping(params.id, params.instanceId)
          .then(() => {
           }
      )

    } else if (action === 'download-d2rml') {
        downloadMapping(params.id)
    // } else if (action === 'update-name') {
      // console.log(this.state.currentIndex);
      // console.log(value);
      // var currentMapping = {...this.state.mappings[this.state.currentMappingIndex], name:value };
      //
      // this.setState(copyReplaceAt('D2RMLMappings', this.state.mappings, this.state.currentIndex, currentMapping));
    } else if (action === 'delete-attachment') {
        this.setState({ deleteModalShow:true, deleteModalCommand: 'delete-attachment', deleteModalParams:params})
    } else if (action === 'delete-attachment-ok') {
        deleteMappingAttachment(params.id, params.instanceId, params.filename)
          .then(response => {
            this.updateMappingInfo(params, response);
          })
          .catch(error => {
          //   throwToast('error', error.message);
          })
    } else if (action === 'download-attachment') {
        downloadMappingAttachment(params.id, params.instanceId, params.filename)
        .then((response) => {
          if (response.ok) {
            const filename = response.headers.get('content-disposition')
              .split(';')
              .find(n => n.includes('filename='))
              .replace('filename=', '')
              .trim();

              response.blob().then(blob => {
          					let url = window.URL.createObjectURL(blob);
          					let a = document.createElement('a');
          					a.href = url;
          					a.download = filename;
          					a.click();
          				});
            } else {
               Promise.reject(response)
            }},
            () => Promise.reject())
  } else if (action === 'add-attachment') {
      if(params.instanceId == null ) {
        uploadMappingAttachment(params.id, null, params.data)
      .then(response => {
        this.sourceDataFileModal.loadingCompleted()
        this.updateMappingInfo(params, response)
      })
      .catch(error => {
      //   throwToast('error', error.message);
      })
    } else {
      uploadMappingAttachment(params.id, params.instanceId, params.data)
        .then(response => {
          this.sourceDataFileModal.loadingCompleted()
          this.updateMappingInfo(params, response);
        })
        .catch(error => {
        //   throwToast('error', error.message);
        })
      }
    } else if (action === 'delete-mapping') {
        this.setState({ deleteModalShow:true, deleteModalCommand: 'delete-mapping', deleteModalParams:params})
    } else if (action === 'delete-mapping-ok') {
      deleteMapping(params.id)
        .then(response => {
          var open = this.computeCollapses(response.data, this.state.files, this.state.indices)
          this.setState({ mappings: response.data, groupsOpen: open.groupsOpen, mappingsOpen: open.mappingsOpen, filesOpen: open.filesOpen, //indexesOpen: openIndexesOpen
          })
        })
        .catch(error => {
           //   throwToast('error', error.message);
        })
    } else if (action === 'change-mapping-group') {
        changeMappingGroup(params.id, params.group)
          .then(response => {
            var open = this.computeCollapses(response.data, this.state.files, this.state.indices)
            this.setState({ mappings: response.data, groupsOpen: open.groupsOpen, mappingsOpen: open.mappingsOpen, filesOpen: open.filesOpen, //indexesOpen: openIndexesOpen
            })
          })
          .catch(error => {
             //   throwToast('error', error.message);
          })
    } else if (action === 'preview-last-mapping-execution') {
        this.setState({ lastExecution: null, resultsShow:true, resultsState: { title:'Execution result', loaded: false, loading: true, failed: false}}, () =>
          previewLastMappingExecution(params.id, params.instanceId)
            .then(response => {
              response.text().then(text =>  {
                var p = parseResult(text)
                this.setState({ lastExecution: { type:'MAPPING', mode:'LAST_EXECUTION', id:params.id, instanceId:params.instanceId, text: p.text, lines: p.lines, shard: p.shard, nextLine: p.nextLine, file: p.file }, resultsState: {title:'Execution result', loaded: true, loading: false, failed: false} })})},
                () => this.setState({ lastExecution: null, resultsState: {title:'Execution result', loaded: false, loading: false, failed: true}}) )
          )
    } else if (action === 'preview-published-mapping-execution') {
        this.setState({ lastExecution: null, resultsShow:true, resultsState: { title:'Execution result', loaded: false, loading: true, failed: false}}, () =>
          previewPublishedMappingExecution(params.id, params.instanceId)
            .then(response => {
              response.text().then(text => {
                var p = parseResult(text)
                this.setState({ lastExecution: { type:'MAPPING', mode:'PUBLISHED_EXECUTION', id:params.id, instanceId:params.instanceId, text: p.text, lines: p.lines, shard: p.shard, nextLine: p.nextLine, file: p.file }, resultsState: {title:'Execution result', loaded: true, loading: false, failed: false} })})},
                () => this.setState({ lastExecution: null, resultsState: {title:'Execution result', loaded: false, loading: false, failed: true}}) )
          )
    } else if (action === 'download-last-mapping-execution') {
        downloadLastMappingExecution(params.id, params.instanceId)
        .then((response) => {
          if (response.ok) {
            const filename = response.headers.get('content-disposition')
              .split(';')
              .find(n => n.includes('filename='))
              .replace('filename=', '')
              .trim();

              response.blob().then(blob => {
          					let url = window.URL.createObjectURL(blob);
          					let a = document.createElement('a');
          					a.href = url;
          					a.download = filename;
          					a.click();
          				});
            } else {
               Promise.reject(response)
            }},
            () => Promise.reject())
      } else if (action === 'download-published-mapping-execution') {
          downloadPublishedMappingExecution(params.id, params.instanceId)
          .then((response) => {
            if (response.ok) {
              const filename = response.headers.get('content-disposition')
                .split(';')
                .find(n => n.includes('filename='))
                .replace('filename=', '')
                .trim();

                response.blob().then(blob => {
            					let url = window.URL.createObjectURL(blob);
            					let a = document.createElement('a');
            					a.href = url;
            					a.download = filename;
            					a.click();
            				});
              } else {
                 Promise.reject(response)
              }},
              () => Promise.reject())
      } else if (action === 'validate-published-mapping-execution') {
        validatePublishedMappingExecution(params.id, params.instanceId)
        .then(response => {} )
        .catch(error => {} )
      } else if (action === 'view-prefix-mapping') {
          // this.setState({ lastExecution: null, resultsShow:true, resultsState: { loaded: false, loading: true, failed: false}}, () =>
          //   viewPrefixMapping(params.id)
          //     .then(response => {
          //       response.text().then(text =>
          //         this.setState({ lastExecution: { text: text }, resultsState: {loaded: true, loading: false, failed: false} }))},
          //         () => this.setState({ lastExecution: null, resultsState: {loaded: false, loading: false, failed: true}}) )
          //   )
      } else if (action === 'preview-last-file') {
          this.setState({ lastExecution: null, resultsShow:true, resultsState: {title:'File content', loaded: false, loading: true, failed: false}}, () =>
          previewLastFile(params.id)
            .then(response => {
              response.text().then(text =>
                this.setState({ lastExecution: { text: text }, resultsState: {title:'File content', loaded: true, loading: false, failed: false} }))},
                () => this.setState({ lastExecution: null, resultsState: {title:'File content', loaded: false, loading: false, failed: true}}) )
          )
      } else if (action === 'preview-published-file') {
          this.setState({ lastExecution: null, resultsShow:true, resultsState: {title:'File content', loaded: false, loading: true, failed: false}}, () =>
          previewPublishedFile(params.id)
            .then(response => {
              response.text().then(text =>
                this.setState({ lastExecution: { text: text }, resultsState: {title:'File content', loaded: true, loading: false, failed: false} }))},
                () => this.setState({ lastExecution: null, resultsState: {title:'File content', loaded: false, loading: false, failed: true}}) )
          )
    } else if (action === 'download-last-file') {
        downloadLastFile(params.id)
        .then((response) => {
          if (response.ok) {
            const filename = response.headers.get('content-disposition')
              .split(';')
              .find(n => n.includes('filename='))
              .replace('filename=', '')
              .trim();

              response.blob().then(blob => {
          					let url = window.URL.createObjectURL(blob);
          					let a = document.createElement('a');
          					a.href = url;
          					a.download = filename;
          					a.click();
          				});
            } else {
               Promise.reject(response)
            }},
            () => Promise.reject())
      } else if (action === 'download-published-file') {
          downloadPublishedFile(params.id)
          .then((response) => {
            if (response.ok) {
              const filename = response.headers.get('content-disposition')
                .split(';')
                .find(n => n.includes('filename='))
                .replace('filename=', '')
                .trim();

                response.blob().then(blob => {
            					let url = window.URL.createObjectURL(blob);
            					let a = document.createElement('a');
            					a.href = url;
            					a.download = filename;
            					a.click();
            				});
              } else {
                 Promise.reject(response)
              }},
              () => Promise.reject())
      } else if (action === 'add-parameter-binding') {
          this.setState({parameters: this.state.mappings.find(el => el.id == params.id).parameters, mappingId:params.id, instance:null, newBindingModalShow:true})
      } else if (action === 'create-parameter-binding') {
        if (!params.instanceId) {
          createMappingInstance(params.mappingId, params.identifier, params.parameters, params.active)
            .then((json) => {
              this.setState({ mappings: this.state.mappings.map(el => el.id !== params.mappingId ? el : {...el, instances: el.instances.concat(json)}),
                   newBindingModalShow:false})
           })
           .catch(error => {
           //    throwToast('error', error.message)
           })
        } else {
          updateMappingInstance(params.mappingId, params.instanceId, params.identifier, params.parameters, params.active)
            .then((json) => {
              this.setState({ mappings: this.state.mappings.map(el => el.id !== params.mappingId ? el : {...el, instances: el.instances.map(inst => inst.id !== params.instanceId ? inst : json)}),
                   newBindingModalShow:false})
            })
            .catch(error => {
            //   throwToast('error', error.message)
            })
        }
      } else if (action === 'update-parameter-binding') {
        this.setState({parameters: this.state.mappings.find(el => el.id == params.id).parameters, mappingId:params.id, instance: params.instance, newBindingModalShow:true})

          // createMappingParameterBinding(params.id, params.parameters)
          //   .then((json) =>
          //     this.setState({ mappings: this.state.mappings.map(el =>
          //         el.id !== params.id ?
          //            el : {...el, instances: el.instances.concat(json)}),
          //          newBindingModalShow:false})
          // )
      } else if (action === 'delete-parameter-binding') {
          this.setState({ deleteModalShow:true, deleteModalCommand: 'delete-parameter-binding', deleteModalParams:params})
      } else if (action === 'delete-parameter-binding-ok') {
          deleteMapping(params.id, params.instanceId)
            .then(() => {
                this.setState({ mappings: this.state.mappings.map(el =>
                el.id !== params.id ?
                   el : {...el, instances: el.instances.filter(inst => inst.id !== params.instanceId)})})
            })
            .catch(error => {
          //      throwToast('error', error.message)
          })
      } else if (action === 'update-mapping') {
        this.setState({ newMappingModalShow: true, newMappingType: params.mapping.type, newMappingModalMapping: params.mapping})
      } else if (action === 'update-file') {
        this.setState({ newDataModalShow: true, newDataModalFile: params.file})
      } else if (action === 'update-index') {
        this.setState({ newIndexModalShow: true, newIndexModalIndex: params.index})
      } else if (action === 'update-prototype') {
        this.setState({ newTTLFileUploadModalShow: true, newTTLFileUploadModalFile: params.prototype, newTTLFileUploadModalType: params.type})
      } else if (action === 'view-dataset-description') {
          this.setState({ lastExecution: null, resultsShow:true, resultsState: {title:'Dataset description', loaded: false, loading: true, failed: false}}, () =>
            getDatasetDescription(params.id, 'TTL')
              .then(text => {
                  this.setState({ lastExecution: { text: text }, resultsState: {title:'Dataset description', loaded: true, loading: false, failed: false} })},
                  () => this.setState({ lastExecution: null, resultsState: {title:'Dataset description', loaded: false, loading: false, failed: true}}) )
            )
      } else if (action === 'update-comparator') {
        this.setState({ newComparatorModalShow: true, newComparatorModalObject: params.comparator})
      } else if (action === 'update-index-structure') {
        this.setState({ newIndexStructureModalShow: true, newIndexStructureModalObject: params.indexStructure})
      } else if (action === 'delete-index') {
        this.setState({ deleteModalShow:true, deleteModalCommand: 'delete-index', deleteModalParams:params})
      } else if (action === 'delete-index-ok') {
        deleteIndex(params.id)
          .then(response => {
            // this.setState({ indexes: this.state.indexes.filter(el => el.id !== params.id)})
            this.setState({ indexes: response.data ? response.data : []})
          })
          .catch(error => {
        //      throwToast('error', error.message)
        })
      } else if (action === 'delete-distribution') {
        this.setState({ deleteModalShow:true, deleteModalCommand: 'delete-distribution', deleteModalParams:params})
      } else if (action === 'delete-distribution-ok') {
        deleteDistribution(params.id)
          .then(response => {
            this.setState({ distributions: this.state.distributions.filter(el => el.id !== params.id)})
          })
          .catch(error => {
        //      throwToast('error', error.message)
        })
      } else if (action === 'create-index') {
        createIndex(params.id)
          .then(response => {
             throwToast('success', response.message)
           })
           .catch(error => {
           //   throwToast('error', error.message);
           })
        } else if (action === 'recreate-index') {
           recreateIndex(params.id)
             .then(response => {
                throwToast('success', response.message)
              })
              .catch(error => {
              //   throwToast('error', error.message);
              })
       } else if (action === 'create-distribution') {
         createDistribution(params.id)
           .then(response => {
              throwToast('success', response.message)
            })
            .catch(error => {
            //   throwToast('error', error.message);
            })
      } else if (action === 'stop-create-index') {
        stopCreateIndex(params.id)
          .then(response => {
             throwToast('success', response.message);
             // this.setState({ indexes: this.state.indexes.map(el => el.id !== params.id ? el : response.data) })
         })
        .catch(error => {
        //     throwToast('error', error.message);
        })
      } else if (action === 'stop-create-distribution') {
        stopCreateDistribution(params.id)
          .then(response => {
             throwToast('success', response.message);
             // this.setState({ distributions: this.state.distributions.map(el => el.id !== params.id ? el : response.data) })
         })
        .catch(error => {
        //     throwToast('error', error.message);
        })
      } else if (action === 'destroy-index') {
        destroyIndex(params.id)
         .then(response => {
           throwToast('success', response.message)
         })
         .catch(error => {
         //   throwToast('error', error.message);
         })
       } else if (action === 'destroy-distribution') {
         destroyDistribution(params.id)
          .then(response => {
            throwToast('success', response.message)
          })
          .catch(error => {
          //   throwToast('error', error.message);
          })
     // } else if (action === 'change-index-default-state') {
     //   updateIndex(params.id, !this.state.indexes.find(el => el.id == params.id)['default'])
     //   .then(response => {
     //     this.getIndexes(this.props.dataset.id)
     //   })
     //   .catch(error => {
     //   //   throwToast('error', error.message);
     //   })
     } else if (action === 'new-user-task') {
        if (params.id && params.id != null) {
          updateUserTask(params.id, params.name, params.tasks, params.cronExpression, params.freshRunOnly)
           .then(response => {
               this.setState({ userTasks: this.state.userTasks.map(el => el.id !== params.id ? el : response.data).sort((a,b) => sortByField('name', a, b)) })
           })
           .catch(error => {
           //   throwToast('error', error.message);
           })
        } else {
         createUserTask(params.datasetId, params.name, params.tasks, params.cronExpression, params.freshRunOnly)
          .then(response => {
             this.setState({ userTasks: this.state.userTasks.slice().concat(response.data).sort((a,b) => sortByField('name', a, b)) })
          })
          .catch(error => {
          //  throwToast('error', error.message);
          })
      }
    } else if (action === 'new-distribution') {
      if (params.id && params.id != null) {
        updateDistribution(params.id, params.name, params.identifier, params.classes, params.ttl, params.nt, params.serializationVocabulary, params.compress, params.license)
         .then(response => {
             this.setState({ distributions: this.state.distributions.map(el => el.id !== params.id ? el : response.data).sort((a,b) => sortByField('name', a, b)) })
         })
         .catch(error => {
         //   throwToast('error', error.message);
         })
      } else {
       newDistribution(params.datasetId, params.name, params.identifier, params.classes, params.ttl, params.nt, params.serializationVocabulary, params.compress, params.license)
        .then(response => {
           this.setState({ distributions: this.state.distributions.slice().concat(response.data).sort((a,b) => sortByField('name', a, b)) })
        })
        .catch(error => {
        //  throwToast('error', error.message);
        })
      }
    } else if (action === 'update-user-task') {
       this.setState({ newUserTaskModalShow: true, newUserTaskModalUserTask: params.userTask})
     } else if (action === 'update-distribution') {
        this.setState({ newDistributionModalShow: true, newDistributionModalDistribution: params.distribution})
    } else if (action === 'run-user-task') {
       runUserTask(params.id)
        .then(response => {
          throwToast('success', response.message)
        })
        .catch(error => {
        //   throwToast('error', error.message);
        })
      } else if (action === 'delete-user-task') {
        this.setState({ deleteModalShow:true, deleteModalCommand: 'delete-user-task', deleteModalParams:params})
      } else if (action === 'delete-user-task-ok') {
        deleteUserTask(params.id)
          .then(response => {
            // this.setState({ indexes: this.state.indexes.filter(el => el.id !== params.id)})
            this.setState({ userTasks: response.data ? response.data : []})
          })
          .catch(error => {
        //      throwToast('error', error.message)
        })
    } else if (action === 'schedule-user-task') {
      scheduleUserTask(params.id)
       .then(response => {
         this.setState({ userTasks: this.state.userTasks.map(el => el.id !== params.id ? el : response.data).sort((a,b) => sortByField('name', a, b)) })
         throwToast('success', response.message)
       })
       .catch(error => {
       //   throwToast('error', error.message);
       })
   } else if (action === 'unschedule-user-task') {
     unscheduleUserTask(params.id)
      .then(response => {
        this.setState({ userTasks: this.state.userTasks.map(el => el.id !== params.id ? el : response.data).sort((a,b) => sortByField('name', a, b)) })
        throwToast('success', response.message)
      })
      .catch(error => {
      //   throwToast('error', error.message);
      })
    } else if (action === 'move-mapping') {
      changeMappingOrder(params.id, null, params.step)
       .then(response => {
         this.setState({ mappings: response.data })
         // throwToast('success', response.message)
       })
       .catch(error => {
       //   throwToast('error', error.message);
       })
     } else if (action === 'move-file') {
       changeFileOrder(params.id, params.step)
        .then(response => {
          this.setState({ files: response.data })
          // throwToast('success', response.message)
        })
        .catch(error => {
        //   throwToast('error', error.message);
        })
     } else if (action === 'move-index') {
       changeIndexOrder(params.id, params.step)
        .then(response => {
          this.setState({ indexes: response.data })
          // throwToast('success', response.message)
        })
        .catch(error => {
        //   throwToast('error', error.message);
        })
    }

  }

  isPrefixMapping(el) {
    return el.type === 'PREFIX';
  }

  hasExecutions(mappings, files) {

    if (this.props.type === 'CATALOG') {
      return true;
    }

    let executedFound = false;

    var contentMappings = mappings.filter(mapping => mapping.type === "CONTENT")

    contentMappings.forEach((mapping) => {
      for (let inst of mapping.instances) {
        if (inst.executeState.state === "EXECUTED") {
          executedFound = true;
          return;
        }
      }
    })

    let contentFiles = files
    contentFiles.forEach(file => {
      if (file.executeState.state === "EXECUTED") {
        executedFound = true;
        return;
      }
    })

    if (!executedFound) {
      return false
    } else {
      return true;
    }
  }

  hasUnpublishedExecutions(mappings, files) {

    let executedFound = false;

    let contentMappings = mappings.filter(mapping => mapping.type === "CONTENT")

    contentMappings.forEach((mapping) => {
      for (let inst of mapping.instances) {
        if (inst.executeState.state === "EXECUTED" && inst.publishState.state === 'UNPUBLISHED') {
          executedFound = true;
          return;
        }
      }
    })

    let contentFiles = files
    contentFiles.forEach(file => {
      if (file.executeState.state === "EXECUTED" && file.publishState.state === 'UNPUBLISHED') {
        executedFound = true;
        return;
      }
    })

    if (!executedFound) {
      return false
    } else {
      return true;
    }
  }

renderGroup(group, isLocal, groups, multi) {

  var groupMappings = []
  var groupFiles = []
  if (this.state.mappings) {
    groupMappings = this.state.mappings.filter(x => x.group == group)
  }
  if (this.state.files) {
    groupFiles = this.state.files.filter(x => x.group == group)
  }

  return (
    <div className="full-width">
  {isLocal && (this.props.scope === 'COLLECTION' || this.props.scope === 'VOCABULARY' || this.props.scope === 'ALIGNMENT') &&
  <Container className={groupMappings.filter(el => !el.template || el.type === 'CONTENT').length > 0 ? "groupborder" : "groupborder-empty"}>
    <Row className={groupMappings.filter(el => !el.template || el.type === 'CONTENT').length > 0 ? "header" : "header-empty"}>
      <Col className="bold">
        {/*{this.props.dataset.name}: */}
        <span className="crimson-std">Mappings</span>
      </Col>

      {toggleBoxColumn('mappingsOpen', this, null, null, groupMappings, group)}

      <Col className="mybutton" md={1}>
        {actionsMenu(
        <div>
          {this.props.type === 'DATASET' &&
          <Dropdown.Item className="py-2"
                         //disabled={!this.props.my}
                         onClick={(event) => this.setState({ newMappingModalShow: true, newMappingType: 'CONTENT', newMappingGroup: group, newMappingModalMapping: null})}>
            <span className="menu-icon fa fa-plus fa-lg mr-3"/>Add data mapping...
          </Dropdown.Item>}
          {this.props.metadata &&
          <Dropdown.Item className="py-2"
                         disabled={!this.props.my || this.props.dataset.template}
                         onClick={(event) => this.setState({ newMappingModalShow: true, newMappingType: 'HEADER', newMappingGroup: group, newMappingModalMapping:null})}>
            <span className="menu-icon fa fa-plus fa-lg mr-3"/>Add metadata mapping...
          </Dropdown.Item>}

          {isLocal &&
          <div>
            {this.props.type === 'DATASET' && <Dropdown.Divider/>}

            {this.props.type === 'DATASET' &&
            <Dropdown.Item className="py-2"
                           disabled={!this.props.my}
                           onClick={(event) => this.props.actions('execute-all-mappings', {id : this.props.dataset.id, group:group})}>
              <span className="menu-icon fa fa-play fa-lg mr-3" />Execute all
            </Dropdown.Item>}
          </div>}



        </div>)}
      </Col>
    </Row>

    <Collapse in={this.state.mappingsOpen[group]}>
      <div>
    {groupMappings.map((el, index) => {
if (el.template && el.type !== 'CONTENT') {
  return
} else {
  var mappingUrl = API_BASE_URL + "/content/" + (this.props.dataset.identifier ? this.props.dataset.identifier : this.props.dataset.uuid) + "/mapping/" + el.uuid;

  var isUnpublished = true;
  for (var x of el.instances) {
    if (x.publishState.state !== 'UNPUBLISHED') {
      isUnpublished = false
    }
  }

  return (

    (!el.parameters || (el.parameters && el.parameters.length === 0)) ?
    <Container key={index} className={(el.active ? "mappingContainer" : "mappingContainer-inactive") + (el.ownedByUser ? "" : " notOwned")}>
      <Row className="mappingRow">
        <Col md={5} className="">
          <Row className="">
            <Col>
               <span className="mapping-name">{el.name}</span> <span className="mapping-name"><a href={mappingUrl} target="_blank" rel="noopener noreferrer"><span className="fa fa-link ml-2"/></a></span>
            </Col>
          </Row>
          {el.d2rmlName &&
          <Row className="">
            <Col>
               <FontAwesomeIcon className="fa mr-2" icon={faLock}/><span className="parametervalue">{el.d2rmlName}</span>
            </Col>
          </Row>}
          {el.description &&
          <Row>
            <Col>
              <span className="parametervalue">{el.description}</span>
            </Col>
          </Row>}
          <Row>
            <Col>
              <span className="parameter">UUID: </span><span className="parametervalue">{el.uuid}</span>
            </Col>
          </Row>
          {el.identifier &&
          <Row className="">
            <Col>
               <span className="parameter">Identifier</span>: <span className="parametervalue">{el.identifier}</span>
            </Col>
          </Row>}
        </Col>

        <Col md={1} className="tablecenter mappingtype">
          <span>{el.type === "HEADER" ? "META" : (el.type === "CONTENT" ? "DATA" : "PRFX")}</span>
        </Col>

        <Col md={5}/>

        {!this.isPrefixMapping(el) &&
        <Col md={1} className="last-action-column">
          {actionsMenu(
          <div>
            <Dropdown.Item className="py-2" onClick={() => this.executeAction('update-mapping', {mapping: el})}
                           disabled={!el.ownedByUser}>
              <span className="menu-icon fa fa-edit fa-lg mr-3" />Edit...
            </Dropdown.Item>
            <Dropdown.Item className="py-2" onClick={() => this.executeAction('delete-mapping', {id: el.id})}
                           disabled={!el.ownedByUser || el.instances[0].executeState.state === "EXECUTING" || el.instances[0].publishState.state === "PUBLISHED" || el.instances[0].publishState.state === "PUBLISHING"}>
              <span className="menu-icon fa fa-trash-o fa-lg mr-3"/>Delete
            </Dropdown.Item >

            <Dropdown.Divider/>

            <Dropdown.Item className="py-2" onClick={() => this.executeAction('edit-d2rml-content', {object: el, type:'D2RML-MAPPING', my: el.ownedByUser})}
                           // disabled={!this.props.my}
                           >
              <span className="menu-icon fa fa-wrench fa-lg mr-3" />{el.ownedByUser ? 'Edit' : 'View'} D2RML document...
            </Dropdown.Item>

            <Dropdown.Item className="py-2" onClick={() => this.setState({ newSourceDataModalShow: true, newSourceDataModalMapping : el, newSourceDataModalInstance: null})}
                           disabled={!el.ownedByUser}>
              <span className="menu-icon fa fa-paperclip fa-lg mr-3"/>Attach source data file...
            </Dropdown.Item >

            <Dropdown.Divider/>

            <Dropdown.Item className="py-2"
                           disabled={!el.ownedByUser || el.instances[0].executeState.state === "EXECUTING"}
                           onClick={() => this.executeAction('execute-mapping', {id: el.id})}>
              <span className="menu-icon fa fa-play-circle fa-lg mr-3"/>Execute
            </Dropdown.Item>

            <Dropdown.Item className="py-2"
                           disabled={!el.ownedByUser || el.instances[0].executeState.state !== "EXECUTING"}
                           onClick={() => this.executeAction('stop-mapping', { id: el.id })}>
              <span className="menu-icon fa fa-stop fa-lg mr-3"></span>Stop Execution
            </Dropdown.Item>

            <Dropdown.Divider/>

            <Dropdown.Item className="py-2"
                           disabled={el.instances[0].executeState.state !== 'EXECUTED' || (el.instances[0].publishedFromCurrentFileSystem && el.instances[0].publishState.state === 'PUBLISHED' && !el.instances[0].newExecution)}
                           onClick={() => this.executeAction('preview-last-mapping-execution', {id: el.id})}>
              <span className="menu-icon fa fa-eye fa-lg mr-3"/>Preview last execution
            </Dropdown.Item>

            <Dropdown.Item className="py-2"
                           disabled={el.instances[0].executeState.state !== 'EXECUTED' || (el.instances[0].publishedFromCurrentFileSystem && el.instances[0].publishState.state === 'PUBLISHED' && !el.instances[0].newExecution)}
                           onClick={() => this.executeAction('download-last-mapping-execution', {id: el.id})}>
              <span className="menu-icon fa fa-download fa-lg mr-3"/>Download last execution
            </Dropdown.Item>


            <Dropdown.Item className="py-2"
                           disabled={!el.ownedByUser || el.instances[0].executeState.state !== 'EXECUTED' || (el.instances[0].publishedFromCurrentFileSystem && el.instances[0].publishState.state === 'PUBLISHED' && !el.instances[0].newExecution)}
                           onClick={() => this.executeAction('clear-mapping-execution', {id: el.id})}>
              <span className="menu-icon fa fa-times fa-lg mr-3" />Clear last execution
            </Dropdown.Item>

            <Dropdown.Item className="py-2"
                          disabled={!el.ownedByUser || el.instances[0].executeState.state !== 'EXECUTED' || (el.instances[0].publishedFromCurrentFileSystem && el.instances[0].publishState.state === 'PUBLISHED' && !el.instances[0].newExecution)}
                          onClick={() => this.executeAction('validate-published-mapping-execution', {id: el.id})}>
              <span className="menu-icon fa fa-eye fa-lg mr-3"/>Validate  last execution
            </Dropdown.Item>

            <Dropdown.Divider/>

            <Dropdown.Item className="py-2"
                           disabled={!el.instances[0].publishedFromCurrentFileSystem}
                           onClick={() => this.executeAction('preview-published-mapping-execution', {id: el.id})}>
                           <span className="menu-icon fa fa-eye fa-lg mr-3"/>Preview published execution
            </Dropdown.Item>

            <Dropdown.Item className="py-2"
                           disabled={!el.instances[0].publishedFromCurrentFileSystem}
                           onClick={() => this.executeAction('download-published-mapping-execution', {id: el.id})}>
              <span className="menu-icon fa fa-download fa-lg mr-3"/>Download published execution
            </Dropdown.Item>

            <Dropdown.Divider/>

            <Dropdown.Item className="py-2"
                           disabled={!el.ownedByUser || index == 0}
                           onClick={() => this.executeAction('move-mapping', {id: el.id, step:-1})}>
              <span className="menu-icon fa fa-chevron-up fa-lg mr-3"/>Move up
            </Dropdown.Item>

            <Dropdown.Item className="py-2"
                            disabled={!el.ownedByUser || index == groupMappings.length - 1}
                           onClick={() => this.executeAction('move-mapping', {id: el.id, step:1})}>
              <span className="menu-icon fa fa-chevron-down fa-lg mr-3"/>Move down
            </Dropdown.Item>

            {this.props.dataset.multiGroup &&
            <div>
              <Dropdown.Divider/>

              <Dropdown.Item className="py-2"
                             disabled={!this.props.my || !el.ownedByUser || group == 0 || !isUnpublished}
                             onClick={() => this.executeAction('change-mapping-group', {id: el.id, group:group-1})}>
                <span className="menu-icon fa fa-chevron-up fa-lg mr-3"/>Move to previous group
              </Dropdown.Item>

              <Dropdown.Item className="py-2"
                              disabled={!this.props.my || !el.ownedByUser || (group == groups.length - 1 && groupMappings.length <= 1) || !isUnpublished}
                             onClick={() => this.executeAction('change-mapping-group', {id: el.id, group:group+1})}>
                <span className="menu-icon fa fa-chevron-down fa-lg mr-3"/>Move to next group
              </Dropdown.Item>
            </div>}

            {/*<Dropdown.Item className="py-2"
                onClick={() => this.executeAction('unpublish-mapping', {id: el.id})}><span className="fa fa-plus"></span>
            </Dropdown.Item>*/}

          </div>)}
        </Col>}
      </Row>
      {!this.isPrefixMapping(el) &&
      <Row>
        <Col md={5}/>

        <Col md={6} className="tablecenter">
          <Row className="stategroup">
            <Col>
              <Row>
                <Col>
                  <ExecutableState value={el.instances[0].executeState}/>
                </Col>
              </Row>
              {el.instances[0].validateState && el.instances[0].validateState.state != 'NOT_VALIDATED' &&
              <Row>
                <Col>
                  <ValidatableState value={el.instances[0].validateState}/>
                </Col>
              </Row>}
              <Row>
                <Col>
                  <PublishableState value={el.instances[0].publishState} executeValue={el.instances[0].executeState}/>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>}
      {el.dataFiles && el.dataFiles.length > 0 &&
      <Row className="attachements-row">
        <Col>
          {el.dataFiles.map((file, index) =>
          <Row key={index} className="attachement-row">
            <Col className="">
              {file}
            </Col>
            <Col md={1} className="last-action-column">
              <DropdownButton size="sm"  title={<span title="Actions" className='fa fa-bars'></span>} className="actions">

                <Dropdown.Item className="py-2" onClick={() => this.executeAction('delete-attachment', {id: el.id, instanceId: null, filename:file})}
                               disabled={!el.ownedByUser}>
                  <span className="menu-icon fa fa-trash-o fa-lg mr-3" />Delete...
                </Dropdown.Item>

                <Dropdown.Item className="py-2" onClick={() => this.executeAction('download-attachment', {id: el.id, instanceId: null, filename:file})}
                               // disabled={!el.ownedByUser}
                               >
                  <span className="menu-icon fa fa-download fa-lg mr-3" />Download
                </Dropdown.Item>

              </DropdownButton>
            </Col>
          </Row>)}
        </Col>
      </Row>}
    </Container>
    :
    <Row key={index} className={(el.active ? "mappingRowGroup" : "mappingRowGroup-inactive") + (el.ownedByUser ? "" : " notOwned")}>
      <Col className="mappingRowGroupColumn">
        <Row key={index} className="mappingRowGroupHeader">
          <Col md={5} className="">
            <Row className="">
              <Col>
                 <span className="mapping-name">{el.name}</span> <span className="mapping-name"><a href={mappingUrl} target="_blank" rel="noopener noreferrer"><span className="fa fa-link ml-2"/></a></span>
              </Col>
            </Row>
            {el.d2rmlName &&
            <Row className="">
              <Col>
                 <FontAwesomeIcon className="fa mr-2" icon={faLock}/><span className="parametervalue">{el.d2rmlName}</span>
              </Col>
            </Row>}
            {el.description &&
            <Row>
              <Col>
                <span className="parametervalue">{el.description}</span>
              </Col>
            </Row>}
            <Row>
              <Col>
                <span className="parameter">UUID: </span><span className="parametervalue">{el.uuid}</span>
              </Col>
            </Row>
            {el.identifier &&
            <Row className="">
              <Col>
                 <span className="parameter">Identifier</span>: <span className="parametervalue">{el.identifier}</span>
              </Col>
            </Row>}
          </Col>

          <Col md={1} className="tablecenter mappingtype">
            <span>{el.type === "HEADER" ? "META" : "DATA"}</span>
            {el.template &&
              <div className="data-template">TEMPLATE</div>}
          </Col>

          <Col md="5"/>

          {!el.template &&
          <Col md={1} className="last-action-column">
            {actionsMenu(
            <div>
              <Dropdown.Item className="py-2"
                             disabled={!el.ownedByUser}
                             onClick={() => this.executeAction('update-mapping', {mapping: el})}>
                <span className="menu-icon fa fa-edit fa-lg mr-3" />Edit...
              </Dropdown.Item>

              <Dropdown.Item className="py-2"
                             disabled={!el.ownedByUser}
                             onClick={() => this.executeAction('delete-mapping', {id: el.id})}>
                <span className="menu-icon fa fa-trash-o fa-lg mr-3"/>Delete
              </Dropdown.Item >

              <Dropdown.Divider/>

              <Dropdown.Item className="py-2"
                             disabled={!el.ownedByUser}
                             onClick={() => this.executeAction('add-parameter-binding', {id: el.id, index: index})}>
                <span className="menu-icon fa fa-plus fa-lg mr-3"/>Add instance...
              </Dropdown.Item >

              <Dropdown.Divider/>

              <Dropdown.Item className="py-2"
                             // disabled={!this.props.my}
                             onClick={() => this.executeAction('edit-d2rml-content', {object: el, type: 'D2RML-MAPPING', my:el.ownedByUser})}
                             >
                <span className="menu-icon fa fa-wrench fa-lg mr-3" />{el.ownedByUser ? 'Edit' : 'View'} D2RML document...
              </Dropdown.Item>

              <Dropdown.Item className="py-2"
                             disabled={!el.ownedByUser}
                             onClick={() => this.setState({ newSourceDataModalShow: true, newSourceDataModalMapping : el, newSourceDataModalInstance: null})}>
                <span className="menu-icon fa fa-paperclip fa-lg mr-3"/>Attach source data file...
              </Dropdown.Item >

              <Dropdown.Divider/>

              <Dropdown.Item className="py-2"
                             disabled={!el.ownedByUser || index == 0}
                             onClick={() => this.executeAction('move-mapping', {id: el.id, step:-1})}>
                <span className="menu-icon fa fa-chevron-up fa-lg mr-3"/>Move up
              </Dropdown.Item>

              <Dropdown.Item className="py-2"
                              disabled={!el.ownedByUser || index == groupMappings.length - 1}
                             onClick={() => this.executeAction('move-mapping', {id: el.id, step:1})}>
                <span className="menu-icon fa fa-chevron-down fa-lg mr-3"/>Move down
              </Dropdown.Item>

              {this.props.dataset.multiGroup &&
              <div>
                <Dropdown.Divider/>

                <Dropdown.Item className="py-2"
                               disabled={!this.props.my || !el.ownedByUser || group == 0 || !isUnpublished}
                               onClick={() => this.executeAction('change-mapping-group', {id: el.id, group:group-1})}>
                  <span className="menu-icon fa fa-chevron-up fa-lg mr-3"/>Move to previous group
                </Dropdown.Item>

                <Dropdown.Item className="py-2"
                                disabled={!this.props.my || !el.ownedByUser || (group == groups.length - 1 && groupMappings.length == 1) || !isUnpublished}
                               onClick={() => this.executeAction('change-mapping-group', {id: el.id, group:group+1})}>
                  <span className="menu-icon fa fa-chevron-down fa-lg mr-3"/>Move to next group
                </Dropdown.Item>
              </div>}
            </div>)}
          </Col>}

          {el.template &&
          <Col md={1} className="last-action-column">
            <Button type="button" className="menubutton"
                    disabled={!el.ownedByUser}
                    onClick={() => this.executeAction('add-parameter-binding', { id: el.id, index: index })}>
              <span className="fa fa-plus fa-sm mr-2" />
            </Button>
          </Col>}
        </Row>
        {el.instances.map((inst, iindex) =>
          <Row key={iindex}>
            <Col>
              <Container key={iindex} className={(inst.active ? "mappingInstanceRow" : "mappingInstanceRow-inactive") + (el.ownedByUser ? "" : " notOwned")}>
                <Row className="mappingInstanceRowData">

                  <Col md={5} className="instance-parameters-column">
                    {inst.identifier &&
                    <Row className="instance-parameter">
                      <Col>
                         <span className="parameter">Identifier</span>: <span className="parametervalue">{inst.identifier}</span>
                      </Col>
                    </Row>}

                    {inst.binding.map((binding, bindex) =>
                    <Row key={bindex} className="instance-parameter">
                      <Col>
                        <span className="parameter">{binding.name}</span>: <span className="parametervalue mb-0">{binding.value}</span>
                      </Col>
                    </Row>)}
                  </Col>

                  <Col md={6} className="tablecenter">
                    <Row className="stategroup">
                      <Col>
                        <Row>
                          <Col>
                            <ExecutableState value={inst.executeState}/>
                          </Col>
                        </Row>
                        {inst.validateState && inst.validateState.state != 'NOT_VALIDATED' &&
                        <Row>
                          <Col>
                            <ValidatableState value={inst.validateState}/>
                          </Col>
                        </Row>}
                        <Row>
                          <Col>
                            <PublishableState value={inst.publishState} executeValue={inst.executeState}/>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Col>

                  <Col md={1} className="">
                    {actionsMenu(
                    <div>
                        {/* <Dropdown.Item onClick={() => this.executeAction('update-mapping', {mapping: el})}>Edit</Dropdown.Item>*/}
                        <Dropdown.Item className="py-2"
                                       disabled={!el.ownedByUser}
                                       onClick={() => this.executeAction('update-parameter-binding', {id: el.id, instance: inst, mappingIndex:index, index:iindex})}>
                          <span className="menu-icon fa fa-edit fa-lg mr-3"/>Edit
                        </Dropdown.Item>

                      <Dropdown.Item className="py-2"
                                     disabled={!el.ownedByUser || inst.executeState.state === "EXECUTING" || inst.publishState.state === "PUBLISHED" || inst.publishState.state === "PUBLISHING"}
                                     onClick={() => this.executeAction('delete-parameter-binding', {id: el.id, instanceId: inst.id, index:iindex})}>
                        <span className="menu-icon fa fa-trash-o fa-lg mr-3"/>Delete
                      </Dropdown.Item>

                      <Dropdown.Divider/>

                      <Dropdown.Item className="py-2"
                                     disabled={!el.ownedByUser}
                                     onClick={() => this.setState({ newSourceDataModalShow: true, newSourceDataModalMapping : el, newSourceDataModalInstance: inst})}>
                        <span className="menu-icon fa fa-paperclip fa-lg mr-3"/>Attach source data file...
                      </Dropdown.Item >

                      <Dropdown.Divider/>

                      <Dropdown.Item className="py-2"
                                     disabled={!el.ownedByUser || inst.executeState.state === "EXECUTING"}
                                     onClick={() => this.executeAction('execute-mapping', {id: el.id, instanceId: inst.id})}>
                        <span className="menu-icon fa fa-play-circle fa-lg mr-3"/>Execute
                      </Dropdown.Item>

                      <Dropdown.Item className="py-2"
                                     disabled={!el.ownedByUser || inst.executeState.state !== "EXECUTING"}
                                     onClick={() => this.executeAction('stop-mapping', {id: el.id, instanceId: inst.id})}>
                        <span className="menu-icon fa fa-stop fa-lg mr-3"></span>Stop Execution
                      </Dropdown.Item>

                      <Dropdown.Divider/>

                      <Dropdown.Item className="py-2"
                                    disabled={inst.executeState.state !== 'EXECUTED' || (inst.publishedFromCurrentFileSystem && inst.publishState.state === 'PUBLISHED' && !inst.newExecution)}
                                    onClick={() => this.executeAction('preview-last-mapping-execution', {id: el.id, instanceId: inst.id})}>
                        <span className="menu-icon fa fa-eye fa-lg mr-3"/>Preview last execution
                      </Dropdown.Item>

                      <Dropdown.Item className="py-2"
                                    disabled={inst.executeState.state !== 'EXECUTED' || (inst.publishedFromCurrentFileSystem && inst.publishState.state === 'PUBLISHED' && !inst.newExecution)}
                                    onClick={() => this.executeAction('download-last-mapping-execution', {id: el.id, instanceId: inst.id})}>
                        <span className="menu-icon fa fa-download fa-lg mr-3"/>Download last execution
                      </Dropdown.Item>

                      <Dropdown.Item className="py-2"
                                    disabled={!el.ownedByUser || inst.executeState.state !== 'EXECUTED' || (inst.publishedFromCurrentFileSystem && inst.publishState.state === 'PUBLISHED' && !inst.newExecution)}
                                    onClick={() => this.executeAction('clear-mapping-execution', {id: el.id, instanceId: inst.id})}>
                        <span className="menu-icon fa fa-times fa-lg mr-3" />Clear last execution
                      </Dropdown.Item>

                      <Dropdown.Item className="py-2"
                                    disabled={!el.ownedByUser || inst.executeState.state !== 'EXECUTED' || (inst.publishedFromCurrentFileSystem && inst.publishState.state === 'PUBLISHED' && !inst.newExecution)}
                                    onClick={() => this.executeAction('validate-published-mapping-execution', {id: el.id, instanceId: inst.id})}>
                        <span className="menu-icon fa fa-times fa-lg mr-3" />Validate published execution
                      </Dropdown.Item>


                      <Dropdown.Divider/>

                      <Dropdown.Item className="py-2"
                                    disabled={!inst.publishedFromCurrentFileSystem}
                                    onClick={() => this.executeAction('preview-published-mapping-execution', {id: el.id, instanceId: inst.id})}>
                        <span className="menu-icon fa fa-eye fa-lg mr-3"/>Preview published execution
                      </Dropdown.Item>

                      <Dropdown.Item className="py-2"
                                    disabled={!inst.publishedFromCurrentFileSystem}
                                    onClick={() => this.executeAction('download-published-mapping-execution', {id: el.id, instanceId: inst.id})}>
                        <span className="menu-icon fa fa-download fa-lg mr-3"/>Download published execution
                      </Dropdown.Item>

                      <Dropdown.Item className="py-2"
                                    disabled={!el.ownedByUser || inst.executeState.state !== 'EXECUTED' || (inst.publishedFromCurrentFileSystem && inst.publishState.state === 'PUBLISHED' && !inst.newExecution)}
                                    onClick={() => this.executeAction('validate-published-mapping-execution', {id: el.id, instanceId: inst.id})}>
                        <span className="menu-icon fa fa-times fa-lg mr-3" />Validate published execution
                      </Dropdown.Item>

                    </div>)}
                  </Col>
                </Row>

                {inst.dataFiles && inst.dataFiles.length > 0 &&
                <Row className="attachements-row">
                  <Col>
                    {inst.dataFiles.map((file, findex) =>
                    <Row key={findex} className="attachement-row">
                      <Col className="">
                        {file}
                      </Col>
                      <Col md={1} className="last-action-column">
                        {actionsMenu(
                        <div>
                          <Dropdown.Item className="py-2"
                                         disabled={!el.ownedByUser}
                                         onClick={() => this.executeAction('delete-attachment', {id: el.id, instanceId: inst.id, filename:file})}>
                            <span className="menu-icon fa fa-trash-o fa-lg mr-3" />Delete...
                          </Dropdown.Item>

                          <Dropdown.Item className="py-2" onClick={() => this.executeAction('download-attachment', {id: el.id, instanceId: inst.id, filename:file})}
                                         // disabled={!el.ownedByUser}
                                         >
                            <span className="menu-icon fa fa-download fa-lg mr-3" />Download
                          </Dropdown.Item>

                        </div>)}
                      </Col>
                    </Row>)}
                  </Col>
                </Row>}
            </Container>
          </Col>
        </Row>
      )}

      {el.dataFiles && el.dataFiles.length > 0 &&
      <Row className="attachements-row">
        <Col>
          {el.dataFiles.map((file, index) =>
          <Row key={index} className="attachement-row">
            <Col className="">
              {file}
            </Col>
            <Col md={1} className="last-action-column">
              <DropdownButton size="sm"  title={<span title="Actions" className='fa fa-bars'></span>} className="actions">

                <Dropdown.Item className="py-2" onClick={() => this.executeAction('delete-attachment', {id: el.id, instanceId: null, filename:file})}
                               disabled={!el.ownedByUser}>
                  <span className="menu-icon fa fa-trash-o fa-lg mr-3" />Delete...
                </Dropdown.Item>

                <Dropdown.Item className="py-2" onClick={() => this.executeAction('download-attachment', {id: el.id, instanceId: null, filename:file})}
                               // disabled={!el.ownedByUser}
                               >
                  <span className="menu-icon fa fa-download fa-lg mr-3" />Download
                </Dropdown.Item>

              </DropdownButton>
            </Col>
          </Row>)}
        </Col>
      </Row>}
      </Col>
    </Row>
  )}
})}
</div>
    </Collapse>
  </Container>}

  {this.props.my && this.props.type === 'DATASET' && isLocal && (this.props.scope === 'COLLECTION' || this.props.scope === 'VOCABULARY' || this.props.scope === 'ALIGNMENT') &&
  <Container className={groupFiles.length > 0 ? "groupborder" : "groupborder-empty"}>
    <Row className={groupFiles.length > 0 ? "header" : "header-empty"}>
      <Col className="bold">
        {/*{this.props.dataset.name}: */}
        <span className="crimson-std">RDF Data</span>
      </Col>

      {toggleBoxColumn('filesOpen', this, null, null, groupFiles, group)}

      <Col className="mybutton" md={1}>
        {actionsMenu(
        <div>
          <Dropdown.Item className="py-2"
                         // disabled={!this.props.my}
                         onClick={(event) => this.setState({ newDataModalShow: true, newDataModalGroup: group, newDataModalFile: undefined})}>
            <span className="menu-icon fa fa-plus fa-lg mr-3"/>Add data file...
          </Dropdown.Item>

        </div>)}
      </Col>
    </Row>

    <Collapse in={this.state.filesOpen[group]}>
      <div>
        {groupFiles.map((el, index) => {

          var isUnpublished = el.publishState.state == 'UNPUBLISHED';

        return (
        <Row key={index} className="mappingRow">
          <Col md={4} className="">
            <Row>
              <Col>
                <span className="mapping-name">{el.name}</span>
              </Col>
            </Row>
            {el.description &&
            <Row>
              <Col>
                <span className="parametervalue">{el.description}</span>
              </Col>
            </Row>}
{/*                  <Row>
              <Col>
                <span>{el.fileName}</span>
              </Col>
            </Row> */}
          </Col>

          <Col md={1} className="tablecenter mappingtype">
            <span>DATA</span>
          </Col>

          <Col md={6} className="tablecenter">
            <Row className="stategroup">
              <Col>
                <Row>
                  <Col>
                    <ExecutableState value={el.executeState}/>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <PublishableState value={el.publishState} executeValue={el.executeState}/>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>

          <Col md={1} className="">
            {actionsMenu(
            <div>
              <Dropdown.Item className="py-2"
                             disabled={!el.ownedByUser}
                             onClick={() => this.executeAction('update-file', {file: el})}>
                <span className="menu-icon fa fa-edit fa-lg mr-3" />Edit...
              </Dropdown.Item>

              <Dropdown.Item className="py-2"
                             disabled={!el.ownedByUser}
                             onClick={() => this.executeAction('delete-file', {id: el.id})}>
                <span className="menu-icon fa fa-trash-o fa-lg mr-3"/>Delete
              </Dropdown.Item>

              <Dropdown.Divider/>

              <Dropdown.Item className="py-2"
                             disabled={el.publishState.state === 'PUBLISHED' && !el.newExecution}
                             onClick={() => this.executeAction('preview-last-file', {id: el.id})}>
                <span className="menu-icon fa fa-eye fa-lg mr-3"/>Preview file
              </Dropdown.Item>

              <Dropdown.Item className="py-2"
                             disabled={el.publishState.state === 'PUBLISHED' && !el.newExecution}
                             onClick={() => this.executeAction('download-last-file', {id: el.id})}>
                <span className="menu-icon fa fa-download fa-lg mr-3"/>Download file
              </Dropdown.Item>

              <Dropdown.Divider/>

              <Dropdown.Item className="py-2"
                             disabled={!el.publishedFromCurrentFileSystem}
                             onClick={() => this.executeAction('preview-published-file', {id: el.id})}>
                <span className="menu-icon fa fa-eye fa-lg mr-3"/>Preview published file
              </Dropdown.Item>

              <Dropdown.Item className="py-2"
                             disabled={!el.publishedFromCurrentFileSystem}
                             onClick={() => this.executeAction('download-published-file', {id: el.id})}>
                <span className="menu-icon fa fa-download fa-lg mr-3"/>Download published file
              </Dropdown.Item>

              <Dropdown.Divider/>

              <Dropdown.Item className="py-2"
                             disabled={!el.ownedByUser || index == 0}
                             onClick={() => this.executeAction('move-file', {id: el.id, step:-1})}>
                <span className="menu-icon fa fa-chevron-up fa-lg mr-3"/>Move up
              </Dropdown.Item>

              <Dropdown.Item className="py-2"
                              disabled={!el.ownedByUser || index == groupFiles.length - 1}
                             onClick={() => this.executeAction('move-file', {id: el.id, step:1})}>
                <span className="menu-icon fa fa-chevron-down fa-lg mr-3"/>Move down
              </Dropdown.Item>

              {this.props.dataset.multiGroup &&
              <div>
                <Dropdown.Divider/>

                <Dropdown.Item className="py-2"
                               disabled={!this.props.my || !el.ownedByUser || group == 0 || !isUnpublished}
                               onClick={() => this.executeAction('change-file-group', {id: el.id, group:group-1})}>
                  <span className="menu-icon fa fa-chevron-up fa-lg mr-3"/>Move to previous group
                </Dropdown.Item>

                <Dropdown.Item className="py-2"
                                disabled={!this.props.my || !el.ownedByUser || (group == groups.length - 1 && groupFiles.length <= 1) || !isUnpublished}
                               onClick={() => this.executeAction('change-file-group', {id: el.id, group:group+1})}>
                  <span className="menu-icon fa fa-chevron-down fa-lg mr-3"/>Move to next group
                </Dropdown.Item>
              </div>}


            </div>)}
          </Col>

        </Row>)}
      )}
      </div>
    </Collapse>
  </Container>}
  </div>
)
}

  render() {
    var supportsIndexing = this.props.database.hasOwnProperty('indexEngines');

    var published = this.props.dataset.publishState.state.startsWith("PUBLISHED");
    var indexed = this.props.dataset.indexState === "INDEXED";

    var hasExecutions = this.hasExecutions(this.state.mappings, this.state.files);
    var hasUnpublishedExecutions = this.hasUnpublishedExecutions(this.state.mappings, this.state.files);

    var isLocal = !this.props.dataset.remoteTripleStore;

    var maxGroup = 0;
    for (var d of this.state.mappings) {
      maxGroup = Math.max(maxGroup, d.group);
    }
    for (var d of this.state.files) {
      maxGroup = Math.max(maxGroup, d.group);
    }
    var groups = [];
    for (var i = 0; i <= maxGroup; i++) {
      groups.push(i);
    }

    return (
      <Container className="userspace-right">
        <Row className=" userspace-right-inner">
          <Col>
            <Container className="groupborder">
              <Row className="header">
                <Col md={5} className="bold">
                  {this.props.type==='DATASET' && <span className="crimson-std">Dataset: </span>}
                  {this.props.type==='CATALOG' && <span className="crimson-std">Catalog: </span>}
                  {this.props.type==='SCHEMA' && <span className="crimson-std">Schema: </span>}
                   {this.props.dataset.name}
                </Col>
                <Col md={6}>
                  {this.props.scope != 'ANNOTATOR' && this.props.scope != 'COMPARATOR' && this.props.scope != 'D2RML' && this.props.scope != 'SHACL' && this.props.scope != 'INDEX' &&
                  <PublishableState2 value={this.props.dataset.publishState} showTripleStore={true}/>}
                </Col>
                <Col className="mybutton" md={1}>
                  {actionsMenu(
                  <div>
                    <Dropdown.Item className="py-2"
                                   disabled={!this.props.my}
                                   onClick={() => this.props.actions('update-dataset', {id : this.props.dataset.id, type: this.props.type, scope: this.props.scope})}>
                      <span className="menu-icon fa fa-edit fa-lg mr-3" />Edit...
                    </Dropdown.Item>

                    <div data-tip={!(!published && !indexed && (this.props.type==='DATASET' || (this.props.type === 'CATALOG' && this.props.dataset.datasets && this.props.dataset.datasets.length === 0)))
                        ? "You cannot delete a published dataset" : null}
                        data-place="left" data-effect="solid">
                      <Dropdown.Item className="py-2"
                                     disabled={!this.props.my || (!(!published && !indexed && (this.props.type==='DATASET' || (this.props.type === 'CATALOG' && this.props.dataset.datasets && this.props.dataset.datasets.length === 0))))}
                                     onClick={() => this.props.actions('delete-dataset', {id: this.props.dataset.id, type: this.props.type})}>
                        <span className="menu-icon fa fa-trash-o fa-lg mr-3" />Delete
                      </Dropdown.Item>
                    </div>
                    <ReactTooltip delay={200} />

                    <Dropdown.Divider/>

                    <Dropdown.Item className="py-2"
                                  disabled={!this.props.my}
                                   onClick={() => this.setState({addToProjectModalShow : true})}>
                      <span className="menu-icon fa fa-plus fa-lg mr-3" />Add to project...
                    </Dropdown.Item>
  {/*                  {this.props.type === 'dataset' && <Dropdown.Item  onClick={(event) => this.setState({ newMappingModalShow: true, newMappingType: 'PREFIX', newMappingModalMapping: null})}>Add Prefix File</Dropdown.Item>} */}


                    {this.props.scope !== 'SHACL' && this.props.scope !== 'D2RML' && this.props.scope !== 'ANNOTATOR' && this.props.scope !== 'COMPARATOR' && this.props.scope !== 'INDEX' &&
                    <div>

                    <Dropdown.Divider/>

                    {//!published && <div data-tip={!hasExecutions ? "There is nothing executed to publish" : null} data-place="left" data-effect="solid">
                    <div>
                      <Dropdown.Item className="py-2"
                        disabled={!this.props.my || (isLocal && (published || this.props.dataset.publishState.state == 'PUBLISHING' || this.props.dataset.publishState.state == 'UNPUBLISHING' || !hasExecutions))}
                        onClick={(event) => this.setState({ publishModalShow: true, publishModalGroup: undefined})}>
                        <span className="menu-icon fa fa-calendar-check-o fa-lg mr-3"/>Publish...
                      </Dropdown.Item>
                    </div>}

                    {this.props.type === 'DATASET' && isLocal &&
                    <Dropdown.Item className="py-2"
                           disabled={!this.props.my || (!published)}
                           onClick={(event) => this.props.actions('republish-dataset', {id : this.props.dataset.id, group: undefined})}>
                      <span className="menu-icon fa fa-calendar fa-lg mr-3"/>Republish (content & metadata)
                    </Dropdown.Item>}

                    {isLocal &&
                    <Dropdown.Item className="py-2"
                         disabled={!this.props.my || (!published || !hasUnpublishedExecutions) }
                         onClick={(event) => this.props.actions('publish-dataset-unpublished-content', {id : this.props.dataset.id, group: undefined})}>
                      <span className="menu-icon fa fa-calendar-check-o fa-lg mr-3"/>Republish (unpublished content & metadata)
                    </Dropdown.Item>}

                    {(true || this.props.metadata) && (published || !published) && isLocal &&
                    <Dropdown.Item className="py-2"
                           disabled={!this.props.my || (!published)}
                           onClick={(event) => this.props.actions('republish-dataset-metadata', {id : this.props.dataset.id, group: undefined})}>
                      <span className="menu-icon fa fa-calendar fa-lg mr-3"/>Republish (only metadata)
                    </Dropdown.Item>}

                    <Dropdown.Item className="py-2"
                             onClick={(event) => this.props.actions('unpublish-dataset', {id : this.props.dataset.id, type:this.props.type, group: undefined})}
                             disabled={!this.props.my}>
                      <span className="menu-icon fa fa-calendar-times-o fa-lg mr-3"/>Unpublish
                    </Dropdown.Item>

                    <Dropdown.Divider/>

                    <Dropdown.Item className="py-2"
                                   disabled={!published}
                                   onClick={() => this.executeAction('view-dataset-description', {id: this.props.dataset.id})}>
                        <span className="menu-icon fa fa-info fa-lg mr-3" />View dataset description
                    </Dropdown.Item>

                    <Dropdown.Divider/>

                    <Dropdown.Item className="py-2"
                          disabled={!published}
                          onClick={() => this.executeAction('edit-sparql', {endpoint: API_BASE_URL + "/content/" + this.props.dataset.uuid + "/sparql"})}>
                       <span className="menu-icon fa fa-cube fa-lg mr-3" />Query with SPARQL...
                    </Dropdown.Item>

                    <Dropdown.Item className="py-2"
                                   disabled={!published}
                                   onClick={() => { navigator.clipboard.writeText(API_BASE_URL + "/content/" + this.props.dataset.uuid + "/sparql")}}>
                        <span className="menu-icon fa fa-clipboard fa-lg mr-3" />Copy SPARQL endpoint to clipboard
                    </Dropdown.Item>

                    </div>}

                  </div>)}



                  {/*this.state.createDistributionModalShow &&
                  <CreateDistributionModal show={this.state.createDistributionModalShow}
                            dataset={this.props.dataset}
                            onOK={(classes, ttl, nt, serializationVocabulary, compress, license) => { this.props.actions('create-dataset-distribution', {id: this.props.dataset.id, type: this.props.type, classes:classes, ttl: ttl, nt: nt, serializationVocabulary, compress: compress, license }); this.setState({ createDistributionModalShow: false }) }}
                            onClose={() => this.setState({ createDistributionModalShow: false })}/>*/}

                  {this.state.newMappingModalShow &&
                  <NewMappingUploadModal show={this.state.newMappingModalShow}
                            type={this.state.newMappingType}
                            database={this.props.database}
                            dataset={this.props.dataset}
                            mapping={this.state.newMappingModalMapping}
                            uuid={this.props.dataset.uuid}
                            templates={this.props.templates}
                            shaclShapes={this.props.shaclShapes}
                            onOK={(id, name, identifier, description, data, templateId, d2rmlId, parameters, dependencies, shaclId, active, d2rmlIdBound) => { this.executeAction('create-mapping', {datasetId: this.props.dataset.id, id, name, identifier, description, group:this.state.newMappingGroup, data, templateId, d2rmlId, parameters, dependencies, shaclId, active, d2rmlIdBound}); this.setState({newMappingModalShow: false, newMappingModalMapping: null})} }
                            onClose={() => this.setState({ newMappingModalShow: false, newMappingType: undefined })}/>}

                  {this.state.newDataModalShow &&
                  <RDFDataFileUploadModal show={this.state.newDataModalShow}
                            file={this.state.newDataModalFile}
                            type='Content'
                            ref={node => (this.fileModal = node)}
                            onOK={(id, name, description, data, url, active) => this.executeAction('create-file', {datasetId: this.props.dataset.id, id, name, group:this.state.newDataModalGroup, description, data, url, active})}
                            onClose={() => this.setState({ newDataModalShow: false, newDataModalFile:undefined })}/>}

                  {this.state.newTTLFileUploadModalShow &&
                  <RDFDataFileUploadModal show={this.state.newTTLFileUploadModalShow}
                            file={this.state.newTTLFileUploadModalFile}
                            type={this.state.newTTLFileUploadModalType}
                            ref={node => (this.ttlFileModal = node)}
                            onOK={(id, name, description, data, url, active, fields, parameters) => this.executeAction('create-prototype', {datasetId: this.props.dataset.id, id, name, description, data, type:this.state.newTTLFileUploadModalType, url, fields, parameters})}
                            onClose={() => this.setState({ newTTLFileUploadModalShow: false, newDataModalFile:undefined, newTTLFileUploadModalType: undefined })}/>}

                  {this.state.newSourceDataModalShow &&
                  <SourceDataFileUploadModal show={this.state.newSourceDataModalShow}
                            ref={node => (this.sourceDataFileModal = node)}
                            onOK={(id, data) => this.executeAction('add-attachment', {id: this.state.newSourceDataModalMapping.id, instanceId: this.state.newSourceDataModalInstance ? this.state.newSourceDataModalInstance.id : null, data:data})}
                            onClose={() => this.setState({ newSourceDataModalShow: false })}/>}

                  {this.state.publishModalShow &&
                  <PublishModal show={this.state.publishModalShow} value={this.props.dataset} allowPublic={this.props.user.role === "EDITOR"}
                             // visibility={this.props.visibility}
                             tripleStores={this.props.database.tripleStores}
                             // onOK={(privat, tripleStore) => {this.props.actions('publish-dataset', {id: this.props.dataset.id, visibility: privat, type: this.props.type, tripleStore}); this.setState({ publishModalShow: false })}}
                             onOK={(tripleStore) => {this.props.actions('publish-dataset', {id: this.props.dataset.id, type: this.props.type, tripleStore, group:this.state.publishModalGroup}); this.setState({ publishModalShow: false, publishModalGroup: undefined })}}
                             onClose={() => this.setState({ publishModalShow: false, publishModalGroup: undefined })}/>}

                  {this.state.TTLEditorModalShow &&
                  <TTLEditorModal show={this.state.TTLEditorModalShow}
                            type={this.state.TTLEditorType}
                            ttl={this.state.TTLEditorDocument}
                            title={this.state.TTLEditorObject.name}
                            allowEdit={this.state.TTLEditorDocumentIsMy}
                            state={this.state.TTLEditorState}
                            ref={node => (this.TTLEditorModal = node)}
                            onOK={(ttl, parameters, dependencies) => this.executeAction('update-content', {type:this.state.TTLEditorType, object:this.state.TTLEditorObject, ttl, parameters, dependencies })}
                            onClose={() => this.setState({ TTLEditorModalShow: false })}/>}

                  {this.state.SPARQLEditorModalShow &&
                  <SPARQLEditorModal show={this.state.SPARQLEditorModalShow}
                            endpoint={this.state.SPARQLEditorEndpoint}
                            ref={node => (this.SPARQLEditorModal = node)}
                            onClose={() => this.setState({ SPARQLEditorModalShow: false })}/>}


                </Col>
              </Row>

              <Row>
                <Col>
                  {this.props.scope != 'ANNOTATOR' && this.props.scope != 'COMPARATOR' && this.props.scope != 'D2RML' && this.props.scope != 'SHACL' && this.props.scope !== 'INDEX' &&
                  <Row>
                    <Col md={2}>
                      <span>UUID</span>
                    </Col>
                    <Col md={9} className="datasetId">
                      {this.props.dataset.uuid}
                    </Col>
                  </Row>}
                  {this.props.dataset.identifier &&
                  <Row className="pt-1">
                    <Col md={2}>
                      <span>Identifier</span>
                    </Col>
                    <Col md={9} className="datasetId">
                      {this.props.dataset.identifier}
                    </Col>
                  </Row>}
                  {this.state.user && this.state.user.name != this.props.user.name &&
                  <Row className="pt-1">
                    <Col md={2}>
                      <span>Owner</span>
                    </Col>
                    <Col md={9} className="datasetId">
                      {this.state.user.name}
                    </Col>
                  </Row>}
                  {this.props.dataset.remoteTripleStore &&
                  <Row className="pt-1">
                    <Col md={2}>
                      <span>SPARQL enpoint</span>
                    </Col>
                    <Col md={9} className="datasetId">
                      {this.props.dataset.remoteTripleStore.sparqlEndpoint}
                    </Col>
                  </Row>}
                  {this.props.dataset.remoteTripleStore && this.props.dataset.remoteTripleStore.namedGraph &&
                  <Row className="pt-1">
                    <Col md={2}>
                      <span>Named graph(s)</span>
                    </Col>
                    <Col md={9} className="datasetId">
                       {this.props.dataset.remoteTripleStore.namedGraph && this.props.dataset.remoteTripleStore.namedGraph.map(e =>
                         <span className="lineblock">{e}</span>
                       )}
                    </Col>
                  </Row>}
                  {this.props.dataset.projects &&
                  <Row className="pt-1">
                    <Col md={2}>
                      <span>Projects</span>
                    </Col>
                    <Col md={9} className="datasetId">
                      {this.props.dataset.projects && this.props.dataset.projects.map((e, pi) =>
                        <span key={pi} className="lineblock">{e.name}</span>
                      )}
                    </Col>
                  </Row>}
                  {this.props.scope !== 'SHACL' && this.props.scope !== 'D2RML' && this.props.scope !== 'ANNOTATOR' && this.props.scope !== 'COMPARATOR' && this.props.scope !== 'INDEX' && this.props.dataset && this.props.dataset.loadState.state != 'NOT_LOADED' &&
                  <Row className="pt-1">
                    <Col md={2} className="align-self-center">
                      <span>State</span>
                    </Col>
                    <Col md={9} className="tablecenter">
                      {/*<Row>
                        <Col>
                          <PublishableState value={this.props.dataset.publishState} showTripleStore={true}/>
                        </Col>
                      </Row>*/}
                      {/*<Row>
                        <Col>
                          <DistributableState value={this.props.dataset} />
                        </Col>
                      </Row>*/}
                      {/*supportsIndexing && this.props.type === 'DATASET' &&
                      <Row>
                        <Col>
                          <IndexableState value={this.props.dataset}
                                          actions={(action, params) => this.props.actions(action, {id : this.props.dataset.id, ...params})}
                          />
                        </Col>
                      </Row>*/}
                      {this.props.type === 'DATASET' && this.props.scope === 'VOCABULARY' &&
                      <Row>
                        <Col>
                          <LoadableState value={this.props.dataset.loadState} />
                        </Col>
                      </Row>}
                    </Col>
                  </Row>}
                </Col>
              </Row>
            </Container>

           {//this.props.my &&
            <Row>
              <Col className="in-down">
                <span className='crimson-std fa fa-angle-down fa-lg'></span>
              </Col>
            </Row>}

            {this.props.type === 'CATALOG' &&
            <Row>
              <Col className="in-down">
                <span className='crimson-std fa fa-angle-down fa-lg'></span>
              </Col>
            </Row>}

            {this.props.type === 'CATALOG' &&
            <Container className={this.props.dataset.datasets && this.props.dataset.datasets.length > 0 ? "groupborder" : "groupborder-empty"}>
              <Row className={this.props.dataset.datasets && this.props.dataset.datasets.length > 0 ? "header" : "header-empty"}>
                <Col>
                  <span className="crimson-std">Members</span>
                </Col>

                <Col className="mybutton" md={1}>
                  {this.state.newCatalogLinkModalShow &&
                  <NewDatasetLinkModal show={this.state.newCatalogLinkModalShow}
                      datasets={this.props.datasets}
                      vocabularies={this.props.vocabularies}
                      alignments={this.props.alignments}
                      onOK={(id) => {this.addDataset(id, this.props.dataset.id); this.setState({ newCatalogLinkModalShow: false})}}
                      onClose={() => this.setState({ newCatalogLinkModalShow: false})}/>}

                  {actionsMenu(
                  <div>
                    {this.props.type === 'CATALOG' &&
                    <Dropdown.Item className="py-2"
                                   disabled={!this.props.my}
                                   onClick={() => this.setState({ newCatalogLinkModalShow: true})}>
                      <span className="menu-icon fa fa-plus fa-lg mr-3" />Add dataset...
                    </Dropdown.Item>}
                  </div>)}

                </Col>

              </Row>
              <Row className="align-items-center">
                <Col>
                  {this.props.dataset.datasets && this.props.dataset.datasets.map((el, index) => (
                  <Row key={"assigned-dataset-" + index}>
                    <Col>
                      {el.name}
                    </Col>
                    <Col md="auto">
                      <Button type="button" className="deleteeditbutton mt-0" aria-label="Delete"
                              disabled={!this.props.my}
                              onClick={() => this.removeDataset(el.id, this.props.dataset.id)}>
                        <span className="fa fa-times failed" title="Remove dataset" />
                      </Button>
                    </Col>
                  </Row>))}
                </Col>
              </Row>

            </Container>}

            {/*this.props.type === 'DATASET' && isLocal &&
            <Row>
              <Col className="in-down">
                <span className='crimson-std fa fa-angle-down fa-lg'></span>
              </Col>
            </Row>*/}

            {//this.props.my &&
              this.props.dataset.multiGroup && groups.map(group => {
              var groupMappings
              if (this.state.mappings) {
                groupMappings = this.state.mappings.filter(x => x.group == group)
              }
              var groupFiles
              if (this.state.files) {
                groupFiles = this.state.files.filter(x => x.group == group)
              }

              var groupHasExecutions = this.hasExecutions(groupMappings, groupFiles);
              var groupHasUnpublishedExecutions = this.hasUnpublishedExecutions(groupMappings, groupFiles);

              var groupPublishState
              var groupPublished
              if (this.props.dataset.publishState.groups) {
                groupPublishState = this.props.dataset.publishState.groups.find(x => x.group == group)
                if (groupPublishState) {
                } else {
                  groupPublishState = {"state": "UNPUBLISHED" }
                }
              } else {
                groupPublishState = {"state": "UNPUBLISHED" }
              }

              groupPublished = groupPublishState.state.startsWith("PUBLISHED");
              return (
                <Container key={'c' + group} className="group-groupborder-blue">
                  <Row className="header">
                    <Col className="bold" md={5}>
                      <span className="blue-std">Graph {group}</span>
                    </Col>

                    <Col>
                    {groups.length > 1 && groupPublishState &&
                      <PublishableState2 value={groupPublishState} showTripleStore={true}/>}
                    </Col>
                    {toggleBoxColumn('groupsOpen', this, null, null, groups, group)}

                    <Col className="mybutton" md={1}>
                      {groups.length > 1 &&
                        actionsMenu(
                        <div>

                          <Dropdown.Item className="py-2"
                            disabled={!this.props.my || (isLocal && (groupPublished || groupPublishState.state == 'PUBLISHING' || groupPublishState.state == 'UNPUBLISHING' || !groupHasExecutions))}
                            onClick={(event) => this.setState({ publishModalShow: true, publishModalGroup: group})}>
                            <span className="menu-icon fa fa-calendar-check-o fa-lg mr-3"/>Publish...
                          </Dropdown.Item>

                          {this.props.type === 'DATASET' && isLocal &&
                          <Dropdown.Item className="py-2"
                                 disabled={!this.props.my || (!groupPublished)}
                                 onClick={(event) => this.props.actions('republish-dataset', {id : this.props.dataset.id, group: group})}>
                            <span className="menu-icon fa fa-calendar fa-lg mr-3"/>Republish (content & metadata)
                          </Dropdown.Item>}

                          {isLocal &&
                          <Dropdown.Item className="py-2"
                               disabled={!this.props.my || (!groupPublished || !groupHasUnpublishedExecutions) }
                               onClick={(event) => this.props.actions('publish-dataset-unpublished-content', {id : this.props.dataset.id, group: group})}>
                            <span className="menu-icon fa fa-calendar-check-o fa-lg mr-3"/>Republish (unpublished content & metadata)
                          </Dropdown.Item>}

                          <Dropdown.Item className="py-2"
                                   onClick={(event) => this.props.actions('unpublish-dataset', {id : this.props.dataset.id, type:this.props.type, group: group})}
                                   disabled={!this.props.my}>
                            <span className="menu-icon fa fa-calendar-times-o fa-lg mr-3"/>Unpublish
                          </Dropdown.Item>

                          <Dropdown.Divider/>

                          <Dropdown.Item className="py-2"
                                disabled={!groupPublished}
                                onClick={() => this.executeAction('edit-sparql', {endpoint: API_BASE_URL + "/content/" + this.props.dataset.uuid + "/" + group + "/sparql"})}>
                             <span className="menu-icon fa fa-cube fa-lg mr-3" />Query with SPARQL...
                          </Dropdown.Item>

                          <Dropdown.Item className="py-2"
                                         disabled={!groupPublished}
                                         onClick={() => { navigator.clipboard.writeText(API_BASE_URL + "/content/" + this.props.dataset.uuid + "/" + group + "/sparql") }}>
                              <span className="menu-icon fa fa-clipboard fa-lg mr-3" />Copy SPARQL endpoint to clipboard
                          </Dropdown.Item>


                        </div>)}
                    </Col>
                  </Row>
                  <Row className="group-row">
                    <Collapse in={this.state.groupsOpen[group]}>
                      <Container key={group}>
                        <Row key={group}>
                          {this.renderGroup(group, isLocal, groups, true)}
                        </Row>
                      </Container>
                    </Collapse>
                  </Row>
                </Container>)}
            )}

            {//this.props.my &&
              !this.props.dataset.multiGroup &&
              this.renderGroup(0, isLocal, [ 0 ], false)
            }

            {//this.props.my &&
              supportsIndexing && this.props.type === 'DATASET' && (this.props.scope === 'COLLECTION' || this.props.scope === 'VOCABULARY' || this.props.scope === 'ALIGNMENT') &&
            <Container className={this.state.indexes && this.state.indexes.length > 0 ? "groupborder" : "groupborder-empty"}>
              <Row className={this.state.indexes && this.state.indexes.length > 0 ? "header" : "header-empty"}>
                <Col className="bold">
                  {/*{this.props.dataset.name}: */}
                  <span className="crimson-std">Indices</span>
                </Col>

                {toggleBoxColumn('indexesOpen', this, 'indexes')}

                <Col className="mybutton" md={1}>
                  {actionsMenu(
                  <div>
                    <Dropdown.Item className="py-2"
                                   disabled={!this.props.my}
                                   onClick={(event) => this.setState({ newIndexModalShow: true, newIndexModalIndex: null})}>
                      <span className="menu-icon fa fa-plus fa-lg mr-3"/>New index...
                    </Dropdown.Item>

                  </div>)}
                </Col>
              </Row>

              <Collapse in={this.state.indexesOpen}>
                <div>
              {this.state.indexes.map((el, index) =>
              <Container key={index} className={"mappingContainer" + (el.ownedByUser ? "" : " notOwned")}>
                <Row key={index} className="mappingRow">
                  <Col md={4} className="">
                    <Row>
                      <Col>
                        <span className="mapping-name">{el.name}</span>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <span className="abutton">{el.indexStructureIdentifier}</span><br/>
                        <span className="times">@{el.elasticConfiguration}</span>
                      </Col>
                    </Row>
  {/*                  <Row>
                      <Col>
                        <span>{el.fileName}</span>
                      </Col>
                    </Row> */}
                  </Col>

                  <Col md={1} className="tablecenter mappingtype">
                    {/*<Form.Check  label="Default" checked={el.default} onClick={() => this.executeAction('change-index-default-state', { id: el.id })}/>*/}
                  </Col>

                  <Col md={6} className="tablecenter">
                    <Row className="stategroup">
                      <Col>
                        <Row>
                          <Col>
                            <CreatableState value={el.createState}/>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Col>

                  <Col md={1} className="">
                    {actionsMenu(
                    <div>
                      <Dropdown.Item className="py-2"
                                     disabled={!this.props.my}
                                     onClick={() => this.executeAction('update-index', {index: el})}>
                        <span className="menu-icon fa fa-edit fa-lg mr-3" />Edit...
                      </Dropdown.Item>

                      <Dropdown.Item className="py-2"
                                     disabled={!this.props.my || el.createState.state != 'NOT_CREATED'}
                                     onClick={() => this.executeAction('delete-index', {id: el.id})}>
                        <span className="menu-icon fa fa-trash-o fa-lg mr-3"/>Delete
                      </Dropdown.Item>

                      <Dropdown.Divider/>

                      <Dropdown.Item className="py-2"
                                     disabled={!this.props.my || !published || el.createState.state == 'CREATING' || el.createState.state == 'CREATED'}
                                     onClick={() => this.executeAction('create-index', {id: el.id})}>
                            <span className="menu-icon fa fa-play-circle fa-lg mr-3" />Index
                      </Dropdown.Item>

                      <Dropdown.Item  className="py-2"
                                      disabled={!this.props.my || el.createState.state != 'CREATED'}
                                      onClick={(event) => this.executeAction('recreate-index', {id : el.id})}>
                        <span className="menu-icon fa fa-play-circle-o fa-lg mr-3" />Reindex
                      </Dropdown.Item>

                      <Dropdown.Item  className="py-2"
                                      disabled={!this.props.my || el.createState.state != 'CREATED'}
                                      onClick={(event) => this.executeAction('destroy-index', {id : el.id})}>
                        <span className="menu-icon fa fa-times-circle-o fa-lg mr-3" />Unindex
                      </Dropdown.Item>

                      <Dropdown.Item className="py-2"
                                       disabled={!this.props.my || el.createState.state != 'CREATING'}
                                       onClick={() => this.executeAction('stop-create-index', {id: el.id})}>
                            <span className="menu-icon fa fa-stop fa-lg mr-3" />Stop indexing
                      </Dropdown.Item>


                      <Dropdown.Divider/>

                      <Dropdown.Item className="py-2"
                                     disabled={!this.props.my || index == 0}
                                     onClick={() => this.executeAction('move-index', {id: el.id, step:-1})}>
                        <span className="menu-icon fa fa-chevron-up fa-lg mr-3"/>Move up
                      </Dropdown.Item>

                      <Dropdown.Item className="py-2"
                                      disabled={!this.props.my || index == this.state.indexes.length - 1}
                                     onClick={() => this.executeAction('move-index', {id: el.id, step:1})}>
                        <span className="menu-icon fa fa-chevron-down fa-lg mr-3"/>Move down
                      </Dropdown.Item>
                    </div>)}
                  </Col>

                </Row>
              </Container>)}
              </div>
              </Collapse>

              {this.state.newIndexModalShow &&
              <IndexModal show={this.state.newIndexModalShow}
                        // dataset={this.props.dataset}
                        indexEngines={this.props.database.indexEngines}
                        // indices={this.props.indices}
                        index={this.state.newIndexModalIndex}
                        project={this.props.project}
                        onOK={(name, indexStructureId, indexEngine) => {this.executeAction('create-index-def', {datasetId: this.props.dataset.id, name, indexStructureId, indexEngine}); this.setState({ newIndexModalShow: false })}}
                        onUpdate={(id, name, indexStructureId, indexEngine) => {this.executeAction('create-index-def', {id, name, indexStructureId, indexEngine}); this.setState({ newIndexModalShow: false })}}
                        onClose={() => this.setState({ newIndexModalShow: false })}/>}

            </Container>}

            {this.props.my && this.props.type === 'DATASET' && (this.props.scope === 'COLLECTION' || this.props.scope === 'VOCABULARY' || this.props.scope === 'ALIGNMENT') &&
            <Container className={this.state.distributions && this.state.distributions.length > 0 ? "groupborder" : "groupborder-empty"}>
              <Row className={this.state.distributions && this.state.distributions.length > 0 ? "header" : "header-empty"}>
                <Col className="bold">
                  {/*{this.props.dataset.name}: */}
                  <span className="crimson-std">Distributions</span>
                </Col>

                <Col className="mybutton" md={1}>
                  {actionsMenu(
                  <div>
                    <Dropdown.Item className="py-2"
                                   disabled={!this.props.my}
                                   onClick={(event) => this.setState({ newDistributionModalShow: true})}>
                      <span className="menu-icon fa fa-plus fa-lg mr-3"/>New distribution...
                    </Dropdown.Item>

                  </div>)}
                </Col>
              </Row>

              {/*
              <Row className="tableheader">
                <Col md={4}>
                  <span>File</span>
                </Col>
                <Col md={1} className="tablecenter">
                  <span>Type</span>
                </Col>
                <Col md={6} className="tablecenter">
                  <span>State</span>
                </Col>
                <Col md={1}/>
              </Row> */}

              {this.state.distributions.map((el, index) =>
              <Container key={index} className="mappingContainer">
                <Row key={index} className="mappingRow">
                  <Col md={4} className="">
                    <Row>
                    <Col>
                      <span className="abutton">{el.identifier}</span><br/>
                      <span className="times">{el.name}</span>
                    </Col>
                    </Row>
                  </Col>

                  <Col md={1} className="tablecenter mappingtype">
                    {/*<Form.Check  label="Default" checked={el.default} onClick={() => this.executeAction('change-index-default-state', { id: el.id })}/>*/}
                  </Col>

                  <Col md={6} className="tablecenter">
                    <Row className="stategroup">
                      <Col>
                        <Row>
                          <Col>
                            <CreatableState value={el.createState}/>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Col>

                  <Col md={1} className="">
                    {actionsMenu(
                    <div>
                    <Dropdown.Item className="py-2"
                                   disabled={!this.props.my}
                                   onClick={() => this.executeAction('update-distribution', {distribution: el})}>
                      <span className="menu-icon fa fa-edit fa-lg mr-3" />Edit...
                    </Dropdown.Item>

                      <Dropdown.Item className="py-2"
                                     disabled={!this.props.my || el.createState.state != 'NOT_CREATED'}
                                     onClick={() => this.executeAction('delete-distribution', {id: el.id})}>
                        <span className="menu-icon fa fa-trash-o fa-lg mr-3"/>Delete
                      </Dropdown.Item>

                      <Dropdown.Divider/>

                      <Dropdown.Item className="py-2"
                                     disabled={!this.props.my || !published || el.createState.state == 'CREATING' || el.createState.state == 'CREATED'}
                                     onClick={() => this.executeAction('create-distribution', {id: el.id})}>
                            <span className="menu-icon fa fa-play-circle fa-lg mr-3" />Create distribution files
                      </Dropdown.Item>

                      <Dropdown.Item className="py-2"
                                       disabled={!this.props.my || el.createState.state != 'CREATING'}
                                       onClick={() => this.executeAction('stop-create-distribution', {id: el.id})}>
                            <span className="menu-icon fa fa-stop fa-lg mr-3" />Stop distribution files creation
                      </Dropdown.Item>

                      <Dropdown.Item  className="py-2"
                                      disabled={!this.props.my || el.createState.state != 'CREATED'}
                                      onClick={(event) => this.executeAction('destroy-distribution', {id : el.id})}>
                        <span className="menu-icon fa fa-times-circle-o fa-lg mr-3" />Delete distribution files
                      </Dropdown.Item>
                    </div>)}
                  </Col>

                </Row>
              </Container>)}

              {this.state.newDistributionModalShow &&
              <DistributionModal show={this.state.newDistributionModalShow}
                        dataset={this.props.dataset}
                        distribution={this.state.newDistributionModalDistribution}
                        onOK={(id, name, identifier, classes, ttl, nt, serializationVocabulary, compress, license) => { this.executeAction('new-distribution', {datasetId: this.props.dataset.id, id, name, identifier, type: this.props.type, classes:classes, ttl: ttl, nt: nt, serializationVocabulary, compress: compress, license }); this.setState({ newDistributionModalShow: false, newDistributionModalDistribution:null }) }}
                        onClose={() => this.setState({ newDistributionModalShow: false, newDistributionModalDistribution: undefined })}/>}

            </Container>}

            {this.props.my && this.props.type === 'DATASET' && (this.props.scope === 'COLLECTION' || this.props.scope === 'VOCABULARY' || this.props.scope === 'ALIGNMENT') &&
            <Container className={this.state.userTasks && this.state.userTasks.length > 0 ? "groupborder" : "groupborder-empty"}>
              <Row className={this.state.userTasks && this.state.userTasks.length > 0 ? "header" : "header-empty"}>
                <Col className="bold">
                  {/*{this.props.dataset.name}: */}
                  <span className="crimson-std">User Tasks</span>
                </Col>

                <Col className="mybutton" md={1}>
                  {actionsMenu(
                  <div>
                    <Dropdown.Item className="py-2"
                                   disabled={!this.props.my}
                                   onClick={(event) => this.setState({ newUserTaskModalShow: true, newUserTaskModalUserTask: null})}>
                      <span className="menu-icon fa fa-plus fa-lg mr-3"/>New user task...
                    </Dropdown.Item>

                  </div>)}
                </Col>
              </Row>

              {this.state.userTasks.map((el, index) =>
              <Container key={index} className="mappingContainer">
                <Row key={index} className="mappingRow">
                  <Col md={4} className="">
                    <Row>
                      <Col>
                        <span className="abutton">{el.name}</span>
                      </Col>
                    </Row>
                  </Col>

                  <Col md={1} className="tablecenter mappingtype">
                    {el.scheduled &&  <div><span className="schedule-icon fa fa-calendar fa-lg mr-3" /><br/><span className="cron-expression">{el.cronExpression}</span></div>}
                    {!el.scheduled &&   <span className="schedule-icon fa fa-calendar-times-o fa-lg mr-3" />}
                  </Col>

                  <Col md={6} className="tablecenter">
                    <Row className="stategroup">
                      <Col>
                        <Row>
                          <Col>
                            <RunnableState value={el.runState}/>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Col>

                  <Col md={1} className="">
                    {actionsMenu(
                    <div>
                      <Dropdown.Item className="py-2"
                                     disabled={!this.props.my}
                                     onClick={() => this.executeAction('update-user-task', {userTask: el})}>
                        <span className="menu-icon fa fa-edit fa-lg mr-3" />Edit...
                      </Dropdown.Item>

                      <Dropdown.Item className="py-2"
                                     disabled={!this.props.my || el.runState.state === 'RUNNING'}
                                     onClick={() => this.executeAction('delete-user-task', {id: el.id})}>
                        <span className="menu-icon fa fa-trash-o fa-lg mr-3" />Delete
                      </Dropdown.Item>

                      <Dropdown.Divider/>

                      <Dropdown.Item className="py-2"
                                     disabled={!this.props.my || el.runState.state === 'RUNNING'}
                                     onClick={() => this.executeAction('run-user-task', {id: el.id})}>
                        <span className="menu-icon fa fa-play fa-lg mr-3" />Run
                      </Dropdown.Item>

                      <Dropdown.Divider/>

                      <Dropdown.Item className="py-2"
                                     disabled={!this.props.my}
                                     onClick={() => this.executeAction('schedule-user-task', {id: el.id})}>
                        <span className="menu-icon fa fa-calendar fa-lg mr-3" />Schedule
                      </Dropdown.Item>

                      <Dropdown.Item className="py-2"
                                     disabled={!this.props.my}
                                     onClick={() => this.executeAction('unschedule-user-task', {id: el.id})}>
                        <span className="menu-icon fa fa-calendar-times-o fa-lg mr-3" />Unschedule
                      </Dropdown.Item>

                   </div>)}

                  </Col>

                </Row>
              </Container>)}

              {this.state.newUserTaskModalShow &&
              <UserTaskModal show={this.state.newUserTaskModalShow}
                        userTask={this.state.newUserTaskModalUserTask}
                        local={isLocal}
                        onOK={(id, name, tasks, cronExpression, freshRunOnly) => { this.executeAction('new-user-task', {datasetId: this.props.dataset.id, id, name, tasks, cronExpression, freshRunOnly}); this.setState({newUserTaskModalShow: false, newUserTaskModalUserTask: null})} }
                        onClose={() => this.setState({ newUserTaskModalShow: false, newUserTaskModalUserTask: undefined })}/>}


            </Container>}

            {this.props.type === 'DATASET' && isLocal && (this.props.scope === 'SHACL') &&
            <Container className={this.state.shacls && this.state.shacls.length > 0 ? "groupborder" : "groupborder-empty"}>
              <Row className={this.state.shacls && this.state.shacls.length > 0 ? "header" : "header-empty"}>
                <Col className="bold">
                  {/*{this.props.dataset.name}: */}
                  <span className="crimson-std">SHACL Shapes</span>
                </Col>

                <Col className="mybutton" md={1}>
                  {actionsMenu(
                  <div>
                    <Dropdown.Item className="py-2"
                                   disabled={!this.props.my}
                                   onClick={(event) => this.setState({ newTTLFileUploadModalShow: true, newTTLFileUploadModalFile: null, newTTLFileUploadModalType: 'SHACL'})}>
                      <span className="menu-icon fa fa-plus fa-lg mr-3"/>Add SHACL shape...
                    </Dropdown.Item>
                  </div>)}
                </Col>
              </Row>

              {this.state.shacls.map((el, index) =>
              <Container key={index} className="mappingContainer">
                <Row key={index} className="mappingRow">
                  <Col md={4} className="">
                    <Row>
                      <Col>
                        <span className="abutton">{el.name}</span>
                      </Col>
                    </Row>
                  </Col>

                  <Col md={1} className="tablecenter mappingtype">
                  </Col>

                  <Col md={6} className="tablecenter">
                  </Col>

                  <Col md={1} className="">
                    {actionsMenu(
                    <div>
                      <Dropdown.Item className="py-2"
                                     // disabled={!this.props.my}
                                     onClick={() => this.executeAction('update-prototype', {prototype: el, type: 'SHACL'})}>
                        <span className="menu-icon fa fa-edit fa-lg mr-3" />Edit...
                      </Dropdown.Item>

                      <Dropdown.Divider/>

                      <Dropdown.Item className="py-2"
                                     disabled={!this.props.my}
                                     onClick={() => this.executeAction('edit-prototype-content', {object: el, type: 'SHACL', my:el.ownedByUser})}>
                        <span className="menu-icon fa fa-wrench fa-lg mr-3" />{el.ownedByUser ? 'Edit' : 'View'} SHACL document...
                      </Dropdown.Item>

                   </div>)}

                  </Col>

                </Row>
              </Container>)}
            </Container>}

            {this.props.type === 'DATASET' && isLocal && (this.props.scope === 'D2RML') &&
            <Container className={this.state.d2rmls && this.state.d2rmls.length > 0 ? "groupborder" : "groupborder-empty"}>
              <Row className={this.state.d2rmls && this.state.d2rmls.length > 0 ? "header" : "header-empty"}>
                <Col className="bold">
                  {/*{this.props.dataset.name}: */}
                  <span className="crimson-std">D2RML Documents</span>
                </Col>

                <Col className="mybutton" md={1}>
                  {actionsMenu(
                  <div>
                    <Dropdown.Item className="py-2"
                                   disabled={!this.props.my}
                                   onClick={(event) => this.setState({ newTTLFileUploadModalShow: true, newTTLFileUploadModalFile: null, newTTLFileUploadModalType: 'D2RML'})}>
                      <span className="menu-icon fa fa-plus fa-lg mr-3"/>Add D2RML document...
                    </Dropdown.Item>
                  </div>)}
                </Col>
              </Row>
              {this.state.d2rmls.map((el, index) =>
              <Container key={index} className="mappingContainer">
                <Row className="mappingRow">
                  <Col md={11} className="">
                    <Row className="">
                      <Col>
                         <span className="mapping-name">{el.name}</span> <span className="mapping-name"><a href={API_BASE_URL + "/content/" + (this.props.dataset.identifier ? this.props.dataset.identifier : this.props.dataset.uuid) + "/prototype/" + el.uuid} target="_blank" rel="noopener noreferrer"><span className="fa fa-link ml-2"/></a></span>
                      </Col>
                    </Row>
                    {el.description &&
                    <Row>
                      <Col>
                        <span className="parametervalue">{el.description}</span>
                      </Col>
                    </Row>}
                  </Col>

                  <Col md={1} className="">
                    {actionsMenu(
                    <div>
                      <Dropdown.Item className="py-2"
                                     disabled={!el.ownedByUser}
                                     onClick={() => this.executeAction('update-prototype', {prototype: el, type: 'D2RML'})}>
                        <span className="menu-icon fa fa-edit fa-lg mr-3" />Edit...
                      </Dropdown.Item>

                      <Dropdown.Item className="py-2"
                                     disabled={!el.ownedByUser}
                                     onClick={() => this.executeAction('delete-prototype', {id: el.id, type: 'D2RML'})}>
                        <span className="menu-icon fa fa-trash fa-lg mr-3" />Delete
                      </Dropdown.Item>

                      <Dropdown.Divider/>

                      <Dropdown.Item className="py-2"
                                     // disabled={!this.props.my}
                                     onClick={() => this.executeAction('edit-prototype-content', {object: el, type: 'D2RML', my:el.ownedByUser})}>
                        <span className="menu-icon fa fa-wrench fa-lg mr-3" />{el.ownedByUser ? 'Edit' : 'View'} D2RML document...
                      </Dropdown.Item>

                   </div>)}

                  </Col>

                </Row>
              </Container>)}
            </Container>}

            {this.props.type === 'DATASET' && isLocal && (this.props.scope === 'ANNOTATOR') &&
            <Container className={this.state.annotators && this.state.annotators.length > 0 ? "groupborder" : "groupborder-empty"}>
              <Row className={this.state.annotators && this.state.annotators.length > 0 ? "header" : "header-empty"}>
                <Col className="bold">
                  {/*{this.props.dataset.name}: */}
                  <span className="crimson-std">Annotator Documents</span>
                </Col>

                <Col className="mybutton" md={1}>
                  {actionsMenu(
                  <div>
                    <Dropdown.Item className="py-2"
                                   // disabled={!this.props.my}
                                   onClick={(event) => this.setState({ newTTLFileUploadModalShow: true, newTTLFileUploadModalFile: null, newTTLFileUploadModalType: 'ANNOTATOR'})}>
                      <span className="menu-icon fa fa-plus fa-lg mr-3"/>Add Annotator document...
                    </Dropdown.Item>
                  </div>)}
                </Col>
              </Row>

              {this.state.annotators.map((el, index) =>
              <Container key={index} className={"mappingContainer" + (el.ownedByUser ? "" : " notOwned")}>
                <Row className="mappingRow">
                  <Col md={11} className="">
                    <Row className="">
                      <Col>
                         <span className="mapping-name">{el.name}</span> <span className="mapping-name"><a href={API_BASE_URL + "/content/" + (this.props.dataset.identifier ? this.props.dataset.identifier : this.props.dataset.uuid) + "/prototype/" + el.uuid} target="_blank" rel="noopener noreferrer"><span className="fa fa-link ml-2"/></a></span>
                      </Col>
                    </Row>

                    {el.description &&
                    <Row>
                      <Col>
                        <span className="parametervalue">{el.description}</span>
                      </Col>
                    </Row>}
                  </Col>

                  <Col md={1} className="">
                    {actionsMenu(
                    <div>
                      <Dropdown.Item className="py-2"
                                     disabled={!el.ownedByUser}
                                     onClick={() => this.executeAction('update-prototype', {prototype: el, type: 'ANNOTATOR'})}>
                        <span className="menu-icon fa fa-edit fa-lg mr-3" />Edit...
                      </Dropdown.Item>

                      <Dropdown.Item className="py-2"
                                     disabled={!el.ownedByUser}
                                     onClick={() => this.executeAction('delete-prototype', {id: el.id, type: 'ANNOTATOR'})}>
                        <span className="menu-icon fa fa-trash fa-lg mr-3" />Delete
                      </Dropdown.Item>

                      <Dropdown.Divider/>

                      <Dropdown.Item className="py-2"
                                     // disabled={!el.ownedByUser}
                                     onClick={() => this.executeAction('edit-prototype-content', {object: el, type: 'ANNOTATOR', my:el.ownedByUser})}>
                        <span className="menu-icon fa fa-wrench fa-lg mr-3" />{el.ownedByUser ? 'Edit' : 'View'} Annotator document...
                      </Dropdown.Item>

                   </div>)}

                  </Col>

                </Row>
              </Container>)}
            </Container>}

            {this.props.type === 'DATASET' && isLocal && (this.props.scope === 'COMPARATOR') &&
            <Container className={this.state.comparators && this.state.comparators.length > 0 ? "groupborder" : "groupborder-empty"}>
              <Row className={this.state.comparators && this.state.comparators.length > 0 ? "header" : "header-empty"}>
                <Col className="bold">
                  {/*{this.props.dataset.name}: */}
                  <span className="crimson-std">Comparator Documents</span>
                </Col>

                <Col className="mybutton" md={1}>
                  {actionsMenu(
                  <div>
                    <Dropdown.Item className="py-2"
                                   // disabled={!this.props.my}
                                   onClick={(event) => this.setState({ newComparatorModalShow: true})}>
                      <span className="menu-icon fa fa-plus fa-lg mr-3"/>Add Comparator document...
                    </Dropdown.Item>
                  </div>)}
                </Col>
              </Row>

              {this.state.comparators.map((el, index) =>
              <Container key={index} className={"mappingContainer" + (el.ownedByUser ? "" : " notOwned")}>
                <Row key={index} className="mappingRow">
                  <Col md={11} className="">
                    <Row>
                      <Col>
                        <span className="mapping-name">{el.name}</span>
                      </Col>
                    </Row>

                    {el.description &&
                    <Row>
                      <Col>
                        <span className="parametervalue">{el.description}</span>
                      </Col>
                    </Row>}
                  </Col>

                  <Col md={1} className="">
                    {actionsMenu(
                    <div>
                      <Dropdown.Item className="py-2"
                                     disabled={!el.ownedByUser}
                                     onClick={() => this.executeAction('update-comparator', {comparator: el})}>
                        <span className="menu-icon fa fa-edit fa-lg mr-3" />Edit...
                      </Dropdown.Item>

                      <Dropdown.Item className="py-2"
                                     disabled={!el.ownedByUser}
                                     onClick={() => this.executeAction('delete-comparator', {id: el.id})}>
                        <span className="menu-icon fa fa-trash fa-lg mr-3" />Delete
                      </Dropdown.Item>

                   </div>)}

                  </Col>

                </Row>
              </Container>)}
            </Container>}

            {this.props.type === 'DATASET' && isLocal && (this.props.scope === 'INDEX') &&
            <Container className={this.state.indexStructures && this.state.indexStructures.length > 0 ? "groupborder" : "groupborder-empty"}>
              <Row className={this.state.indexStructures && this.state.indexStructures.length > 0 ? "header" : "header-empty"}>
                <Col className="bold">
                  {/*{this.props.dataset.name}: */}
                  <span className="crimson-std">Index Definitions</span>
                </Col>

                <Col className="mybutton" md={1}>
                  {actionsMenu(
                  <div>
                    <Dropdown.Item className="py-2"
                                   // disabled={!this.props.my}
                                   onClick={(event) => this.setState({ newIndexStructureModalShow: true})}>
                      <span className="menu-icon fa fa-plus fa-lg mr-3"/>Add Index Definition...
                    </Dropdown.Item>
                  </div>)}
                </Col>
              </Row>

              {this.state.indexStructures.map((el, index) =>
              <Container key={index} className={"mappingContainer" + (el.ownedByUser ? "" : " notOwned")}>
                <Row key={index} className="mappingRow">
                  <Col md={11} className="">
                    <Row>
                      <Col>
                        <span className="mapping-name">{el.name}</span>
                      </Col>
                    </Row>

                    {el.description &&
                    <Row>
                      <Col>
                        <span className="parametervalue">{el.description}</span>
                      </Col>
                    </Row>}
                  </Col>

                  <Col md={1} className="">
                    {actionsMenu(
                    <div>
                      <Dropdown.Item className="py-2"
                                     disabled={!el.ownedByUser}
                                     onClick={() => this.executeAction('update-index-structure', {indexStructure: el})}>
                        <span className="menu-icon fa fa-edit fa-lg mr-3" />Edit...
                      </Dropdown.Item>

                      <Dropdown.Item className="py-2"
                                     disabled={!el.ownedByUser}
                                     onClick={() => this.executeAction('delete-index-structure', {id: el.id})}>
                        <span className="menu-icon fa fa-trash fa-lg mr-3" />Delete
                      </Dropdown.Item>

                   </div>)}

                  </Col>

                </Row>
              </Container>)}
            </Container>}
          </Col>
        </Row>

        {this.state.newComparatorModalShow &&
        <ComparatorModal show={this.state.newComparatorModalShow}
          dataset={this.props.dataset}
          comparator={this.state.newComparatorModalObject}
          project={this.props.project}
          onOK={(id, name, identifier, description, schemaDatasetId, indexStructure, keysMetadata, computation) => { this.executeAction('create-comparator', { name, datasetId: this.props.dataset.id, identifier, description, schemaDatasetId, indexStructure, keysMetadata, computation }); this.setState({ newComparatorModalShow: false }) }}
          onUpdate={(id, name, identifier, description, schemaDatasetId, indexStructure, keysMetadata, computation) => { this.executeAction('create-comparator', { id, name, identifier, description, schemaDatasetId, indexStructure, keysMetadata, computation }); this.setState({ newComparatorModalShow: false, newComparatorModalObject: null }) }}
          onClose={() => this.setState({ newComparatorModalShow: false, newComparatorModalObject: null })} />}


        {this.state.newIndexStructureModalShow &&
        <IndexStructureModal show={this.state.newIndexStructureModalShow}
          dataset={this.props.dataset}
          indexStructure={this.state.newIndexStructureModalObject}
          project={this.props.project}
          onOK={(id, name, identifier, description, schemaDatasetId, schema, elements, keysMetadata, computation) => { this.executeAction('create-index-structure', { id, name, datasetId: this.props.dataset.id, identifier, description, schemaDatasetId, schema, elements, keysMetadata, computation }); this.setState({ newIndexStructureModalShow: false, newIndexStructureModalObject: null }) }}
          // onUpdate={(id, name, identifier, description, schemaDatasetId, elements, keysMetadata, computation) => { this.executeAction('create-index-structure', { id, name, identifier, description, schemaDatasetId, elements, keysMetadata }); this.setState({ newIndexStructureModalShow: false, newIndexStructureModalObject: null }) }}
          onClose={() => this.setState({ newIndexStructureModalShow: false, newIndexStructureModalObject: null })} />}

        {this.state.newBindingModalShow &&
        <NewBindingModal show={this.state.newBindingModalShow}
                      mappingId={this.state.mappingId}
                      dataset={this.props.dataset}
                      instance={this.state.instance}
                      parameters={this.state.parameters}
                     onOK={(mappingId, instanceId, identifier, params, active)=> this.executeAction('create-parameter-binding', {mappingId: mappingId, instanceId: instanceId, identifier, parameters: params, active})}
                     onClose={() => this.setState({ newBindingModalShow: false })}/>
        }

        {this.state.deleteModalShow &&
        <DeleteModal show={this.state.deleteModalShow}
                     command={this.state.deleteModalCommand}
                     params={this.state.deleteModalParams}
                     actions={(choice, command, params) => { this.setState( {deleteModalShow:false }); return (choice === 'ok' ? this.executeAction(command + '-ok', params): null)} }/>}

        {this.state.resultsShow &&
        <ResultsModal show={this.state.resultsShow}
                      state={this.state.resultsState}
                      execution={this.state.lastExecution}
                      onClose={() => this.setState({resultsShow:false})}/>}

        {this.state.addToProjectModalShow &&
        <AddToProjectModal show={this.state.addToProjectModalShow}
                           myProjects={this.props.myProjects}
                           joinedProjects={this.props.joinedProjects}
                           onOK={(projectId, status) => { this.props.actions('add-dataset-to-project', {datasetId: this.props.dataset.id, projectId, status}); this.setState({ addToProjectModalShow: false})}}
                           onClose={() => this.setState({ addToProjectModalShow: false})}/>}

      </Container>
    );
  }
}

export default DatasetEditor;
