import { API_BASE_URL } from '../../../constants/index.js';
import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import Form from "react-bootstrap/Form";
import BarLoader from "react-spinners/BarLoader";

import Yasgui from "@triply/yasgui";
import "@triply/yasgui/build/yasgui.min.css";


export class SPARQLEditorModal extends Component {
  constructor(props) {
    super(props);

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    // console.log(localStorage)
    // let turtleStorage = JSON.parse(localStorage.getItem("yagui__config"));
    // // let turtleStorage = JSON.parse(localStorage.getItem("yate_editor_docVal"));
    // localStorage.setItem("yagui__config", JSON.stringify({...turtleStorage, tabs: [], tabConfig: {}}));
    localStorage.removeItem("yagui__config")

    let settings = {
      requestConfig: { endpoint: this.props.endpoint },
      copyEndpointOnNewTab: false,
    }

    const yasgui = new Yasgui(document.getElementById("yasgui"), settings);

  }

  // componentWillReceiveProps(props) {
  //   if (document.getElementById("yasgui")) {
  //     localStorage.removeItem("yagui__config")
  //
  //     let settings = {
  //       requestConfig: { endpoint: props.endpoint },
  //       copyEndpointOnNewTab: false,
  //     }
  //
  //     var element = document.getElementById("yasgui")
  //     while (element.firstChild) {
  //       element.removeChild(element.firstChild);
  //     }
  //     const yasgui = new Yasgui(document.getElementById("yasgui"), settings);
  //   }
  // }

  handleSubmit(event) {
    event.preventDefault();

    // var ttl = JSON.parse(localStorage.getItem("yate_editor_docVal")).val;

  }

  render() {
    return (
      <Modal size="xl" show={this.props.show} onHide={this.props.onClose} animation={false} backdrop="static">
        <Form onSubmit={this.handleSubmit}>
          <Modal.Header>
            <Modal.Title>SPARQL editor</Modal.Title>
          </Modal.Header>
          <Modal.Body>

            <div id='yasgui' />

          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.props.onClose}>
              Close
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    );
  }
}



export default SPARQLEditorModal;
