import React, { Component } from "react";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import ListGroup from 'react-bootstrap/ListGroup';
import Table from 'react-bootstrap/Table';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import ProgressBar from "react-bootstrap/ProgressBar";
import { ToastContainer, toast } from 'react-toastify';
import TableScrollbar from 'react-table-scrollbar';
import Collapse from "react-bootstrap/Collapse";
import NewCampaignModal from "./modals/NewCampaignModal.js";
import JoinModal from "./modals/JoinModal.js";
import AssignDatasetModal from "./modals/AssignDatasetModal.js";
import ValidationModal from "./modals/ValidationModal.js";
import PropertyValuesModal from "./modals/PropertyValuesModal.js";
import DeleteModal from "./modals/DeleteModal.js";
import DatasetValidationProgress from "./DatasetValidationProgress"
import AddToProjectModal from "./modals/AddToProjectModal.js";

import { Localizer } from '../../config/localizer.js'
import LoadingComponent from '../LoadingComponent';
import { getCampaign, createCampaign, updateCampaign, deleteCampaign, getActiveCampaigns, getOwnedCampaigns, getJoinedCampaigns, joinCampaign, unjoinCampaign, getAssignedDatasets, getAssignedDatasetsToUser, assignDatasetToUser, assignAllDatasetsToUser, unassignDatasetFromUser, unassignAllDatasetsFromUser, addDatasetToCampaign, removeDatasetFromCampaign, addCampaignToProject, removeCampaignFromProject, getMyAssignedDatasets, getDatasetProgress } from '../../utils/CampaignAPI.js';
import { viewAnnotationValidation, commitAnnotationValidationPage } from '../../utils/PagedAnnotationValidationAPI.js';
import { getDatasetPropertyValueItems } from '../../utils/DatasetAPI.js'
import { API_BASE_URL  } from '../../constants/index.js';
import { throwToast } from '../../utils/UIUtils';
import { actionsMenu, toggleBoxColumn } from '../../utils/UIUtils';

export class CampaignsTab extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedCampaign: null,
      selectionTimestamp: 0,
      campaigns: [],
      myCampaigns: [],

      // campaignAssignedDatasets: null,
      validatorAssignedDatasets: null,
      // validatorNonAssignedDatasets: null,

      assignDatasetModalShow: false,

      // allDatasetsAssigned: false,
      // allDatasetsNonAssigned: false,
      everythingLoaded: false,

      annotationValidations: {},
      annotationValidationsModalShow: false,
      annotationValidationsState: { loaded: false, loading:false, failed:false },
      annotationsEditEnabled: false,
      annotationsEditOnProperty: null,
      annotationsEditOnPropertyPath: null,

      valuesModalShow: false,
      valuesModalControls: true,
      values: [],
      valuesEndpoint: null,
      valuesState: { loaded: false, loading:false, failed:false },

      myCampaignsOpen: true,
      myCampaignsUsersOpen: true,
      campaignsOpen: true,

      selectCampaignType: "validation",

      selectedCampaignDatasets: [] // campaign datasets
    };
  }

  componentDidMount() {
    this.getCampaigns(this.props.selectedProject ? this.props.selectedProject.id : null)
  }

  componentWillReceiveProps(props) {
    var id1 = this.props.selectedProject ? this.props.selectedProject.id : null;
    var id2 = props.selectedProject ? props.selectedProject.id : null;
    if (id1 !== id2) {
      this.getCampaigns(id2)
    }

    if (this.state.selectedCampaign && this.state.selectedUser) {
      this.getAssignedDatasetsToUser(this.state.selectedCampaign.id, this.state.selectedUser.id); //ok
    }
  }

  getCampaigns(projectId) {
    if (this.props.mode === 'VALIDATOR') {
      this.getMyCampaigns(projectId);
      this.getActiveCampaigns();
    } else if (this.props.mode === 'EDITOR') {
      this.getMyCampaigns(projectId);
    }
  }

  getActiveCampaigns() {
    getActiveCampaigns('ANNOTATION_VALIDATION')
      .then(response => {
        this.setState({ campaigns: response.data });
      })
      .catch(error => {
      });
  }

  projectChanged(event) {
    // var projectId = event.target.value;
    // this.getMyCampaigns(projectId);
  }

  getMyCampaigns(projectId) {
    if (this.props.mode === 'VALIDATOR') {
      getJoinedCampaigns('ANNOTATION_VALIDATION')
        .then(response => {
          this.setState({ myCampaigns: response.data })
        })
        .catch(error => {
        });
    } else if (this.props.mode === 'EDITOR') {
      getOwnedCampaigns('ANNOTATION_VALIDATION', projectId)
        .then(response => {
          this.setState({ myCampaigns: response.data })
        })
        .catch(error => {
        });
    }
  }

  getAssignedDatasetsToUser(campaignId, userId) {
    getAssignedDatasetsToUser(campaignId, userId)
      .then(response => {
          // let ownedAssignedDifference = [];
          //
          // for (let entry of this.props.datasets) {
          //
          //    if (!response.data.map(x => x.id).includes(entry.id)) {
          //      ownedAssignedDifference.push(entry);
          //    }
          //  }
          //
          //  if (ownedAssignedDifference.length === 0) {
          //    this.allDatasetsAssigned = true;
          //  } else if (response.length === 0) {
          //    this.allDatasetsNonAssigned = true;
          //  }

           this.setState({ validatorAssignedDatasets: response.data, // validatorNonAssignedDatasets: ownedAssignedDifference
           });
         })
         .catch(error => {
      })
  }

  getDatasetsProgress(datasets) {

    var prog = new Array(datasets.length);
    var promises = [];

    for (var i in datasets) {
      var _this = this;

      promises.push(
        new Promise(function(resolve, reject) {
          var promisei = i;
          var ds  = datasets[promisei]
          var id = ds.id

          getDatasetProgress(_this.state.selectedCampaign.id, id)
            .then(json => {

              var response = json.data

              let entry = {
                dataset: ds,
                progArray: response.sort((a, b) => (a.progress.progress > b.progress.progress) ? 1 : -1),
                avgProgress: _this.getAverageProgress(json.data)
              };

              prog[promisei] = entry;

              resolve();
            })
            .catch(error => {
                console.error(error)
                resolve();
            })
        })
      )
    }

    Promise.all(promises)
     .then(() => {

       var map = new Map()
       for (var x of prog) {
         map.set(x.dataset.id, {progArray: x.progArray, avgProgress: x.avgProgress});
       }

       this.setState({
         // datasetsProgress: prog,
         everythingLoaded: true,

         selectedCampaignDatasets: this.state.selectedCampaignDatasets.map(dataset => {
           var r = map.get(dataset.id);
           if (r) {
             return {...dataset, ...r}
           } else {
             return dataset;
           }
         })})
     })
     .catch(error => {
     });
  }

  getAverageProgress(progArray) {
    if (progArray.length > 0) {
      let progs = progArray.map(el => el.progress.progress);
      return parseFloat((progs.reduce((a, b) => a + b, 0) / progs.length).toFixed(2));
    }
    return 101;
  }

  validationModalClosed(){
    this.setState({ annotationValidationsModalShow: false });

    this.getDatasetsProgress(this.state.selectedCampaignDatasets);
    //
    // if (this.state.selectedCampaign && this.state.selectedUser) {
    //   // this.getCampaignAssignedDatasets(this.state.selectedCampaign.id);
    //   this.getAssignedDatasetsToUser(this.state.selectedCampaign.id, this.state.selectedUser.id); //ok
    // } else if (this.state.selectedCampaign) {
    //   // this.getCampaignAssignedDatasets(this.state.selectedCampaign.id);
    // }
  }

  executeAction(action, params) {
    if (action === 'create-campaign') {
      if (params.id && params.id != null) {
        updateCampaign(params.id, params.name, params.state)
          .then(response => {
              this.setState( { myCampaigns: this.state.myCampaigns.map(el => el.id === params.id ? response.data : el), selectedCampaign: response.data} )
          })
          .catch(error => {

          })
      } else {
        createCampaign(params.name, params.state, 'ANNOTATION_VALIDATION', this.props.selectedProject ? this.props.selectedProject.id : null)
          .then(response => {
            this.setState( { myCampaigns: this.state.myCampaigns.concat(response.data)} )
          })
          .catch(error => {

          })
        }
    } else if (action === 'join-campaign') {
      joinCampaign(params.id)
        .then(response => {
          throwToast('success', response.message);
          this.getMyCampaigns();
        })
        .catch(error => {
          // throwToast('error', 'You have already joined this campaign.');
        })
    } else if (action === 'unjoin-campaign') {
      this.setState({ deleteModalShow: true, deleteModalCommand: 'unjoin-campaign', deleteModalParams:params})
    } else if (action === 'unjoin-campaign-ok') {
      unjoinCampaign(params.campaignId, params.userId)
        .then(response => {
          throwToast('success', response.message);
          this.setState({ selectedUser: null  });
          this.getMyCampaigns();
        })
        .catch(error => {
        });
    } else if (action === 'select-campaign') {
      if (this.props.mode === 'EDITOR') {
        // this.allDatasetsAssigned = false;
        // this.allDatasetsNonAssigned = false;
        this.setState({ everythingLoaded: false, selectedUser: null, campaignsOpen: true });

        // this.getCampaignAssignedDatasets(params.campaign.id);

        getCampaign(params.campaign.id, true)
          .then(response => {
            this.setState({selectedCampaign: response.data, selectedCampaignDatasets: response.data.datasets },
                           () => this.getDatasetsProgress(this.state.selectedCampaignDatasets))
          })
          .catch(error => {
          })

      } else if (this.props.mode === 'VALIDATOR') {
        if (this.state.selectedCampaign && this.state.selectedCampaign.id == params.campaign.id) {
            return;
        } else {
          // this.allDatasetsAssigned = false;
          // this.allDatasetsNonAssigned = false;
          this.setState({ everythingLoaded: false, selectedCampaign: params.campaign, selectionTimestamp: Date.now(), //datasetsProgress: []
                        });

          getMyAssignedDatasets(params.campaign.id)
            .then(response => {
              this.setState({selectedCampaignDatasets: response.data },
                           () => this.getDatasetsProgress(this.state.selectedCampaignDatasets))
            })
            .catch(error => {
            })
        }
      }
    } else if (action == 'select-user')  {
      if (this.state.selectedCampaign && this.state.selectedUser && this.state.selectedCampaign.id == params.campaign.id && this.state.selectedUser.id == params.user.id) {
        return;
      } else {
        // this.state.everythingLoaded = false;
        // this.allDatasetsAssigned = false;
        // this.allDatasetsNonAssigned = false;
        // this.setState({ selectedCampaign: campaign, selectedUser: user, selectionTimestamp: Date.now(), datasetsProgress: [] });
        // this.setState({ selectedCampaign: params.campaign, selectedUser: params.user, validatorAssignedDatasets: null, validatorNonAssignedDatasets: null, campaignsOpen: false },
        this.setState({ selectedCampaign: params.campaign, selectedUser: params.user, campaignsOpen: false },
        this.getAssignedDatasetsToUser(params.campaign.id, params.user.id)); //ok
      }
    } else if (action === 'update-campaign') {
      this.setState({ newCampaignModalShow: true })
    } else if (action === 'add-campaign-to-project') {
      addCampaignToProject(params.campaignId, params.projectId)
       .then(response => {
         throwToast('success', response.message)
       })
       .catch(error => {
       //   throwToast('error', error.message);
       })
     } else if (action === 'remove-campaign-from-project') {
       this.setState({ deleteModalShow:true, deleteModalCommand: 'remove-campaign-from-project', deleteModalParams:params})
     } else if (action === 'remove-campaign-from-project-ok') {
       removeCampaignFromProject(params.campaignId, this.props.selectedProject.id)
        .then(response => {
          throwToast('success', response.message)
          this.setState( { myCampaigns: this.state.myCampaigns.filter(el => el.id !== params.campaignId), selectedCampaign: null} )
        })
        .catch(error => {
        //   throwToast('error', error.message);
        })
    } else  if (action === 'delete-campaign') {
      this.setState({ deleteModalShow:true, deleteModalCommand: 'delete-campaign', deleteModalParams:params})
    } else  if (action === 'delete-campaign-ok') {
      deleteCampaign(params.id)
         .then(response => {
            throwToast('success', response.message);
            this.setState({ myCampaigns: this.state.myCampaigns.filter(el => el.id !== params.id),
                          selectedCampaign: null, selectedUser: null })
         })
         .catch(error => {
           throwToast('error', error.message);
         })
    } else if (action === 'assign-dataset-to-user') {
      var _this = this;
      assignDatasetToUser(this.state.selectedCampaign.id, this.state.selectedUser.id, params.datasetId)
        .then(response => {
          // this.allDatasetsNonAssigned = false;
          this.getAssignedDatasetsToUser(_this.state.selectedCampaign.id, _this.state.selectedUser.id); //ok
          throwToast('success', response.message);
        })
        .catch(error => {
        });
    } else if (action === 'add-dataset-to-campaign') {
      // if (this.props.mode === 'EDITOR') {
      //   assignDatasetToCampaign(params.uri);
      // } else if (this.props.mode === 'VALIDATOR') {
        addDatasetToCampaign(this.state.selectedCampaign.id, params.id)
        .then(response => {
            this.setState({ selectedCampaignDatasets: response.data.datasets },
              () => this.getDatasetsProgress(this.state.selectedCampaignDatasets))
        })
        .catch(error => {
        })
    } else if (action === 'remove-dataset-from-campaign') {
      this.setState({ deleteModalShow:true, deleteModalCommand: 'remove-dataset-from-campaign', deleteModalParams:params})
    } else if (action === 'remove-dataset-from-campaign-ok') {
      // if (this.props.mode === 'EDITOR') {
      //   assignDatasetToCampaign(params.uri);
      // } else if (this.props.mode === 'VALIDATOR') {
        removeDatasetFromCampaign(this.state.selectedCampaign.id, params.id)
        .then(response => {
            this.setState({ selectedCampaignDatasets: this.state.selectedCampaignDatasets.filter(dataset => dataset.id != params.id) })
        })
        .catch(error => {
        })
    } else if (action === 'select-campaign-type') {
      if (params.selectCampaignType === 'validations') {
        this.setState({ selectedCampaign: null})
      }
    } else if (action === 'assign-all-datasets-to-user') {
      var _this = this;
      assignAllDatasetsToUser(params.campaignId, params.userId)
        .then(response => {
          // this.allDatasetsNonAssigned = false;
          this.getAssignedDatasetsToUser(params.campaignId, params.userId); //ok
          throwToast('success', response.message);
        })
        .catch(error => {
          // throwToast('error', 'Giving access to all datasets, failed');
          // console.error(error);
        })
    } else if (action === 'unassign-dataset-from-user') {
      this.setState({ deleteModalShow:true, deleteModalCommand: 'unassign-dataset-from-user', deleteModalParams:params})
    } else if (action === 'unassign-dataset-from-user-ok') {
      var _this = this;
      unassignDatasetFromUser(this.state.selectedCampaign.id, params.user.id, params.datasetId)
        .then(response => {
          // this.allDatasetsAssigned = false;
          this.getAssignedDatasetsToUser(_this.state.selectedCampaign.id, params.user.id); //ok
          throwToast("success", response.message);
        })
        .catch(error => {
        });
    } else if (action === 'unassign-all-datasets-from-user') {
      if (window.confirm('Are you sure you want to remove access to all datasets for user"' + params.user.email + '"?')) {
        var _this = this;
        unassignAllDatasetsFromUser(this.state.selectedCampaign.id, params.user.id)
          .then(response => {
            // this.allDatasetsAssigned = false;
            this.getAssignedDatasetsToUser(this.state.selectedCampaign.id, params.user.id); //ok
            throwToast('success', response.message);
          })
          .catch(error => {
          })
      }
    }
  }

  schemaActions(action, params) {
  if (action === 'commit-annotation-validation-page') {
    commitAnnotationValidationPage(params.pageid, params.edits, params.lockId)
    .then(response =>
      this.setState({ annotationValidations: {}, annotationValidationsModalShow:true, annotationValidationsState: { loaded: false, loading: true, failed: false}}, () =>
      viewAnnotationValidation(params.id, params.currentPage, params.mode, params.serial, params.navigation, params.requestedPage)
      .then(
        json => {
          if (json.errorMessage === "NO_PAGE_FOUND") {
            let obj = {
              currentPage: params.currentPage,
              data: [],
              errorMessage: json.errorMessage,
              filter: null,
              id: params.id,
              lockId: params.lockId,
              mode: params.mode.split('_')[0]+'_ONLY',
              pagedAnnotationValidationId: params.id,
              pagedAnnotationValidationPageId: params.pageid,
              totalPages: 0
            }
            this.setState({ annotationValidations: obj, annotationValidationsModalShow:true, annotationValidationsState: { loaded: true, loading: false, failed: false}});
          }
          else {
            this.setState({ annotationValidations: json, annotationValidationsModalShow:true, annotationValidationsState: { loaded: true, loading: false, failed: false}});
          }
        },
        () => this.setState({ annotationValidations: {}, annotationValidationsState: {loaded: false, loading: false, failed: true}}) )
    )
    )
  } else if (action === 'get-items-by-property-value') {
    // var uri = this.state.schemaDataset['@id']
    var uri = this.props.database.resourcePrefix + "dataset/" + this.state.schemaDataset.uuid

    var lodview = true;
    if (lodview) {
      var xendpoint = API_BASE_URL + "/content/" + uri.substr(uri.lastIndexOf('/') + 1) + "/sparql"
    } else {
      xendpoint = null
    }

    if (params.page == 1) {
     this.setState({ values: [], valuesMetadata: null, valuesPagination: null, valuesValue: null, valuesModalShow: true, valuesEndpoint: null, valuesPath: [], valuesState: { loaded: false, loading:true, failed:false }}, () =>
       getDatasetPropertyValueItems(this.state.schemaDataset.id, params.path, params.value, params.page, params.size)
        .then(json => this.setState({ values: json.data, valuesMetadata: json.metadata, valuesPagination: json.pagination, valuesValue: params.value, valuesModalControls: false, valuesEndpoint: xendpoint, valuesPath: params.path, valuesState: { loaded: true, loading:false, failed:false } }),
               () => this.setState({ values: [], valuesMetadata: null, valuesPagination: null, valuesValue: null, valuesEndpoint: null, valuesPath: [], valuesState: { loaded: false, loading:false, failed:true } }),
       ))
    } else {
      this.setState({ valuesState: { loaded: true, loading:true, failed:false }}, () =>
        getDatasetPropertyValueItems(this.state.schemaDataset.id, params.path, params.value, params.page, params.size)
         .then(json => this.setState({ values: this.state.values.concat(json.data), valuesMetadata: json.metadata, valuesPagination: json.pagination, valuesValue: params.value, valuesModalControls: false, valuesEndpoint: xendpoint, valuesPath: params.path, valuesState: { loaded: true, loading:false, failed:false } }),
                () => this.setState({ values: [], valuesMetadata: null, valuesPagination: null, valuesValue: null, valuesEndpoint: null, valuesPath: [], valuesState: { loaded: false, loading:false, failed:true } }),
        ))
    }
  }
}

  // openValidations(dataset, pav, allowEdit) {
  openValidations(dataset, pav, allowEdit) {
    var params = {
      id: pav.id,
      onProperty: pav.propertyPath[pav.propertyPath.length - 1].uri,
      currentPage: 0,
      requestedPage: null,
      mode: "ANNOTATED_ONLY_SERIAL",
      serial: true,
      navigation: "RIGHT",
      edit: allowEdit,
      onPropertyPath: pav.propertyPath,
      pav: pav
    };

    if (pav.annotatedPagesCount === 0) {
      params.mode = "UNANNOTATED_ONLY_SERIAL";
      // obj.totalPages = el.nonAnnotatedPagesCount;
    }
// console.log(pav)
    // this.setState({ schemaDataset:dataset, annotationValidations: {}, annotationValidationsModalShow:true, annotationValidationsState: { loaded: false, loading: true, failed: false}}, () =>
    this.setState({ schemaDataset:dataset, annotationValidations: {}, annotationPav: null, annotationValidationsModalShow:true, annotationValidationsState: { loaded: false, loading: true, failed: false}}, () =>
    viewAnnotationValidation(params.id, params.currentPage, params.mode, params.serial, params.navigation, params.requestedPage)
    .then(json => {
        this.setState({ annotationValidations: json, annotationPav: params.pav, annotationsEditOnProperty: params.onProperty, annotationsEditOnPropertyPath: params.onPropertyPath,  annotationsEditEnabled: params.edit, annotationValidationsModalShow:true, annotationValidationsState: { loaded: true, loading: false, failed: false}})},
          () => this.setState({ annotationValidations: {}, annotationPav: null, annotationValidationsState: {loaded: false, loading: false, failed: true}}) )
        )
  }

  render() {

    return (
      <Container className="pl-2">
        <Row>
          <Col md={3}>

            {this.props.mode === 'EDITOR' &&
            <Row>
              <Col md={3}>Project</Col>
              <Col className="align-self-center mb-2">
                <Form.Control as="select" ref={node => (this.projectA = node)}
                  defaultValue={this.props.selectedProject ? this.props.selectedProject.id : ''}
                  onChange={(event) => { this.projectChanged(event); this.props.projectChanged(event);  this.setState({selectedCampaign : null})}}
                  required>
                  <option disabled value='@'> --- My projects --- </option>
                  <option value=''> &lt;All my projects&gt; </option>
                  {this.props.myProjects.map((project, index) =>
                    <option key={index} value={'m' + project.id}>{project.name}</option>
                  )}
                  <option disabled value='@'> --- Joined projects --- </option>
                  {this.props.joinedProjects.map((project, index) =>
                    <option key={index} value={'p' + project.id}>{project.name}</option>
                  )}
                </Form.Control>
              </Col>
            </Row>}

            <Row>
              <Col className="align-self-center data-import-buttons">
                <ButtonGroup className="choice-buttons">
                  <Button className="data-choice-button" variant={this.state.selectCampaignType === "validation"? "danger" : "secondary"} onClick={() => this.executeAction('select-campaign-type', { selectCampaignType: "validations"})}>Validation</Button>
                </ButtonGroup>
              </Col>
            </Row>

            <Container className={this.state.myCampaigns && this.state.myCampaigns.length > 0 ? "groupborder" : "groupborder-empty"}>
              <Row className={this.state.myCampaigns && this.state.myCampaigns.length > 0 ? "header" : "header-empty"}>
                <Col>
                  Campaigns
                </Col>

                {toggleBoxColumn('myCampaignsOpen', this, 'myCampaigns')}

                <Col className="mybutton" md="auto">
                  {actionsMenu(
                  <div>
                    {this.props.mode === 'EDITOR' &&
                    <Dropdown.Item className="py-2"
                                   onClick={() => this.setState({ editCampaign: null, newCampaignModalShow: true })}>
                      <span className="menu-icon fa fa-plus fa-lg mr-3" />New campaign...
                    </Dropdown.Item>}

                    {this.props.mode === 'VALIDATOR' &&
                    <Dropdown.Item className="py-2"
                                   onClick={() => this.setState({ joinCampaignModalShow: true })}>
                      <span className="menu-icon fa fa-link fa-lg mr-3" />Join campaign...
                    </Dropdown.Item>}
                  </div>)}

                  {this.state.newCampaignModalShow &&
                  <NewCampaignModal show={this.state.newCampaignModalShow}
                                    campaign={this.state.editCampaign}
                                    onOK={(id, name, state) => { this.executeAction('create-campaign', {id: id, name: name, state: state}); this.setState({ newCampaignModalShow: false})} }
                                    onClose={() => this.setState({ newCampaignModalShow: false})}/>}

                  {this.state.joinCampaignModalShow &&
                  <JoinModal show={this.state.joinCampaignModalShow}
                                     objects={this.state.campaigns}
                                     objectType='Campaign'
                                     onOK={(id) => { this.executeAction('join-campaign', {id: id}); this.setState({ joinCampaignModalShow: false})} }
                                     onClose={() => this.setState({ joinCampaignModalShow: false})}/>}

                </Col>
              </Row>

              <Collapse in={this.state.myCampaignsOpen}>
                <div>
                  {this.state.myCampaigns.map((campaign,index) => (
                  <Row key={index}>
                    <Col md={12} className={this.state.selectedCampaign && this.state.selectedCampaign.id === campaign.id? " selected-item" : ""}>
                      <span className="lbutton alink" onClick={() => this.executeAction('select-campaign', { campaign: campaign })}>
                      {campaign.name}
                      </span>
                    </Col>
                  </Row>))}
                </div>
              </Collapse>

            </Container>

            {this.props.mode === 'EDITOR' && this.state.selectedCampaign && this.state.selectedCampaign.validators && this.state.selectedCampaign.validators.length > 0 && // users
            <Row>
              <Col className="in-down">
                <span className='crimson-std fa fa-angle-down fa-lg'></span>
              </Col>
            </Row>}

            {this.props.mode === 'EDITOR' && this.state.selectedCampaign && this.state.selectedCampaign.validators && this.state.selectedCampaign.validators.length > 0 && // users
            <Container className="groupborder">
              <Row className="header">
                <Col>
                  Campaign users
                </Col>
                <Col className="mybutton" md="auto">
                  <Button type="button" className="menubutton"  aria-label="Toggle" onClick={() => this.setState({ myCampaignsUsersOpen: !this.state.myCampaignsUsersOpen})}><span className={this.state.myCampaignsUsersOpen ? 'fa fa-angle-double-up' : 'fa fa-angle-double-down'}></span></Button>
                </Col>
              </Row>

              <Collapse in={this.state.myCampaignsUsersOpen}>
                <div>
                  {this.state.selectedCampaign.validators.map((validator,index) => (
                  <Row key={index}>
                    <Col md={12} className={this.state.selectedUser && this.state.selectedUser.id === validator.id? " selected-item" : ""}>
                      <span className="lbutton alink" onClick={() => this.executeAction('select-user', { campaign: this.state.selectedCampaign, user: validator } )}>
                      {validator.name}
                      </span>
                    </Col>
                  </Row>))}
                </div>
              </Collapse>
            </Container>}

          </Col>

          <Col>

            <Container className="userspace-right">
              <Row className="userspace-right-inner">
                <Container>
                  <Row>
                    <Col>

                      {this.props.mode === 'VALIDATOR' && !this.state.selectedCampaign &&
                      <Container className="groupborder">
                        <Row className="mt-4 text-center">
                          <Col>Select a campaign from the list on the left to view the shared datasets, or click the <span className="fa fa-bars fa-md m-2"/> button to join a campaign.</Col>
                        </Row>
                      </Container>}

                      {this.props.mode == 'EDITOR' && this.state.selectedCampaign &&
                      <Container className="groupborder">
                        <Row className="header">
                          <Col>
                            <span className="crimson-std">Campaign:</span> {this.state.selectedCampaign.name}
                          </Col>

                          {(this.props.mode === 'EDITOR' && this.state.selectedCampaign) &&
                          <Col className="mybutton" md="auto">
                            {actionsMenu(
                            <div>
                              <Dropdown.Item className="py-2"
                                             onClick={() => { this.setState({ editCampaign: this.state.selectedCampaign}); this.executeAction('update-campaign', {id : this.state.selectedCampaign.id})}}>
                                 <span className="menu-icon fa fa-edit fa-lg mr-3" />Edit...
                              </Dropdown.Item>

                              <Dropdown.Item className="py-2"
                                             disabled={this.state.selectedCampaign.validators && this.state.selectedCampaign.validators.length > 0}
                                             onClick={() => this.executeAction('delete-campaign', {id : this.state.selectedCampaign.id})}>
                                 <span className="menu-icon fa fa-trash fa-lg mr-3" />Delete
                              </Dropdown.Item>

                              <Dropdown.Divider/>

                              <Dropdown.Item className="py-2"
                                             onClick={() => this.setState({addToProjectModalShow : true})}>
                                <span className="menu-icon fa fa-plus fa-lg mr-3" />Add to project...
                              </Dropdown.Item>

                              <Dropdown.Item className="py-2"
                                             disabled={!this.props.currentProject}
                                             onClick={() => this.executeAction('remove-campaign-from-project', {campaignId: this.state.selectedCampaign.id})}>
                                <span className="menu-icon fa fa-plus fa-lg mr-3" />Remove from current project
                              </Dropdown.Item>

                            </div>)}

                          </Col>}
                        </Row>

                        <Row className="object-field-row">
                          <Col md={3}>
                            State
                          </Col>
                          <Col md={9}>
                            {this.state.selectedCampaign.state}
                          </Col>
                        </Row>

                      </Container>}

                      {this.props.mode == 'VALIDATOR' && this.state.selectedCampaign &&
                      <Container className="groupborder-empty">
                        <Row className="header-empty">
                          <Col>
                            <span className="crimson-std">Campaign:</span> {this.state.selectedCampaign.name}
                          </Col>
                        </Row>
                      </Container>}

                      {((this.props.mode == 'EDITOR' && this.state.selectedCampaign && !this.state.selectedUser) ||
                        (this.props.mode == 'VALIDATOR' && this.state.selectedCampaign)) &&
                      <Container className={this.state.selectedCampaignDatasets && this.state.selectedCampaignDatasets.length > 0 ? "groupborder" : "groupborder-empty"}>
                        <Row className={this.state.selectedCampaignDatasets && this.state.selectedCampaignDatasets.length > 0 ? "header" : "header-empty"}>
                          <Col className="bold">
                            <span className="crimson-std">Datasets</span>
                          </Col>

                          {this.props.mode === 'EDITOR' &&
                          <Col className="mybutton" md="1">
                            {actionsMenu(
                            <div>
                              <Dropdown.Item className="py-2"
                                             onClick={() => this.setState({addDatasetModalShow: true})}>
                                 <span className="menu-icon fa fa-trash fa-lg mr-3" />Add dataset...
                              </Dropdown.Item>
                            </div>)}

                            {this.state.addDatasetModalShow &&
                            <JoinModal show={this.state.addDatasetModalShow}
                                               objects={this.props.datasets}
                                               objectType='Dataset'
                                               onOK={(id) => { this.executeAction('add-dataset-to-campaign', {id: id}); this.setState({ addDatasetModalShow: false})} }
                                               onClose={() => this.setState({ addDatasetModalShow: false})}/>}

                          </Col>}
                        </Row>

                        <Row className="align-items-center">
                          <Col>
                            {this.state.selectedCampaignDatasets && this.state.selectedCampaignDatasets.map((el, index) => (
                            <Container key={index} className="mappingContainer">
                              <Row key={index} className="mappingRow">
                                <Col md={11} className="">
                                  <Row>
                                    <Col>
                                      <span className="abutton">{el.name}</span>
                                    </Col>
                                  </Row>
                                </Col>

                                <Col md={1} className="">
                                  {actionsMenu(
                                  <div>
                                    <Dropdown.Item className="py-2"
                                                   onClick={() => this.executeAction('remove-dataset-from-campaign', {id: el.id})}>
                                      <span className="menu-icon fa fa-edit fa-lg mr-3" />Remove
                                    </Dropdown.Item>
                                  </div>)}
                                </Col>
                              </Row>

                              {el.progArray &&
                              <Row className="align-items-center">
                                <Col>
                                  <DatasetValidationProgress dataset={el}
                                                             validation={true}
                                                             openValidations={(entry, active) => this.openValidations(el, entry, active)}/>
                                </Col>
                              </Row>}
                            </Container>))}

                            {!this.state.everythingLoaded &&
                              <LoadingComponent text="Loading" />}

                            {this.props.mode === 'VALIDATOR' && this.state.everythingLoaded && (!this.state.selectedCampaignDatasets || this.state.selectedCampaignDatasets.length === 0) &&
                            <Row>
                              <Col className="text-center mt-3">
                                {this.state.selectedCampaign.name} hasn't assigned any datasets to you yet.
                              </Col>
                            </Row>}

                          </Col>
                        </Row>
                      </Container>}

                    </Col>
                  </Row>
                </Container>

                {(this.props.mode === 'EDITOR' && this.state.selectedCampaign && this.state.selectedUser) &&
                <Container>
                  <Row>
                    <Col className="in-down">
                      <span className='crimson-std fa fa-angle-down fa-lg'></span>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Container className="groupborder-empty">
                        <Row className="header-empty">
                          <Col className="bold">
                            <span className="crimson-std">Validator: </span>{this.state.selectedUser.name} ({this.state.selectedUser.email})
                          </Col>

                          <Col className="mybutton" md="auto">
                            {actionsMenu(
                            <div>
                              <Dropdown.Item className="py-2"
                                             onClick={() => this.executeAction('unjoin-campaign', { campaignId: this.state.selectedCampaign.id, userId: this.state.selectedUser.id })}>
                                 <span className="menu-icon fa fa-times fa-lg mr-3" />Remove user from campaign
                              </Dropdown.Item>
                            </div>)}
                          </Col>
                        </Row>
                      </Container>
                    </Col>
                  </Row>
                </Container>}

                {(this.props.mode === 'EDITOR' && this.state.selectedCampaign && this.state.selectedUser) &&
                <Container>
                  <Row>
                    <Col className="in-down">
                      <span className='crimson-std fa fa-angle-down fa-lg'></span>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Container className={this.state.validatorAssignedDatasets && this.state.validatorAssignedDatasets.length > 0 ? "groupborder" : "groupborder-empty"}>
                        <Row className={this.state.validatorAssignedDatasets && this.state.validatorAssignedDatasets.length > 0 ? "header" : "header-empty"}>
                          <Col>
                            <span className="crimson-std">Assigned datasets</span>
                          </Col>

                          <Col className="mybutton" md="auto">
                            {actionsMenu(
                            <div>
                              <Dropdown.Item className="py-2"
                                             onClick={() => this.setState({assignDatasetModalShow: true})}>
                                 <span className="menu-icon fa fa-plus fa-lg mr-3" />Assign dataset to user
                              </Dropdown.Item>

                              <Dropdown.Item className="py-2"
                                             disabled={!this.state.validatorAssignedDatasets || this.state.validatorAssignedDatasets.length == 0}
                                             onClick={() => this.executeAction('unassign-all-datasets-from-user', { user : this.state.selectedUser })}>
                                 <span className="menu-icon fa fa-times fa-lg mr-3" />Unassign all datasets from user
                              </Dropdown.Item>

                            </div>)}
                          </Col>
                        </Row>
                        <Row className="align-items-center">
                          <Col>
                            {this.state.validatorAssignedDatasets && this.state.validatorAssignedDatasets.map((el, index) => (
                            <Row key={"assigned-dataset-" + index}>
                              <Col>
                                {el.name}
                              </Col>
                              <Col md="auto">
                                <Button type="button" className="deleteeditbutton mt-0" aria-label="Delete"
                                        onClick={() => this.executeAction('unassign-dataset-from-user', { user : this.state.selectedUser, datasetId: el.id })}>
                                  <span className="fa fa-times failed" title="Remove dataset access" />
                                </Button>
                              </Col>
                            </Row>))}
                          </Col>
                        </Row>

                      </Container>
                    </Col>
                  </Row>
                </Container>}
              </Row>
            </Container>
        </Col>
      </Row>

      {this.state.assignDatasetModalShow &&
      <AssignDatasetModal show={this.state.assignDatasetModalShow}
                         // datasets={this.state.validatorNonAssignedDatasets}
                         datasets={this.state.selectedCampaignDatasets}
                         onOK={(datasetId) => { datasetId === 'ALL' ?
                                                   this.executeAction('assign-all-datasets-to-user', { campaignId: this.state.selectedCampaign.id, userId: this.state.selectedUser.id}) :
                                                   this.executeAction('assign-dataset-to-user', {datasetId: datasetId});
                                                this.setState({ assignDatasetModalShow: false})} }
                         onClose={() => this.setState({ assignDatasetModalShow: false})}/>}


      {this.state.annotationValidationsModalShow &&
        <ValidationModal
             // dataset={this.state.schemaDataset['@id']}
             dataset={this.state.schemaDataset}
             mode='VALIDATOR'
             show={this.state.annotationValidationsModalShow}
             state={this.state.annotationValidationsState}
             value={this.state.annotationValidations}
             pav={this.state.annotationPav}
             edit={this.state.annotationsEditEnabled}
             onProperty={this.state.annotationsEditOnProperty}
             onPropertyPath={this.state.annotationsEditOnPropertyPath}
             datasetName={this.state.schemaDataset.name}
             // asProperty={this.state.annotationsEditAsProperty}
             // mode={this.state.annotationsEditMode}
             // page={this.state.annotationsEditPage}
             // id={this.state.editGroupId}
             // annotators={this.state.annotationsEditAnnotators}
             language={this.props.language}
             rdfVocabularies={this.props.rdfVocabularies}
             actions={(action, params) => this.schemaActions(action, params)}
             onClose={() => this.validationModalClosed()}/>}

       {this.state.valuesModalShow &&
         <PropertyValuesModal show={this.state.valuesModalShow}
                            type='ITEMS'
                            value={this.state.valuesValue}
                            values={this.state.values}
                            metadata={this.state.valuesMetadata}
                            pagination={this.state.valuesPagination}
                            state={this.state.valuesState}
                            endpoint={this.state.valuesEndpoint}
                            database={this.props.database}
                            path={this.state.valuesPath}
                            language={this.props.language}
                            controls={this.state.valuesModalControls}
                            actions={(action, params) => this.schemaActions(action, params)}
                            onClose={() => this.setState({ valuesModalShow: false })}/>}

        {this.state.deleteModalShow && <DeleteModal show={this.state.deleteModalShow}
                                command={this.state.deleteModalCommand}
                                params={this.state.deleteModalParams}
                                actions={(choice, command, params) => { this.setState( {deleteModalShow:false }); return (choice === 'ok' ? this.executeAction(command + '-ok', params): null)} }
                                />}

        {this.state.addToProjectModalShow &&
        <AddToProjectModal show={this.state.addToProjectModalShow}
                           myProjects={this.props.myProjects}
                           joinedProjects={this.props.joinedProjects}
                           onOK={(projectId, status) => { this.executeAction('add-campaign-to-project', {campaignId: this.state.selectedCampaign.id, projectId, status}); this.setState({ addToProjectModalShow: false})}}
                           onClose={() => this.setState({ addToProjectModalShow: false})}/>}

    </Container>


    );
  }
}

export default CampaignsTab;
