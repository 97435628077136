import React, { Component } from "react";
import Container from "react-bootstrap/Container";
import Collapse from "react-bootstrap/Collapse";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import InputGroup from "react-bootstrap/InputGroup";
import FormControl from "react-bootstrap/FormControl";
import { toast } from 'react-toastify';
import { getAllTemplates, createTemplate, deleteTemplate, getTemplate } from "../../../utils/TemplateAPI"
import { getOtherPublicDatasets, getMyDatasets, getDatasetMetadata } from "../../../utils/DatasetAPI"
// import { getPrototypes } from "../../../utils/PrototypeAPI"
//import { getAllTemplates, createAnnotatorTemplate, deleteTemplate, getTemplate, getEditAnnotator } from "../../../utils/APIUtils"
import { renderClasses, applySelection, readClasses, getSchema } from '../../../utils/SchemaUtils';

import '../Profile.css'

import { copyReplaceAt } from '../../../utils/functions.js'
import { toggleBoxColumn } from '../../../utils/UIUtils.js'
import { checkAnnotatorIdentifier } from "../../../utils/AnnotatorAPI.js";

export class AnnotatorModal extends Component {
  constructor(props) {
    super(props);
    this.preprocess = [];
    this.preprocessRole = [];
    // this.modifier = [];

    var selectedAnnotatorType
    var selectedRdfVocabulary
    var selectedProperty

    // console.log(props)
    //
    if (props.annotator) {
      // console.log(props.annotator)
      // console.log(props.userAnnotators)
      if (props.annotator.annotator) {
        for (var i in props.dataAnnotators) {
          if (props.dataAnnotators[i].identifier === props.annotator.annotator) {
            selectedAnnotatorType = props.dataAnnotators[i];
            break;
          }
        }
      } else if (props.annotator.annotatorId) {
        for (var i in props.userAnnotators) {
          if (props.userAnnotators[i].id === props.annotator.annotatorId) {
            selectedAnnotatorType = props.userAnnotators[i];
            break;
          }
        }
      }

      if (props.annotator.defaultTarget) {
        for (var i in this.props.rdfVocabularies) {
          if (this.props.rdfVocabularies[i].namespace === props.annotator.defaultTarget.namespace) {
            selectedRdfVocabulary = this.props.rdfVocabularies[i];
            break;
          }
        }

        selectedProperty = props.annotator.defaultTarget.uri

      }
    } else {
      // console.log(props);

      for (var i in this.props.rdfVocabularies) {
        // TEMP SOLUTION
        if (props.onProperty && props.onProperty.uri.startsWith(this.props.rdfVocabularies[i].namespace)) {
          selectedRdfVocabulary = this.props.rdfVocabularies[i];
          break;
        }
      }

      if (props.onProperty) {
        selectedProperty = props.onProperty.uri;
      }
    }

    this.state = {
      annotator: props.annotator,
      selectedAnnotatorType,
      preprocess: [],
      preprocessSaved: [],
      savedAnnotators: [],
      loadedAnnotators: false,
      showSaveAsForm: false,
      saveAsName: '',

      identifierExists: false,

      selectedRdfVocabulary,
      selectedProperty,

      thesaurusVocabularies: [],

      schemaOpen: true,
      preprocessOpen: true,
      parametersOpen: true,

      checkboxes: {},
    }

    this.checkboxes = {}
    this.names = {}
    this.values = {}

    getSchema(this.onLoad, this, this.props.dataset.id)

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  onLoad(_this) {
      if (_this.props.annotator) {
          _this.indexChanged();
     }
  }

  nameChanged() {

  }

  indexChanged() {

    var checkboxes = {};
    for (var ch in this.state.checkboxes) {
      if (ch.match('^check-c[0-9]+$')) {
        if (this.checkboxes[ch]) {
          checkboxes[ch] = this.state.checkboxes[ch]
          this.checkboxes[ch].checked = false
        }
      // } else {
      //   delete this.checkboxes[ch]
      }
    }

    this.setState({ checkboxes }, () => {
       // this.applySelection()
       applySelection(this, this.props.annotator)
     } )
  }

  // do not delete. ir is used by the schema UI
  enableNewIndex() {
  }

  componentDidMount() {
    if (this.props.annotator) {
      // this.completeFields(this.props.annotator)

      if (this.props.annotator.preprocess) {
        this.completePreprocesses(this.props.annotator.preprocess)
      }
    }

    if (this.props.project) {
      getOtherPublicDatasets(['VOCABULARY'],'DATASET',this.props.project.id)
        .then(response => {
          // this.setState({ thesaurusVocabularies: response.data.filter(e => e.identifier)})
          this.setState({ thesaurusVocabularies: response.data }, () => {
            if (this.props.annotator) {
              this.completeFields(this.props.annotator)
            }})
        })
    } else {
      getMyDatasets(['VOCABULARY'],'DATASET')
        .then(response => {
          // this.setState({ thesaurusVocabularies: response.data.filter(e => e.identifier)})
          this.setState({ thesaurusVocabularies: response.data }, () => {
            if (this.props.annotator) {
              this.completeFields(this.props.annotator)
            }})
        })
    }
  }

  // Fill all fields except for preprocess. Works for editing and saved annotators
   completeFields(response) {

    let annotator = {
      annotator: response.annotator,
      variant: response.variant,
      asProperty: response.asProperty,
    }

    var selectedAnnotatorType;
    if (response.annotator) {
      this.annotatorType.value = 's' + response.annotator;
      selectedAnnotatorType = this.props.dataAnnotators.filter(el => el.identifier === this.annotatorType.value.substring(1))[0]
    } else {
      this.annotatorType.value = 'u' + response.annotatorId;
      selectedAnnotatorType = this.props.userAnnotators.filter(el => el.id === this.annotatorType.value.substring(1))[0]

    }
    var selectedRdfVocabulary = null
    var selectedProperty = null

    if (response.defaultTarget) {
      for (var i in this.props.rdfVocabularies) {
        if (this.props.rdfVocabularies[i].uriDescriptors && this.props.rdfVocabularies[i].uriDescriptors[0].namespace === response.defaultTarget.namespace) {
          selectedRdfVocabulary = this.props.rdfVocabularies[i];
          break;
        }
      }

      selectedProperty = response.defaultTarget.uri
    }

    var thesaurus = null
    var _this = this;

    if (response.thesaurusId) {
      this.thesaurus.value = response.thesaurusId;
      // thesaurus = this.state.thesaurusVocabularies.filter(el => el.identifier === this.thesaurus.value)[0];
      // thesaurus = this.state.thesaurusVocabularies.filter(el => el.id === this.thesaurus.value)[0];

      getDatasetMetadata(response.thesaurusId)
      .then(xresponse => {
        if (xresponse.data && xresponse.data) {
          thesaurus = xresponse.data;
        }
        _this.postCompleteFields(annotator, selectedAnnotatorType, thesaurus, response, selectedRdfVocabulary, selectedProperty)
      })
    } else {
      this.postCompleteFields(annotator, selectedAnnotatorType, thesaurus, response, selectedRdfVocabulary, selectedProperty)
    }
  }

  thesaurusChanged(thesaurusId) {
    getDatasetMetadata(thesaurusId)
    .then(response => {
      if (response.data && response.data) {
        this.setState({ thesaurus: response.data})
      }
    })

    // this.setState({ thesaurus: this.state.thesaurusVocabularies.filter(el => el.identifier === this.thesaurus.value)[0] })
  }

  postCompleteFields(annotator, selectedAnnotatorType, thesaurus, response, selectedRdfVocabulary, selectedProperty) {
    this.setState({ annotator, selectedAnnotatorType, thesaurus, preprocess: [], preprocessSaved: [], selectedParameters:response.parameters, selectedRdfVocabulary, selectedProperty,
      valuesLists: (selectedAnnotatorType.fields ? { names: selectedAnnotatorType.fields.map(e => e.name)} : undefined) },
     () => {
        if (this.property) {
          this.property.value = response.asProperty ? response.asProperty : ''
        }
        // if (this.tags) {
        //   this.tag.value = response.tags && response.tags.length == 1 ? response.tags[0] : ''
        // }
        if (this.variant) {
          this.variant.value = response.variant
        }
        if (this.rdfVocabularyPrefix) {
          this.rdfVocabularyPrefix.value = selectedRdfVocabulary ? selectedRdfVocabulary.uriDescriptors[0].prefix : ''
        }
        if (this.rdfVocabularyProperty) {
          this.rdfVocabularyProperty.value = selectedProperty ? selectedProperty : ''
        }

        response.parameters.map(el => {
          this['param-' + el.name].value = el.value
        })
    })
  }
  // Fiil preprocess fields
  completePreprocesses(preproc) {
    // var functions = [];
    // functions = functions.concat(this.props.preprocessFunctions);
    // functions = functions.concat(this.props.preprocessOperations);

    var preprocCopy = []
    var preprocessSaved = []
    for (let i = 0; i < preproc.length; i++) {
      var preproci = {...preproc[i]}

      var operator = this.props.preprocessOperations.filter(el => el.uri === preproci.function)[0];

      var f = preproci.function
      var fp = preproci.parameters

       var modifiers = [];

       if (preproci.modifier) {
          modifiers = [ {uri:'None'}, {uri:'http://islab.ntua.gr/ns/d2rml-op#logicalNot'} ].map(el => {return el.uri === preproc[i].modifier ? {...el, selected:true } : {...el, selected:false }})
          preproci = {...preproci, modifiers: [ {uri:'None'}, {uri:'http://islab.ntua.gr/ns/d2rml-op#logicalNot'} ].map(el => {return el.uri === preproc[i].modifier ? {...el, selected:true } : {...el, selected:false }}) }
          delete preproci.modifier;
       } else if (operator) {
         modifiers = [ {uri:'None', selected: true}, {uri:'http://islab.ntua.gr/ns/d2rml-op#logicalNot',  selected: false} ]
         preproci = {...preproci, modifiers: [ {uri:'None', selected:true}, {uri:'http://islab.ntua.gr/ns/d2rml-op#logicalNot', selected:false} ] }
       } else {
         modifiers = [ ]
         preproci = {...preproci, modifiers: [] }
       }

      preprocCopy = preprocCopy.concat({ function: f, parameters: fp, modifiers: modifiers });
      preprocessSaved = preprocessSaved.concat(true);

    }

    this.setState({ preprocess: preprocCopy, preprocessSaved: [] }, () => {
      for (var i in preprocCopy)  {
        this.preprocess[i].value = preprocCopy[i].function
      }
    })
  }


  onSavedTemplateClicked(id) {
    getTemplate(id).then(response => {
      this.completeFields(response.templateJson)
      let preproc = response.templateJson.hasOwnProperty('preprocess') ? response.templateJson.preprocess : []
      this.setState({ preprocess: response.templateJson.hasOwnProperty('preprocess') ? response.templateJson.preprocess : [] })
      this.completePreprocesses(preproc)
      // document.getElementById('modal-title').innerHTML = response.name
    })
  }

  get saveAsCssClasses() {
    return this.state.showSaveAsForm ? 'showForm mt-2 mb-2' : 'hideForm mt-2 mb-2';
  }

  handleSubmit(event) {
    event.preventDefault();

    var params = [];
    for (const i in this.state.selectedAnnotatorType.parameters) {
      params.push({ name: this.state.selectedAnnotatorType.parameters[i].name, value: this['param-' + this.state.selectedAnnotatorType.parameters[i].name].value })
    }

    var _this = this;
    var pp = this.state.preprocess.map((el, index) => {
      var modifier = null;
      if (el.modifiers) {
        var mod = el.modifiers.filter(m => m.selected === true);
        if (mod.length > 0 && mod[0].uri !== 'None') {
          modifier = mod[0].uri;
        }
      }

      var r;
      if (!modifier) {
        r = { function: this.state.preprocessSaved[index] ? el.function : el.function, parameters: el.parameters }
      } else {
        r = { function: this.state.preprocessSaved[index] ? el.function : el.function, parameters: el.parameters, modifier: modifier }
      }

      if (_this.props.onClass) {
        r = { ...r, target: this.preprocessRole[index].value }
      }

      return r;
    })

    if (this.props.onClass) {
      var res = readClasses('', [], { value: 0 }, this )
    }

    if (this.formIsValid()) {

        this.props.onOK(this.props.annotator ? this.props.annotator.id : null,
                        this.name.value,
                        this.identifier.value,
                        this.property ? this.property.value : null,
                        this.getTags(),
                        this.state.selectedAnnotatorType.identifier, // system annotator
                        this.state.selectedAnnotatorType.id, // user annotator
                        this.thesaurus && this.state.selectedAnnotatorType.identifier && this.state.selectedAnnotatorType.identifier.startsWith('inthesaurus') ? this.thesaurus.value : null,
                        params,
                        pp,
                        this.variant ? this.variant.value : (this.state.selectedAnnotatorType.variants ? this.state.selectedAnnotatorType.variants[0].name : null),
                        this.rdfVocabularyProperty ? this.rdfVocabularyProperty.value : null,
                        res && res.elements ? res.elements[0] : null,
                        res ? res.metadata : null);
    }
  }

  existsAnnotatorIdentifier() {
    if (this.identifier.value) {
      checkAnnotatorIdentifier(this.identifier.value, this.props.dataset.id)
      .then(response => {
        if (!response.data.valid || response.data.exists) {
           this.setState( {identifierExists : true })
        } else
            this.setState( {identifierExists : false })
        })
      }
   }

   formIsValid() {
     var ok = !(this.identifier && this.identifier.value && ((!this.props.dataset && this.state.identifierExists) || (this.props.dataset && this.state.identifierExists && this.props.dataset.identifier !== this.identifier.value)));

     return ok;
   }

  getTags() {
    var tags = null
    if (this.tags) {
      tags = [];
      for (var t of this.tags.value.split(",")) {
        if (t.trim().length > 0) {
          tags.push(t.trim())
        }
      }

      if (tags.length == 0) {
        tags = null;
      }
    }

    return tags
  }

  annotatorTypeChanged() {

    if (this.annotatorType.value.startsWith('s')) {
      var selectedAnnotatorType = this.props.dataAnnotators.filter(el => el.identifier === this.annotatorType.value.substring(1))[0]
    } else {
      var selectedAnnotatorType = this.props.userAnnotators.filter(el => el.id === this.annotatorType.value.substring(1))[0]
    }

    this.setState({ selectedAnnotatorType: selectedAnnotatorType, preprocess: [], preprocessSaved: [],
      valuesLists: (selectedAnnotatorType.fields ? { names: selectedAnnotatorType.fields.map(e => e.name)} : undefined) },
      () => {
      if (this.property) {
        this.property.value = selectedAnnotatorType.asProperties && selectedAnnotatorType.asProperties.length === 1 ? selectedAnnotatorType.asProperties[0] : ''
      }
      // if (this.tag) {
      //   this.tag.value = selectedAnnotatorType.tags && selectedAnnotatorType.tags.length === 1 ? selectedAnnotatorType.tags[0] : ''
      // }

      if (selectedAnnotatorType.variants && selectedAnnotatorType.variants.length > 1) {
        this.variant.value = '';
      }

    })


  }

  rdfVocabularyPrefixChanged() {
    if (this.rdfVocabularyProperty) {
      this.rdfVocabularyProperty.value = '';
    }

    this.setState({ selectedRdfVocabulary: this.props.rdfVocabularies.filter(el => el.uriDescriptors[0].prefix === this.rdfVocabularyPrefix.value )[0] })
  }

  functionChanged(index, event) {

    var functions = [];
    functions = functions.concat(this.props.preprocessFunctions);
    functions = functions.concat(this.props.preprocessOperations);

    // var f = this.props.preprocessFunctions.filter(el => el.uri === event.target.value)[0];
    var f = functions.filter(el => el.uri === event.target.value)[0];
    var fp = [];
    for (var i in f.parameters) {
      if (f.parameters[i] !== "input") {
        fp.push({ name: f.parameters[i] })
      }


    }

    var op = this.props.preprocessOperations.filter(el => el.uri === event.target.value)[0];

    if (op != null) {
      //hack to clear old parameter values from form
      this.setState(copyReplaceAt('preprocess', this.state.preprocess, index, { function: f.uri, parameters: [],  modifiers: []}),
      () => { this.setState(copyReplaceAt('preprocess', this.state.preprocess, index, { function: f.uri, parameters: fp,  modifiers: [ {uri:'None', selected:true}, {uri:'http://islab.ntua.gr/ns/d2rml-op#logicalNot', selected:false}]})) } )
    } else {
      //hack to clear old parameter values from form
      this.setState(copyReplaceAt('preprocess', this.state.preprocess, index, { function: f.uri, parameters: [],  modifiers: []}),
      () =>  { this.setState(copyReplaceAt('preprocess', this.state.preprocess, index, { function: f.uri, parameters: fp,  modifiers: []}))}  )
    }
    let ppSavedtmp = this.state.preprocessSaved;
    ppSavedtmp[index] = false;
    this.setState({ preprocessSaved: ppSavedtmp })
  }

  addPreprocess() {
    this.setState({ preprocess: this.state.preprocess.slice().concat({ function: '', parameters: [] }) });
    this.setState({ preprocessSaved: this.state.preprocessSaved.slice().concat(false) });
  }

  deletePreprocess(index) {
    this.setState({ preprocess: this.state.preprocess.slice(0, index).concat(this.state.preprocess.slice(index + 1)) })
    this.setState({ preprocessSaved: this.state.preprocessSaved.slice(0, index).concat(this.state.preprocessSaved.slice(index + 1)) })
  }

  functionParameterChanged(index, parameter, event) {

    var obj = this.state.preprocess[index];
    var pindex = obj.parameters.findIndex(el => el.name === parameter.name);

    var np = obj.parameters.slice(0, pindex).concat({ name: parameter.name, value: event.target.value }).concat(obj.parameters.slice(pindex + 1));
    this.setState(copyReplaceAt('preprocess', this.state.preprocess, index, { function: obj.function, parameters: np, modifiers: obj.modifiers }))
  }

  functionModifierChanged(index, event) {

    var obj = this.state.preprocess[index];

    var np = obj.modifiers.map(el => { return (el.uri === event.target.value) ? { ...el, selected:true } : { ...el, selected:false } } );

    this.setState(copyReplaceAt('preprocess', this.state.preprocess, index, { function: obj.function, parameters: obj.parameters, modifiers: np }))
  }

  parameterChanged(){

  }

  jsonldsort(a, b) {
    if (a['@value'] !== undefined && b['@value'] !== undefined) {
      if (a['@value'] < b['@value']) {
        return -1;
      } else if (a['@value'] > b['@value']) {
        return 1;
      } else {
        return 0;
      }
    } else if (a['@id'] !== undefined && b['@id'] !== undefined) {
      if (a['@id'] < b['@id']) {
        return -1;
      } else if (a['@id'] > b['@id']) {
        return 1;
      } else {
        return 0;
      }
    }
  }

  getAllSavedAnnotators() {
    if (this.state.loadedAnnotators) {
      return
    }
    getAllTemplates('ANNOTATOR')
      .then(response => {
        response.sort((a,b) => (a.name > b.name) ? 1 : -1);
        this.setState({ savedAnnotators: response, loadedAnnotators: true });
      });

  }

  tnameChanged(evt) {
    this.setState({ saveAsName: evt.target.value })
  }

  onSave() {
    if (!this.state.selectedAnnotatorType) {
      this.throwToast('error', 'You have to select an annotator from the list.');
      return;
    }
    if (this.state.saveAsName.length === 0) {
      this.throwToast('error', 'Annotator name cannot be blank. Please enter a name.');
      return;
    }

    var params = [];
    for (const i in this.state.selectedAnnotatorType.parameters) {
      params.push({ name: this.state.selectedAnnotatorType.parameters[i].name, value: this['param-' + this.state.selectedAnnotatorType.parameters[i].name].value })
    }

    var pp = this.state.preprocess.map((el, index) => {
      var modifier = null;
      if (el.modifiers) {
        var mod = el.modifiers.filter(m => m.selected === true);
        if (mod.length > 0 && mod[0].uri !== 'None') {
          modifier = mod[0].uri;
        }
      }

      if (!modifier) {
        return { function: this.state.preprocessSaved[index] ? el.function : el.function, parameters: el.parameters }
      } else {
        return { function: this.state.preprocessSaved[index] ? el.function : el.function, parameters: el.parameters, modifier: modifier }
      }
    })

   // var params = [];
    // for (const i in this.state.selectedAnnotatorType.parameters) {
    //   params.push({ name: this.state.selectedAnnotatorType.parameters[i].name, value: this['param-' + this.state.selectedAnnotatorType.parameters[i].name].value })
    // }
    // var pp = this.state.preprocess.map((el, index) => {
    //   return { function: this.state.preprocessSaved[index] ? el.function : el.function.uri, parameters: el.parameters }
    // })

                                                       // this.property.value, this.state.selectedAnnotatorType.identifier, this.thesaurus ? this.thesaurus.value : null, params, pp, this.variant ? this.variant.value : this.state.selectedAnnotatorType.variants[0].name, this.rdfVocabularyProperty ? this.rdfVocabularyProperty.value : null);

    var defaultTarget = null
    if (this.rdfVocabularyPrefix && this.rdfVocabularyProperty) {
       var voc = this.props.rdfVocabularies.filter(el => el.prefix === this.rdfVocabularyPrefix.value)[0]
       defaultTarget = { 'uri': this.rdfVocabularyProperty.value, 'prefix':this.rdfVocabularyPrefix.value, 'namespace': voc.namespace, 'localName': this.rdfVocabularyProperty.value.substring(voc.namespace.length)}
    }

    // createTemplate(this.state.saveAsName, 'ANNOTATOR', this.property.value, this.state.selectedAnnotatorType.identifier, this.thesaurus ? this.thesaurus.value : null, params, pp, this.variant ? this.variant.value : this.state.selectedAnnotatorType.variants[0].name, this.rdfVocabularyProperty ? this.rdfVocabularyProperty.value : null)
    createTemplate(this.state.saveAsName, 'ANNOTATOR', this.property ? this.property.value : null, this.getTags(), this.state.selectedAnnotatorType.identifier, this.thesaurus && this.state.selectedAnnotatorType.identifier.startsWith('inthesaurus') ? this.thesaurus.value : null, params, pp, this.variant ? this.variant.value : this.state.selectedAnnotatorType.variants[0].name, defaultTarget)
      .then(response => {
        this.throwToast('success', 'Annotator saved successfully!')
      })
      .catch(error => {
        console.error(error);
        this.throwToast('error', 'Annotator was not saved. Please try again.')
      });

    // document.getElementById('modal-title').innerHTML = this.state.saveAsName
    this.setState({ saveAsName: '', showSaveAsForm: false, loadedAnnotators: false })
  }

  throwToast(type, message) {
    if (type === 'error') {
      toast.error(message, {
        position: "top-right",
        autoClose: 4000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
    else if (type === 'success') {
      toast.success(message, {
        position: "top-right",
        autoClose: 4000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  }

  onDeleteSavedAnnotator(id) {
    deleteTemplate(id).then(response => {
      this.setState({ loadedAnnotators: false })
      this.getAllSavedAnnotators()
      this.throwToast('success', 'Saved Annotator deleted successfully!')
    })
      .catch(error => {
        console.error(error)
        this.throwToast('error', 'Saved Annotator deletion failed')
      })
  }

  getDefaultParameter(name) {
    if (!this.state.selectedParameters) {
      return null
    }
    var obj = this.state.selectedParameters.find(p => p.name === name);
    if (obj == null) {
      return null;
    } else {
      return obj.value;
    }

  }

  beautifyJSON(string) {
    try {
      return JSON.stringify(JSON.parse(string), null, 4);
    } catch (e) {
        return string;
    }
  }

  render() {

    return (
      <Modal show={this.props.show} onHide={this.props.onClose} animation={false} backdrop="static" size="xl" >
        <Form onSubmit={this.handleSubmit} id="annotator-modal-form">
          <Modal.Header>
            <Modal.Title>{this.props.annotator && this.props.annotator.id ? 'Edit Annotator' : 'New Annotator'}</Modal.Title>
            <Row className="mr-0">
              <DropdownButton title="Load Template" variant="outline-primary" onClick={() => this.getAllSavedAnnotators()}>
                <div className="dropdown-scroll-annotator">
                  {this.state.savedAnnotators.map((el, index) => (
                    <Dropdown.Item key={"annotator-" + index}>
                      <Row>
                        <Col className="col-10" onClick={() => this.onSavedTemplateClicked(el.id)}>
                          {el.name}
                        </Col>
                        <Col>
                          <i className="fa fa-trash float-right red" aria-hidden="true" onClick={() => this.onDeleteSavedAnnotator(el.id)}></i>
                        </Col>
                      </Row>
                    </Dropdown.Item>
                  ))}
                </div>
              </DropdownButton>
            </Row>
          </Modal.Header>

          <Modal.Body>
          <Form.Group>
            <Form.Label className="required">Name</Form.Label>
            <Form.Control ref={node => (this.name = node)}
                 onChange={() => this.setState({ error: false })}
                 pattern="\S.*"
                 defaultValue={this.state.annotator ? this.state.annotator.name : ""}
                 required/>
          </Form.Group>

          <Form.Group>
            <Form.Label>Identifier</Form.Label>
            <Form.Control ref={node => (this.identifier = node)}
                 onChange={() => this.existsAnnotatorIdentifier()}
                 isInvalid={this.identifier && this.identifier.value && ((!this.props.annotator && this.state.identifierExists) || (this.props.annotator && this.state.identifierExists && this.props.annotator.identifier !== this.identifier.value))}
                 defaultValue={this.props.annotator ? this.props.annotator.identifier : ""}/>
            <Form.Control.Feedback type="invalid">
                 Identifier already exists or is invalid.
           </Form.Control.Feedback>
          </Form.Group>

          <Form.Group>
            <Form.Label className="required">Annotator</Form.Label>
            <Form.Control as="select" ref={node => (this.annotatorType = node)}
              defaultValue={this.state.annotator ? (this.state.annotator.identifier ? 's' + this.state.annotator.annotator : 'u' + this.state.annotator.annotatorId) : ''}
              onChange={() => this.annotatorTypeChanged()}
              required>
              <option hidden disabled value=''> -- Select an annotator -- </option>
              {this.props.dataAnnotators.map((el, index) =>
                <option key={index} value={'s' + el.identifier}>{el.title}</option>
              )}
              {this.props.userAnnotators && this.props.userAnnotators.map((el, index) =>
                <option key={index} value={'u' + el.id}>{el.name}</option>
              )}
            </Form.Control>
          </Form.Group>

            {/*<Form.Group id="annotator-selector">
              <Form.Label className="required">Annotator</Form.Label>
              <DropdownButton
                id="annotator-selector"
                title={this.state.annotatorTitle}
                variant="light"
                bsPrefix="form-control w-100"
              >
                {this.props.dataAnnotators.map((el, index) =>
                  <OverlayTrigger key={`overlay-${index}`} placement="top" delay={150} overlay={<Tooltip className="annotator-tooltip" id={`tooltip-${index}`}>{el.description !== null ? el.description : el.title}</Tooltip>}>
                    <Dropdown.Item as="button" className="py-1" key={`annotator-${index}`} onClick={e => this.annotatorChanged(e, el)}>
                      {el.title}
                    </Dropdown.Item>
                  </OverlayTrigger>
                )}
              </DropdownButton>
            </Form.Group>*/}

            {this.state.selectedAnnotatorType && this.state.selectedAnnotatorType.variants && this.state.selectedAnnotatorType.variants.length > 1 &&
              <Form.Group>
                <Form.Label className="required">Variant</Form.Label>
                <Form.Control as="select" ref={node => (this.variant = node)}
                  defaultValue={this.state.annotator ? this.state.annotator.variant : ''}
                  required>
                  <option hidden disabled value=''> -- Select a variant -- </option>
                  {this.state.selectedAnnotatorType && this.state.selectedAnnotatorType.variants.map((el, index) =>
                    <option key={index} value={el.name}>{el.name}</option>
                  )}
                </Form.Control>
              </Form.Group>}

            {this.state.annotator && this.state.annotator.asProperty &&
            <Form.Group>
              <Form.Label className="required">Annotations type</Form.Label>
              <Form.Control as="select" ref={node => (this.property = node)}
                defaultValue={this.state.annotator ? this.state.annotator.asProperty : ''}
                required>
                <option hidden disabled value=''> -- Select a property -- </option>
                {this.state.selectedAnnotatorType && this.state.selectedAnnotatorType.asProperties && this.state.selectedAnnotatorType.asProperties.map((el, index) =>
                  <option key={index} value={el}>{el}</option>
                )}
              </Form.Control>
            </Form.Group>}

            {(!this.state.annotator || !this.state.annotator.asProperty) &&
              <Form.Group>
                <Form.Label>Tags</Form.Label>
                <Form.Control ref={node => (this.tags = node)}
                     onChange={() => this.setState({ error: false })}
                     defaultValue={this.state.annotator && this.state.annotator.tags ? this.state.annotator.tags.join(", ") : ""}
                     />
              </Form.Group>}

            {/*(!this.state.annotator || !this.state.annotator.asProperty) &&
            <Form.Group>
              <Form.Label className="required">Annotations tag</Form.Label>
              <Form.Control as="select" ref={node => (this.tag = node)}
                defaultValue={this.state.annotator && this.state.annotator.tags ? this.state.annotator.tags[0] : ''}
                required>
                <option hidden disabled value=''> -- Select a tag -- </option>
                {this.state.selectedAnnotatorType && this.state.selectedAnnotatorType.tags && this.state.selectedAnnotatorType.tags.map((el, index) =>
                  <option key={index} value={el}>{el}</option>
                )}
              </Form.Control>
            </Form.Group>*/}

            {this.props.onProperty && this.props.rdfVocabularies &&
            <Form.Group>
              <Form.Label className="">Default target property</Form.Label>
              <Row>
                <Col md={4}>
                  <Form.Control as="select" ref={node => {this.rdfVocabularyPrefix = node}}
                     defaultValue={this.state.selectedRdfVocabulary ? this.state.selectedRdfVocabulary.uriDescriptors[0].prefix : ''}
                     onChange={() => this.rdfVocabularyPrefixChanged()}>
                     <option disabled value=''> -- Prefix -- </option>
                     {this.props.rdfVocabularies.map((el, index) =>
                        <option key={index} value={el.uriDescriptors[0].prefix}>{el.uriDescriptors[0].prefix}</option>
                     )}
                  </Form.Control>
                </Col>
                {this.state.selectedRdfVocabulary &&
                  <React.Fragment>
                    <Col md={1}>:</Col>

                    <Col>
                      <Form.Control as="select" ref={node => {this.rdfVocabularyProperty = node}}
                      defaultValue={this.state.selectedProperty ? this.state.selectedProperty : ''}>
                      <option disabled value=''> -- Property -- </option>
                      {this.state.selectedRdfVocabulary.properties.map((el, index) =>
                         <option key={index} value={el}>{el.substr(this.state.selectedRdfVocabulary.uriDescriptors[0].namespace.length)}</option>
                      )}
                      </Form.Control>
                    </Col>
                  </React.Fragment>
                }
              </Row>
            </Form.Group>}

            {this.state.selectedAnnotatorType && this.state.selectedAnnotatorType.identifier && this.state.selectedAnnotatorType.identifier.startsWith('inthesaurus') &&
              <Form.Group>
                <Form.Label className="required">Thesaurus</Form.Label>
                <Form.Control as="select" ref={node => (this.thesaurus = node)}
                  onChange={(event) => this.thesaurusChanged(event.target.value)}
                  defaultValue={this.state.annotator ? this.state.annotator.thesaurusId : ''}
                  required>
                  <option hidden disabled value=''> -- Select a thesaurus -- </option>
                  {this.state.thesaurusVocabularies.map((el, index) =>
                    //<option key={index} value={el.identifier}>{el.name} {el.user ? '(' + el.user.name + ')' : ''}</option>)
                    <option key={index} value={el.id}>{el.name} {el.user ? '(' + el.user.name + ')' : ''}</option>)
                  }
                </Form.Control>
              </Form.Group>}

            {this.props.onClass && this.state.selectedAnnotatorType &&

            <Container className={this.state.checkboxes.length > 0 ? "groupborder" : "groupborder-empty"}>
              <Row className={this.state.checkboxes.length > 0 ? "header" : "header-empty"}>

                <Col className="bold">
                  <span className="crimson-std">Roles</span>
                </Col>

                {/*toggleBoxColumn('schemaOpen', this, 'checkboxes')*/}
                <Col className="mybutton" md="auto">
                  <Button type="button" className="menubutton"  aria-label="Toggle" onClick={() => this.setState({ schemaOpen: !this.state.schemaOpen})}><span className={this.state.checkboxes ? 'fa fa-angle-double-up' : 'fa fa-angle-double-down'}></span></Button>
                </Col>

                <Col className="mybutton" md={1}>
                </Col>
              </Row>

              <Collapse in={this.state.schemaOpen}>
                <div>
                  <Row className="index-field-row">
                    <Col md="5" >
                      Path
                    </Col>
                    <Col md="6">
                      <Row>
                        <Col md="3" className="index-column">
                          Role
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  {renderClasses(Object.entries(this.state.checkboxes).filter(([k,v]) => v.class == this.props.onClass).map(([k,v]) => k).filter(k => k.match('^check-c[0-9]+$')), 0, this)}
                </div>
              </Collapse>
            </Container>



            }

            {this.state.selectedAnnotatorType && this.state.selectedAnnotatorType.parameters &&
            <Container className={this.state.selectedAnnotatorType.parameters.length > 0 ? "groupborder" : "groupborder-empty"}>
              <Row className={this.state.selectedAnnotatorType.parameters.length > 0 ? "header" : "header-empty"}>

                <Col className="bold">
                  <span className="crimson-std">Parameters</span>
                </Col>

                {toggleBoxColumn('parametersOpen', this, null, null, this.state.selectedAnnotatorType.parameters)}

                <Col className="mybutton" md={1}>
                </Col>
              </Row>

              <Collapse in={this.state.parametersOpen}>
                <div>

                {this.state.selectedAnnotatorType.parameters.map((el, index) =>
                  <Form.Group key={index}>
                    <Form.Label className={"" + (el.required? " required":"")}>[{this.state.selectedAnnotatorType.title ? this.state.selectedAnnotatorType.title : this.state.selectedAnnotatorType.name }] : {el.name}</Form.Label>
                    {(!el.values || el.values.length === 0) && (el.type && el.type.startsWith("text") ?
                      <Form.Control required={el.required} as="textarea" ref={node => (this['param-' + el.name] = node)} /> :
                      <Form.Control required={el.required} ref={node => (this['param-' + el.name] = node)} />)
                    }
                    {el.values && el.values.length > 0 &&
                      <Form.Control as="select" ref={node => (this['param-' + el.name] = node)}
                        onChange={() => this.parameterChanged()}
                        defaultValue={el.defaultValue}
                        required={el.required}
                        >
                        {el.values.map((v, index) => {
                          var matches = v.match("^\\{thesaurus:(.*)\\}$")
                          if (matches) {
                            if (this.state.thesaurus && this.state.thesaurus[matches[1]]) {
//                               return this.state.thesaurus[matches[1]].sort(this.jsonldsort).map((t, id) => { return <option key={index + "_" + id} value={t['@value'] ? t['@value'] : t['@id']}>{t['@value'] ? t['@value'] : t['@id']}</option> })
                               return this.state.thesaurus[matches[1]].sort(this.jsonldsort).map((t, id) => { return <option key={index + "_" + id} value={t}>{t}</option> })
                             } else {
                               return '';
                            }
                          } else {
                            return <option key={index} value={v}>{v}</option>
                          }
                         })
                      }
                      </Form.Control>}
                      {false && el.type == "text/json" &&
                        <Button type="button" className="beautify" aria-label="New" onClick={() => this['param-' + el.name].value = this.beautifyJSON(this['param-' + el.name].value)}><span className="fa fa-plus"></span></Button>
                      }

                  </Form.Group>)}
                </div>
              </Collapse>
            </Container>}

            <Container className={this.state.preprocess.length > 0 ? "groupborder" : "groupborder-empty"}>
              <Row className={this.state.preprocess.length > 0 ? "header" : "header-empty"}>

                <Col className="bold">
                  <span className="crimson-std">Preprocess/Filter</span>
                </Col>

                {toggleBoxColumn('preprocessOpen', this, 'preprocess')}

                <Col className="mybutton" md={1}>
                  <Button type="button" className="deleteaddbutton" aria-label="New" onClick={(event) => this.addPreprocess(event)}><span className="fa fa-plus"></span></Button>
                </Col>
              </Row>

              <Collapse in={this.state.preprocessOpen}>
                <div>
                {this.state.preprocess.map((el, index) =>
                  <Row key={index}>
                  <Col md="auto">
                    <span className="bold">{index + 1}</span>
                  </Col>
                    <Col>
                    {this.props.onClass &&
                    <Form.Group>
                      <Form.Label className="required">Role</Form.Label>
                      <Form.Control as="select"
                        ref={node => (this.preprocessRole[index] = node)}
                //                    onChange={(event) => this.functionChanged(index, event)}
                        defaultValue={el.target} required>
                        {this.state.valuesLists.names.map((el2, index2) =>
                          <option key={index2} value={el2}>{el2}</option>
                          // el2.uri === this.state.preprocess[index].function.uri ?
                          //   <option key={index2} value={el2.uri} >{el2.uri}</option> : <option key={index2} value={el2.uri} >{el2.uri}</option>
                        )}
                      </Form.Control>
                    </Form.Group>}

                      <Form.Group>
                        <Form.Label className="required">Function / Boolean Operation</Form.Label>
                        <Form.Control as="select"
                          ref={node => (this.preprocess[index] = node)}
                          onChange={(event) => this.functionChanged(index, event)}
                          defaultValue={el.function} required>
                          <option disabled value=''> -- Select a function -- </option>
                          {this.props.preprocessFunctions.map((el2, index2) =>
                            <option key={index2} value={el2.uri}>{el2.uri}</option>
                            // el2.uri === this.state.preprocess[index].function.uri ?
                            //   <option key={index2} value={el2.uri} >{el2.uri}</option> : <option key={index2} value={el2.uri} >{el2.uri}</option>
                          )}
                          <option disabled value=''> -- Select an operation -- </option>
                          {this.props.preprocessOperations.map((el2, index2) =>
                            <option key={index2} value={el2.uri}>{el2.uri}</option>
                            // el2.uri === this.state.preprocess[index].function.uri ?
                            //   <option key={index2} value={el2.uri} >{el2.uri}</option> : <option key={index2} value={el2.uri} >{el2.uri}</option>
                          )}
                        </Form.Control>
                      </Form.Group>

                      {el.parameters && el.parameters.filter(el2 => el2 !== "input").map((el2, index2) =>
                        <Form.Group key={index2}>
                          <Form.Label>{el2.name}</Form.Label>
                          <Form.Control onChange={(v) => this.functionParameterChanged(index, el2, v)}
                            defaultValue={el2.value}
                            required>
                          </Form.Control>
                        </Form.Group>)}

                        {el.modifiers && el.modifiers.length > 0 &&
                          <Form.Group>
                            <Form.Label>Modifier</Form.Label>
                            <Form.Control as="select" defaultValue={el.modifiers.filter(x => x.selected === true)[0].uri} onChange={(event) => this.functionModifierChanged(index, event)}>
                              {el.modifiers.map((el2, index2) =>
                                <option key={index2}>{el2.uri}</option>
                              )}
                            </Form.Control>
                          </Form.Group>}
                    </Col>
                    <Col md="auto">
                      <Button type="button" className="deleteeditbutton" aria-label="New" onClick={(event) => this.deletePreprocess(index)}><span className="fa fa-times"></span></Button>
                    </Col>

                  </Row>
                )}
                </div>
              </Collapse>
            </Container>


            {/*<Form.Group className="mb-0">
              <Form.Label className="bold">Preprocess / Filter</Form.Label>
              <Button type="button" className="deleteaddbutton" aria-label="New" onClick={(event) => this.addPreprocess(event)}><span className="fa fa-plus"></span></Button>
            </Form.Group>*/}

            {/*this.state.preprocess.map((el, index) =>
              <Row key={index}>
              <Col md="auto">
                <span className="bold">{index + 1}</span>
              </Col>
                <Col>
                {this.props.onClass &&
                <Form.Group>
                  <Form.Label className="required">Role</Form.Label>
                  <Form.Control as="select"
                    ref={node => (this.preprocessRole[index] = node)}
//                    onChange={(event) => this.functionChanged(index, event)}
                    defaultValue={el.target} required>
                    {this.state.valuesLists.names.map((el2, index2) =>
                      <option key={index2} value={el2}>{el2}</option>
                      // el2.uri === this.state.preprocess[index].function.uri ?
                      //   <option key={index2} value={el2.uri} >{el2.uri}</option> : <option key={index2} value={el2.uri} >{el2.uri}</option>
                    )}
                  </Form.Control>
                </Form.Group>}

                  <Form.Group>
                    <Form.Label className="required">Function / Boolean Operation</Form.Label>
                    <Form.Control as="select"
                      ref={node => (this.preprocess[index] = node)}
                      onChange={(event) => this.functionChanged(index, event)}
                      defaultValue={el.function} required>
                      <option disabled value=''> -- Select a function -- </option>
                      {this.props.preprocessFunctions.map((el2, index2) =>
                        <option key={index2} value={el2.uri}>{el2.uri}</option>
                        // el2.uri === this.state.preprocess[index].function.uri ?
                        //   <option key={index2} value={el2.uri} >{el2.uri}</option> : <option key={index2} value={el2.uri} >{el2.uri}</option>
                      )}
                      <option disabled value=''> -- Select an operation -- </option>
                      {this.props.preprocessOperations.map((el2, index2) =>
                        <option key={index2} value={el2.uri}>{el2.uri}</option>
                        // el2.uri === this.state.preprocess[index].function.uri ?
                        //   <option key={index2} value={el2.uri} >{el2.uri}</option> : <option key={index2} value={el2.uri} >{el2.uri}</option>
                      )}
                    </Form.Control>
                  </Form.Group>

                  {el.parameters && el.parameters.filter(el2 => el2 !== "input").map((el2, index2) =>
                    <Form.Group key={index2}>
                      <Form.Label>{el2.name}</Form.Label>
                      <Form.Control onChange={(v) => this.functionParameterChanged(index, el2, v)}
                        defaultValue={el2.value}
                        required>
                      </Form.Control>
                    </Form.Group>)}

                    {el.modifiers && el.modifiers.length > 0 &&
                      <Form.Group>
                        <Form.Label>Modifier</Form.Label>
                        <Form.Control as="select" defaultValue={el.modifiers.filter(x => x.selected === true)[0].uri} onChange={(event) => this.functionModifierChanged(index, event)}>
                          {el.modifiers.map((el2, index2) =>
                            <option key={index2}>{el2.uri}</option>
                          )}
                        </Form.Control>
                      </Form.Group>}
                </Col>
                <Col md="auto">
                  <Button type="button" className="deleteeditbutton" aria-label="New" onClick={(event) => this.deletePreprocess(index)}><span className="fa fa-times"></span></Button>
                </Col>

              </Row>
            )*/}

          </Modal.Body>
          <Modal.Footer className="justify-content-between">
            {this.state.showSaveAsForm ?
              <InputGroup className={this.saveAsCssClasses}>
                <InputGroup.Prepend>
                  <InputGroup.Text>
                    Save as:
                  </InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl
                  onChange={(event) => this.tnameChanged(event)}
                  defaultValue={this.state.saveAsName}>
                </FormControl>
                <Button variant="outline-danger" className="ml-3" onClick={() => this.setState({ showSaveAsForm: false, saveAsName: '' })}>Cancel</Button>
                <Button variant="primary" className="ml-3" onClick={() => this.onSave()}>Save</Button>
              </InputGroup> :

              <React.Fragment>
                <Button onClick={() => this.setState(prevState => ({ showSaveAsForm: !prevState.showSaveAsForm }))} variant="outline-primary" className="float-left">
                  Save Template
                </Button>
                <div>
                  <Button type="submit" variant="primary" id="submit-btn" className="mr-2">
                    {this.props.annotator && this.props.annotator.id ? 'Update' : 'Create'}
                  </Button>
                  <Button variant="secondary" onClick={this.props.onClose}>
                    Cancel
                  </Button>
                </div>
              </React.Fragment>}
          </Modal.Footer>
        </Form>
        {/* <ToastContainer
          position="top-right"
          autoClose={4000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        /> */}
      </Modal>

    )
  }
}

export default AnnotatorModal;
