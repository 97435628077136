import { API_BASE_URL, ACCESS_TOKEN } from '../constants/index.js';
import { noTokenRequest, jsonSuccessFailureRequest, jsonSuccessFailureRequestNoError } from './APIUtils.js';

export function signin(loginRequest) {
  return noTokenRequest({
    url: API_BASE_URL + "/users/signin",
    method: 'POST',
    body: JSON.stringify(loginRequest)
  });
}


export function signout() {
  return jsonSuccessFailureRequest({
    url: API_BASE_URL + "/users/signout",
    method: 'POST'
  });
}

export function register(registerRequest) {
  return noTokenRequest({
    url: API_BASE_URL + "/users/new",
    method: 'POST',
    body: JSON.stringify(registerRequest)
  });
}

export function resetPassword(email) {
  return noTokenRequest({
    url: API_BASE_URL + "/users/reset-password-request?email=" + email,
    method: 'POST',
  });
}

export function savePassword(newPassRequest) {
  return noTokenRequest({
    url: API_BASE_URL + "/users/save-password",
    method: 'POST',
    body: JSON.stringify(newPassRequest)
  });
}

export function userFieldChanged(changeRequest) {
  return jsonSuccessFailureRequest({
    url: API_BASE_URL + "/users/update",
    method: 'POST',
    body: JSON.stringify(changeRequest)
  });
}

export function getCurrentUser() {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  return jsonSuccessFailureRequestNoError({
    url: API_BASE_URL + "/users/me",
    method: 'GET'
  });
}

export function getUserDetails() {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  return jsonSuccessFailureRequest({
    url: API_BASE_URL + "/users/get",
    method: 'GET'
  });
}

export function getUsers() {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  return jsonSuccessFailureRequest({
    url: API_BASE_URL + "/users/get-all",
    method: 'GET',
  })
}

export function getUserInfo(id) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  return jsonSuccessFailureRequest({
    url: API_BASE_URL + "/users/details/" + id,
    method: 'GET',
  })
}

export function updateUserRoles(id, newRoles) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  return jsonSuccessFailureRequest({
    url: API_BASE_URL + "/users/update-roles/" + id,
    body: JSON.stringify(newRoles),
    method: 'POST',
  })
}

export function checkUserEmail(email) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  return jsonSuccessFailureRequest({
    url: API_BASE_URL + "/users/exists-email?email=" + encodeURIComponent(email),
    method: 'GET'
  })
}

export function inviteUser(email, projectId, role) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  var body = { email, role: [ role ] };

  if (projectId) {
    body = { ...body, projectId: [ projectId ]}
  }

  return jsonSuccessFailureRequest({
    url: API_BASE_URL + "/users/invite-user-request",
    method: 'POST',
    body: JSON.stringify(body)
  })
}
