import React, { Component } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import BarLoader from "react-spinners/BarLoader";
import { Localizer } from '../../../config/localizer.js'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGlobe } from '@fortawesome/free-solid-svg-icons'

export class PropertyValuesModal extends Component {

  constructor(props) {
    super(props);
  }

  handleScroll = (e) => {
   const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
   if (bottom && this.props.pagination && this.props.pagination.currentPage < this.props.pagination.totalPages) {
     if (this.props.type == 'VALUES') {
       this.props.actions('show-values', {path: this.props.path, mode: this.props.mode, page: this.props.pagination.currentPage + 1})
     } else if (this.props.type == 'ITEMS') {
       this.props.actions('get-items-by-property-value', { path: this.props.path, value: this.props.value, page: this.props.pagination.currentPage + 1} )
     }
   }
 }

  render() {
    return (
      <Modal size="xl" show={this.props.show} onHide={this.props.onClose} animation={false} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>{this.props.type == 'VALUES' ? 'Values' : 'Items'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {this.props.type == 'VALUES' && this.props.state.loaded && this.props.controls &&
            <Row className="bottomrow">
              <Col md="auto"></Col>
              <Col><Row>Total Values: {this.props.metadata.totalCount}</Row><Row>Distinct Values: {this.props.metadata.distinctTotalCount}</Row></Col>
              <Col md="auto">
                <ButtonGroup>
                  <Button variant="secondary" onClick={() => this.props.actions('show-values', {path: this.props.path, mode: "ALL", page: 1})}>{Localizer.all_values[this.props.language]}</Button>
                  <Button variant="secondary" onClick={() => this.props.actions('show-values', {path: this.props.path, mode: "LITERAL", page: 1})}>{Localizer.literals[this.props.language]}</Button>
                  <Button variant="secondary" onClick={() => this.props.actions('show-values', {path: this.props.path, mode: "IRI", page: 1})}>{Localizer.iris[this.props.language]}</Button>
                </ButtonGroup>
              </Col>
          </Row>}
          {this.props.type == 'ITEMS' && this.props.state.loaded &&
            <Row className="bottomrow">
              <Col md="auto"></Col>
              <Col><Row>Total Items: {this.props.metadata.totalCount}</Row></Col>
              <Col md="auto">
              </Col>
          </Row>}
          {this.props.state.loaded &&
            <div className="scrollContainer">
              <div className="scroll" onScroll={this.handleScroll}>
              {this.props.values.map((el, index) =>
                <Row className="grouping bottomrow" key={index}>
                  <Col md="auto align-items-center pt-2">
                    {index +1}
                  </Col>
                  <Col className="property-value align-items-center pr-1">
                    {el.value.iri && this.props.endpoint === null &&
                        <code><pre class='mb-0'><a target="_blank" rel='noopener noreferrer' href={el.value.iri}>{el.value.iri}</a></pre></code> }
                    {el.value.iri && this.props.endpoint !== null &&
                        <code><pre class='mb-0'><a target="_blank" rel='noopener noreferrer' href={this.props.database.lodview + 'queryResource?iri=' + encodeURIComponent(el.value.iri) + '&endpoint=' + this.props.endpoint}>{el.value.iri}</a></pre></code>}
                    {el.value.lexicalForm &&
                      <code><pre class='mb-0'>{el.value.lexicalForm}
                      {el.value.language &&
                        <div><span title="Literal language" class="litlanguage">{el.value.language}</span></div>}
                      </pre></code>}
                  </Col>
                  {this.props.controls &&
                  <Col md="auto align-items-center pt-2">
                  <Container className="annotation-uris sticky-value">
                    <Row><span title="Items count" className="annotation-uris-count">{el.count}</span></Row>
                      <Row><Button type="button" className="menubutton annotation-uris-browse"  aria-label="Browse" onClick={() => this.props.actions('get-items-by-property-value', {path : this.props.path, value: el.value, page:1 })}><FontAwesomeIcon title="Browse items" className="fa" icon={faGlobe}/></Button></Row>
                  </Container>
                  </Col>
                }
                </Row>
              )}
              </div>
            </div>}

            {this.props.state.loading &&
              <Col className="loader">
                <BarLoader
                  css='spinner'
                  height={6}
                  width={200}
                  color='crimson'
                  loading={true}/>
              </Col>
            }
            {this.props.state.failed &&
              <Col>
                <span className="error">Loading values failed.</span>
              </Col>
            }

        </Modal.Body>
        <Modal.Footer>
          <Button primary="secondary" onClick={this.props.onClose}>
            {Localizer.close[this.props.language]}
          </Button>
        </Modal.Footer>
      </Modal>
  )}
}

export default PropertyValuesModal;
