import React, { Component } from "react";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import ProgressBar from "react-bootstrap/ProgressBar";
import Table from "react-bootstrap/Table";
import LoadingComponent from "../LoadingComponent";
import Button from 'react-bootstrap/Button';

export class DatasetValidationProgress extends Component {
    constructor(props) {
        super(props);
    }

    formatProperty(name) {
      if (name) {
        let n = name.split('/').pop();
        return n.charAt(0).toUpperCase() + n.slice(1);
      }
    }

    render() {
      var dataset = this.props.dataset;
      // console.log(dataset)

        return (
          <Row className="mb-3">
              <Table borderless striped size="md">
                  <thead>
                      <tr className="border-bottom border-secondary">
                          <th className="w-50">
                              Overall progress
                          </th>
                          <th className="w-50 pt-3 align-items-center">
                              {dataset.avgProgress >= 0 && dataset.avgProgress <= 100 &&
                                  <Row>
                                      <Col className="align-self-center w-85" md="auto">
                                          <ProgressBar now={dataset.avgProgress} />
                                      </Col>
                                      <Col className="align-self-center w-15 pl-0" md="auto">
                                          {dataset.avgProgress}%
                                      </Col>
                                  </Row>
                              }
                          </th>
                      </tr>
                  </thead>

                  <tbody>
                      {/*dataset.progArray.length === 0 &&
                          <tr key={"dataset-" + index + "-property-0"}>
                              <td colSpan="2" className="text-center font-italic">
                                  No validations running for this dataset
                              </td>
                          </tr>
                      */}
                      {dataset.progArray.length > 0 && Array.from(dataset.progArray).map((entry, i) => (
                              <tr key={"property-" + i}>
                                  <td colSpan="2" className={"w-50 align-middle" + (entry.locked ? " active-background" : "")}>
                                      <Row key={"property-" + i}>
                                          <Col className="w-80 pr-2" md="5">
                                              {entry.propertyName}
                                          </Col>
                                          <Col className="w-20 pr-2" md="1">
                                              ({this.formatProperty(entry.asProperty)})
                                          </Col>

                                          <Col className="align-self-center w-85 align-middle" md="4">
                                              <ProgressBar>
                                                  <ProgressBar variant="success" now={entry.progress.totalAnnotations === 0 ? 0 : (entry.progress.totalAccepted / entry.progress.totalAnnotations * 100).toFixed(2)} />
                                                  <ProgressBar variant="secondary" now={entry.progress.totalAnnotations === 0 ? 0 : (entry.progress.totalNeutral / entry.progress.totalAnnotations * 100).toFixed(2)} />
                                                  <ProgressBar variant="danger" now={entry.progress.totalAnnotations === 0 ? 0 : (entry.progress.totalRejected / entry.progress.totalAnnotations * 100).toFixed(2)} />
                                              </ProgressBar>
                                          </Col>
                                          <Col className="align-self-center w-15 pl-0" md="1">
                                              {entry.progress.progress}%
                                          </Col>

                                          {this.props.validation &&
                                          <Col className="mybutton align-self-center pr-4" md="1">
                                            <Button type="button" className="menubutton" aria-label="Validate" title="Validate"
                                              disabled={this.props.mode === 'VALIDATOR' && !entry.active}
                                              // onClick={() => this.openValidations(dataset.dataset, entry, entry.active)}>
                                              onClick={() => this.props.openValidations(entry, entry.active)}>
                                              <span className="fa fa-list-alt"></span>
                                            </Button>
                                          </Col>}

                                      </Row>
                                  </td>
                              </tr>
                          ))
                      }
                  </tbody>
              </Table>
          </Row>
        )
    }
}

export default DatasetValidationProgress;
