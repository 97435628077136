import { API_BASE_URL, ACCESS_TOKEN } from '../constants/index.js';
import { request, textRequest, sRequest, jsonSuccessFailureRequest, downloadFileRequest } from './APIUtils.js';

export function createEmbedder(datasetId, embedder, variant, indexStructure, onClass, keys) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  var object = { embedder: embedder, variant: variant, indexElement: indexStructure, onClass: onClass, keys: keys };

  return jsonSuccessFailureRequest({
    url: API_BASE_URL + "/embedder/new?datasetId=" + datasetId,
    body: JSON.stringify(object),
    method: 'POST',
  })
}

export function deleteEmbedder(id) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  return jsonSuccessFailureRequest({
    url: API_BASE_URL + "/embedder/delete/" + id,
    method: 'DELETE'
  });

}

export function previewLastEmbedderExecution(id, shard, nextLine) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  var x = "";
  if (shard != null && nextLine != null) {
    x = "?shard=" + shard + "&offset=" + nextLine;
  }

  return textRequest({
    url: API_BASE_URL + "/embedder/preview-last-execution/" + id + x,
    method: 'GET'
  });
}

export function previewPublishedEmbedderExecution(id, shard, nextLine) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  var x = "";
  if (shard != null && nextLine != null) {
    x = "?shard=" + shard + "&offset=" + nextLine;
  }

  return textRequest({
    url: API_BASE_URL + "/embedder/preview-published-execution/" + id + x,
    method: 'GET'
  });
}

export function clearEmbedderExecution(id) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  return jsonSuccessFailureRequest({
    url: API_BASE_URL + "/embedder/clear-execution/" + id,
    method: 'POST'
  })
}

export function downloadLastEmbedderExecution(id) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  downloadFileRequest({
    url: API_BASE_URL + "/embedder/download-last-execution/" + id,
    method: 'GET'
  });
}

export function downloadPublishedEmbedderExecution(id) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  downloadFileRequest({
    url: API_BASE_URL + "/embedder/download-published-execution/" + id,
    method: 'GET'
  });
}

export function getEmbeddersByUser(datasetId) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  return jsonSuccessFailureRequest({
    url: API_BASE_URL + "/embedder/get-all-my?datasetId=" + datasetId,
    method: 'GET',
  })
}

export function executeEmbedder(id) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  return jsonSuccessFailureRequest({
    url: API_BASE_URL + "/embedder/execute/" + id,
    method: 'POST',
  })
}

export function stopEmbedder(id) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  return jsonSuccessFailureRequest({
    url: API_BASE_URL + "/embedder/stop-execution/" + id,
    method: 'POST',
  })
}

export function publishEmbedder(id) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  return jsonSuccessFailureRequest({
    url: API_BASE_URL + "/embedder/publish/" + id,
    method: 'POST',
  })
}

export function unpublishEmbedder(id) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  return jsonSuccessFailureRequest({
    url: API_BASE_URL + "/embedder/unpublish/" + id,
    method: 'POST',
  })
}
