import React, { Component } from "react";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import ListGroup from 'react-bootstrap/ListGroup';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import ProgressBar from "react-bootstrap/ProgressBar";
import { ToastContainer, toast } from 'react-toastify';
import TableScrollbar from 'react-table-scrollbar';
import Collapse from "react-bootstrap/Collapse";
import DeleteModal from "./modals/DeleteModal.js";
import ProjectModal from "./modals/ProjectModal.js";
import JoinModal from "./modals/JoinModal.js";
import InviteUserModal from "./modals/InviteUserModal.js";

import { getProject, getOtherPublicProjects } from '../../utils/ProjectAPI.js';
import { API_BASE_URL  } from '../../constants/index.js';

import { actionsMenu, toggleBoxColumn, sortByField } from '../../utils/UIUtils';

export class ProjectsTab extends Component {
  constructor(props) {
    super(props);
    this.state = {

      editProject: null,

      myProjectsOpen: true,
      joinedProjectsOpen: true,

      newProjectModalShow: false,
      inviteUserModalShow: false,

      joinProjectModalShow: false,

      publicProjects: [],

    };
  }

  showJoinModal() {
    getOtherPublicProjects()
      .then(response => {
        this.setState({ publicProjects: response.data, joinProjectModalShow: true });
      })
      .catch(error => { this.setState({ joinProjectModalShow: true }) });
  }

  executeAction(action, params) {
    if (action === 'update-project') {
      this.setState({ editProject: params.project, newProjectModalShow: true })
    } else if (action === 'invite-user') {
      this.setState({ inviteProject: params.project, inviteUserModalShow: true })
    }
  }


  render() {

    return (
      <Container className="pl-2">
        <Row>
          {/* Left-side menu */}
          <Col md={3}>

            <Container className={this.props.myProjects && this.props.myProjects.length > 0 ? "groupborder" : "groupborder-empty"}>
              <Row className={this.props.myProjects && this.props.myProjects.length > 0 ? "header" : "header-empty"}>
                <Col>
                  My Projects
                </Col>

                {toggleBoxColumn('myProjectsOpen', this, 'myProjects', 'props')}

                <Col className="mybutton" md="auto">
                  {actionsMenu(
                  <div>
                    <Dropdown.Item className="py-2"
                                   onClick={() => this.setState({ editProject: null, newProjectModalShow: true })}>
                      <span className="menu-icon fa fa-plus fa-lg mr-3" />New project...
                    </Dropdown.Item>

                  </div>)}

                  {this.state.newProjectModalShow &&
                  <ProjectModal show={this.state.newProjectModalShow}
                                    project={this.state.editProject}
                                    onOK={(id, name, identifier, publik) => { this.props.actions('create-project', {id, name, identifier, public: publik}); this.setState({ newProjectModalShow: false})} }
                                    onClose={() => this.setState({ newProjectModalShow: false})}/>}

                </Col>
              </Row>

              <Collapse in={this.state.myProjectsOpen}>
                <div>
                  {this.props.myProjects && this.props.myProjects.map((project,index) => (
                  <Row key={index}>
                    <Col md={12} className={this.props.currentProject && this.props.currentProject.id === project.id? " selected-item" : ""}>
                      <span className="lbutton alink" onClick={() => this.props.actions('select-project', { project: project })}>
                      {project.name}
                      </span>
                    </Col>
                  </Row>))}
                </div>
              </Collapse>

            </Container>

            <Container className={this.props.joinedProjects && this.props.joinedProjects.length > 0 ? "groupborder" : "groupborder-empty"}>
              <Row className={this.props.joinedProjects && this.props.joinedProjects.length > 0 ? "header" : "header-empty"}>
                <Col>
                  Joined Projects
                </Col>

                {toggleBoxColumn('joinedProjectsOpen', this, 'joinedProjects', 'props')}

                <Col className="mybutton" md="auto">
                  {actionsMenu(
                  <div>
                    {<Dropdown.Item className="py-2"
                                   onClick={() => this.showJoinModal()}>
                      <span className="menu-icon fa fa-link fa-lg mr-3" />Join project...
                    </Dropdown.Item>}
                  </div>)}

                  {this.state.joinProjectModalShow &&
                  <JoinModal show={this.state.joinProjectModalShow}
                                     objects={this.state.publicProjects}
                                     objectType='Project'
                                     onOK={(id) => { this.props.actions('join-project', {id: id}); this.setState({ joinProjectModalShow: false})} }
                                     onClose={() => this.setState({ joinProjectModalShow: false})}/>}

                </Col>
              </Row>

              <Collapse in={this.state.joinedProjectsOpen}>
                <div>
                  {this.props.joinedProjects && this.props.joinedProjects.map((project,index) => (
                  <Row key={index}>
                    <Col md={12} className={this.props.currentProject && this.props.currentProject.id === project.id? " selected-item" : ""}>
                      <span className="lbutton alink" onClick={() => this.executeAction('select-project', { project: project })}>
                      {project.name}
                      </span>
                    </Col>
                  </Row>))}
                </div>
              </Collapse>

            </Container>

          </Col>

          <Col md={9}>
            <Container className="userspace-right">
              <Row className="userspace-right-inner">
                <Col>

                  {this.props.currentProject &&
                  <Container className="groupborder">
                    <Row className="header">
                      <Col>
                        <span className="crimson-std">Project:</span> {this.props.currentProject.name}
                      </Col>

                      <Col className="mybutton" md={1}>
                        {actionsMenu(
                        <div>
                          <Dropdown.Item className="py-2"
                                         onClick={() => this.executeAction('update-project', { project : this.props.currentProject })}>
                            <span className="menu-icon fa fa-edit fa-lg mr-3" />Edit...
                          </Dropdown.Item>

                          <Dropdown.Divider/>

                          <Dropdown.Item className="py-2"
                                         onClick={() => this.executeAction('invite-user', { project : this.props.currentProject })}>
                            <span className="menu-icon fa fa-edit fa-lg mr-3" />Invite User...
                          </Dropdown.Item>
                        </div>)}
                      </Col>

                      {this.state.inviteUserModalShow &&
                      <InviteUserModal show={this.state.inviteUserModalShow}
                                       project={this.state.inviteProject}
                                       onOK={(email, role) => { this.props.actions('invite-user', { projectId: this.props.currentProject.id, email, role }); this.setState({ inviteUserModalShow: false})} }
                                       onClose={() => this.setState({ inviteUserModalShow: false})}/>}

                    </Row>

                    <Row className="object-field-row">
                      <Col md={3}>
                        Identifier
                      </Col>
                      <Col md={9}>
                        {this.props.currentProject.identifier}
                      </Col>
                    </Row>

                    <Row className="object-field-row">
                      <Col md={3}>
                        <span>Visibility</span>
                      </Col>
                      <Col md={9}>
                        {this.props.currentProject.public ? "public" : "private"}
                      </Col>
                    </Row>

                  </Container>}

                  {this.props.currentProject &&
                  <Container className={this.props.currentProject.joinedUsers && this.props.currentProject.joinedUsers.length > 0 ? "groupborder" : "groupborder-empty"}>
                    <Row className={this.props.currentProject.joinedUsers && this.props.currentProject.joinedUsers.length > 0 ? "header" : "header-empty"}>
                      <Col>
                        <span className="crimson-std">Joined Users</span>
                      </Col>

                      <Col className="mybutton" md={1}>
                        {/*actionsMenu(
                        <div>
                          <Dropdown.Item className="py-2"
                                         onClick={() => this.setState({assignDatasetModalShow: true})}>
                             <span className="menu-icon fa fa-plus fa-lg mr-3" />Assign dataset to user
                          </Dropdown.Item>

                          <Dropdown.Item className="py-2"
                                         disabled={!this.state.validatorAssignedDatasets || this.state.validatorAssignedDatasets.length == 0}
                                         onClick={() => this.unassignAllDatasetsFromUser(this.state.selectedUser.id)}>
                             <span className="menu-icon fa fa-times fa-lg mr-3" />Remove all datasets from user
                          </Dropdown.Item>

                        </div>)*/}
                      </Col>
                    </Row>

                    <Row className="align-items-center">
                      <Col>
                        {this.props.currentProject.joinedUsers && this.props.currentProject.joinedUsers.map((el, index) => (
                        <Row key={"joined-user" + index}>
                          <Col>
                            {el.name}
                          </Col>
                          <Col md="auto">
                            {/*<Button type="button" className="deleteeditbutton mt-0" aria-label="Delete">
                              <span className="fa fa-times failed" title="Remove dataset access" />
                            </Button>*/}
                          </Col>
                        </Row>))}
                      </Col>
                    </Row>
                  </Container>}


                </Col>

              </Row>
            </Container>
          </Col>
      </Row>

    </Container>


    );
  }
}

export default ProjectsTab;
