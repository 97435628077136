import { API_BASE_URL, ACCESS_TOKEN } from '../constants/index.js';
import { request, textrequest, textRequest, formSuccessFailureRequest, sRequest, jsonSuccessFailureRequest, mpRequest } from './APIUtils.js';

export function checkMappingIdentifier(identifier, datasetId, mappingId) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  return jsonSuccessFailureRequest({
    url: API_BASE_URL + "/mapping/exists-identifier?identifier=" + encodeURIComponent(identifier) + "&datasetId=" + datasetId + (mappingId ? "&mappingId=" + mappingId : ""),
    method: 'GET'
  })
}

export function getD2RMLDocument(mappingid) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  return jsonSuccessFailureRequest({
    url: API_BASE_URL + "/mapping/get-content/" + mappingid,
    method: 'GET'
  });

}

export function getMappings(datasetId) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  return jsonSuccessFailureRequest({
    url: API_BASE_URL + "/mapping/get-all-my?datasetId=" + datasetId,
    method: 'GET'
  })
}

export function getMapping(id) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  return jsonSuccessFailureRequest({
    url: API_BASE_URL + "/mapping/get/" + id,
    method: 'GET'
  })
}

export function createMapping(datasetId, type, name, identifier, description, group, data, templateId, d2rmlId, parameters, dependencies, shaclId, active, d2rmlIdBound) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

   var body = { name, type, active, group: group ? group : 0 }

   if (identifier && identifier != null) {
     body = { ...body, identifier }
   }
   if (description) {
      body = { ...body, description }
    }

   if (parameters && parameters != null) {
     body = { ...body, parameters }
   }
   if (dependencies && dependencies != null) {
     body = { ...body, dependencies }
   }

   if (templateId != null) {
     body = { ...body, templateId }
     if (d2rmlIdBound) {
       body = { ...body, d2rmlIdBound }
     }
   }
   if (d2rmlId != null) {
     body = { ...body, d2rmlId }
     if (d2rmlIdBound) {
       body = { ...body, d2rmlIdBound }
     }
   }
   if (shaclId != null) {
     body = { ...body, shaclId: [shaclId] }
   }

  var formData = new FormData();
  if (data != null) {
    formData.append('file', data);
  }

  formData.append('json', JSON.stringify(body));

   return formSuccessFailureRequest({
     url: API_BASE_URL + "/mapping/new?datasetId=" + datasetId,
     method: 'POST',
     body: formData,
   })
}

export function updateMapping(id, name, identifier, description, data, templateId, d2rmlId, parameters, dependencies, shaclId, active, d2rmlIdBound) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  var body = { active }
  if (name && name != null) {
     body = { ...body, name }
   }
   if (identifier && identifier != null) {
      body = { ...body, identifier }
    }
    if (description) {
       body = { ...body, description }
     }

   // if (d2rml && d2rml != null) {
   //   body = { ...body, d2rml: d2rml }
   // }
   if (parameters && parameters != null) {
     body = { ...body, parameters }
   }
   if (dependencies && dependencies != null) {
     body = { ...body, dependencies }
   }
   if (templateId != null) {
     body = { ...body, templateId }
     if (d2rmlIdBound) {
       body = { ...body, d2rmlIdBound }
     }
   }
   if (d2rmlId != null) {
     body = { ...body, d2rmlId }
     if (d2rmlIdBound) {
       body = { ...body, d2rmlIdBound }
     }
   }
   if (shaclId != null) {
     body = { ...body, shaclId: [shaclId] }
   }
  // return request({
  //   url: API_BASE_URL + "/mapping/update/" + id,
  //   method: 'POST',
  //   body: JSON.stringify(body),
  // })

  var formData = new FormData();
  // if (d2rml && d2rml != null && data !== undefined) {
  //   formData.append('file', data);
  // }
  if (data !== undefined) {
    formData.append('file', data);
  }

  formData.append('json', JSON.stringify(body));

  return formSuccessFailureRequest({
    url: API_BASE_URL + "/mapping/update/" + id,
    method: 'POST',
    body: formData,
  })
}

export function validatePublishedMappingExecution(id, instanceId) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  return jsonSuccessFailureRequest({
    url: API_BASE_URL + "/mapping/shacl-validate-published-execution/" + id + (instanceId ? "?instanceId=" + instanceId : ""),
    method: 'POST'
  })
}

export function changeMappingOrder(id, instanceId, step) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  return jsonSuccessFailureRequest({
    url: API_BASE_URL + "/mapping/change-order/" + id + "?step=" + step + (instanceId ? "&instanceId=" + instanceId : ""),
    method: 'POST'
  })
}

export function changeMappingGroup(id, group) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  return jsonSuccessFailureRequest({
    url: API_BASE_URL + "/mapping/change-group/" + id + "?group=" + group,
    method: 'POST'
  })
}

export function executeMapping(id, instanceId) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  return jsonSuccessFailureRequest({
    url: API_BASE_URL + "/mapping/execute/" + id + (instanceId ? "?instanceId=" + instanceId : ""),
    method: 'POST'
  })
}

export function stopMapping(id, instanceId) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  return jsonSuccessFailureRequest({
    url: API_BASE_URL + "/mapping/stop-execution/" + id + (instanceId ? "?instanceId=" + instanceId : ""),
    method: 'POST',
  })
}

export function unpublishMapping(id, instanceId) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  return textrequest({
    url: API_BASE_URL + "/mapping/unpublish/" + id + (instanceId ? "?instanceId=" + instanceId : ""),
    method: 'POST'
  })
}


export function clearMappingExecution(id, instanceId) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  return jsonSuccessFailureRequest({
    url: API_BASE_URL + "/mapping/clear-execution/" + id + (instanceId ? "?instanceId=" + instanceId : ""),
    method: 'POST'
  })
}

export function createMappingInstance(mappingId, identifier, bindings, active) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  var body = { bindings, active };
  if (identifier) {
    body = {...body, identifier }
  }

  return jsonSuccessFailureRequest({
    url: API_BASE_URL + "/mapping/create-instance/" + mappingId,
    method: 'POST',
    body: JSON.stringify(body)
  })
}

export function updateMappingInstance(mappingId, instanceId, identifier, bindings, active) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  var body = { active };
  if (bindings) {
     body = { ...body, bindings }
   }
   if (identifier) {
     body = {...body, identifier }
   }


  return jsonSuccessFailureRequest({
    url: API_BASE_URL + "/mapping/update-instance/" + mappingId + "?instanceId=" + instanceId,
    method: 'POST',
    body: JSON.stringify(body)
  })
}

export function downloadMapping(id) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  return textrequest({
    url: API_BASE_URL + "/mapping/download/" + id,
    method: 'GET'
  });

}

export function previewLastMappingExecution(id, instanceId, shard, nextLine) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  var x = "";
  if (shard != null && nextLine != null) {
    x = (instanceId ? "&" : "?") + "shard=" + shard + "&offset=" + nextLine;
  }

  return textRequest({
    url: API_BASE_URL + "/mapping/preview-last-execution/" + id + (instanceId ? "?instanceId=" + instanceId : "") + x,
    method: 'GET'
  });

}

export function previewPublishedMappingExecution(id, instanceId, shard, nextLine) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  var x = "";
  if (shard != null && nextLine != null) {
    x = (instanceId ? "&" : "?") + "shard=" + shard + "&offset=" + nextLine;
  }

  return textRequest({
    url: API_BASE_URL + "/mapping/preview-published-execution/" + id + (instanceId ? "?instanceId=" + instanceId : "") + x,
    method: 'GET'
  });

}


export function downloadLastMappingExecution(id, instanceId) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  return sRequest({
    url: API_BASE_URL + "/mapping/download-last-execution/" + id + (instanceId ? "?instanceId=" + instanceId : ""),
    method: 'GET'
  });

}

export function downloadPublishedMappingExecution(id, instanceId) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  return sRequest({
    url: API_BASE_URL + "/mapping/download-published-execution/" + id + (instanceId ? "?instanceId=" + instanceId : ""),
    method: 'GET'
  });

}

export function deleteMapping(id, instanceId) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  return jsonSuccessFailureRequest({
    url: API_BASE_URL + "/mapping/delete/" + id + (instanceId ? "?instanceId=" + instanceId : ""),
    method: 'DELETE'
  });
}

// export function deleteMappingInstance(mappingId, instanceId) {
//   if (!localStorage.getItem(ACCESS_TOKEN)) {
//     return Promise.reject("No access token set.");
//   }
//
//   return jsonSuccessFailureRequest({
//     url: API_BASE_URL + "/mapping/delete/" + mappingId + "?instanceId=" + instanceId,
//     method: 'DELETE'
//   });
// }

// export function uploadMappingAttachment(mappingid, filename, file) {
//   if (!localStorage.getItem(ACCESS_TOKEN)) {
//     return Promise.reject("No access token set.");
//   }
//
//   var formData = new FormData();
//   formData.append('file', file);
//
//   return sRequest({
//     url: API_BASE_URL + "/mapping/upload-attachment/" + mappingid + "",
//     method: 'POST',
//     body: formData,
//   })
// }

export function uploadMappingAttachment(mappingId, instanceid, data) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  var formData = new FormData();
  formData.append('file', data);

  return formSuccessFailureRequest({
    url: API_BASE_URL + "/mapping/upload-attachment/" + mappingId + (instanceid != null ? "?instanceId=" + instanceid : ""),
    method: 'POST',
    body: formData,
  })
}

export function deleteMappingAttachment(mappingid, instanceid, filename) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  return jsonSuccessFailureRequest({
    url: API_BASE_URL + "/mapping/delete-attachment/" + mappingid + "?filename=" + filename + (instanceid != null ? "&instanceId=" + instanceid : ""),
    method: 'DELETE',
  })
}

export function downloadMappingAttachment(mappingid, instanceid, filename) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  return sRequest({
    url: API_BASE_URL + "/mapping/download-attachment/" + mappingid + "?filename=" + filename + (instanceid != null ? "&instanceId=" + instanceid : ""),
    method: 'GET',
  })
}
