import React, { Component } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Spinner from "react-bootstrap/Spinner";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { copyReplaceAt } from '../../../utils/functions.js'

export class RDFDataFileUploadModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      // show: this.props.show,
      error: false,
      loading: false,

      file: null,
      fields: [],
      parameters: []
   }

   this.fields = [];
   this.fieldsRequired = [];
   // this.fieldsHidden = [];
   // this.fieldsType = [];

   this.parameters = [];
   this.parametersRequired = [];
   this.parametersHidden = [];
   this.parametersType = [];

   // this.complete(props);

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(event) {
    event.preventDefault();

    // if (!this.state.file && !this.url.value) {
    //   return
    // }

    if (this.props.type == "ANNOTATOR") {
      var fields = [] ;
      for (var i in this.state.fields) {
        fields.push(this.state.fields[i]);
      }

      if (fields.length == 0) {
        fields = undefined;
      }

      var parameters = [] ;
      for (var i in this.state.parameters) {
        parameters.push(this.state.parameters[i]);
      }

      if (parameters.length == 0) {
        parameters = undefined;
      }
    }

    this.setState({ loading: true });
    this.props.onOK(this.props.file ? this.props.file.id : null,
                    this.name.value,
                    this.description.value,
                    this.state.file,
                    this.url.value,
                    this.active ? this.active.checked : false,
                    fields,
                    parameters);
  }

  componentDidMount() {
    this.complete(this.props)
  }

  complete(props) {
    var fields = props.file && props.file.fields ? props.file.fields : [];

    this.setState({ fields: fields }, () => {
      // for (var i in fields)  {
        // this.fields[i].value = fields[i].name
        // this.fieldsRequired[i].checked = fields[i].required
//        // this.fieldsHidden[i].checked = fields[i].hidden
  //      // this.fieldsType[i].value = fields[i].type
      // }
    })

    var parameters = props.file && props.file.parameters ? props.file.parameters : [];

    this.setState({ parameters: parameters }, () => {
      // for (var i in parameters)  {
      //   this.parameters[i].value = parameters[i].name
      //   this.parametersRequired[i].checked = parameters[i].required
      //   this.parametersHidden[i].checked = parameters[i].hidden
      //   this.parametersType[i].value = parameters[i].type
      // }
    })
  }

  addField() {
    // this.setState({ fields: this.state.fields.slice().concat({ name:'', required: true, hidden: false, type:"string"})});
    this.setState({ fields: this.state.fields.slice().concat({ name:'', required: true})});
  }

  deleteField(index) {
    this.setState({ fields: this.state.fields.slice(0, index).concat(this.state.fields.slice(index + 1)) },
      () => {
      for (var i in this.state.fields)  {
        this.fields[i].value = this.state.fields[i].name
        this.fieldsRequired[i].checked = this.state.fields[i].required
        // this.fieldsHidden[i].checked = this.state.fields[i].hidden
        // this.fieldsType[i].value = this.state.fields[i].type
      }})
  }

  // fieldChanged(index, name, required, hidden, type) {
  //   this.setState(copyReplaceAt('fields', this.state.fields, index, { name, required, hidden, type }))
  // }
  fieldChanged(index, name, required) {
    this.setState(copyReplaceAt('fields', this.state.fields, index, { name, required }))
  }

  parameterChanged(index, name, required, hidden, type) {
    this.setState(copyReplaceAt('parameters', this.state.parameters, index, { name, required, hidden, type }))
  }

  loadingCompleted() {
    this.setState({ loading: false, file: null });
    this.props.onClose();
  }

  fileChange(event) {
      this.setState({file : event.target.files[0]}); // FileList object
  }

  render() {

    return (
      <Modal show={this.props.show} onHide={this.props.onClose} animation={false} backdrop="static">
        <Form onSubmit={this.handleSubmit}>
          <Modal.Header>
            <Modal.Title>New {this.props.type} file</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group>
              <Form.Label className="required">Name</Form.Label>
              <Form.Control ref={node => (this.name = node)}
                   onChange={() => this.setState({ error: false })}
                   pattern="\S.*"
                   defaultValue={this.props.file ? this.props.file.name : ""}
                   required/>
            </Form.Group>
            <Form.Control.Feedback type="invalid">
              Please provide a valid name.
            </Form.Control.Feedback>

            <Form.Group>
              <Form.Label>Description</Form.Label>
              <Form.Control as="textarea" ref={node => (this.description = node)}
                   defaultValue={this.props.file ? this.props.file.description : ""}/>
            </Form.Group>

            <Form.Group>
              <Form.Label>File</Form.Label>
              <Form.Control type="file"
                            onChange={(event) => this.fileChange(event)}
                            />
            </Form.Group>
            or
            <Form.Group>
              <Form.Label>URL</Form.Label>
              <Form.Control ref={node => (this.url = node)}
                            defaultValue={this.props.file ? this.props.file.url : ""}
                            //onChange={(event) => this.fileChange(event)}
                            />
            </Form.Group>

            {this.props.type == "ANNOTATOR" &&
            <Form.Group className="mb-0">
              <Form.Label className="bold">Parameters</Form.Label>
            </Form.Group>}

            {this.props.type == "ANNOTATOR" && this.state.parameters.map((el, index) =>
              <Row key={index}>
              <Col md="auto">
                <span className="bold">{index + 1}</span>
              </Col>
                <Col>
                  <Form.Group>
                    <Form.Label>Parameter</Form.Label>
                    <Form.Control
                      ref={node => (this.parameters[index] = node)}
                      defaultValue={el.name}
                      disabled={true}>
                    </Form.Control>
                    <Form.Check label="Required" ref={node => (this.parametersRequired[index] = node)}
                      onChange={(event) => this.parameterChanged(index, this.parameters[index].value, event.target.checked, this.parametersHidden[index].checked, this.parametersType[index].value)}
                      defaultChecked={el.required}/>
                    <Form.Check label="Hidden" ref={node => (this.parametersHidden[index] = node)}
                      onChange={(event) => this.parameterChanged(index, this.parameters[index].value, this.parametersRequired[index].checked, event.target.checked, this.parametersType[index].value)}
                      defaultChecked={el.hidden}/>
                    <Form.Control label="Type" as="select" ref={node => (this.parametersType[index] = node)}
                      onChange={(event) => this.parameterChanged(index, this.parameters[index].value, this.parametersRequired[index].checked, this.parametersHidden[index].checked, event.target.value)}
                      defaultValue={el.type}>
                      <option value="string">string</option>
                      <option value="text">text</option>
                      <option value="text/json">text/json</option>
                    </Form.Control>
                  </Form.Group>
                </Col>

              </Row>)}

            {this.props.type == "ANNOTATOR" &&
            <Form.Group className="mb-0">
              <Form.Label className="bold">Fields</Form.Label>
              <Button type="button" className="deleteaddbutton" aria-label="New" onClick={(event) => this.addField()}><span className="fa fa-plus"></span></Button>
            </Form.Group>}

            {this.props.type == "ANNOTATOR" && this.state.fields.map((el, index) =>
              <Row key={index}>
              <Col md="auto">
                <span className="bold">{index + 1}</span>
              </Col>
                <Col>
                  <Form.Group>
                    <Form.Label className="required">Field</Form.Label>
                    <Form.Control
                      ref={node => (this.fields[index] = node)}
                      // onChange={(event) => this.fieldChanged(index, event.target.value, this.fieldsRequired[index].checked, this.fieldsType[index].value)}
                      onChange={(event) => this.fieldChanged(index, event.target.value, this.fieldsRequired[index].checked)}
                      defaultValue={el.name}>
                    </Form.Control>
                    <Form.Check label="Required" ref={node => (this.fieldsRequired[index] = node)}
                      // onChange={(event) => this.fieldChanged(index, this.fields[index].value, event.target.checked, this.fieldsHidden[index].checked, this.fieldsType[index].value)}
                      onChange={(event) => this.fieldChanged(index, this.fields[index].value, event.target.checked)}
                      defaultChecked={el.required}/>
                    {/*<Form.Check label="Hidden" ref={node => (this.fieldsHidden[index] = node)}
                      onChange={(event) => this.fieldChanged(index, this.fields[index].value, this.fieldsRequired[index].checked, event.target.checked, this.fieldsType[index].value)}
                      defaultChecked={el.hidden}/>
                    <Form.Control label="Type" as="select" ref={node => (this.fieldsType[index] = node)}
                      onChange={(event) => this.fieldChanged(index, this.fields[index].value, this.fieldsRequired[index].checked, this.fieldsHidden[index].checked, event.target.value)}
                      defaultValue={el.type}>
                      <option value="string">string</option>
                      <option value="text">text</option>
                      <option value="text/json">text/json</option>
                    </Form.Control>*/}
                  </Form.Group>
                </Col>
                <Col md="auto">
                  <Button type="button" className="deleteeditbutton" aria-label="New" onClick={(event) => this.deleteField(index)}><span className="fa fa-times"></span></Button>
                </Col>

              </Row>)}

            {this.props.type != "D2RML" && this.props.type != "SHACL" && this.props.type != "ANNOTATOR" &&
            <Form.Group>
              <Form.Check label="Active" ref={node => (this.active = node)}
                defaultChecked={this.props.file ? this.props.file.active : true}/>
            </Form.Group>}

          </Modal.Body>
          <Modal.Footer>
            {this.state.loading &&
            <Spinner animation="border" role="status">
              <span className="sr-only">Uploading...</span>
            </Spinner>}

            <Button type="submit" variant="primary" disabled={this.state.loading}>
              OK
            </Button>
            <Button variant="secondary" onClick={this.props.onClose} disabled={this.state.loading}>
              Cancel
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
  )}
}

export default RDFDataFileUploadModal;
