import React, { Component } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import ReactTooltip from "react-tooltip";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { filterByLanguage } from "../../../utils/functions.js";
import { checkUserEmail } from "../../../utils/UsersAPI.js";
import BootstrapSwitchButton from 'bootstrap-switch-button-react'

export class InviteUserModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      show: this.props.show,
      error: false,
      emailExists: false,
    }

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  formIsValid() {
    var ok = this.email.value && !this.existsUserEmail();

    return ok;
  }

  // existsUserEmail() {
  //   var emailExists;
  //
  //   var _this = this;
  //   var promises = [] ;
  //   promises.push(
  //     new Promise(function(resolve, reject) {
  //       checkUserEmail(_this.email.value)
  //         .then(response => {
  //           if (!response.data.valid || response.data.exists) {
  //             emailExists = true;
  //           } else {
  //             emailExists = false;
  //           }
  //
  //           resolve();
  //        })
  //        .catch(error => {
  //          emailExists = true;
  //          resolve()
  //        })
  //     })
  //   )
  //
  //   Promise.all(promises).then(() => {
  //     _this.setState( { emailExists : emailExists })
  //   })
  //
  //   return emailExists;
  // }

  handleSubmit(event) {
     event.preventDefault();

     var _this = this;

     if (!this.email.value) {
        _this.setState( { emailExists : true })
        return;
     }

     var emailExists
     var promises = [] ;
     promises.push(
       new Promise(function(resolve, reject) {
         checkUserEmail(_this.email.value)
           .then(response => {
             if (!response.data.valid || response.data.exists) {
               emailExists = true;
             } else {
               emailExists = false;
             }

             resolve();
          })
          .catch(error => {
            emailExists = true;
            resolve()
          })
       })
     )

     Promise.all(promises).then(() => {
       _this.setState( { emailExists : emailExists }, () => {
       if (!emailExists) {
         this.props.onOK(_this.email.value, _this.role.value)
       }
     }) })

  }

  render() {
    // console.log(this.props);
    // console.log(this.state.europeanaImportMethod)

    return (
      <Modal show={this.props.show} onHide={this.props.onClose} animation={false} backdrop="static">
        <Form onSubmit={this.handleSubmit}>
          <Modal.Header>
            <Modal.Title>Invite user to join SAGE and {this.props.project.name}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group>
              <Form.Label className="required">User email</Form.Label>
              <Form.Control
                   type="email"
                   ref={node => (this.email = node)}
                   isInvalid={this.email && this.email.value && this.state.emailExists}
                   required/>
                   <Form.Control.Feedback type="invalid">
                        Invalid email or the user has already joined SAGE.
                  </Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId="roleSelect">
              <Form.Label className="required">
                Role
              </Form.Label>
              <Form.Control
                as="select" ref={node => (this.role = node)}
                 defaultValue='EDITOR'>
                <option value='EDITOR'>Editor</option>
                <option value='VALIDATOR'>Validator</option>
              </Form.Control>
            </Form.Group>

          </Modal.Body>
          <Modal.Footer>
            <Button type="submit" variant="primary">
              OK
            </Button>
            <Button variant="secondary" onClick={this.props.onClose}>
              Cancel
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
  )}
}

export default InviteUserModal;
