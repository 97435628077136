import React, { Component } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Multiselect from 'multiselect-react-dropdown';
import { getDatasetSchemaClasses } from "./DatasetAPI"

export function getSchema(onLoad, _this, datasetId, schema) {
  if (!schema) {
    getDatasetSchemaClasses(datasetId, true)
       .then(response => {

          var newState = { ..._this.state, classStructure: response }

          var checkboxes = {}
          for (var i in response) {
            checkboxes['check-c' + i] = { class: response[i].class, path: [ response[i].class ] }
          }

          _this.setState({ classStructure: response, checkboxes }, () => { onLoad(_this) })

       })
       .catch(error => {
         // this.throwToast('error', error.message)
       })
    } else {
      var response = schema
      var newState = { ..._this.state, classStructure: response }

      var checkboxes = {}
      for (var i in response) {
        checkboxes['check-c' + i] = { class: response[i].class, path: [ response[i].class ] }
      }

      _this.setState({ classStructure: response, checkboxes }, () => { onLoad(_this) })
    }
}

export function readClasses(rootPathIdentifier, keysMetadata, counter, _this) {

    var elements = []
    var metadata = keysMetadata

    for (var ch of Object.keys(_this.state.checkboxes).filter(k => k.match('^check-' + (rootPathIdentifier.length > 0 ? (rootPathIdentifier + '-c[0-9]+$') : 'c[0-9]+$')))) {
      // console.log(">> CLASS");
      // console.log(ch);
      if (_this.checkboxes[ch] && _this.checkboxes[ch].checked) {
        var newRootPathIdentifier = ch.substring(6)

        var pres = readProperties(newRootPathIdentifier, metadata, counter, _this)
        var properties = pres.properties;
        metadata = pres.metadata;

        var eres = readEmbedders(newRootPathIdentifier, metadata, counter, _this)
        var embedders = eres.embedders;
        metadata = eres.metadata;

        var obj = { clazz: _this.state.checkboxes[ch].class };

        if (properties != null) {
          obj = {...obj, properties: properties }
        }
        if (embedders != null) {
          obj = {...obj, embedders: embedders }
        }

        elements.push(obj);
      }
    }

    if (elements.length == 0) {
      return { metadata }
    } else {
      return { metadata, elements }
    }
  }

export function readProperties(rootPathIdentifier, keysMetadata, counter, _this) {

    var properties = []
    var metadata = keysMetadata

    for (var ch of Object.keys(_this.state.checkboxes).filter(k => k.match('^check-' + rootPathIdentifier + '-p[0-9]+$'))) {
      // console.log(">> PROPERTY");
      // console.log(ch);
      if (_this.checkboxes[ch] && _this.checkboxes[ch].checked) {
        var newRootPathIdentifier = ch.substring(6)
        var res = readClasses(newRootPathIdentifier, metadata, counter, _this)
        var elements = res.elements;
        metadata = res.metadata;

        var lines = _this.state.checkboxes[ch].lines;

        if (elements == null) {
          var selectors = [];
          if (lines) {
            for (var i of lines) {
              var name = _this.names['names' + ch.substring(5) + "#" + i].value;
              if (name) {
                var selectorObj = { index: counter.value };

                if (_this.rdftypes) {
                  selectorObj.termType = _this.rdftypes['rdfty' + ch.substring(5) + "#" + i].value.toUpperCase();
                }

                if (_this.languages) {
                  var language = _this.languages['langu' + ch.substring(5) + "#" + i].value;
                  if (language) {
                    var languages = [];
                    for (var lang of language.split(',')) {
                      languages.push( lang.trim() )
                    }

                    selectorObj.languages = languages;
                  }
                }
                // var target = _this.targets['targe' + ch.substring(5)+ "#" + i].value;
                // if (target) {
                //    selectorObj.target = target;
                // }

                if (_this.values) {
                  var value = _this.values['value' + ch.substring(5) + "#" + i].value;
                  if (value && value.length > 0) {
                    selectorObj.values = [value];
                  }
                }

                selectors.push(selectorObj);

                if (_this.datatypes) {
                  var datatype = _this.datatypes['datat' + ch.substring(5) + "#" + i].value;
                }



                if (_this.expansions) {
                  var expand = _this.expansions['expan' + ch.substring(5) + "#" + i].getSelectedItems().map(el => el.id)
                }

                var mobj = { index:counter.value, name, languageField: language ? true : false, datatype, expand } ;



                metadata.push( mobj )
                counter.value++;
              }
            }

            properties.push( { property: _this.state.checkboxes[ch].property, selectors })
          }
        } else {
          properties.push( { property: _this.state.checkboxes[ch].property, elements: elements })
        }
      }
    }

    if (properties.length == 0) {
      return { metadata }
    } else {
      return { metadata, properties }
    }
  }

  export function readEmbedders(rootPathIdentifier, keysMetadata, counter, _this) {

    var embedders = []
    var metadata = keysMetadata

    for (var ch of Object.keys(_this.state.checkboxes).filter(k => k.match('^check-' + rootPathIdentifier + '-e[0-9]+$'))) {
      // console.log(">> PROPERTY");
      // console.log(ch);
      if (_this.checkboxes[ch].checked) {
        var newRootPathIdentifier = ch.substring(6)

        var lines = _this.state.checkboxes[ch].lines;

        var selectors = [];
        if (lines) {
          for (var i of lines) {
            var name = _this.names['names' + ch.substring(5) + "#" + i].value;
            if (name) {
              var selectorObj = { index: counter.value };

              if (_this.rdftypes) {
                selectorObj.termType = _this.rdftypes['rdfty' + ch.substring(5) + "#" + i].value.toUpperCase();
              }

              selectors.push(selectorObj);

              if (_this.datatypes) {
                var datatype = _this.datatypes['datat' + ch.substring(5) + "#" + i].value;
              }

              if (_this.values) {
                var value = _this.values['value' + ch.substring(5) + "#" + i].value;
              }

              metadata.push( { index:counter.value, name, languageField: false, datatype, value } )
              counter.value++;
            }
          }

          embedders.push( { embedderId: _this.state.checkboxes[ch].embedderId, selectors })
        }
      }
    }

    if (embedders.length == 0) {
      return { metadata }
    } else {
      return { metadata, embedders }
    }
  }

  export function applySelection(_this, selectedIndex) {

     if (selectedIndex) {
       var checkboxes = { ..._this.state.checkboxes }
       var selectIdentifiers = []

       if (selectedIndex.elements) {
         for (var element of selectedIndex.elements) {
           checkboxes = applyClassSelection(checkboxes, selectIdentifiers, '', element, _this)
         }
       } else if (selectedIndex.element) {
         checkboxes = applyClassSelection(checkboxes, selectIdentifiers, '', selectedIndex.element, _this)
       }

       _this.setState({ checkboxes: checkboxes }, () => {
         for (var el of selectIdentifiers) {
           // console.log(el);
           _this.checkboxes[el.identifier].checked = true
         }
         _this.forceUpdate(() => {
           for (var el of selectIdentifiers) {
             var iel = el.identifier.substring(5);

             if (iel.match('^.*-p[0-9]+$' ) && el.selectors) { // only properties

               var labelPath = _this.state.checkboxes[el.identifier].path

               var classElement = _this.state.classStructure.find(el => el.class === labelPath[labelPath.length - 2])
               var propElement = classElement.children.find(el => el.property === labelPath[labelPath.length - 1])

               var ranges = propElement.range

               for (var i in el.selectors) {
                 var selector = el.selectors[i]

  // console.log("HERE")
  // console.log(selector)

                 var km = selectedIndex.keysMetadata.find(e => e.index == selector.index)

  // console.log(km)
  // console.log(_this)
  // console.log(_this.names)
                 if (km.name) {
                   _this.names['names' + iel + '#' + i].value = km.name
                 }

                 if (km.datatype && _this.datatypes) {
                   _this.datatypes['datat' + iel + '#' + i].value = km.datatype
                 }

                 if (selector.values && _this.values) {
                   _this.values['value' + iel + '#' + i].value = selector.values
                 }

                 if (km.expand && _this.expansions) {
                   var x = km.expand.map(el => _this.state.expandOptions.find(s => s.id === el));
                   _this.expansions['expan' + iel + '#' + i].state.preSelectedValues = x;
                   _this.expansions['expan' + iel + '#' + i].resetSelectedValues(); // is not working

                   // console.log(this.expansions['expan' + iel + '#' + i])
                 }

                 if (selector.termType && _this.rdftypes) {
                   _this.rdftypes['rdfty' + iel + '#' + i].value = selector.termType == 'LITERAL' ? 'Literal' : selector.termType
                 }
                 if (selector.languages && _this.languages) {
                   _this.languages['langu' + iel + '#' + i].value = selector.languages
                 }
               }
              } else if (iel.match('^.*-p[0-9]+$' ) && !el.selectors) {
                 if (_this.rdftypes) {
                   _this.rdftypes['rdfty' + iel + '#0'].disabled = true
                 }
                 if (_this.datatypes) {
                   _this.datatypes['datat' + iel + '#0'].disabled = true
                 }
                 if (_this.values) {
                   _this.values['value' + iel + '#0'].disabled = true
                 }
                 if (_this.languages) {
                   _this.languages['langu' + iel + '#0'].disabled = true
                 }
                 if (_this.expansions) {
                   _this.expansions['expan' + iel + '#0'].disabled = true
                 }
                 // if (_this.targets) {
                 //   _this.targets['targe' + iel + '#0'].disabled = true
                 // }
             } else if (iel.match('^.*-e[0-9]+$' ) && el.selectors) { // only properties

                for (var i in el.selectors) {
                  var selector = el.selectors[i]

                  var km = selectedIndex.keysMetadata.find(e => e.index == selector.index)
                  if (km.name) {
                    _this.names['names' + iel + '#' + i].value = km.name
                  }

                  if (km.datatype && _this.datatypes) {
                    _this.datatypes['datat' + iel + '#' + i].value = km.datatype
                  }

                  if (km.value && _this.values) {
                    _this.values['value' + iel + '#' + i].value = km.value
                  }

                  if (selector.termType && _this.rdftypes) {
                    _this.rdftypes['rdfty' + iel + '#' + i].value = selector.termType == 'LITERAL' ? 'Literal' : selector.termType
                  }

                }
              } else if (iel.match('^.*-e[0-9]+$' ) && !el.selectors) {
                  if (_this.rdftypes) {
                    _this.rdftypes['rdfty' + iel + '#0'].disabled = true
                  }
                  if (_this.datatypes) {
                    _this.datatypes['datat' + iel + '#0'].disabled = true
                  }
                  if (_this.values) {
                    _this.values['value' + iel + '#0'].disabled = true
                  }
              }
           }
         })
       })
      }
  }

  export function applyClassSelection(checkboxes, selectIdentifiers, rootPathIdentifier, element, _this) {
    // console.log('applyClassSelection')
    // console.log(rootPathIdentifier)
    // console.log(element)
    // console.log(checkboxes)
    for (var ch in checkboxes) {
      if (ch.match('^check-' + (rootPathIdentifier.length > 0 ? (rootPathIdentifier + '-c[0-9]+$') : 'c[0-9]+$') ) && checkboxes[ch].class === element.clazz) {
        selectIdentifiers.push({ identifier: ch })
        var newRootPathIdentifier = ch.substring(6)
        checkboxes = selectClass(checkboxes, newRootPathIdentifier, element.clazz, _this)

        if (element.properties) {
          for (var propElement of element.properties) {
            checkboxes = applyPropertySelection(checkboxes, selectIdentifiers, newRootPathIdentifier, propElement, _this)
          }
        }

        if (element.embedders) {
          for (var embedderElement of element.embedders) {
            checkboxes = applyEmbedderSelection(checkboxes, selectIdentifiers, newRootPathIdentifier, embedderElement, _this)
          }
        }
        break;
      }
    }

    return checkboxes
  }

  export function applyPropertySelection(checkboxes, selectIdentifiers, rootPathIdentifier, element, _this) {
    // console.log('applyPropertySelection')
    // console.log(rootPathIdentifier)
    // console.log(element)
    // console.log(checkboxes)
    for (var ch in checkboxes) {
      if (ch.match('^check-' + rootPathIdentifier + '-p[0-9]+$') && checkboxes[ch].property === element.property) {
        if (element.selectors) {
          selectIdentifiers.push({ identifier: ch, selectors: element.selectors })
        } else {
          selectIdentifiers.push({ identifier: ch })
        }
        var newRootPathIdentifier = ch.substring(6)
        checkboxes = selectProperty(checkboxes, newRootPathIdentifier, element.selectors ? element.selectors.length : -1, _this )

        if (element.elements) {
          for (var classElement of element.elements) {
            checkboxes = applyClassSelection(checkboxes, selectIdentifiers, newRootPathIdentifier, classElement, _this)
          }
        }
        break;
      }
    }

    return checkboxes
  }

  export function applyEmbedderSelection(checkboxes, selectIdentifiers, rootPathIdentifier, element, _this) {
    // console.log('applyPropertySelection')
    // console.log(rootPathIdentifier)
    // console.log(element)
    // console.log(checkboxes)
    for (var ch in checkboxes) {
      if (ch.match('^check-' + rootPathIdentifier + '-e[0-9]+$') && checkboxes[ch].embedderId === element.embedderId) {
        if (element.selectors) {
          selectIdentifiers.push({ identifier: ch, selectors: element.selectors })
        } else {
          selectIdentifiers.push({ identifier: ch })
        }
        var newRootPathIdentifier = ch.substring(6)
        checkboxes = selectEmbedder(checkboxes, newRootPathIdentifier, _this)

        if (element.elements) {
          for (var classElement of element.elements) {
            checkboxes = applyClassSelection(checkboxes, selectIdentifiers, newRootPathIdentifier, classElement, _this)
          }
        }
        break;
      }
    }

    return checkboxes
  }

  export function selectClass(checkboxes, pathIdentifier, uri, _this) {
    var classElement = _this.state.classStructure.find(cl => cl.class === uri)

    var newCheckboxes = { ...checkboxes }
    var embedders = classElement.embedders;

    if (embedders) {
      for (var i in embedders) {
        newCheckboxes['check-' + pathIdentifier + '-e' + i] = { ...embedders[i], path: newCheckboxes['check-' + pathIdentifier].path }
      }
    }

    for (var i in classElement.children) {
      newCheckboxes['check-' + pathIdentifier + '-p' + i] = { property: classElement.children[i].property, path: newCheckboxes['check-' + pathIdentifier].path.concat(classElement.children[i].property)  }
    }

    return newCheckboxes;
  }

  export function selectProperty(checkboxes, pathIdentifier, elementSelectorsSize, _this) {
    var labelPath = checkboxes['check-' + pathIdentifier].path

    var classElement = _this.state.classStructure.find(el => el.class === labelPath[labelPath.length - 2])
    var propElement = classElement.children.find(el => el.property === labelPath[labelPath.length - 1])

    var ranges = propElement.range

    var newCheckboxes = { ...checkboxes }
    var lines = newCheckboxes['check-' + pathIdentifier].lines
    if (!lines) {
      if (elementSelectorsSize && elementSelectorsSize > 0) {
        newCheckboxes['check-' + pathIdentifier].lines = [];
        for (var i = 0; i < elementSelectorsSize; i++) {
          newCheckboxes['check-' + pathIdentifier].lines.push(i)
        }
      } else {
        newCheckboxes['check-' + pathIdentifier].lines = [0]
      }
    }

    if (ranges) {
      for (var i in ranges) {
        newCheckboxes['check-' + pathIdentifier + '-c' + i] = { class: ranges[i], path: newCheckboxes['check-' + pathIdentifier].path.concat(ranges[i]) }
      }
    }

    return newCheckboxes
  }

  export function selectEmbedder(checkboxes, pathIdentifier, _this) {
    var labelPath = checkboxes['check-' + pathIdentifier].path

    var newCheckboxes = { ...checkboxes }
    var lines = newCheckboxes['check-' + pathIdentifier].lines
    if (!lines) {
       newCheckboxes['check-' + pathIdentifier].lines = [0]
    }

    return newCheckboxes
  }

  export function classSelected(pathIdentifier, uri, _this) {
    _this.enableNewIndex();

    var checked = _this.checkboxes['check-' + pathIdentifier].checked;

    if (!checked) {
       var newCheckboxes = { ..._this.state.checkboxes }
      for (var key of Object.keys(_this.state.checkboxes).filter(k => k.startsWith('check-' + pathIdentifier + '-'))) {
        delete newCheckboxes[key]
      }
      _this.setState({ checkboxes: newCheckboxes })

    } else {
      _this.setState({ checkboxes: selectClass(_this.state.checkboxes, pathIdentifier, uri, _this) })
    }
  }

  export function getNames(_this) {
    var names = []
    for (var n of Object.keys(_this.names)) {
      if (_this.names[n] && _this.names[n].value) {
        names.push(_this.names[n].value)
      }
    }

    return names;
  }

  export function nameChanged(pathIdentifier, line, _this) {
    _this.enableNewIndex();

    if (pathIdentifier.includes('-e')) { // embedder
      return;
    } else { // property
      var name = _this.names['names-' + pathIdentifier + "#" + line].value;

      if (!name || name.length == 0) {
        if (_this.rdftypes) {
          _this.rdftypes['rdfty-' + pathIdentifier + "#" + line].disabled = true;
        }
        if (_this.datatypes) {
          _this.datatypes['datat-' + pathIdentifier + "#" + line].disabled = true;
        }
        // if (_this.values) {
        //   _this.values['value-' + pathIdentifier + "#" + line].disabled = true;
        // }
        if (_this.languages) {
          _this.languages['langu-' + pathIdentifier + "#" + line].disabled = true;
        }
        if (_this.expansions) {
          _this.expansions['expan-' + pathIdentifier + "#" + line].disabled = true;
        }
        // this.targets['targe-' + pathIdentifier + "#" + line].disabled = true;
      } else {
        if (_this.rdftypes) {
          _this.rdftypes['rdfty-' + pathIdentifier + "#" + line].disabled = false;
        }
        if (_this.datatypes) {
          _this.datatypes['datat-' + pathIdentifier + "#" + line].disabled = false;
        }
        if (_this.values) {
          _this.values['value-' + pathIdentifier + "#" + line].disabled = false;
        }
        if (_this.languages) {
          _this.languages['langu-' + pathIdentifier + "#" + line].disabled = false;
        }
        if (_this.expansions) {
          _this.expansions['expan-' + pathIdentifier + "#" + line].disabled = false;
        }
        // this.targets['targe-' + pathIdentifier + "#" + line].disabled = false;
      }
    }

    _this.nameChanged()
  }

  export function propertySelected(pathIdentifier, _this) {
    _this.enableNewIndex();

    var checked = _this.checkboxes['check-' + pathIdentifier].checked;

    if (!checked) {
      var newCheckboxes = { ..._this.state.checkboxes }
      for (var key of Object.keys(_this.state.checkboxes).filter(k => k.startsWith('check-' + pathIdentifier + '-'))) {
        delete newCheckboxes[key]
      }

      delete newCheckboxes['check-' + pathIdentifier].lines

      _this.setState({ checkboxes: newCheckboxes })

    } else {
      _this.setState({ checkboxes: selectProperty(_this.state.checkboxes, pathIdentifier, undefined, _this) })
    }
  }

  export function embedderSelected(pathIdentifier, _this) {
    _this.enableNewIndex();

    var checked = _this.checkboxes['check-' + pathIdentifier].checked;

    if (!checked) {
      var newCheckboxes = { ..._this.state.checkboxes }

      delete newCheckboxes['check-' + pathIdentifier].lines

      _this.setState({ checkboxes: newCheckboxes })

    } else {
      _this.setState({ checkboxes: selectEmbedder(_this.state.checkboxes, pathIdentifier, _this) })
    }
  }


  export function addLine(pathIdentifier, _this) {
    var newCheckboxes = { ..._this.state.checkboxes }

    var lines = newCheckboxes['check-' + pathIdentifier].lines
    lines.push(lines[lines.length - 1] + 1)

    _this.setState( { checkboxes: newCheckboxes } )
  }

export function   deleteLine(pathIdentifier, line, _this) {
    // console.log(pathIdentifier)
    // console.log(line)

    var newCheckboxes = { ..._this.state.checkboxes }
// console.log(newCheckboxes)

    var lines = newCheckboxes['check-' + pathIdentifier].lines
    // console.log(lines)
    lines = lines.filter(el => el !== line)
    // console.log(lines)
    newCheckboxes['check-' + pathIdentifier].lines = lines
// console.log('names-' + pathIdentifier + "#" + line)
    delete _this.names['names-' + pathIdentifier + "#" + line]
// console.log(newCheckboxes)
// console.log(this.names)
    _this.setState( { checkboxes: newCheckboxes } )
  }

  export function renderClasses(roots, depth, _this) {

    if (roots) {
      const style = {
        paddingLeft: (0 + depth*20) + "px",
      }

      const estyle = {
        paddingLeft: (0 + (depth + 1)*20 + 40) + "px",
      }

      return (
        <React.Fragment>
          {roots.map((el, index) => {
            var pathIdentifier = getIdentifier(el)
            var embedders = Object.keys(_this.state.checkboxes).filter(k => k.match('^check-' + pathIdentifier + '-e[0-9]+$'));
            var properties = Object.keys(_this.state.checkboxes).filter(k => k.match('^check-' + pathIdentifier + '-p[0-9]+$'));

            return (
              <React.Fragment key={pathIdentifier}>
                <Row className={"index-field-row"}>
                  <Col style={style}>
                    <Form.Check className="bold" id={pathIdentifier} key={pathIdentifier} ref={node => (_this.checkboxes['check-' + pathIdentifier] = node)}
                      onChange={() => classSelected(pathIdentifier, _this.state.checkboxes[el].class, _this)}
                      label = {_this.state.checkboxes[el].class}/>
                  </Col>
                </Row>
                {embedders.length > 0 && renderEmbedders(embedders, depth + 1, _this)}
                {properties.length > 0 && renderProperties(properties, depth + 1, _this)}
              </React.Fragment>)
            })}
        </React.Fragment>
      )
    }
  }

  export function renderProperties(roots, depth, _this) {
    if (roots) {
      const style = {
        paddingLeft: (0 + depth*20) + "px",
      }

      return (
        <React.Fragment>
        {roots.map((el, index) => {
          var pathIdentifier = getIdentifier(el)
          var properties = Object.keys(_this.state.checkboxes).filter(k => k.match('^check-' + pathIdentifier + '-c[0-9]+$'))
          return (
          <React.Fragment key={pathIdentifier}>
            <Row className="index-field-row">
              <Col md="5" style={style}>
                <Form.Check id={pathIdentifier}  key={pathIdentifier} ref={node => (_this.checkboxes['check-' + pathIdentifier] = node)}
                        onChange={() => propertySelected(pathIdentifier, _this)}
                        label = {_this.state.checkboxes[el].property}/>
              </Col>
              <Col md="6">
              {_this.checkboxes['check-' + pathIdentifier] && _this.checkboxes['check-' + pathIdentifier].checked &&
               _this.state.checkboxes['check-' + pathIdentifier].lines && _this.state.checkboxes['check-' + pathIdentifier].lines.map((line, li) =>
                <Row key={li}>
                  {_this.names &&
                  <Col md="3" className="index-column">
                      {_this.state.valuesLists && _this.state.valuesLists.names &&
                      <Form.Control className="index-field" as="select"
                              key={'name-'+ pathIdentifier} ref={node => (_this.names['names-' + pathIdentifier + "#" + line] = node)}
                              onChange={() => nameChanged(pathIdentifier, line, _this)}
                              defaultValue = ''>
                              <option value=''></option>
                              {_this.state.valuesLists.names.map((n,index) =>
                                <option value={n}>{n}</option>
                              )}
                      </Form.Control>
                      }
                      {(!_this.state.valuesLists || !_this.state.valuesLists.names) &&
                      <Form.Control className="index-field"
                              key={'name-'+ pathIdentifier} ref={node => (_this.names['names-' + pathIdentifier + "#" + line] = node)}
                              onChange={() => nameChanged(pathIdentifier, line, _this)}
                              defaultValue = ''/>
                      }
                  </Col>}
                  {_this.values &&
                  <Col md="2" className="index-column">
                      <Form.Control className="index-field-m"
                              key={'value-'+ pathIdentifier} ref={node => (_this.values['value-' + pathIdentifier + "#" + line] = node)}
                              onChange={() => _this.enableNewIndex()}
                              >
                      </Form.Control>
                  </Col>}
                  {_this.rdftypes &&
                  <Col md="2" className="index-column">
                      <Form.Control className="index-field-m" as="select"
                              key={'rdftype-'+ pathIdentifier} ref={node => (_this.rdftypes['rdfty-' + pathIdentifier + "#" + line] = node)}
                              onChange={() => _this.enableNewIndex()}
                              >
                              <option value="IRI">IRI</option>
                              <option value="Literal">Literal</option>
                      </Form.Control>
                  </Col>}
                  {_this.datatypes &&
                  <Col md="2" className="index-column">
                      <Form.Control className="index-field-m" as="select"
                              key={'datatype-'+ pathIdentifier} ref={node => (_this.datatypes['datat-' + pathIdentifier + "#" + line] = node)}
                              onChange={() => _this.enableNewIndex()}
                              >
                              {_this.getDatatypeValues().map((v,i) =>
                                <option key={i} value={v}>{v}</option>)}
                      </Form.Control>
                  </Col>}
                  {_this.languages &&
                  <Col md="2" className="index-column">
                      <Form.Control className="index-field"
                              key={'language-'+ pathIdentifier} ref={node => (_this.languages['langu-' + pathIdentifier + "#" + line] = node)}
                              onChange={() => _this.enableNewIndex()}
                              defaultValue = ''/>
                  </Col>}
                  {_this.expansions &&
                  <Col md="2" className="index-column">
                    <Multiselect ref={node => (_this.expansions['expan-' + pathIdentifier + "#" + line] = node)}
                      options={_this.state.expandOptions} // Options to display in the dropdown
                      // selectedValues={this.state.expandOptions} // Preselected value to persist in dropdown
                      onSelect={() => _this.enableNewIndex()} // Function will trigger on select event
                      onRemove={() => _this.enableNewIndex()} // Function will trigger on remove event
                      displayValue="name" // Property name to display in the dropdown options
                      />
                  </Col>}
                  {/*_this.targets &&
                  <Col md="2" className="index-column">
                      <Form.Control className="index-field"
                              key={'target-'+ pathIdentifier} ref={node => (this.targets['targe-' + pathIdentifier + "#" + line] = node)}
                              onChange={() => this.enableNewIndex()}
                              defaultValue = ''/>
                  </Col>*/}
                  <Col md="1" className="index-column">
                    <Button type="button" className="menubutton mt-0" onClick={() => deleteLine(pathIdentifier, line, _this)}>
                      <span className="fa fa-minus"/>
                    </Button>
                  </Col>
                </Row>)}
              </Col>
              <Col md="1" className="index-column">
                {_this.checkboxes['check-' + pathIdentifier] && _this.checkboxes['check-' + pathIdentifier].checked &&
                <Button type="button" className="menubutton mt-0 ml-1 mr-1" onClick={() => addLine(pathIdentifier, _this)}>
                  <span className="fa fa-plus"/>
                </Button>}
              </Col>
            </Row>
            {properties.length > 0 && renderClasses(properties, depth + 1, _this)}
          </React.Fragment>)
        })}
        </React.Fragment>
      )
    }
  }

  export function renderEmbedders(roots, depth, _this) {
    if (roots) {
      const style = {
        paddingLeft: (0 + depth*20) + "px",
      }

      return (
        <React.Fragment>
        {roots.map((el, index) => {
          var pathIdentifier = getIdentifier(el);
          var classes = Object.keys(_this.state.checkboxes).filter(k => k.match('^check-' + pathIdentifier + '-c[0-9]+$'))
          return (
          <React.Fragment key={pathIdentifier}>
            <Row className="index-field-row">
              <Col md="5" style={style}>
                <Form.Check id={pathIdentifier}  key={pathIdentifier} ref={node => (_this.checkboxes['check-' + pathIdentifier] = node)}
                        onChange={() => embedderSelected(pathIdentifier, _this)}
                        label = {_this.state.checkboxes[el].embedder}/>
              </Col>
              <Col md="6">
              {_this.checkboxes['check-' + pathIdentifier] && _this.checkboxes['check-' + pathIdentifier].checked &&
               _this.state.checkboxes['check-' + pathIdentifier].lines && _this.state.checkboxes['check-' + pathIdentifier].lines.map(line =>
                <Row>
                  {_this.names &&
                  <Col md="3" className="index-column">
                      <Form.Control className="index-field"
                              key={'name-'+ pathIdentifier} ref={node => (_this.names['names-' + pathIdentifier + "#" + line] = node)}
                              onChange={() => nameChanged(pathIdentifier, line, _this)}
                              defaultValue = ''/>
                  </Col>}
                  {_this.values &&
                  <Col md="3" className="index-column">
                      <Form.Control className="index-field"
                              key={'value-'+ pathIdentifier} ref={node => (_this.values['value-' + pathIdentifier + "#" + line] = node)}
                              onChange={() => _this.enableNewIndex()}
                              />
                  </Col>}
                  {_this.rdftypes &&
                  <Col md="2" className="index-column">
                      <Form.Control className="index-field-m" as="select"
                              key={'rdftype-'+ pathIdentifier} ref={node => (_this.rdftypes['rdfty-' + pathIdentifier + "#" + line] = node)}
                              value='Literal'
                              disabled={true}
                              onChange={() => _this.enableNewIndex()}
                              >
                              <option value="IRI">IRI</option>
                              <option value="Literal">Literal</option>
                      </Form.Control>
                  </Col>}
                  {_this.datatypes &&
                  <Col md="2" className="index-column">
                      <Form.Control className="index-field-m" as="select"
                              key={'datatype-'+ pathIdentifier} ref={node => (_this.datatypes['datat-' + pathIdentifier + "#" + line] = node)}
                              value='dense_vector'
                              disabled={true}
                              onChange={() => _this.enableNewIndex()}
                              >
                              <option value="boolean">boolean</option>
                              <option value="date">date</option>
                              <option value="dense_vector">dense_vector</option>
                              <option value="double">double</option>
                              <option value="float">float</option>
                              <option value="integer">integer</option>
                              <option value="keyword">keyword</option>
                              <option value="long">long</option>
                              <option value="search_as_you_type">search_as_you_type</option>
                              <option value="text">text</option>
                      </Form.Control>
                  </Col>}
                  <Col md="2" className="index-column">
                      {/*<Form.Control className="index-field"
                              key={'language-'+ pathIdentifier} ref={node => (this.languages['langu-' + pathIdentifier + "#" + line] = node)}
                              onChange={() => this.enableNewIndex()}
                              defaultValue = ''/>*/}
                  </Col>
                  <Col md="2" className="index-column">
                  </Col>
                  {/*<Col md="1">
                      <Form.Control className="index-field"
                              key={'target-'+ pathIdentifier} ref={node => (this.targets['targe-' + pathIdentifier + "#" + line] = node)}
                              onChange={() => this.enableNewIndex()}
                              defaultValue = ''/>
                  </Col>*/}
                  <Col md="1" className="index-column">
                    {/*<Button type="button" className="menubutton mt-0" onClick={() => this.deleteLine(pathIdentifier, line)}>
                      <span className="fa fa-minus"/>
                    </Button>*/}
                  </Col>
                </Row>)}
              </Col>
              <Col md="1" className="index-column">
                {/*this.checkboxes['check-' + pathIdentifier] && this.checkboxes['check-' + pathIdentifier].checked &&
                <Button type="button" className="menubutton mt-0 ml-1 mr-1" onClick={() => this.addLine(pathIdentifier)}>
                  <span className="fa fa-plus"/>
                </Button>*/}
              </Col>
            </Row>
            {classes.length > 0 && renderClasses(classes, depth + 1, _this)}
          </React.Fragment>)
        })}
        </React.Fragment>
      )
    }
  }

  export function getCheckboxes(_this) {
    return _this.state.checkboxes
  }

  export function getIdentifier(field) {
      return field.substring(field.indexOf('-') + 1);
  }
