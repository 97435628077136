import { API_BASE_URL, ACCESS_TOKEN } from '../constants/index.js';
import { jsonSuccessFailureRequest, formSuccessFailureRequest } from './APIUtils.js';


export function getComparatorsByUser(datasetId) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  var rq = (datasetId ? '?datasetId=' + datasetId : '');

  return jsonSuccessFailureRequest({
    url: API_BASE_URL + "/comparator/get-all-my" + rq,
    method: 'GET',
  })
}

export function getComparators(datasetId, projectId) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  var rq = (datasetId ? '?datasetId=' + datasetId : '');
  rq += (projectId ? '?projectId=' + projectId : ''); // cannot have both project and dataset !!!

  return jsonSuccessFailureRequest({
    url: API_BASE_URL + "/comparator/get-all" + rq,
    method: 'GET',
  })
}

export function getComparator(id) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  return jsonSuccessFailureRequest({
    url: API_BASE_URL + "/comparator/get/" + id,
    method: 'GET'
  })
}

export function updateComparator(id, name, identifier, description, schemaDatasetId, element, keysMetadata, computation) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  var body = { name, identifier, schemaDatasetId, element, keysMetadata, computation }
   if (description) {
      body = { ...body, description }
    }

  return jsonSuccessFailureRequest({
    url: API_BASE_URL + "/comparator/update/" + id,
    method: 'POST',
    body: JSON.stringify(body),
  })
}

export function createComparator(name, datasetId, identifier, description, schemaDatasetId, element, keysMetadata, computation) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  var body = { name, identifier, schemaDatasetId, element, keysMetadata, computation }
  if (description) {
     body = { ...body, description }
   }

  return jsonSuccessFailureRequest({
    url: API_BASE_URL + "/comparator/new?datasetId=" + datasetId,
    method: 'POST',
    body:  JSON.stringify(body),
  })
}

export function deleteComparator(id) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  return jsonSuccessFailureRequest({
    url: API_BASE_URL + "/comparator/delete/" + id,
    method: 'DELETE'
  });

}

export function checkComparatorIdentifier(identifier) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  return jsonSuccessFailureRequest({
    url: API_BASE_URL + "/comparator/exists-identifier?identifier=" + encodeURIComponent(identifier),
    method: 'GET'
  })
}
