import { API_BASE_URL, ACCESS_TOKEN } from '../constants/index.js';
import { jsonSuccessFailureRequest, formSuccessFailureRequest } from './APIUtils.js';


export function getPrototypesByUser(datasetId, type) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  var rq = (datasetId ? '?datasetId=' + datasetId : '');
  rq += (type ? ((rq.length > 0 ? '&' : '?') + 'type=' + type) : '');

  return jsonSuccessFailureRequest({
    url: API_BASE_URL + "/prototype/get-all-my" + rq,
    method: 'GET',
  })
}

export function getPrototypes(datasetId, type, projectId) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  var rq = (datasetId ? '?datasetId=' + datasetId : '');
  rq += (projectId ? '?projectId=' + projectId : ''); // cannot have both project and dataset !!!
  rq += (type ? ((rq.length > 0 ? '&' : '?') + 'type=' + type) : '');


  return jsonSuccessFailureRequest({
    url: API_BASE_URL + "/prototype/get-all" + rq,
    method: 'GET',
  })
}

export function getPrototypeContent(id) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  return jsonSuccessFailureRequest({
    url: API_BASE_URL + "/prototype/get-content/" + id,
    method: 'GET'
  });

}

export function updatePrototype(id, name, description, data, url, parameters, dependencies, fields) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  var body = {}
  if (name) {
     body = { ...body, name }
   }
   if (description) {
      body = { ...body, description }
    }
   if (url) {
     body = {...body, url }
   }
   if (parameters) {
     body = {...body, parameters }
   }
   if (dependencies) {
     body = {...body, dependencies }
   }
   if (fields) {
     body = {...body, fields }
   }

  var formData = new FormData();

  if (data !== undefined) {
    formData.append('file', data);
  }

  formData.append('json', JSON.stringify(body));

  return formSuccessFailureRequest({
    url: API_BASE_URL + "/prototype/update/" + id,
    method: 'POST',
    body: formData,
  })
}

export function createPrototype(name, datasetId, type, description, data, url, parameters, dependencies, fields) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  var body = { name, type }
  if (description) {
     body = { ...body, description }
   }
  if (url) {
    body = {...body, url }
  }
  if (parameters) {
    body = {...body, parameters }
  }
  if (dependencies) {
    body = {...body, dependencies }
  }
  if (fields) {
    body = {...body, fields }
  }

  var formData = new FormData();
  if (data != null) {
    formData.append('file', data);
  }

  formData.append('json', JSON.stringify(body));

  return formSuccessFailureRequest({
    url: API_BASE_URL + "/prototype/new?datasetId=" + datasetId,
    method: 'POST',
    body: formData,
  })
}

export function deletePrototype(id) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  return jsonSuccessFailureRequest({
    url: API_BASE_URL + "/prototype/delete/" + id,
    method: 'DELETE'
  });

}
